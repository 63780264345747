import {create} from "zustand";
import { INotification } from './Notification';
import { NotificationState } from "./NotificationState";

const _getCount = (notifications:INotification[]) => 
  notifications && notifications.filter((n:any) => !n.deleted ).length;

const _getUnreadCount = (notifications:INotification[]) => 
  notifications && notifications.filter((n:any) => !n.read && !n.deleted ).length;

const isOutageBannerClosed = () => 
  localStorage.getItem('unify-outage-banner-closed')! || false;

const setOutageBannerClose = (flag:string) =>
  localStorage.setItem('unify-outage-banner-closed',flag);

const clearLocalStorageBannerKey = () =>
  localStorage.removeItem('unify-outage-banner-closed');

const _read = (set: any) => (notification:INotification) =>
  set((state: NotificationState) => {
    const notifications = state.notifications.map((item: INotification) => 
         item.messageId === notification.messageId ?  { ...notification, read:true } : item
    );
    return { 
      notifications ,
      count: _getCount(notifications) ,
      unreadCount:_getUnreadCount(notifications)
    };
  });

const _remove = (set: any) => (notification:INotification) =>
  set((state: NotificationState) => {
    const notificationsData = state.notifications.map((item: INotification) => 
          item.messageId === notification.messageId ?  { ...notification, deleted:true } : item
    );
    const notifications = notificationsData.filter((item: INotification) => !item.deleted);
    return { 
      notifications ,
      count: _getCount(notifications) ,
      unreadCount:_getUnreadCount(notifications)
    };
});

const _setNotifications  = (set: any) => (notificationsData:INotification[]) =>
  set(() => {
    const notifications = notificationsData.filter((item: INotification) => !item.deleted);
    return { 
      notifications ,
      count: _getCount(notifications),
      unreadCount:_getUnreadCount(notifications),
      outageNotifications : _setoutageNotifications(notifications)
    };
  });

const _updateNotifications  = (set: any) => (notifications:INotification[]) =>
  set((state: NotificationState) => {
    const newNotifications = notifications || [];
    const existingNotification = state.notifications ;
    if(existingNotification.length < newNotifications.length){
      const oldOutageNotificationCount = existingNotification.filter((v:INotification) => v.type === 'outage');
      const newOutageNotificationCount = newNotifications.filter((v:INotification) => v.type === 'outage');
      if (oldOutageNotificationCount < newOutageNotificationCount){
        setOutageBannerClose('false');
      }
    }
    return { 
      notifications ,
      count: _getCount(notifications),
      unreadCount:_getUnreadCount(notifications),
      outageNotifications : _setoutageNotifications(notifications)
    };
  });

const _closeOutageBanner  = (set: any) => () =>
  set(() => {
  setOutageBannerClose('true');
  return { 
    outageNotifications :[]
  };
});

const _reset = (set : any ) => () =>
  set((state:NotificationState) =>{
    clearLocalStorageBannerKey();
    return {
      outageNotifications : _setoutageNotifications(state.notifications)
    }
  });

const _setoutageNotifications = (notifications: INotification[]) =>{
  const isBannerClosed = isOutageBannerClosed();
  notifications = notifications || [];
  const outageNotifications = notifications.filter((v:INotification) => v.type === 'outage');
  return isBannerClosed === 'true' ? [] : outageNotifications;
}
  
export const useNotificationStore = create<NotificationState>((set) => ({
   count:0,
   unreadCount:0,
   shouldNotificationRefresh:false,
   outageNotifications:[],
   notifications:[],
   read: _read(set),
   remove: _remove(set),
   closeOutageBanner: _closeOutageBanner(set),
   setNotifications: _setNotifications(set),
   updateNotifications: _updateNotifications(set),
   reset: _reset(set),
   setshouldNotificationRefresh: (shouldNotificationRefresh:any) => set({ shouldNotificationRefresh }),
 }));
