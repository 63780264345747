import {useUnifyAdminStore} from '../../AdminStore';
import {CampaignLinkInputWithHelperText} from '../Blocks/CampaignLinkInputWithHelperText'

export const CampaignLinkField = (): JSX.Element => {
    /**
     * Data Store
     */
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore((state) => state.updateCreateCampaignData);
    const permissions = useUnifyAdminStore(state => state.permissions); 

    const handleUpdateLinkField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateCreateCampaignData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <>
            <CampaignLinkInputWithHelperText
                dataTestId="campaign-link-input"
                errorMessage="Please enter link"
                id="campaign-link-abbr-input"
                inputDivider="left"
                label="Campaign link "
                name="link"
                onChange={handleUpdateLinkField}
                placeholder="Enter campaign URL"
                value={campaignItems.link}
                isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
                isRequired
                helperLabel="The 'Learn More' button link"
            />
        </>
    );
};
