import { APP_ENV, BYPASS_AUTH } from '../constants';
import { OktaAuth } from '@okta/okta-auth-js';

enum Environments {
  'us-dit' = 'us-dit',
  'us-stage' = 'us-stage',
  'us-perf' = 'us-perf',
  'us-prod' = 'us-prod',
  'develop' = 'develop',
}

const clientId = {
  'us-dit': '0oa18b1zpbtWJde4q0h8',
  develop: '0oa18b1zpbtWJde4q0h8',
  'us-stage': '0oa18b1zpbtWJde4q0h8',
  'us-perf': '0oa18b1zpbtWJde4q0h8',
  'us-prod': '0oaphqy0vfFEv2fzh1t7',
};

const issuer = {
  'us-dit': 'https://connectcdk-dit.oktapreview.com/oauth2/default',
  develop: 'https://connectcdk-dit.oktapreview.com/oauth2/default',
  'us-perf': 'https://connectcdk-dit.oktapreview.com/oauth2/default',
  'us-stage': 'https://connectcdk-dit.oktapreview.com/oauth2/default',
  'us-prod': 'https://connectcdk.okta.com/oauth2/default',
};

const authorizeUrl = {
  'us-dit': 'https://login-dit.connectcdk.com/login/authorize',
  develop: 'https://login-dit.connectcdk.com/login/authorize',
  'us-perf': 'https://login-dit.connectcdk.com/login/authorize',
  'us-stage': 'https://login-dit.connectcdk.com/login/authorize',
  'us-prod': 'https://login.connectcdk.com/login/authorize',
};

const tokenUrl: Record<Environments, string> = {
  'us-dit': 'https://cml-api-nginx.global-dit.connectcdk.com/token',
  develop: 'https://cml-api-nginx.global-dit.connectcdk.com/token',
  'us-perf': 'https://cml-api-nginx.global-dit.connectcdk.com/token',
  'us-stage': 'https://cml-api-nginx.global-dit.connectcdk.com/token',
  'us-prod': 'https://api.connectcdk.com/api/cs-common-login-api/v1/token', // CAR-829 - will be taken care for prod HA IAM change
};

const okta = {
  issuer: issuer[APP_ENV as unknown as keyof  typeof Environments],
  clientId: clientId[APP_ENV  as unknown as keyof  typeof Environments],
  redirectUri: `${window.location.origin}/login/callback`,
  authorizeUrl: authorizeUrl[APP_ENV  as unknown as keyof  typeof Environments],
  tokenUrl: tokenUrl[APP_ENV  as unknown as keyof  typeof Environments],
};

export const OKTA_ME_URLS: Record<Environments, string> = {
  'us-dit': 'https://connectcdk-dit.oktapreview.com/api/v1/users/me',
  develop: 'https://connectcdk-dit.oktapreview.com/api/v1/users/me',
  'us-perf': 'https://connectcdk-dit.oktapreview.com/api/v1/users/me',
  'us-stage': 'https://connectcdk-dit.oktapreview.com/api/v1/users/me',
  'us-prod': 'https://connectcdk.okta.com/api/v1/users/me',
};

export const OKTA_LOGOUT_URLS: Record<Environments, string> = {
  'us-dit': 'https://login-dit.connectcdk.com/sso/logoff.html?source=unify',
  develop: 'https://login-dit.connectcdk.com/sso/logoff.html?source=unify',
  'us-perf':
    'https://login-dit.connectcdk.com/sso/logoff.html?source=unify',
  'us-stage':
    'https://login-dit.connectcdk.com/sso/logoff.html?source=unify',
  'us-prod': 'https://login.connectcdk.com/sso/logoff.html?source=unify',
};

export const OKTA_SESSION_URLS: Record<Environments, string> = {
  'us-dit': 'https://connectcdk-dit.oktapreview.com/api/v1/sessions/me',
  develop: 'https://connectcdk-dit.oktapreview.com/api/v1/sessions/me',
  'us-perf': 'https://connectcdk-dit.oktapreview.com/api/v1/sessions/me',
  'us-stage': 'https://connectcdk-dit.oktapreview.com/api/v1/sessions/me',
  'us-prod': 'https://connectcdk.okta.com/api/v1/sessions/me',
};

export default function createOktaInstance() {
  return BYPASS_AUTH ? {} : new OktaAuth(okta);
}
