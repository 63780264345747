import moment from "moment";

/***
 * @author @Ashif
 * @param apiTime
 * @returns apiTime as "now, x mins ago, x hours ago, yesterday and more than 2 days old
 */
export function getTimeDiffText(apiTime: any, t: Function): string {

    const parsedApiTime = moment.utc(apiTime).local();

    const currentLocalTime = moment();

    const timeDiffMin = currentLocalTime.diff(parsedApiTime, 'minutes');
    const timeDiffHrs = currentLocalTime.diff(parsedApiTime, 'hours');

    // Case 1: "Now" if time is within a minute
    if (timeDiffMin === 0) {
        return t('notification.time.now');
    }

    // Case 2: "x min ago" if time is old x min
    if (timeDiffMin > 0 && timeDiffMin < 2) {
        return t('notification.time.minago', { time: timeDiffMin });
    }

    if (timeDiffMin > 1 && timeDiffMin < 60) {
        return t('notification.time.minsago', { time: timeDiffMin });
    }

    // Case 3: "x hours ago" if time is old x hours and less than 24 hours
    if (timeDiffHrs > 0 && timeDiffHrs < 2) {
        return t('notification.time.hourago', { time: timeDiffHrs });
    }

    if (timeDiffHrs > 1 && timeDiffHrs < 24) {
        return t('notification.time.hoursago', { time: timeDiffHrs });
    }

    // Case 4: "yesterday" if the time is a day old
    if (timeDiffHrs >= 24 && timeDiffHrs < 48) {
        return t('notification.time.yesterday', { time: parsedApiTime.format("h:mm A") });
    }

    // Additional case: for over 2 days, show the exact date (optional and can be adjusted as per requirements)
    return parsedApiTime.format('MM/DD/YYYY h:mm A');
}

