import { useState, useEffect } from 'react';
import { UnifyAdminContainer } from './index';
import * as StyledComponents from '../StyledComponents';
import UnifyAdminMainTitle from '../Blocks/UnifyAdminMainTitle';
import AdminContainerTab from '../Blocks/AdminContainerTab';
import CMSAdminContainer from '../CampaignManagement/Containers/CMSAdminContainer';
import { useUnifyAdmin } from '../Hooks/useUnifyAdmin';
import { useUnifyAdminStore } from '../AdminStore';
import OutageAdminContainer from '../Notification/Containers/Outage/OutageAdminContainer';
import FeatureAdminContainer from '../Notification/Containers/Feature/FeatureAdminContainer'

export default function UnifyMainContainer(): JSX.Element {

  const { permissions:adminPermissions } = useUnifyAdmin();

  const setPermissions = useUnifyAdminStore(state => state.setPermissions);
  const permissions = useUnifyAdminStore(state => state.permissions); 

  const [title, setTitle] = useState('');
  const [component, setComponent] = useState(<AdminContainerTab />);

  const showEnvironmentChangeToast = useUnifyAdminStore(
    (state) => state.showToast
  );
  const showApplicationUpdateToast = useUnifyAdminStore(
    (state) => state.showCustomContentToast
  );
  const setShowApplicationUpdateToast = useUnifyAdminStore(
    (state) => state.setShowCustomContentToast
  );
  const customToastContent = useUnifyAdminStore(
    (state) => state.customToastContent
  );
  const setShowToast = useUnifyAdminStore((state) => state.setShowToast);
  const selectedEnvironment = useUnifyAdminStore(
    (state) => state.selectedEnvironment
  );

  useEffect(() => {
      setPermissions(adminPermissions);
    // eslint-disable-next-line 
  }, [setPermissions]);

  useEffect(() => {
    let hasApplicationsPermissions =
    permissions?.hasAppReadOnlyPermissions ||
    permissions?.hasAppUpdatePermissions ||
    permissions?.hasAppSuperAdminPermissions;
    let hasCampaignPermissions =
    permissions?.hasCampaignReadOnlyPermissions ||
    permissions?.hasCampaignUpdatePermissions ||
    permissions?.hasCampaignSuperAdminPermissions;
    let hasProductAlertPermissions =
    permissions?.hasProductAlertReadOnlyPermissions ||
    permissions?.hasProductAlertUpdatePermissions ||
    permissions?.hasProductAlertSuperAdminPermissions;
    let hasReleaseNotificationPermissions =
    permissions?.hasReleaseReadOnlyPermissions ||
    permissions?.hasReleaseUpdatePermissions ||
    permissions?.hasReleaseSuperAdminPermissions;
      

    const trueVariables = Object.entries({
      hasApplicationsPermissions,
      hasCampaignPermissions,
      hasProductAlertPermissions,
      hasReleaseNotificationPermissions
    }).filter(([_, value]) => value === true);

    if (trueVariables.length === 1) {
      if (hasApplicationsPermissions) {
        setTitle('- Applications');
        setComponent(
          <StyledComponents.StyledCampaignStandaloneWrapper>
            <UnifyAdminContainer />
          </StyledComponents.StyledCampaignStandaloneWrapper>
        );
      }
      if (hasCampaignPermissions) {
        setTitle('- Campaigns');
        setComponent(
          <StyledComponents.StyledCampaignStandaloneWrapper>
            <CMSAdminContainer />
          </StyledComponents.StyledCampaignStandaloneWrapper>
        );
      }
      if (hasProductAlertPermissions) {
        setTitle('- Product Alerts');
        setComponent(
          <StyledComponents.StyledCampaignStandaloneWrapper>
            <OutageAdminContainer />
          </StyledComponents.StyledCampaignStandaloneWrapper>
        );
      }
      if (hasReleaseNotificationPermissions) {
        setTitle('- Feature Release');
        setComponent(
          <StyledComponents.StyledCampaignStandaloneWrapper>
             <FeatureAdminContainer />
          </StyledComponents.StyledCampaignStandaloneWrapper>
        );
      }
    }
  }, [
    permissions?.hasAppReadOnlyPermissions,
    permissions?.hasAppUpdatePermissions,
    permissions?.hasAppSuperAdminPermissions,
    permissions?.hasCampaignReadOnlyPermissions,
    permissions?.hasCampaignUpdatePermissions,
    permissions?.hasCampaignSuperAdminPermissions,
    permissions?.hasProductAlertReadOnlyPermissions,
    permissions?.hasProductAlertUpdatePermissions,
    permissions?.hasProductAlertSuperAdminPermissions,
    permissions?.hasReleaseReadOnlyPermissions,
    permissions?.hasReleaseUpdatePermissions,
    permissions?.hasReleaseSuperAdminPermissions,
  ]);

  return (
    <StyledComponents.StyledAdminMainContainer>
      {showEnvironmentChangeToast && (
        <StyledComponents.StyledToast
          position="fixed"
          content={`You have selected: ${selectedEnvironment.label} Environment`}
          visibilityDuration={6000}
          onVisibilityDurationEnd={() => setShowToast(false)}
        />
      )}
      {showApplicationUpdateToast && (
        <StyledComponents.StyledToast
          variant={
            customToastContent.type === 'Error' ? 'negative' : 'positive'
          }
          position="fixed"
          content={customToastContent.message}
          visibilityDuration={6000}
          onVisibilityDurationEnd={() => setShowApplicationUpdateToast(false)}
        />
      )}
      <UnifyAdminMainTitle title={title} />
      {component}
    </StyledComponents.StyledAdminMainContainer>
  );
}
