import { useUnifyAdminStore } from '../../AdminStore';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { Button, BUTTON_VARIANTS } from 'cdk-radial';

export const CustomOutageDeleteRenderer = (): JSX.Element => {
    const setDeleteDialogOpen = useUnifyAdminStore(
      (state) => state.setDeleteDialogOpen
    );
    const onDeleteClick = (
      event: React.MouseEvent,
      params: { api: GridApi; columnApi: ColumnApi }
    ) => {
      setDeleteDialogOpen(true);
    };
  
    return (
      <Button
        text="Delete"
        variant={BUTTON_VARIANTS.TEXT}
        onClick={onDeleteClick}
      />
    );
};


export const CustomReleaseDeleteRenderer = (): JSX.Element => {
  const setReleaseDeleteDialogOpen = useUnifyAdminStore(
    (state) => state.setReleaseDeleteDialogOpen
  );
  const onDeleteClick = (
    event: React.MouseEvent,
    params: { api: GridApi; columnApi: ColumnApi }
  ) => {
    setReleaseDeleteDialogOpen(true);
  };

  return (
    <Button
      text="Delete"
      variant={BUTTON_VARIANTS.TEXT}
      onClick={onDeleteClick}
    />
  );
};
