import { useAnalytics } from 'dms-analytics';
import { usePaneManagement } from '../../contexts/PaneManagementContext';
import * as Styles from './styles';
import HistoryItem from '../Cards/OpenFramesCard';
import { useAllWorkflowsAndApplicationsById } from '../../hooks/queries';
import { AMPLITUDE_EVENTS } from '../../constants';
import { PaneData } from '../../types';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import { useSideSheet } from '../../contexts/SideSheet';
import {useTranslation} from 'react-i18next';
import { useTheme } from '../../contexts/Theme';

export default function HistoryContent() {
  const { openPanes, clearOpenPanes, openPane, activePane, closePane, setActivePane } = usePaneManagement();
  const panes = useAllWorkflowsAndApplicationsById(openPanes);
  const { trackEvent } = useAnalytics();
  const { removeProduct } = useChannelMessage();
  const { setSideSheetContent } = useSideSheet();
  const { t } = useTranslation();
  const { darkMode } = useTheme();

  const handleOpenClick = (pane: PaneData) => {
    trackEvent(AMPLITUDE_EVENTS.OPEN_HISTORY, { 'History Item': pane.name });
    openPane(pane.id);
    setSideSheetContent(null);
  };
  const handleCloseClick = (paneId: string) => {
    closePane(paneId);
    setActivePane(null);
    removeProduct({ id: paneId });
  };

  const handleCloseAllClick = (paneId: string) => {
    panes.forEach((pane) => {
      closePane(pane.id); 
      removeProduct({ id: pane.id });
    });
    clearOpenPanes();
    setActivePane(null);
  };

  return (
    <Styles.HistoryContentWrapper data-testid="history-content">
      {panes.length > 1 &&
      <Styles.CloseAllButtonContainer>
      <Styles.CloseAllButton data-testid="history-close-all"
            onClick={handleCloseAllClick}
            text={t('history.closeAll')}
            darkMode={darkMode}/>
        </Styles.CloseAllButtonContainer>}
      {panes.map((pane) => (
        <HistoryItem
          key={pane.id}
          iconColor={pane.icon}
          iconText={pane.iconText}
          isActive={activePane === pane.id}
          name={pane.name}
          onCloseClick={() => handleCloseClick(pane.id)}
          onOpenClick={() => handleOpenClick(pane as PaneData)}
        />
      ))}
    </Styles.HistoryContentWrapper>
  );
}
