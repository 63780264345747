import { EnumValues } from './types/utility';
import { getBffUrl } from './util/getBffUrl';

export const SIDESHEET_CONTENT = {
  HELP: 'help',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  HISTORY: 'history',
  NOTIFICATION: 'notification',
  MEET_MY_CDK_TEAM: 'meet_my_cdk_team'
} as const;

export type SideSheetContent = EnumValues<typeof SIDESHEET_CONTENT>;

export const ENVIRONMENTS = {
  LOCAL: 'develop',
  STAGE: 'us-stage',
  DEV: 'us-dev',
  DIT: 'us-dit',
  PERF: 'us-perf',
  PROD: 'us-prod',
} as const;

export type Environments = EnumValues<typeof ENVIRONMENTS>;

export const URL_ROUTES = {
  workflows: '/workflows',
  applications: '/applications',
  myunify: '/myUnify',
  unifyAdmin: '/admin',
};


export const UNIFY_ADMIN_ROUTERS = {
  applicationAdmin: '/admin/applications',
  campaignAdmin: '/admin/campaigns',
  productOutageAdmin: '/admin/outages'
}

export const LOCAL_BASE_URL = process.env.REACT_APP_BFF;
export const PROD_BASE_URL = getBffUrl();
export const BYPASS_AUTH =
  process.env.REACT_APP_BYPASS_AUTH || process.env.NODE_ENV === 'test';
export const APP_ENV = process.env.REACT_APP_ENV || 'develop';
export const DRIVE_TITLE_ID = 'DMSDRIVE';
export const UNIFYADMIN_APP_ID = `${process.env.REACT_APP_UNIFYADMIN_APP_ID}`;
export const LOGOUT_URL = `${process.env.REACT_APP_LOGOUT_URL}`;
export const AG_GRID_LICENSE = `${process.env.REACT_APP_AG_GRID_LICENSE}`;
export const REACT_APP_NEWRELIC_LICENSE = `${process.env.REACT_APP_NEWRELIC_LICENSE}`;
export const REACT_APP_HARNESS = `${process.env.REACT_APP_HARNESS}`;
export const REACT_APP_DWM_ID = `${process.env.REACT_APP_DWM_ID}`;
export const REACT_APP_AHA_IDEA_WIDGET = `${process.env.REACT_APP_AHA_IDEA_WIDGET}`;
export const REACT_APP_AHA_APPLICATION_ID = `${process.env.REACT_APP_AHA_APPLICATION_ID}`;

export enum AMPLITUDE_EVENTS {
  VIEW_UNIFY_PAGE = 'Open Unify Page',
  OPEN_APPLICATION = 'Select Application',
  OPEN_WORKFLOW = 'Select Workflow',
  VIEW_MY_UNIFY = 'Open My Unify',
  VIEW_APPLICATIONS = 'Open Applications',
  VIEW_WORKFLOWS = 'Open Workflows',
  LOGIN_UNIFY = 'Log in Unify',
  SINGLE_LOGIN_UNIFY = 'Single Log in Unify',
  LOGOUT_UNIFY = 'Log out Unify',
  OPEN_CAROUSEL = 'Open Carousel Item',
  OPEN_HISTORY = 'Open History',
  OPEN_HELP = 'Open Help',
  OPEN_PROFILE = 'Open Profile',
  OPEN_PREVIOUS = 'Open Previous Application',
  OPEN_FEEDBACK = 'Open Feedback',
  VIEW_NOTIFICATIONS = 'Open Notifications',
  VIEW_NOTIFICATION_DETAIL = 'Open Notification Detail',
  USER_GENERATED_CARD = 'Create New Link',
  OPEN_DRIVE = 'Open Drive',
  OPEN_MEET_MY_CDK_TEAM = 'Open Meet My CDK Team'
}

export const SUPPORT_LINK =
  'https://www.cdkglobal.com/support-service-connect-top-level-support';

export const SPOKE_USERS = 'Spoke Users';

export enum APP_URL {
  'us-dev' = 'https://app-unify.app-dev.connectcdk.com',
  'us-dit' = 'https://app-unify.app-dit.connectcdk.com',
  'us-stage' = 'https://app-unify.app-stage.connectcdk.com',
  'us-perf' = 'https://app-unify.app-perf.connectcdk.com',
  'us-prod' = 'https://app-unify.app.connectcdk.com',
  'develop' = 'http://localhost:8080'
}


export const appUrlDiscovery = (environment: 'us-dev' | 'us-dit' | 'us-stage' | 'us-perf' | 'us-prod' | 'develop'): string => APP_URL[environment]



