import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface WorkflowContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isExpanded: boolean;
  darkMode: boolean| null;
  closedHeight?: string;
}

const StyledWorkflowContainer = styled.div<{
  darkMode: boolean | null;
  isExpanded: boolean,
  height?: string
}>`
  height: ${({ isExpanded, height }) => (isExpanded ? 'auto' : height || '52px')};
  border-radius: 4px;
  overflow: ${({ isExpanded }) => (isExpanded ? 'auto' : 'hidden')};
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid;
  border-color: ${({ darkMode }) => (darkMode ? '#333' : '#e6e6e6')};
  transition: border-color 1s ease-out;
  @media (max-width: 470px) {
    padding:16px 5px;
    width: 90vw;
  }
  @media (max-width: 390px) {
    padding:16px 5px;
  }
`;

const WorkflowContainer: React.FC<WorkflowContainerProps> = ({
  children,
  isExpanded,
  darkMode,
  closedHeight
}) => {
  return (
    <StyledWorkflowContainer
      data-testid="workflow-container"
      darkMode={darkMode}
      isExpanded={isExpanded}
      height={closedHeight}
    >
      {children}
    </StyledWorkflowContainer>
  );
};

export default WorkflowContainer;
