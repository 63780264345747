import React, {useState} from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import {IWorkflow} from '../AdminStore/slices/types/types'
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';
import find from 'lodash/find';

type AccordionItems = {
    content: string;
    id: string;
    title: string;
};

type Workflow = {
    workflowId: string;
    name: string;
    domain: string;
    description: string;
    url: string;
    applications: Record<string, string>[];
};

const mapWorkflowPaneOptions = (wrokflows: Workflow[]) => {
    return wrokflows.map((eachWorkflow: Partial<Workflow>) => {
        return {
            content: eachWorkflow.name,
            id: eachWorkflow.workflowId,
            title: eachWorkflow.name,
        };
    });
};

export const AdminWorkflowExplorer = (): JSX.Element => {
    /**
     * Local States
     */
    const [openPanels, setOpenPanels] = useState<AccordionItems[]>([]);

    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const setSideSheetDataSubWorkflow = useUnifyAdminStore(
        (state) => state.setSideSheetDataSubWorkflow
    );
    const setWorkflowSideSheetCollapse = useUnifyAdminStore(
        (state) => state.setWorkflowSideSheetCollapse
    );

    /**
     * Functional constants
     */
    const associatedWorkflows = get(sideSheetData, 'workflows', []);

    const handlePanelToggle = (id: string) => {
        const expandedWorkflow = find(
            get(sideSheetData, 'workflows', []),
            (eachWorkflow: IWorkflow) => eachWorkflow.workflowId === id
        );
        setWorkflowSideSheetCollapse(false);
        setSideSheetDataSubWorkflow(expandedWorkflow as unknown as IWorkflow);

        const isOpen = openPanels[id as unknown as number];

        // @ts-ignore
        setOpenPanels({
            [id]: isOpen ? !isOpen : isOpen, // open just once
        });
    };

    return (
        <StyledComponents.StyledSimpleAccordion
            items={mapWorkflowPaneOptions(associatedWorkflows as Workflow[])}
            onPanelToggle={handlePanelToggle}
            openPanels={openPanels}
            showDivider
        />
    );
};
