import { useEffect, useState } from 'react';
import { MessageContext } from './MessageContext';

export type ProductWindow = {
  origin: string;
  frameWindow: Window;
  id: string;
  acknowledged: boolean;
  logoutUrl: string;
};

export enum MESSAGES {
  CLIENT_READY = 'CLIENT_READY',
  INIT_PRODUCT = 'INIT_PRODUCT',
  INIT_ACKNOWLEDGE = 'INIT_ACKNOWLEDGE',
  LOGOUT = 'LOGOUT',
}

export enum LOGOUT_STATUS {
  LOGOUT_USERCONFIRM = 'LOGOUT_USERCONFIRM',
  LOGOUT_INIT = 'LOGOUT_INIT',
  LOGOUT_PROGRESS = 'LOGOUT_PROGRESS',
  APPS_COMPLETE = 'APPS_COMPLETE',
  NONE = 'NONE',
}

export enum SLO_MODE {
  MESSAGING = 'MESSAGING',
  END_POINTS = 'END_POINTS',
}

export const MessageProvider = (props: any) => {
  const unifyOrigin = document.URL;
  const [productWindows, setProductWindows] = useState<ProductWindow[]>([]);
  const [logoutStatus, setLogoutStatus] = useState(LOGOUT_STATUS.NONE);
  const [sloMode, setSloMode] = useState(SLO_MODE.END_POINTS);

  const initClient = () => {
    const prodWins = productWindows.filter(prod => prod.acknowledged !== true)
    prodWins.forEach(prodWin => prodWin.frameWindow.postMessage({ id: prodWin.id, action: MESSAGES.INIT_PRODUCT, origin: unifyOrigin }, prodWin.origin))
  }

  const notifyMessage = (msg: { action: string, payload: any }) => {
    productWindows
      ?.filter((prod) => prod.acknowledged)
      .forEach((prodWin) => {
        prodWin.frameWindow.postMessage(
          {
            id: prodWin.id,
            action: msg.action,
            payload: msg.payload,
            origin: unifyOrigin,
          },
          prodWin.origin
        );
      });
  }

  /***
   * Initializing the message listener from loaded apps, so far we are listening LOGOUT action,
   * and initiating the other applications to logout.
   */
  const onMessage = (evt: MessageEvent) => {
    try {
      const { id, action, payload } = evt.data;
      if (id) {
        switch (action) {
          case MESSAGES.CLIENT_READY:
            initClient();
            break;

          case MESSAGES.INIT_ACKNOWLEDGE:
            updateProductAcknowledge(id);
            break;

          case MESSAGES.LOGOUT:
            setLogoutStatus(LOGOUT_STATUS.LOGOUT_INIT);
            break;

          default:
            notifyMessage({ action, payload });
            break;
        }
      }
    }
    catch (err: any) {
      console.warn('Unify Messaging - addProduct Error', err.stackTrace)
    }
  };

  const updateProductAcknowledge = (id: string) => {
    const prodWIndow = productWindows.filter((prod) => prod.id === id)[0];
    prodWIndow && (prodWIndow.acknowledged = true);
  };

  /***
   * This method is called when app/workflow loaded into iFrame,
   * The frame information is stored into loaded frames list.
   * INIT message is sent to loaded app, and the loaded app will acknoledge with INIT_ACKNOWLEDGE
   * message,
   */
  const addProduct = (prod: ProductWindow) => {
    try {
      const { id, origin } = prod;
      const prodWin = productWindows.filter((win) => win.id === id)[0];
      const prodWindows = productWindows.concat(prod);

      if (prodWin) {
        prodWin.origin = origin
      }
      else {
        setProductWindows(prodWindows);
      }

      if (sloMode === SLO_MODE.MESSAGING) {
        prod.frameWindow.postMessage(
          { id, action: MESSAGES.INIT_PRODUCT, origin: unifyOrigin },
          origin
        );
      }
    }
    catch (err: any) {
      console.warn('Unify Messaging - addProduct Error', err.stackTrace);
    }
  };

  const removeProduct = (prod: ProductWindow) => {
    const openedFrames = productWindows?.filter((win) => win.id !== prod.id);
    setProductWindows(openedFrames);
  };

  const messagingLogout = () => {
    /* if message is triggered by any loaded app, then exempt that app from sending LOGOUT message,
     *  because it's logout started already after its message to Unify.
     */
    try {
      notifyMessage({ action: MESSAGES.LOGOUT, payload: null });
      setLogoutStatus(LOGOUT_STATUS.LOGOUT_PROGRESS);
      setTimeout(
        () => setLogoutStatus(LOGOUT_STATUS.APPS_COMPLETE),
        productWindows.length > 0 ? 5000 : 0
      );
    }
    catch (err: any) {
      console.warn('Unify Messaging - messagingLogout', err.stackTrace)
    }
  };

  const endPointLogout = () => {
    try {
      const endPointsCount = productWindows.filter(
        (prod) => prod.logoutUrl && prod.logoutUrl !== ''
      ).length;
      setLogoutStatus(LOGOUT_STATUS.LOGOUT_PROGRESS);
      productWindows.forEach((product) => {
        product.logoutUrl &&
          ((document.getElementById(product.id) as HTMLIFrameElement).src =
            product.logoutUrl);
      });

      setTimeout(
        () => setLogoutStatus(LOGOUT_STATUS.APPS_COMPLETE),
        endPointsCount > 0 ? 5000 : 0
      );
    }
    catch (err: any) {
      console.warn('Unify Messaging - messagingLogout', err.stackTrace)
    }
  };

  /***
   * This method is invoked when loggout triggred and also  LOGOUT messsage is received from
   * any of the loaded app, so that all loaded apps informed to LOGOUT message to forse their logout process.
   */
  const logoutProducts = () => {
    sloMode === SLO_MODE.MESSAGING ? messagingLogout() : endPointLogout();
  };

  useEffect(() => {
    const msgListener = function (evt: any) {
      onMessage(evt)
    }
    if (sloMode === SLO_MODE.MESSAGING) {
      window.addEventListener('message', msgListener);
      return () => {
        window.removeEventListener('message', msgListener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sloMode, productWindows]);

  return (
    <MessageContext.Provider
      value={{
        logoutProducts,
        addProduct,
        removeProduct,
        setLogoutStatus,
        setSloMode,
        logoutStatus,
        openedProducts: productWindows,
      }}
    >
      {props.children}
    </MessageContext.Provider>
  );
};
