import styled from 'styled-components';

export const TabsHeader = styled.ul`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.unify.colors.white : theme.unify.colors.black};
  color: ${({ theme }) => theme.unify.colors.black};
  list-style-type: none;
  display: flex;
  padding: ${({ theme }) => theme.unify.spacing.xs};
  border-radius: ${({ theme }) => theme.unify.borderRadius.md};
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-weight: ${({ theme }) => theme.unify.typography.header.fontWeight};
  :hover {
    cursor: pointer;
  }
  @media (max-width: 470px) {
    width: 361px;
  }
`;

export const Tab = styled.li<{ active: boolean; name: string }>`
  padding: 8px 24px;
  border-radius: ${({ theme }) => theme.unify.borderRadius.sm};
  background: ${({ theme, active }) =>
    active && theme.darkMode
      ? theme.unify.colors.black
      : active && theme.unify.colors.white};
  color: ${({ theme, active }) =>
    theme.darkMode
      ? active && theme.unify.colors.white
      : active
      ? theme.unify.colors.black
      : theme.unify.colors.white};
  @media (max-width: 500px) {
        padding: 8px 1px 8px 16px;
        width: ${({ name }) =>
         name === 'myUnify' ? '96px' :
         name === 'applications' ? '103px' :
         name === 'workflows' ? '130px' :
         'auto'};
      }
`;
