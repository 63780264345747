import React from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { combinedTheme, themes } from '../../theme/theme';
import { useUserSettings } from '../../hooks/queries';
import { ThemeContext } from '../../contexts/Theme';

export default function ThemeProvider({
  children,
}: {
  children: JSX.Element | React.ReactNode;
}) {
  const { data } = useUserSettings();
  const [styles, setStyles] = React.useState(combinedTheme);

  const isDarkMode = data?.theme === 'dark';

  React.useEffect(() => {
    setStyles((style) => {
      const newStyle = { ...style };
      newStyle.carbon.color = isDarkMode
        ? themes.default.color.dark
        : themes.default.color.light;
      newStyle.darkMode = isDarkMode;
      document.body.style.backgroundColor = newStyle.carbon.color.element.backplane.backgroundColor;
      return newStyle;
    });
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode: isDarkMode }}>
      <StyledProvider theme={styles}>{children}</StyledProvider>
    </ThemeContext.Provider>
  );
}
