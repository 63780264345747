import styled, { css } from 'styled-components';
import {
    TableContainer,
    SideSheetContainer,
    IconClose,
    IconFileDownload,
    PageHeaderWrapper,
    Heading,
    Button,
    SearchInput,
    Menu,
    Content,
    Input,
    SecondaryButton,
    SimpleAccordion,
    TextButton,
    TextArea,
    IconChevronLeft,
    Toast,
    Divider,
    SimpleTableData,
    Dialog,
    TabPanel,
    Tab,
    Tabs,
    CustomScrollbarWrapper
} from 'cdk-radial';

export const StyledAdminWrapper = styled.div`
  height: 94vh;
  position: relative;
  z-index: 105;
  width: 100%;
`;
export const StyledAdminContainer = styled.div`
  position: relative;
  z-index: 104;
  display: flex;
  flex-direction: column;
`;
export const StyledAdminTableContainer = styled(TableContainer)`
  height: 70vh;
`;
export const StyledAdminPageHeader = styled(PageHeaderWrapper)
`display:flex;
justify-content: space-between;
margin:15px;
`
;

export const StyledAdminPageTitle = styled.div`
  display: flex;
  width:75%;
`;

export const StyledEnvironmentSelector = styled.div`
`

export const StyledHeading = styled(Heading)`
  margin: 10px 0;
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  color: ${({color}) => color};
  padding-right: 10px;
`;

export const StyledSubHeading = styled(Heading)`
  margin: 10px 0;
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  color: ${({color}) => color};
  padding-right: 10px;
  padding-left:17px;
  postion:relative;
`;

export const StyledCampaignHeading = styled(Heading)`
  margin: 10px 0;
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  color: ${({color}) => color};
  height: 44px;
  font-weight: 500;
  font-size: 20px;
`;

export const StyledButtonWithIcon = styled(Button)`
  height: 32px;
  min-height: 32px;
  display: flex;
  flex-direction: row-reverse;
  padding-left: .8rem;
  padding-right: 0;
  max-width: 15%;
  margin: 17px 0px;
  margin-left:17px;

  svg {
    padding: 5px;
  }

  @media (max-width: 1370px) {
         min-width:205px;
  }
`;

export const StyledIconFileDownload = styled(IconFileDownload)`
  cursor: pointer;
  padding-right:25px;
  `

export const StyledTextButton = styled(TextButton)`
  margin: 5px;
`;

export const StyledSearchBox = styled(SearchInput)`
  width: 40%;
  height: 20px;
  left: 17px;
  min-height: 20px;
`;

export const StyledAdminGridTableHeaderContainer = styled.div``;
export const StyledSearchBoxContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0rem 0rem;
  padding-left: 0px;
`;

export const StyledSearchBoxCampaignContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0rem 0rem;
  padding-left: 0px;
  height: 75px;
`;

export const StyledChipFilterContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledSkeletonContainer = styled.div``;

export const StyledTableBodyWrapper = styled.div`
  width: 100%;
  height: 300rem;

  .ag-root-wrapper {
    border: none !important;
  }

  .ag-pivot-off .ag-header-cell {
    font-size: 17px;
  }

  .ag-cell-wrapper{
    overflow: hidden;
  }

  .ag-menu-option{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-icon{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-tabs-header{
      background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  }
  .ag-menu-header{
      background-color: ${({ theme }) => (theme.darkMode ?'#1A1A1A' : '#f2f2f2')};
  }

  .ag-column-select-column-label{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }
`;

export const StyledSVGContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMenu = styled(Menu)`
  z-index: 105 !important;
  width: 100%;
  max-width: 20% !important;

`;

export const StyledDataCellText = styled(Content)`
  font-weight: ${({fontWeight}) => fontWeight};
  line-height: ${({lineHeight}) => lineHeight};
`;

export const StyledSideSheetContainer = styled(SideSheetContainer)`
  border-radius: 16px;
  border: none;
  bottom: 16px;
  box-shadow: 0px 8px 25px 2px rgba(21, 27, 37, 0.35);
  font-family: 'Montserrat', 'Roboto', 'sans-serif';
  height: auto;
  margin-right: ${({isCollapsed}) => (isCollapsed ? '-450px' : 0)};
  position: fixed;
  right: 8px;
  top: 56px;
  z-index: 1002;
  min-width: 420px;
  border: ${({ theme }) => (theme.darkMode ? '2px solid gray' : 'none')}; 
`;

export const SideSheetHeader = styled.div`
  align-items: center;
  background: #000;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: space-between;
  line-height: 20px;
  padding: 0 16px;
  position: sticky;
  top: 0;
  z-index: 500;
`;

export const StyledCloseIcon = styled(IconClose)`
  fill: ${({theme}) => theme.unify.colors.white} !important;
`;

export const StyledSideSheetBodyContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 85vh;
`;

export const StyledInput = styled(Input)`
  margin: 10px auto;
`;

export const StyledPermissionContainer = styled.div`
  margin: 10px 0px 0px 0px;
  height: 90px;
`;

export const StyledPermission = styled(TextArea)`
   height: 50px;
   overflow: hidden;
   overflow-y: auto;  
`;

export const StyledInputContainer = styled.div`
  min-height: auto;
`;

export const StyledHelperLabel = styled.span`
  width: 39px;
  height: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledHelperLabelContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledSingleSelectDropDown = styled.div`
  margin: 10px 0;
`;

export const StyledDropDownContainer = styled.div`
  width: auto;
`
export const StyledActionButtonContainer = styled.div<{
    position?: string;
    positionProps?: Partial<{
        top: string;
        bottom: string;
        left: string;
        right: string;
    }>;
    background?: string;
}>`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  position: ${({position}) => position};
  bottom: ${({positionProps}) => positionProps?.bottom};
  background: ${({background}) => background};
  // CTA buttons
  :last-child {
    margin-top: auto;
  }

`;

export const StyledSecondaryIconButton = styled(SecondaryButton)`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

export const StyledSimpleAccordion = styled(SimpleAccordion)`
  margin: 10px 0;

  svg {
    transform: rotate(0deg);
  }
`;
export const StyledAdminCardContainer = styled.div``;

export const StyledAdminSideSheetFooterContainer = styled.div``;

export const StyledTextArea = styled(TextArea)``;

export const StyledIconContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const StyledIconChevronLeft = styled(IconChevronLeft)`
  cursor: pointer;
  fill: white !important;
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledToast = styled(Toast)`
  max-width: 25rem;
`;

export const StyledCardContainer = styled.div`


`

export const StyledAdminCardList = styled.div`
  max-width: calc(100% - 20px);
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5%;
`

export const StyledAdminHomeIcon = styled(Button)`
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  background-color: black !important;
`
export const StyledAdminHome = styled.div`
  display: flex;
`
export const StyledCampaignStandaloneWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledAdminMainContainer = styled.div`
position: absolute;
left: 0px;
width: 100%
`

export const StyledUnifyAdminMainTitle = styled.div`
display: flex;
position: relative;
width:254;
height:45px;
padding: 0px 16px 0px 16px;
border: 0px 0px 1px 0px;
gap: 8px;
top: 10px;
font-weight:450;
font-size:28px;
`

export const StyledAdminPageUserSearch = styled.div`
display:flex;
width:20%;
top:15px;
position: relative;
padding-right: 25px;
padding-left: 5px;
`

export const StyledUserDetailDialog = styled(Dialog)`
    min-width: 600px;
    z-index: 9999;
    height: 500px;
`;

export const ApplicationTableData = styled(SimpleTableData)`
    font-weight:500;
    padding:0.3rem 1rem;
    font-size:16px;
    line-height:24px;
    height:32px
`;

export const WorkflowTableData = styled(SimpleTableData)`
    padding:0.2rem 2rem;
    font-size:16px;
    line-height:24px;
    height:32px
`;

export const UserDetailHeading = styled.div`
  font-weight:500;
  font-size:16px;
  line-height:24px;
  margin-top:8px;
`;

export const UserDetailDivider = styled(Divider)`
  height:3px;
  background-color:black;
`;

export const UserDetailNotFoundDiv = styled.div`
  font-size:14px;
  line-height:20px;
  margin-top:15px;
`;

export const StyledTabPanel = styled(TabPanel)<{ isDialogOpened: boolean }>`
  width: 100%;
  display: flex;
  position: fixed;
  padding: .65rem 0rem 1rem 1rem;
  z-index: ${({ isDialogOpened }) => (isDialogOpened ? '1' : '104')};
`;

export const StyledTabs = styled(Tabs)`
`

export const MultiSelectDropdownInput = styled.div`
  margin-top:10px;
`;

export const StyledTab = styled(Tab)`
  height: 40px;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  
    & > span {
    border-bottom: 2px solid transparent;
  }

  ${(props) =>
        props.isActive &&
        css`
      & > span {
        border-color: Grey;
      }
    `}
`;

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
height: 100vh;
overflow-x: hidden;
width: 100%;
left: 0px;
position: fixed;
z-index: 104;

@media (max-height: 780px) {
  height: 85vh;
}

@media (max-height: 600px) {
  height: 70vh;
}

@media (max-height: 600px) {
  height: 60vh;
}
`;
