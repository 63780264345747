import {create} from "zustand";
import { Application } from '../types';

export interface ApplicationState {
    applications: Application[];
    setApplications: (applications:Application[]) => void;
    apiStatus: string;//"error" | "idle" | "loading" | "success"
    setApiStatus: (apiStatus:string) => void;
    isFetched: boolean;
    setIsFetched: (isFetched:boolean) => void;
  }
  
export const useApplicationStore = create<ApplicationState>((set) => ({
   applications: [],
   setApplications: (applications) => set(() => ({ applications })),
   apiStatus: "",
   setApiStatus: (apiStatus) => set(() => ({ apiStatus })),
   isFetched: false,
   setIsFetched: (isFetched) => set(() => ({ isFetched }))
 }));
