import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {
    createAdminEnvironmentSlice,
    ICreateAdminEnvironmentSlice,
    createAdminSideSheetSlice,
    ICreateAdminSideSheetSlice,
    createAdminGridSearchSlice,
    ICreateAdminGridSearchSlice,
    ICreateCampaignSlice,
    ICreateFeatureReleaseSlice,
    createCampaignSlice,
    ICreateAdminUserSearchSlice,
    createAdminUserSearchSlice,
    ICreateProductOutageSlice,
    createProductOutageSlice,
    createFeatureReleaseSlice
} from './slices';

/**
 * Make sure to enforce type for each slice
 */

export const useUnifyAdminStore = create<ICreateAdminEnvironmentSlice &
    ICreateAdminSideSheetSlice &
    ICreateAdminGridSearchSlice &
    ICreateCampaignSlice &
    ICreateAdminUserSearchSlice &
    ICreateProductOutageSlice &
    ICreateFeatureReleaseSlice
    >()(
    devtools((...a) => ({
        ...createAdminGridSearchSlice(...a),
        ...createAdminEnvironmentSlice(...a),
        ...createAdminSideSheetSlice(...a),
        ...createCampaignSlice(...a),
        ...createAdminUserSearchSlice(...a),
        ...createProductOutageSlice(...a),
        ...createFeatureReleaseSlice(...a)
    }))
);
