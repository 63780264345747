import { IconButton as RadialIconButton } from 'cdk-radial';
import styled from 'styled-components';

export const RoundedIconButton = styled(RadialIconButton)<{
  darkMode: boolean;
}>`
  border-radius: 36px;
  &&& {
    svg {
      fill: ${({ darkMode }) => (darkMode ? '#E6E6E6' : '#333')};
      transition-property: all;
      :hover {
        background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
        fill: ${({ darkMode }) => (darkMode ? '#F2F2F2' : '#333')};
      }
      :active {
        background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
      }
    }
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
  @media (max-width: 470px) {
    min-width: 1rem;
    &&& {
      svg {
        :hover {
          background-color: transparent;
        }
      }

      :hover {
        background-color: transparent;
      }

      :focus-visible {
        background-color: transparent;
      }
    }
  }
`;

export const IconButton = styled(RadialIconButton)<{
  darkMode: boolean;
  isBannerButton: boolean;
}>`
  border-radius: 8px;
  &&& {
    svg {
      fill: ${({ darkMode }) => (darkMode ? '#E6E6E6' : '#333')};
      transition-property: all;
      width: ${({ isBannerButton }) => isBannerButton && `18px`};
      height: ${({ isBannerButton }) => isBannerButton && `18px`};
      :hover {
        background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
        fill: ${({ darkMode }) => (darkMode ? '#F2F2F2' : '#333')};
      }
      :active {
        background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
      }
    }
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
`;
