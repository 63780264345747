import styled,{ keyframes } from 'styled-components';
import { IconButton, IconLogoDarkBg,IconApps, IconCheck } from 'cdk-radial';

/**
 * Animations keyframs
 */

export const shake = keyframes`
  
  0% {
    transform: translateX(0) rotate(0);
  }
  25% {
    transform: translateX(-1px) rotate(-10deg);
  }
  50% {
    transform: translateX(1px) rotate(10deg);
  }
  75% {
    transform: translateX(-1px) rotate(-10deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
`

export const popOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

export const ApplicationAnimatedIcon = styled(IconApps)`
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.carbon.element.carbonHeader.text.size};
  font-weight: ${({ theme }) => theme.carbon.element.carbonHeader.text.weight};
  padding: 0 16px;
  text-transform: ${({ theme }) => theme.carbon.element.carbonHeader.text.case};
  background: ${({ theme }) => theme.unify.colors.black};
  height: 48px;
  position: sticky;
`;

export const PageHeaderLogo = styled.div`
  cursor: pointer;
  min-width: 200px;
`;
export const PageHeaderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  min-width: 200px;
  justify-content: flex-end;
  outline: none;
`;

export const HeaderButton = styled(IconButton)`
  &.animated-apps-icon{
    animation: ${shake} 0.5s, ${popOut} 0.5s 0.5s;
    animation-fill-mode: forwards;
  }
  &&& svg {
    fill: #e6e6e6;
    :hover {
      fill: #f2f2f2;
    }
  }
`;

export const MenuText = styled.span`
  padding-left: 10px;
`;

export const SubMenuText = styled.span`
  padding-left: 10px;
`;

export const Logo = styled(IconLogoDarkBg)`
  path {
    fill: #fff;
  }
`;

export const ProfileIcon = styled.span`
  cursor: pointer;
`;

export const HistoryCount = styled.div<{ notificationsFlag?: boolean }>`
  color: #000;
  font-family: 'Montserrat', 'Roboto', 'sans-serif';
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: ${props => props.notificationsFlag === true ? '201px' : '156px' };
  z-index: 11;
  cursor: pointer;
  pointer-events: none;
  text-align: center;
  width: 18px;
`;

export const CheckIcon = styled(IconCheck)`
  position: absolute;
  margin-left: -6px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
`;