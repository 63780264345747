import { useState } from 'react';
import { IAMUserInfo, UserInfo } from '../../types';
import profileLogo from '../../assets/profile/profile-img.png';
import { Loader, NoDataError } from '../NoDataError/NoDataError';
import * as Styles from './styles';
import { Button } from '../Buttons/Button';
import { UseQueryResult } from 'react-query';
import LogoutConfirmDialog from '../Dialogs/LogoutConfirmDialog';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import { LOGOUT_STATUS } from '../MessageProvider/MessageProvider';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../contexts/Theme';

export default function ProfileContent(props: {
  oktaUser: UseQueryResult;
  iamUser: UseQueryResult;
}) {
  const { oktaUser, iamUser } = props;
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
  const { logoutProducts, setLogoutStatus } = useChannelMessage();

  const oktaUserInfo = (oktaUser.data || {}) as UserInfo;
  const iamUserInfo = (iamUser.data || {}) as IAMUserInfo;

  const empId = iamUserInfo?.fullUser?.user?.employeeId;
  const defaultStore = iamUserInfo?.fullUser?.stores?.filter(
    (store) => store.defaultStore
  );
  const storeNMame = defaultStore?.length && defaultStore[0]?.name;
  const iamDataExists = empId || storeNMame;
  const {t} = useTranslation();
  const { darkMode } = useTheme();

  const handleSignOut = () => {
    setShowLogoutDialog(false);
    logoutProducts();
  };

  const onSignOutButtonClicked = () => {
    setLogoutStatus(LOGOUT_STATUS.LOGOUT_USERCONFIRM);
    setShowLogoutDialog(true);
  };

  const UserProfile = (
    <>
      <Styles.ProfileHeader>
        <Styles.Titles>
          <Styles.ProfileImage data-testid="user-img" src={profileLogo} />
          <div>
            <h2 data-testid="user-name">
              {oktaUserInfo.profile?.firstName} {oktaUserInfo.profile?.lastName}
            </h2>
            <h4>{iamUserInfo?.fullUser?.enterprise?.name}</h4>
          </div>
        </Styles.Titles>
      </Styles.ProfileHeader>
      <Styles.ProfileBody>
        {iamDataExists && (
          <>
            <h2>{t('profile.details')}</h2>
            <Styles.Details>
              {empId && (
                <>
                  <Styles.Label data-testid="label-phone">
                    {t('profile.empID')}
                  </Styles.Label>
                  <Styles.Value data-testid="value-phone">{empId}</Styles.Value>
                </>
              )}
              {storeNMame && (
                <>
                  <Styles.Label data-testid="label-email">
                    {t('profile.store')}
                  </Styles.Label>
                  <Styles.Value data-testid="value-email">
                    &nbsp;
                    {storeNMame}
                  </Styles.Value>
                </>
              )}
            </Styles.Details>
          </>
        )}
        <h2>{t('profile.contactInfo')}</h2>
        <Styles.Details>
          <Styles.Label data-testid="label-phone">
            {t('profile.phone')}
          </Styles.Label>
          <Styles.Value data-testid="value-phone">
            {oktaUserInfo.profile?.mobilePhone}
          </Styles.Value>
          <Styles.Label data-testid="label-email">
            {t('profile.email')}
          </Styles.Label>
          <Styles.Value data-testid="value-email">
            &nbsp;
            {oktaUserInfo.profile?.email}
          </Styles.Value>
        </Styles.Details>
        <Styles.ProfileFooter>
          <Button
            darkMode={darkMode}
            onClick={onSignOutButtonClicked}
            text={t('profile.signOut')}
          />
        </Styles.ProfileFooter>
      </Styles.ProfileBody>
    </>
  );

  return (
    <>
      <LogoutConfirmDialog
        isOpen={showLogoutDialog}
        onLogout={handleSignOut}
        onCancel={() => {
          setLogoutStatus(LOGOUT_STATUS.NONE);
          setShowLogoutDialog(false);
        }}
      />
      {oktaUser.isError ? (
        <NoDataError
          headerText={t('profile.unavailable')}
        />
      ) : oktaUser.isLoading || iamUser.isLoading ? (
        <Loader />
      ) : (
        UserProfile
      )}
    </>
  );
}
