import { BrowserRouter } from 'react-router-dom';
import { useAnalytics } from 'dms-analytics';
import { get } from 'lodash';
import {
  useUnifyUserProfile,
  useNotifications,
  useUserProfile,
  useUpdateUserLastLogin,
  useIAMUserProfile,
  useApplications,
  useWorkflows,
  useWorkflowsViewMap,
  useGetAllApplications,
  useGetAllWorkflows,
  useUserSettings,
  useFetchDMS,
  useUpdateUserSettings
} from '../../hooks/queries';
import {SSENotification} from '../SSE/NotificationReciever';
import * as Styles from '../styles';
import Header from '../Header/Header';
import SideSheetProvider from '../SideSheetProvider/SideSheetProvider';
import { SideSheet } from '../SideSheet/SideSheet';
import PaneManagementProvider from '../PaneManagementProvider/PaneManagementProvider';
import Routes from '../Routes';
import PaneManager from '../PaneManager/PaneManager';
import { useEffect, useState } from 'react';
import NotificationsDialog from '../Notifications/NotificationsDialog';
import {
  darkMode as RDSDarkMode,
  theme as RDSLightMode,
} from 'cdk-radial';
import { ThemeProvider } from 'styled-components';
import createOktaInstance from '../../config/okta';
import { OktaAuth } from '@okta/okta-auth-js';
import { AMPLITUDE_EVENTS, LOGOUT_URL, SPOKE_USERS } from '../../constants';
import getUserIdFromOkta from '../../util/getUserIdFromOkta';
import { LOGOUT_STATUS } from '../MessageProvider/MessageProvider';
import LogoutConfirmDialog from '../Dialogs/LogoutConfirmDialog';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import { useTranslation } from 'react-i18next';
import  {useNotificationStore} from '../../Store';
import {useApplicationStore, 
  useWorkflowsStore, 
  useWorkflowsViewMapStore, 
  useAllApplicationsStore, 
  useAllWorkflowsStore,
  useIamUserProfileStore,
  useUserSettingsStore,
  useDriveCNumbersStore} 
  from '../../Store/index'
import Walkme from '../../components/Walkme/Walkme';
import BannerContainer from '../Banner/BannerContainer';
import moment from 'moment';
import OutageNotificationContainer from '../Notifications/OutageNotificationContainer';
import { useTheme } from '../../contexts/Theme';
import { CustomScrollbarWrapper } from 'cdk-radial';
import styled from "styled-components";
import { IAMRole, IAMStore } from '../../types';
import getCdkSidFromOkta from '../../util/getCdkSidFromOkta';
import { useWillShowDialog } from '../../contexts/AppStateContext';
import CardMoreDialog from '../Cards/CardMoreDialog';
import { Aiva } from 'conversational-ai-interface';
import { useUnifyAdmin } from '../UnifyAdministration/Hooks/useUnifyAdmin';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100% - 50px);
  overflow-x: hidden;
`;

const AppLoader = (props :{loaderText:string}) => {
  return (
    <Styles.LoaderContainer data-testid='loader-container'>
      <Styles.Loader />
      <h2>{props.loaderText}</h2>
    </Styles.LoaderContainer>
  )
}  ;

export default function Home() {
  const setNotifications = useNotificationStore((state:any) => state.setNotifications);
  const setApplications = useApplicationStore((state:any) => state.setApplications);
  const setApiStatus = useApplicationStore((state:any) => state.setApiStatus);
  const setIsFetched = useApplicationStore((state:any) => state.setIsFetched);
  const setWorkflows = useWorkflowsStore((state:any) => state.setWorkflows);
  const setWorkflowsViewMap = useWorkflowsViewMapStore((state:any) => state.setWorkflowsViewMap);
  const setFavoriteWorkflowsViewMap = useWorkflowsViewMapStore((state:any) => state.setFavoriteWorkflowsViewMap);
  const setWorkflowsViewMapApiStatus = useWorkflowsViewMapStore((state:any) => state.setApiStatus);
  const setAllApplications = useAllApplicationsStore((state:any) => state.setApplications);
  const setAllWorkflows = useAllWorkflowsStore((state:any) => state.setWorkflows);
  const setIamUserInfo = useIamUserProfileStore((state:any) => state.setIamUserInfo);
  const setIamUserIsLoading = useIamUserProfileStore((state:any) => state.setIsLoading);
  const setIamUserIsError = useIamUserProfileStore((state:any) => state.setIsError);
  const setUserSettings = useUserSettingsStore((state:any) => state.setUserSettings);
  const setUserSettingsStatus = useUserSettingsStore((state:any) => state.setApiStatus);
  const setCNumbers = useDriveCNumbersStore((state:any) => state.setCNumbers);
  const setCNumbersIsFetched = useDriveCNumbersStore((state:any) => state.setIsFetched);
  const oktaAuth = createOktaInstance() as OktaAuth;
  const { trackEvent, identify } = useAnalytics();
  const { data: userProfile, isLoading: iamLoading, isError: iamError, } = useIAMUserProfile();
  const { logoutStatus, setLogoutStatus, logoutProducts } = useChannelMessage();
  const [loaderText, setLoaderText] = useState('CDK Unify');
  const { t } = useTranslation();
  const { data: notificationsData } = useNotifications();
  const {data: applications, isFetched, apiStatus} = useApplications();
  const {data: workflows} = useWorkflows();
  const {data: workflowsViewMap, status: workflowsViewMapStat} = useWorkflowsViewMap();
  const {data: favoriteWorkflowsViewMap} = useWorkflowsViewMap(true);
  const { data } = useUserProfile();
  const shouldNotificationRefresh = useNotificationStore((state: any) => state.shouldNotificationRefresh);
  const reset = useNotificationStore((state: any) => state.reset);
  const { data: allApplications } = useGetAllApplications();
  const { data: allWorkflows } = useGetAllWorkflows();
  const { data: userSettings, status: userSettingStatus } = useUserSettings();
  const { data: dmsCNumbers, isFetching: cNumbersIsFetching } = useFetchDMS();
  const { mutate:themeMutate } = useUpdateUserSettings();
  const { mutate } = useUpdateUserLastLogin();
  const {data:unifyProfile} = useUnifyUserProfile();
  const [notifyMessage, showNotification] = useState(false); 
  const { darkMode } = useTheme();
  const useSystemTheme =  localStorage.getItem('use-system-theme') === 'yes';
  const systemDarkTheme = window.matchMedia('(prefers-color-scheme: dark)')?.matches;
  const theme = darkMode ? RDSDarkMode : RDSLightMode;
  const { permissions } = useUnifyAdmin();
  const [aivaEnabled, setAivaEnabled] = useState(false);
  const aivaFlag = useFeatureFlag('UNIFY_UI_AIVA');
  const [ lastLoginInHandle, setLastLoginInHandle ] = useState(false);

  useEffect(() => {
    applications && setApplications(applications);
    apiStatus && setApiStatus(apiStatus);
    setIsFetched(isFetched);
  }, [applications, setApplications, isFetched, apiStatus,
    setApiStatus, setIsFetched]);

  useEffect(() => {
    workflows && setWorkflows(workflows);
  }, [workflows, setWorkflows]);

  useEffect(() => {
    workflowsViewMap && setWorkflowsViewMap(workflowsViewMap);
    favoriteWorkflowsViewMap && setFavoriteWorkflowsViewMap(favoriteWorkflowsViewMap);
    workflowsViewMapStat && setWorkflowsViewMapApiStatus(workflowsViewMapStat);
  }, [workflowsViewMap, setWorkflowsViewMap, favoriteWorkflowsViewMap,
    setFavoriteWorkflowsViewMap, workflowsViewMapStat, setWorkflowsViewMapApiStatus]);

  useEffect(() => {
    allApplications && setAllApplications(allApplications);
    allWorkflows && setAllWorkflows(allWorkflows);
  }, [allApplications, setAllApplications, allWorkflows, setAllWorkflows]);

  useEffect(() => {
    userProfile && setIamUserInfo(userProfile)
    setIamUserIsLoading(iamLoading);
    setIamUserIsError(iamError);
  }, [userProfile, setIamUserInfo,
    iamLoading, setIamUserIsLoading,
    iamError, setIamUserIsError]);

  useEffect(() => {
    const settings = useSystemTheme  ? { theme: systemDarkTheme ? 'dark' : 'light' } : userSettings ;
    settings && setUserSettings(settings);
    settings?.theme !== userSettings?.theme && themeMutate( { theme: settings?.theme as 'dark' | 'light'} )
    setUserSettingsStatus(userSettingStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings, setUserSettings, setUserSettingsStatus, userSettingStatus]);

  useEffect(() => {
    dmsCNumbers && setCNumbers(dmsCNumbers);
    setCNumbersIsFetched(cNumbersIsFetching);
  }, [dmsCNumbers, setCNumbers, setCNumbersIsFetched, cNumbersIsFetching]);

  useEffect(() => {
    if (Array.isArray(notificationsData)) {
      setNotifications(notificationsData);
    }
  }, [notificationsData, setNotifications]);

  useEffect(() => {
    if (data && data.lastLogin && unifyProfile && !iamLoading){
      let isMutated = handleLastLoginDate(unifyProfile.lastLoggedIn,data.lastLogin);
      setLastLoginInHandle(isMutated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unifyProfile, data?.lastLogin,iamLoading]);

  useEffect(() => {
    if (lastLoginInHandle && 
        notificationsData && notificationsData.length > 0 && 
        notificationsData[0].type === 'outage' &&
        notificationsData[0].read === false
        && !shouldNotificationRefresh) {
          showNotification(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lastLoginInHandle,notificationsData,shouldNotificationRefresh]);

  const handleLastLoginDate = (unifyProfileLastLoggedIn: Date,oktaProfileLastLoggedIn: Date) =>{
    let lastLoggedInDate = moment.utc(unifyProfileLastLoggedIn,'dddd, MMMM DD, YYYY at h:mm:ss A Z');
    let oktaLoggedInDate = moment.utc(oktaProfileLastLoggedIn,'YYYY-MM-DDThh:mm:ss A Z');
    if (!lastLoggedInDate.isSame(oktaLoggedInDate)){

      const cdksid = getCdkSidFromOkta();
      const enterpriseId = userProfile?.fullUser
      ? userProfile?.fullUser?.enterprise?.id 
      : !cdksid ?  SPOKE_USERS : null;

      reset(true);
      mutate({ lastLogin: oktaProfileLastLoggedIn, enterpriseId });
      trackEvent(AMPLITUDE_EVENTS.SINGLE_LOGIN_UNIFY);
      return true;
    }
    return false;
  }

  const handleLogout = () => {
    logoutProducts();
  };

  const signOutUnify = () => {
    trackEvent(AMPLITUDE_EVENTS.LOGOUT_UNIFY);
    const idToken = oktaAuth.getIdToken();
    oktaAuth.tokenManager.clear();
    localStorage.clear();
    oktaAuth.signOut({
      postLogoutRedirectUri: `${LOGOUT_URL}?source=unify&id_token_hint=${idToken}`
    });
  };

  useEffect(() => {
    try {
      if (!iamLoading) {
        const userId = getUserIdFromOkta();
        const cdksid = getCdkSidFromOkta();

        const enterpriseId = userProfile?.fullUser
          ? userProfile?.fullUser?.enterprise?.id
          : !cdksid ? SPOKE_USERS : null;

        let enterpriseName = userProfile?.fullUser ? ` ${userProfile?.fullUser?.enterprise?.name}` : ``;

        if (userId && enterpriseId) {
          const userRoles = get(
            userProfile,
            'fullUser.roles',
            []
          ).map((role: IAMRole) => role.roleName);
          const userDefaultStore = get(
            userProfile,
            'fullUser.stores',
            []
          ).find((store: IAMStore) => (store?.defaultStore === true)) || {};
          identify({
            'User ID': userId,
            'Enterprise ID': enterpriseId,
            'User role': userRoles,
            'Stores': userProfile?.fullUser?.stores.map(({ id, name }) => `${id} ${name}`),
            'Store Id': (userDefaultStore as IAMStore)?.id,
            'Enterprise Name': enterpriseId + enterpriseName,
            'Store Name': (userDefaultStore as IAMStore)?.id + ` ${(userDefaultStore as IAMStore)?.name}`,
          });
        }
      }
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, iamLoading]);

  useEffect(() => {
    const userId = getUserIdFromOkta();
    if (userId) {
      identify({
        'User ID': userId
      });
      trackEvent(AMPLITUDE_EVENTS.LOGIN_UNIFY);
      trackEvent(AMPLITUDE_EVENTS.VIEW_UNIFY_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logoutStatus === LOGOUT_STATUS.APPS_COMPLETE) {
      const logoutMsg = t('home.logoutUnify');
      setLoaderText(logoutMsg);
      signOutUnify();
    } else if (logoutStatus === LOGOUT_STATUS.LOGOUT_PROGRESS) {
      const logoutMsg = t('home.logoutApplications');
      setLoaderText(logoutMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutStatus]);

  useEffect(() => {
    aivaFlag && userProfile?.fullUser &&
    permissions?.hasEmbeddedIntelligencePermission &&  setAivaEnabled(true); 
  }, [
    userProfile?.fullUser, 
    permissions?.hasEmbeddedIntelligencePermission,
    aivaFlag
  ]);

  useEffect(() => {
    const handleFeedbackClick = (evt: MouseEvent) => {
      try {
        const feedbackButton = (evt.target as HTMLElement).parentElement;
        if (
          feedbackButton?.id === 'QSIFeedbackButton-btn' &&
          feedbackButton.getAttribute('aria-expanded') === 'true'
        ) {
          trackEvent(AMPLITUDE_EVENTS.OPEN_FEEDBACK);
        }
      } catch (err) {
        console.warn('Amplitude - Feedback Tracking error', err);
      }
    };
    document.addEventListener('click', handleFeedbackClick); 
    return () => {
      document.removeEventListener('click', handleFeedbackClick);
    };
  }, [trackEvent]);

  const isAdminMode = /^\/admin(\/.*)?$/.test(window.location.pathname);

/***  Dialog Management   */
  const [ dialogData, setDialogData ] = useState({});
  const willShowDialog = useWillShowDialog();
  willShowDialog( (type: string, data: any) => {
    setDialogData({...data, isEnabled: true, isFavorite: true, isIconRequired: true, onClose:() => setDialogData({type: 'none'}),  isOpen: type === 'LEARN_MORE'});
  });
/***  Dialog Management End  */

  return (
    <>
    <ThemeProvider theme={theme}>
   
      <PaneManagementProvider>
        {(iamLoading ||
          logoutStatus === LOGOUT_STATUS.LOGOUT_PROGRESS ||
          logoutStatus === LOGOUT_STATUS.APPS_COMPLETE) && <AppLoader loaderText={loaderText}/>}

        <SideSheetProvider>
          <Styles.Backplane id="backplane">
          <SSENotification />
            <Header />
            <StyledCustomScrollbarWrapper>
              { !isAdminMode && <OutageNotificationContainer/>  }
              {!isAdminMode && (
                  <BannerContainer/>
                  )}
              <Styles.BackplaneBodyWrapper>
              <Styles.BackplaneBody
                  id="backplane-body"
                  isAdmin={isAdminMode}
                  margin={'5px 0px'}
                >
                  
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                  <PaneManager />
                </Styles.BackplaneBody>
              </Styles.BackplaneBodyWrapper>
              <SideSheet />
            </StyledCustomScrollbarWrapper>
          </Styles.Backplane>
              <NotificationsDialog
                isOpen={notifyMessage}
                onClose={() => showNotification(false)} />
            <LogoutConfirmDialog
              isOpen={logoutStatus === LOGOUT_STATUS.LOGOUT_INIT}
              data-testid="logout_dialog"     
              onLogout={handleLogout}
              onCancel={() => setLogoutStatus(LOGOUT_STATUS.NONE)}
            />
            <CardMoreDialog cardData={dialogData} />
              { aivaEnabled && 
                  <Aiva 
                      agentProfile={{
                        teamName: 'Intelligence Suite Chat Bot'
                      }}
                      source="Unify"
                      user={userProfile && userProfile.fullUser }
                      userLanguage={ userProfile?.fullUser?.user.locale === 'en_US' ? 'en' : 'fr' }
                      aivaVoice={true}/> }
        </SideSheetProvider>
      </PaneManagementProvider>
      
      <div id="walkme-pane"><Walkme></Walkme></div>
      </ThemeProvider>
    </>
  );
}
