import {
    InlineInput,
    KEY_CODES,
    MenuListItem,
    Autocomplete
  } from 'cdk-radial';
  import {
    ChangeEvent,
    MutableRefObject,
    forwardRef,
    memo,
    useEffect,
    useRef,
    useState,
    useDeferredValue
  } from 'react';
  import { AutocompleteProps, Option } from './types';
  
  const getHighlightedText = (text : string , highlight: string, noRecordMsg: string) => {
    if(text === noRecordMsg){
      return <span> {text} </span>;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
            { part }
        </span>)
    } </span>;
  }

  const _filterOptions = (inputText: string,
                        defaultOptions :any,
                        setOptions : Function,
                        isLoading: boolean,
                        noRecordMsg: string) => {
    const results =
      defaultOptions?.length &&
      defaultOptions?.filter(
        (opt :any) => opt.label.toLowerCase().indexOf(inputText.toLowerCase()) !== -1
      );
    if ( results?.length ) {
      setOptions(results);
    } else {
      if (!isLoading)
        setOptions([{ label: noRecordMsg, disabled: true }]);
    }
  };

  const AutocompleteDropdown = forwardRef<HTMLInputElement, AutocompleteProps>(
    (componentProps, ref) => {
      const {
        className,
        options: defaultOptions,
        minChars = 0,
        isDisabled,
        inputProps,
        autoCompleteProps,
        menuListItemProps,
        onChange = () => {},
        onClear = () => {},
        onChangeInput = () =>{},
        value,
        dataTestId: parentTestId,
        isLoading,
        noRecordMsg = "No Record Found",
        isHighlightRequired
      } = componentProps;
  
      const thisRef = useRef<HTMLInputElement>();
      const dimensionRef = useRef<HTMLDivElement>(null);
      const targetRef = (ref ?? thisRef) as MutableRefObject<HTMLInputElement>;
      const [options, setOptions] = useState(defaultOptions);
      const [isOpen, setIsOpen] = useState(false);
      const [selectedOption, setSelectedOption] = useState(value);
      const [inputValue, setInputValue] = useState('');
      const {  ENTER, SPACE, TAB } = KEY_CODES;
      const deferredValue = useDeferredValue(inputValue);

      const handleEnterKeyDown = () => {
        if (
            inputValue?.length &&
            options?.length &&
            !options[0]?.disabled &&
            inputValue?.toLowerCase() ===
              options[0].value?.toString()?.toLowerCase()
          ) {
            handleClose();
            onChange(options[0]);
          }
      }

      const handleTabKeyDown = () => {
        handleEnterKeyDown();
        if (selectedOption.label === '') {
          setInputValue('');
          setOptions([{ label: '', value: '' }]);
        }
      }

      const handleMenu = () => {
        if (inputValue.length >= minChars) {
          handleOpen();
          filterOptions(inputValue);
        }
        setOptions(defaultOptions);
      };

      const keyMap = new Map();
      keyMap.set(ENTER,handleEnterKeyDown);
      keyMap.set(TAB,handleTabKeyDown);
      keyMap.set(SPACE,handleMenu);

      useEffect(() => {
        setSelectedOption(value);
        setInputValue(value?.label);
      }, [value]);
  
      useEffect(() => {
        setOptions(defaultOptions);
        filterOptions(inputValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defaultOptions,inputValue]);
  
      useEffect(()=>{
        if (isLoading === true && minChars === inputValue.length){
            setOptions([{ label: 'Loading ...', disabled: true }]);
        }
      }, [isLoading,minChars,inputValue]);

      const handleOpen = () => setIsOpen(true);
  
      const handleClose = () => setIsOpen(false);
  
      const handleSelect = (option: Option) => {
        if (option.label !== '') {
          setSelectedOption(option);
          setInputValue(option.label);
          setOptions(defaultOptions);
          handleClose();
          onChange(option);
        }
        targetRef.current.focus();
      };
  
      const filterOptions = (inputText: string) => {
        _filterOptions(inputText,
          defaultOptions ,
          setOptions,
          isLoading as boolean,
          noRecordMsg);
      };
  
      const findOptions = (event: ChangeEvent<HTMLInputElement>) => {
        const inputText = event.target.value;
        const check = /[^a-zA-Z0-9._@]/.test( inputText );
        if(check){
          return;
        }

        setInputValue(inputText);
        
        if (inputText.length >= minChars) {
            onChangeInput(inputText);
            setSelectedOption({ label: '', value: '' });
            handleOpen();
            filterOptions(inputText);
        } else {
          handleClose();
        }
      };
  
      const handleClear = (event:any) => {
        event.preventDefault();
        handleClose();
        setInputValue('');
        onClear();
        setSelectedOption({
          label: '',
          value: ''
        });
      };

      const handleKeyDown = (e:any) => {
        const func = keyMap.get(e.keyCode);
        func?.();
      };
  
      const dataTestId = `${parentTestId || 'autocomplete'}-menu`;

      return (
        <>
          <div className={className}>
            <InlineInput
              dataTestId={`${dataTestId}-input`}
              autoComplete="off"
              {...inputProps}
              isDisabled={isDisabled}
              labelRef={dimensionRef}
              ref={targetRef}
              value={inputValue}
              onChange={findOptions}
              hasClearButton={selectedOption.label !== ''}
              onInputClear={handleClear}
              onClick={handleMenu}
              onKeyDown={handleKeyDown}
            />
          </div>
            <Autocomplete
              dataTestId={dataTestId}
              {...autoCompleteProps}
              isOpen={isOpen}
              onUnselect={selectedOption?.label === ''}
              dimensionRef={dimensionRef}
              labelRef={targetRef}
              onClose={handleClose}
              onChange={findOptions}
              onOpen={handleOpen}
              isAuto={false}
              style={{ zIndex:'1000',
                    width: dimensionRef?.current?.offsetWidth,
                    maxWidth: dimensionRef?.current?.offsetWidth,  }}
            >
              {options.map((option, idx) => (
                <MenuListItem
                  key={option.label}
                  dataTestId={`${dataTestId}-list-item-${idx}`}
                  isSelected={selectedOption?.label === option.label}
                  isDisabled={option.disabled}
                  {...menuListItemProps}
                  onClick={() => handleSelect(option)}
                  style={{ zIndex:'1000',
                  width: dimensionRef?.current?.offsetWidth,
                  maxWidth: dimensionRef?.current?.offsetWidth,  }}
                >
                  {isHighlightRequired && getHighlightedText(option.label,deferredValue, noRecordMsg)}
                  {!isHighlightRequired && option.label}
                   
                </MenuListItem>
              ))}
            </Autocomplete>
        </>
      );
    }
  );
  
  AutocompleteDropdown.defaultProps = {
    inputProps: {},
    autoCompleteProps: {},
    menuListItemProps: {},
    width: 224
  };
  
  AutocompleteDropdown.displayName = 'AutocompleteDropdown';
  
  export default memo(AutocompleteDropdown);