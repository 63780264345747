import {useMutation, useQuery, useQueryClient} from "react-query";
import useAuthToken from "../../../../../hooks/useAuthToken";
import {useUnifyAdminStore} from "../../../AdminStore";
import * as campaignApis from './campaignApis'


export function useGetAllCampaigns() {
    /**
     * Hooks
     */
    const token = useAuthToken();

    return useQuery(
        'getAllCampaigns',
        () => campaignApis.fetchListOfCampaigns(token),
    );
}

export function useCreateCampaign() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const campaignItem = useUnifyAdminStore(
        (state) => state.campaignItems
    )
    const setCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsCreateCampaignDialogOpen)
    const resetCampaignItem = useUnifyAdminStore((state) => state.setCampaignItem)
    const queryClient = useQueryClient();

    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)

    /**
     * Request
     */
    const ReqBody = {
        name: campaignItem.name,
        link: campaignItem.link,
        description: campaignItem.description,
        darkLogo: campaignItem.darkLogo,
        logo: campaignItem.logo,
        isActive: campaignItem.isActive,
        showLogo: campaignItem.showLogo,
    }



    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => campaignApis.createCampaign(token,
        ReqBody), {
        onSuccess: () => {
            queryClient.invalidateQueries(['getAllCampaigns']);
            setCreateCampaignDialogOpen(false);
            resetCampaignItem(null);
            setCustomToastContent({type: 'Success', message: 'Campaign has been created successfully!'})
            setShowCustomContentToast(true)
            
        },
        onError: (error: any) => {
            console.error('Failed to create a new Campaign:', error);
            setCustomToastContent({
                type: 'Error',
                message: 'Failed to create a new Campaign,check logs for more details'
            })
            setShowCustomContentToast(true)
        },
        mutationKey: 'createNewCampaign',
    })
}

export function useUpdateCampaign() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const campaignItem = useUnifyAdminStore(
        (state) => state.campaignItems
    )
    const setCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsCreateCampaignDialogOpen)
    const resetCampaignItem = useUnifyAdminStore((state) => state.setCampaignItem)
    const queryClient = useQueryClient();

    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)

    /**
     * Request
     */
    const ReqBody = {
        id: campaignItem.id,
        name: campaignItem.name,
        link: campaignItem.link,
        description: campaignItem.description,
        darkLogo: campaignItem.darkLogo,
        logo: campaignItem.logo,
        isActive: campaignItem.isActive,
        showLogo: campaignItem.showLogo,
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => campaignApis.updateCampaign(token,
        ReqBody, ReqBody.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(['getAllCampaigns']);
            setCreateCampaignDialogOpen(false);
            resetCampaignItem(null);
            setCustomToastContent({type: 'Success', message: 'Campaign has been updated successfully!'})
            setShowCustomContentToast(true)
        },
        onError: (error: any) => {
            console.error('Failed to update Campaign:', error);
            setCustomToastContent({
                type: 'Error',
                message: 'Failed to update Campaign,check logs for more details'
            })
            setShowCustomContentToast(true)
        },
        mutationKey: 'updateCampaign',
    })
}

