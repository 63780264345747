import {useUnifyAdminStore} from '../../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';
import {CampaignDescriptionInputWithHelperText} from '../Blocks/CampaignDescriptionInputWithHelperText'

export const CampaignDescriptionField = (): JSX.Element => {
    /**
     * Data Store
     */
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore((state) => state.updateCreateCampaignData);
    const permissions = useUnifyAdminStore(state => state.permissions); 

    const handleUpdateDescriptionField = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        return updateCreateCampaignData({
          fieldName: 'description',
          value: {enUS: event.target.value, frCA: description?.frCA}, 
        });
      };

      const description = get(
        campaignItems,
        'description'
      );

    return (
        <StyledComponents.StyledCreateCampaignElementWrapper>
            <CampaignDescriptionInputWithHelperText
                dataTestId="campaign-desc-abbr-inpu"
                errorMessage="Please enter description"
                id="campaign-desc-abbr-input"
                inputDivider="left"
                label="Campaign Description"
                name="enUS"
                onChange={handleUpdateDescriptionField}
                placeholder="Enter campaign description"
                value={description?.enUS}
                customWidth={'100%'}
                isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
                maxLength={200}
                helperLabel={`${description?.enUS?.length || 0}/200`}
                isRequired
            />
        </StyledComponents.StyledCreateCampaignElementWrapper>
    );
};
