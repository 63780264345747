import { useState, useCallback, PropsWithChildren } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

/***
 * use-context-selector is used to avoid tree re-renders when store value changed
 */

interface IStoreContext {
  hasDriveAccess: boolean;
  setDriveAccess: (access: boolean) => void;
  showDialog: (type: string, data: any) => void;
  willShowDialog: (cb: Function) => void; 
}

const initialState: IStoreContext = {
  hasDriveAccess: false,
  setDriveAccess: () => {},
  showDialog: () => {},
  willShowDialog: () => {}
};

const AppStateContext = createContext<IStoreContext>(initialState);

export const AppStateProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [hasDriveAccess, setHasDriveAccess] = useState(false);

  const setDriveAccess = useCallback((access: boolean) => {
    setHasDriveAccess(access);
  }, []);

  let _willShowDialogCB: Function;
  const willShowDialog = (cb: Function) => {
    _willShowDialogCB = cb;
  }

  const showDialog = (type:string, data: any)  => {
    _willShowDialogCB(type, data);
  }

  const store: IStoreContext = {
    hasDriveAccess,
    setDriveAccess,
    showDialog,
    willShowDialog
  };

  return (
    <AppStateContext.Provider value={store}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useDriveAccess = (): boolean => {
  const { hasDriveAccess } = useContextSelector(
    AppStateContext,
    (state) => state
  );
  return hasDriveAccess;
};

export const useShowDialog = (): Function => {
  const { showDialog } = useContextSelector(
    AppStateContext,
    (state) => state
  );
  return showDialog;
};

export const useWillShowDialog = (): Function => {
  const { willShowDialog } = useContextSelector(
    AppStateContext,
    (state) => state
  );
  return willShowDialog;
};

export const useSetDriveAccess = (): ((access: boolean) => void) => {
  const { setDriveAccess } = useContextSelector(
    AppStateContext,
    (state) => state
  );
  return setDriveAccess;
};
