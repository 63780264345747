import React from 'react';
export interface ThemeContextValue {
  darkMode: boolean | null;
}

export const ThemeContext = React.createContext<ThemeContextValue | null>(null);

export const useTheme = () => {
  const value = React.useContext(ThemeContext);
  if (!value) {
    throw new Error('useTheme');
  }
  return value;
};
