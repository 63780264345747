import { useEffect, useState} from 'react';
import * as Styles from '../styles';
import ApplicationsList from '../ApplicationsList/ApplicationsList';
import { withTab } from '../Tabs/withTab';


export function ApplicationsTabInit({apps}:any) {
  const [applications, setApplications] = useState();
  
  useEffect(() => {
    if(apps) 
    setApplications(apps);
  }, [applications, apps]);

  return (
    <Styles.ContentContainer>
      {applications && <ApplicationsList applications={applications} />}
    </Styles.ContentContainer>
  );
}

const ApplicationsTab =  withTab(ApplicationsTabInit);
export default ApplicationsTab;