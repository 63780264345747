import { useState } from 'react';
import * as Styles from '../styles';
import WorkflowCard from '../Cards/WorkflowCard';
import WorkflowContainer from '../WorkflowContainer/WorkflowContainer';
import { WorkflowViewMap, Workflow, Application } from '../../types';
import { RoundedIconButton } from '../Buttons/IconButton';
import { IconExpandLess, IconExpandMore } from 'cdk-radial';
import { useTheme } from '../../contexts/Theme';

function DomainGrouping({
  domain,
  isExpanded,
  workflows,
}: {
  domain?: string;
  isExpanded: boolean;
  workflows: Workflow[];
}) {
  return (
    <div>
      {domain && <Styles.DomainHeader>{domain}</Styles.DomainHeader>}
      <Styles.CardList>
        {workflows.map((workflow) => (
          <WorkflowCard
            appId={workflow.appId}
            id={workflow.id}
            key={workflow.id}
            name={workflow.name}
            description={workflow.description}
            url={workflow.url}
            isFavorite={workflow.isFavorite}
            isEnabled
            iconColor={workflow.icon || ''}
            iconText={workflow.iconText || ''}
            tabIndex={!isExpanded ? -1 : 0}
            cdkuUrl={workflow.cdkuUrl || ''}
          />
        ))}
      </Styles.CardList>
    </div>
  );
}

export default function WorkflowList({
  workflows,
  apps,
}: {
  workflows: WorkflowViewMap;
  apps: Record<string, Application>;
}) {
  const { darkMode } = useTheme();
  const initializeExpandedWorkflows = () => {
    const expandedWorkflows: Record<string, boolean> = {};
    Object.keys(workflows).forEach((applicationTitle) => {
      expandedWorkflows[applicationTitle] = true;
    });
    return expandedWorkflows;
  };

  const [expandedWorkflows, setExpandedWorkflows] = useState<
    Record<string, boolean>
  >(initializeExpandedWorkflows());

  const toggleExpandCollapse = (applicationTitle: string) => {
    setExpandedWorkflows((prevExpandedWorkflows) => ({
      ...prevExpandedWorkflows,
      [applicationTitle]: !prevExpandedWorkflows[applicationTitle],
    }));
  };
  return (
    <div>
      {workflows &&
        Object.entries(workflows).map(([applicationTitle, domain], index) => (
          <WorkflowContainer
            isExpanded={!!expandedWorkflows[applicationTitle]}
            key={index}
            darkMode={darkMode}
          >
            <Styles.AppContainerHeader>
              <Styles.ProductIcon iconColor={apps[applicationTitle]?.icon}>
                {apps[applicationTitle]?.iconText}
              </Styles.ProductIcon>
              {applicationTitle}
              <RoundedIconButton
                icon={
                  !!expandedWorkflows[applicationTitle] ? (
                    <IconExpandLess />
                  ) : (
                    <IconExpandMore />
                  )
                }
                style={{ marginLeft: 'auto' }}
                darkMode={darkMode}
                onClick={() => toggleExpandCollapse(applicationTitle)}
                text=""
              />
            </Styles.AppContainerHeader>
            {Array.isArray(domain) ? (
              <DomainGrouping
                isExpanded={!!expandedWorkflows[applicationTitle]}
                workflows={domain}
              />
            ) : (
              Object.entries(domain).map(([domainTitle, _workflows]) => (
                <DomainGrouping
                  key={domainTitle}
                  domain={domainTitle}
                  // TODO: thought the ternary would narrow this but it doesn't
                  workflows={_workflows as Workflow[]}
                  isExpanded={!!expandedWorkflows[applicationTitle]}
                />
              ))
            )}
          </WorkflowContainer>
        ))}
    </div>
  );
}
