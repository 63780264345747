import * as Styles from './styles';
import { useTranslation } from 'react-i18next';
import { AvatarWrapper,IconContentCopy} from 'cdk-radial';
import CopyLabel from './CopyLabel';
import { useTheme } from "styled-components";

export const Profile =({profile}:any) =>{
    const {t} = useTranslation();
    const { darkMode } = useTheme();
    const nameAbbr = (profile?.firstName || '').substring(0,1) + (profile?.lastName || '').substring(0,1);
    return (
      <Styles.ProfileContainer>
        <Styles.AvatarContainer>
            <AvatarWrapper label={profile.firstName +" "+profile.lastName}>
              <Styles.AvatarContent>{nameAbbr}</Styles.AvatarContent>
          </AvatarWrapper>
            <Styles.MarginLeftSpacing>
            <Styles.Name>{profile.firstName +" "+profile.lastName}</Styles.Name>
            <Styles.Job>{profile.title}</Styles.Job>
          </Styles.MarginLeftSpacing>
        </Styles.AvatarContainer>
          <Styles.PhoneAndEmailContainer>
            <Styles.PhoneLabel darkMode={darkMode}>{t('profile.phone')}</Styles.PhoneLabel>
            <CopyLabel icon={<IconContentCopy/>} label={profile.phoneNo}/>
            <Styles.EmailLabel darkMode={darkMode}>{t('profile.email')}</Styles.EmailLabel>
            <Styles.Email>{profile.email}</Styles.Email>
          </Styles.PhoneAndEmailContainer>
      </Styles.ProfileContainer>
    )
}