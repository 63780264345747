import * as StyledComponents from '../StyledComponents';

export const CampaignLinkInputWithHelperText = ({
  helperLabel,
  textLength,
  ...props
}: any): JSX.Element => {
  return (
    <StyledComponents.StyledLinkInputContainer>
      <StyledComponents.StyledNameAndLinkTextArea {...props} />
      <StyledComponents.StyledNameHelperLabelContainer>
        <StyledComponents.StyledNameHelperLabel>
          {helperLabel}
        </StyledComponents.StyledNameHelperLabel>
        {textLength?.length &&
        <StyledComponents.StyledNameCountLabel>
          {textLength}
        </StyledComponents.StyledNameCountLabel>}
      </StyledComponents.StyledNameHelperLabelContainer>
    </StyledComponents.StyledLinkInputContainer>
  );
};
