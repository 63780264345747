import { useAnalytics } from 'dms-analytics';
import { useUpdateApplication, useFetchDMSBoxMapping, useEncryptParams } from '../../hooks/queries';
import BaseCard from './BaseCard';
import { MultipleDMSDialog } from '../Dialogs/MultipleDMSDialog';
import React from 'react';
import { CNumber } from '../../types';
import { useCookies } from '../../hooks/useCookies';
import get from 'lodash/get';
import { IOktaContext } from '@okta/okta-react/bundles/types/OktaContext';
import { useOktaAuth } from '@okta/okta-react';
import { getDMSProxyFirewallUrl, getDMSProxyFirewallUrlEncrypted } from '../../util/getDMSProxyUrls';
import { AMPLITUDE_EVENTS } from '../../constants';
import {driveLaunchFallback} from '../../util/driveLaunchFallback'
import { useDriveCNumbersStore } from '../../Store';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

const DMSCNumber = 0;

interface Props {
  id: string;
  isEnabled: boolean;
  isFavorite?: boolean;
  description: string;
  name: string;
  iconColor: string;
  iconText: string;
  url: string;
  onCdkuOpen: Function
  cdkuUrl: string;
}

export default function DriveApplicationCard({
  id,
  isFavorite,
  isEnabled,
  description,
  name,
  iconColor,
  iconText,
  onCdkuOpen,
  cdkuUrl = '',
}: Props) {
  /**
   * hooks
   */
  const { mutate } = useUpdateApplication();
  const session: IOktaContext = useOktaAuth();

  const authToken = get(session, 'authState.idToken.idToken', '');
  const dmsCNumbers = useDriveCNumbersStore((state:any) => state.cNumbers);
  const isFetching = useDriveCNumbersStore((state:any) => state.isFetched);
  const { data: dmsBoxes } = useFetchDMSBoxMapping();

  const [shouldMultipleDMSDialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const { trackEvent } = useAnalytics();
  const ipAddressFlag = useFeatureFlag('UNIFY_ENCRYPT_IPADDRESS');
  const { url } = getDMSProxyFirewallUrl({
    cNumber: get(dmsCNumbers, [DMSCNumber, 'cNumber']),
    driveToken: authToken,
  });

  const { data: encryptedParams } = useEncryptParams();

  const handleOpenDrive = async () => {
    trackEvent(AMPLITUDE_EVENTS.OPEN_APPLICATION, {
      Application: name,
    });

    if ((dmsCNumbers?.length as unknown as number) > 1) {
      return setDialogOpen(true);
    }

    /**
     * Logic at Drive side to open it via custom Portal, hence assume that Registry edit is in place and
     *  cdkdrive://url will invoke the Drive installed or launch it.
     *  func driveLaunchFallback accepts the dms proxy url
     *  @returns new Promise<{isProcessing:boolean,status:string, uri:string}>
     */

    const { url: encryptedUrl} = getDMSProxyFirewallUrlEncrypted({ driveToken: encryptedParams?.data });
    const driveLaunchResponse = await driveLaunchFallback( (ipAddressFlag && encryptedUrl) ? encryptedUrl : url);
    console.log(driveLaunchResponse,':drive launch');

    // For adding Alias Name
    const dmsDataWithName = dmsCNumbers?.map((cNumber: any) => {
      const dmsBoxAliasName =  dmsBoxes?.dmsBoxMappings
          .find(eachBoxDetails=>(eachBoxDetails?.cnumber as string)
              .toLowerCase() === (cNumber.cNumber).toLowerCase())
          ?.alias || ''
      return {
          'data-testid': cNumber.cNumber,
          label: `${cNumber.cNumber} ${dmsBoxAliasName && `- ${dmsBoxAliasName}`}`,
          value: cNumber.cNumber,
          dmsUserId: cNumber.dmsUserId,
          dmsBoxAliasName: dmsBoxAliasName.toLowerCase()
      };
  });

    trackEvent(AMPLITUDE_EVENTS.OPEN_DRIVE, {
      'C-Number': dmsDataWithName[0].value, 'Name' : dmsDataWithName[0].label
    });
    return setDialogOpen(false);

  };

  const onFavoriteClick = () => {
    mutate({ id, isFavorite: Boolean(!isFavorite) });
    trackFavoriteChange(!isFavorite);
  };

  const trackFavoriteChange = (isFavorite: boolean) => {
    isFavorite &&
      trackEvent(AMPLITUDE_EVENTS.OPEN_APPLICATION, {
        'Favorite Application': name,
      });
  };

  useCookies({
    name: 'ID_TOKEN',
    value: authToken,
    sameSite: 'None',
    maxAge: 28800,
  });

  return (
    <>
      {shouldMultipleDMSDialogOpen && (
        <MultipleDMSDialog
          setDialogOpen={setDialogOpen}
          shouldDialogOpen={shouldMultipleDMSDialogOpen}
          dmsCNumbers={dmsCNumbers as CNumber[]}
          isFetching={isFetching}
        />
      )}
      <BaseCard
        onOpenClick={handleOpenDrive}
        name={name}
        iconColor={iconColor}
        description={description}
        iconText={iconText}
        isEnabled={isEnabled}
        isFavorite={isFavorite}
        onFavoriteClick={onFavoriteClick}
        isIconRequired={true}
        onCdkuOpen={onCdkuOpen}
        cdkuUrl={cdkuUrl}
      />
    </>
  );
}
