import { StateCreator } from 'zustand';
import { IReleaseModel, defaultReleaseModel } from '../slices/types/types';

export interface ICreateFeatureReleaseSlice {
  releaseSearchValue: string;
  setReleaseSearchValue: (releaseSearchValue: string) => void;
  releaseArchiveSwitch: boolean;
  setReleaseArchiveSwitch: (archiveSwitchValue: boolean) => void;
  releaseSideSheetData: IReleaseModel;
  setReleaseSideSheetData: (releaseSideSheetData: IReleaseModel) => void;
  updateReleaseSideSheetData: (event: any) => void;
  resetReleaseSideSheetState: () => void;
  setReleaseShowToastState: (toastMsg: string, toastType: string) => void;
  resetReleaseToastState: () => void;
  releaseToastMsg: string,
  releaseToastType: string,
  releaseShowToast: boolean
  setReleaseDeleteDialogOpen: (releaseDeleteDialogOpen: boolean) => void;
  releaseDeleteDialogOpen: boolean
  setIsReleaseValidationState: (isReleaseValidationState: boolean) => void;
  isReleaseValidationState: boolean;
  setReleasePermission: (releasePermission: any) => void;
  releasePermission: any;
  releaseNotifications: any,
  setReleaseNotifications: (releaseNotifications: any) => void;
}

const resetReleaseSideSheetState = (set: any) => () =>
  set((state: any) => {
    return {
      releaseSideSheetData: defaultReleaseModel
    };
  });

const setReleaseShowToastState = (set: any) => (toastMsg: string, toastType: string) =>
  set((state: any) => {
    return {
      releaseToastMsg: toastMsg,
      releaseToastType: toastType,
      releaseShowToast: true
    };
  });

const resetReleaseToastState = (set: any) => () =>
  set((state: any) => {
    return {
      releaseToastMsg: '',
      releaseToastType: '',
      releaseShowToast: false
    };
  });

const _setReleaseNotifications = (set: any) => (notifications: any) =>
  set((state: any) => {
    return {
      releaseNotifications: [...notifications]
    };
  });

export const createFeatureReleaseSlice: StateCreator<ICreateFeatureReleaseSlice,
  [['zustand/devtools', never]],
  [],
  ICreateFeatureReleaseSlice> = (set) => {
    return {
      releaseToastMsg: '',
      releaseShowToast: false,
      releaseToastType: '',
      releaseSearchValue: '',
      releaseArchiveSwitch: false,
      releaseNotifications: [],
      setReleaseNotifications: _setReleaseNotifications(set),
      setReleaseSearchValue: (releaseSearchValue: any) => set(() => ({ releaseSearchValue })),
      setReleaseArchiveSwitch: (releaseArchiveSwitch: any) => set(() => ({ releaseArchiveSwitch })),
      releaseSideSheetData: defaultReleaseModel as unknown as | IReleaseModel,
      resetReleaseSideSheetState: resetReleaseSideSheetState(set),
      setReleaseShowToastState: setReleaseShowToastState(set),
      resetReleaseToastState: resetReleaseToastState(set),
      setReleaseSideSheetData: (releaseSideSheetData) => set(() => ({ releaseSideSheetData })),
      setReleaseDeleteDialogOpen: (releaseDeleteDialogOpen) => set(() => ({ releaseDeleteDialogOpen })),
      releaseDeleteDialogOpen: false,
      setIsReleaseValidationState: (isReleaseValidationState) => set(() => ({ isReleaseValidationState })),
      isReleaseValidationState: false,
      setReleasePermission: (releasePermission: any) => set(() => ({ releasePermission })),
      releasePermission: {},
      updateReleaseSideSheetData: (event) =>
        set((state: any) => {
          return {
            releaseSideSheetData: {
              ...state.releaseSideSheetData,
              [event.fieldName]: event.value,
            }
          };
        }),
    };
  };



