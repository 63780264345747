import { useEffect } from 'react';

type Cookie = {
  name: string;
  value: string;
  expires: string | unknown;
  maxAge: string | number;
  path: string;
  domain: string;
  sameSite: string;
};

export const useCookies = (cookie: Partial<Cookie>) => {
  useEffect(() => {
    document.cookie = `${encodeURIComponent(
      cookie.name as string
    )}=${encodeURIComponent(cookie.value as string)};path=${
      cookie.path || '/'
    };Max-Age=${cookie.maxAge}`;
  }, [cookie]);
};
