import { BUTTON_VARIANTS } from 'cdk-radial';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../contexts/Theme';
import { 
  StyledDialog, 
  StyledDialogContentText, 
  StyledDialogTitle, 
  StyledDialogTitleContainer, 
  StyledIconAccountCircle } from './LogoutConfirmDialog';

interface ICardDeleteDialog {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CardDeleteDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: ICardDeleteDialog ): JSX.Element => {
  const { t } = useTranslation();
  const { darkMode } = useTheme();

  return (
    <div>
      <StyledDialog
        buttonsProps={[
          {
            id: 'Cancel',
            dataTestId: 'delete-dialog-cancel',
            onClick: onCancel,
            text: t('logoutConfirmDialog.cancelBtn'),
            variant: BUTTON_VARIANTS.TEXT,
            style: {
              color: darkMode ? 'white' : 'black'
            },
          },
          {
            id: 'delete-card-alert-yes',
            dataTestId:"delete-dialog-yes",
            onClick: onConfirm,
            text: t('userCardForm.delete'),
            variant: BUTTON_VARIANTS.PRIMARY,
            style: {
              color: darkMode ? 'black' : 'white',
              backgroundColor: darkMode ? 'white' : 'black',
            },
          },
        ]}
        id="delete-card-alert"
        dataTestId="delete-card-alert"
        isOpen={isOpen}
        onClose={onCancel}
        title={
          <StyledDialogTitleContainer>
            <StyledIconAccountCircle />
            <StyledDialogTitle>
              {t('userCardForm.deleteTitle')}
            </StyledDialogTitle>
          </StyledDialogTitleContainer>
        }
      >
        <StyledDialogContentText>{t('userCardForm.deleteText')}</StyledDialogContentText>
      </StyledDialog>
    </div>
  );
};
export default CardDeleteDialog;
