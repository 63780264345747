import { useState } from "react";
import { IconButton as RadialIconButton} from 'cdk-radial';
import styled from 'styled-components';
import { useTheme } from '../../contexts/Theme';

export const IconButton = styled(RadialIconButton)`
    border-radius: 8px;
    border-color: black; 
    background-color:white; 
    &&& {
      svg {
        fill: #333;
        transition-property: all;
      }
      :hover {
        background-color:#E6E6E6;
      }
      :active {
        background-color:#CCC;
      }
      :focus-visible {
        background-color: #E6E6E6;
        box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
      }
    }
  `;

  export const CopyButtonStyle = styled.div<{ darkMode: any, isHovering:boolean }>`
    width: 260px;
    font-weight:500;
    position: relative;
    padding: 0px 4px 0px 4px;
    border: 1px solid transparent;
    background: ${({ darkMode,isHovering }) => ( isHovering && darkMode ? 'rgb(102, 102, 102)' :'inherit')};
    :hover {
      border-color: #00000080;
      border-radius:4px;
    } 
  `;


const CopyLabel = ({ icon, label }:any) => {
  const [isHovering, setIsHovering] = useState(false);
  const { darkMode } = useTheme();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const copy = () =>{
    navigator.clipboard.writeText(label);
    setIsHovering(false);
  }

  return (
    <CopyButtonStyle
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      darkMode={darkMode}
      isHovering={isHovering}
      data-testid="copybutton"
    >
      {label}
      
      {isHovering && (
        <div
          style={{
            position: "absolute",
            top: "21px",
            right: "5px",
            border: '1px solid #00000080',
            boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.5)',
            borderRadius: '8px'
          }}
        >
          <IconButton
                dataTestId="icon-button"
                icon={icon}
                size="small"
                onClick={copy}
                iconSize={16}
                text="Button"
                />
        </div>
      )}
    </CopyButtonStyle>
  );
};

export default CopyLabel;