import { useAnalytics } from 'dms-analytics';
import { useHistory, useLocation } from 'react-router-dom';
import { AMPLITUDE_EVENTS } from '../../constants';
import * as Styles from './styles';
import { useTranslation } from 'react-i18next';
import {useApplicationStore, useWorkflowsStore} from '../../Store/index';

export function Tabs() {
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const {t} = useTranslation();

  const applications = useApplicationStore((state:any) => state.applications);
  const filteredApps = applications.filter((app: any) => app.isFavorite);
  const workflows = useWorkflowsStore((state:any) => state.workflows);
  const filteredWorkflows = workflows?.filter((workflow: any) => workflow.isFavorite);
  
  const genNavigate =
    (tab: string, featureName: string = '') =>
    () => {
      featureName && tab === 'myUnify' ?  trackEvent(featureName, {
        'Favorited Apps': filteredApps.length,
        'Favorited Workflows': filteredWorkflows?.length,
        'Is Favorited': filteredApps.length || filteredWorkflows?.length ? true : false,
      }) :  trackEvent(featureName);
      history.push(`/${tab}`);
    };

  const isActive = (tab: string) => {
    return location.pathname.includes(tab);
  };

  return (
    <Styles.TabsHeader>
      <Styles.Tab
        onClick={genNavigate('myUnify', AMPLITUDE_EVENTS.VIEW_MY_UNIFY)}
        data-testid="myunify-tab"
        active={isActive('myUnify')}
        name={'myUnify'}
      >
        {t('tabs.myUnify')}
      </Styles.Tab>
      <Styles.Tab
        onClick={genNavigate(
          'applications',
          AMPLITUDE_EVENTS.VIEW_APPLICATIONS
        )}
        data-testid="applications-tab"
        active={isActive('applications')}
        name={'applications'}
      >
        {t('tabs.applications')}
      </Styles.Tab>
      <Styles.Tab
        onClick={genNavigate('workflows', AMPLITUDE_EVENTS.VIEW_WORKFLOWS)}
        data-testid="workflows-tab"
        active={isActive('workflows')}
        name={'workflows'}
      >
         {t('tabs.workflows')}
      </Styles.Tab>
    </Styles.TabsHeader>
  );
}
