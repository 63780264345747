import styled, {keyframes, css} from 'styled-components';

const TOP = 80;
export const openSheet = keyframes`
  from {
    top: calc(100% - ${TOP}px);
  }
  to {
    top: 48px;
  }
`;

export const openApplicationSheet =
    keyframes`
      from {
        width: 10px;
        height: 10px;
        top: 0 ;
        left: 50%;
        opacity: 0;
        background: white;
      }
      to {
        width: 100%;
        height: calc(100%);
        top: 4%;
        opacity: 1;
        background: white;
      }
    `
export const closeAppMinimization = keyframes`
  from {
    width: 100%;
    height: calc(100% - 100px);
    top: 100px;
    left: 0%;
    opacity: 1;
    background: white;
  }
  to {
    width: 5px;
    height: 5px;
    top: 0;
    left: calc(100% - 210px);
    opacity: 0;
    background: white;
  }
`;

const closeSheet = keyframes`
  from {
    top: ${TOP}px;
  }
  to {
    top: calc(100% - ${TOP}px);
  }
`


export const Sheet = styled.div<{ active: boolean, appMinimizationFlag: boolean, clickX: string, clickY: string }>`
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0px;
  top: 48px !important;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  z-index: ${({active}) => (active ? 101 : 100)};

  iframe {
    border: 0;

    &.show {
      display: show;
    }

    &.hide {
      display: hide;
    }
  }

  &.initial {
    display: none;
  }

  &.open {
    animation: ${({appMinimizationFlag, clickX, clickY}) => !appMinimizationFlag
            ? css`${openSheet} 0.15s ease-in-out 1`
            : css`${openApplicationSheet} 0.55s ease-in-out 1`
    };
    animation-direction: alternate;
    animation-fill-mode: forwards;
    z-index: 1001;
  }

  &.closed {
    animation: ${({appMinimizationFlag}) => !appMinimizationFlag
            ? css`${closeSheet} 0.15s ease-in-out 1`
            : css`${closeAppMinimization} 0.55s ease-in-out 1`
    };
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  h2 {
    margin: 0 0 16px 0;
  }

  h2:hover {
    cursor: pointer;
  }

  &.open h2:hover {
    cursor: text;
  }
`;

export const Frame = styled.iframe`
  height: calc(100vh - 48px);
  width: 100%;
`;


export const FrameOverlayWrapper = styled.div<{ headerIconClicked: boolean }>`
  display: ${({headerIconClicked}) => (headerIconClicked ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  height: 100vh;
`;

