import styled from 'styled-components';
import { IconInfo, Dialog, Divider } from 'cdk-radial';

export const Button = styled.button`
  font-size: 12px;
  border-radius: 8px;
  letter-spacing: 0.8px;
  font-weight: 600;
  border: 1px solid #000;
  padding: 4px 12px;
  background: #fff;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background: #000;
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#F2F2F2')};
  backdrop-filter: blur(3px);
  border: 1px solid ${({ theme }) => (theme.darkMode ? '#333333' : '#e6e6e6')};
  border-radius: ${({ theme }) => theme.carbon.element.card.border.radius};
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-height: 250px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.carbon.element.card.padding};

  :hover{
    background: ${({ theme }) => (theme.darkMode ? '#333333' : '#E6E6E6')};
    border: 1px solid ${({ theme }) => (theme.darkMode ? '#4D4D4D' : '#CCCCCC')};
    cursor: pointer;
    [data-id="icon-card-more"] {
      display: block
    }
  }

  :active {
    background: ${({ theme }) => (theme.darkMode ? '#4D4D4D' : '#CCCCCC')};
    border: 1px solid ${({ theme }) => (theme.darkMode ? '#666666' : '#B3B3B3')};
  }

  :focus-visible {
    background: ${({ theme }) => (theme.darkMode ? '#333333' : '#E6E6E6')};
    border: 1px solid ${({ theme }) => (theme.darkMode ? '#4D4D4D' : '#CCCCCC')};
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) =>
    theme.darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
    outline: none;
  }
  @media (max-width: 470px) {
    width:80vw;
  }
  @media (max-width: 450px) {
    width:82vw;
  }
  @media (max-width: 390px) {
    width:315px;
    [data-id="icon-card-more"] {
      display: block
    }
  }
  @media (max-width: 1024px) {
    [data-id="icon-card-more"] {
      display: block
    }
  }
`;

export const EmptyCard = styled(Card)<{userCard: boolean}>`
 width: ${({ userCard }) => (userCard ? '340px' : '360px')};
  @media (max-width: 1440px) {
    width:570px;
  }
  @media (max-width: 1300px) {
    //width:46%;
    width: ${({ userCard }) => (userCard ? '93%' : '46%')};
  }
  @media (max-width: 906px) {
    width:96%;
  }
    @media (max-width: 770px) {
    width:94%;
  }
  @media (max-width: 470px) {
    width:80vw;
  }
  @media (max-width: 450px) {
    width:82vw;
  }
  @media (max-width: 390px) {
    width:315px;
  }
`;

export const CardDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipse;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.carbon.color.element.card.description};

  [data-tooltip]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
  }

  [data-tooltip]:after {
      content: attr(data-tooltip);
      background-color: #00FF00;
      color: #111;
      font-size: 150%;
      position: absolute;
      padding: 1px 5px 2px 5px;
      bottom: -1.6em;
      left: 100%;
      white-space: nowrap;
      box-shadow: 1px 1px 3px #222222;
      opacity: 0;
      border: 1px solid #111111;
      visibility: hidden;
  }

  [data-tooltip] {
    position: relative;
  }
`;

export const CardHeader = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  gap: 10px 8px;
  font-weight: 600;
`;

export const FrameCard = styled.div`
  border-color: ${({ theme }) => (theme.darkMode ? '#333333' : '#E6E6E6')};
  border-radius: ${({ theme }) => theme.carbon.element.card.border.radius};
  border-style: ${({ theme }) => theme.carbon.element.card.border.style};
  border-width: ${({ theme }) => theme.carbon.element.card.border.width};
  padding: ${({ theme }) => theme.carbon.element.card.padding};
  width: 100%;
  backdrop-filter: blur(3px);
  background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#F2F2F2')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  &:hover {
    background-color: ${({ theme }) =>
    theme.darkMode ? '#333333' : '#F2F2F2'};
    border-color: ${({ theme }) => (theme.darkMode ? '#4D4D4D' : '#E6E6E6')};
    cursor: pointer;
  }
  &.activeCard {
    background-color: ${({ theme }) =>
    theme.darkMode ? '#4D4D4D' : '#F2F2F2'};
    border-color: ${({ theme }) => (theme.darkMode ? '#666666' : '#E6E6E6')};
    box-shadow: ${({ theme }) =>
    theme.darkMode
      ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
      : '0px 0px 0px 4px rgba(0, 0, 0, 0.5);'};
  }
  &:active {
    box-shadow: ${({ theme }) =>
    theme.darkMode
      ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
      : '0px 0px 0px 4px rgba(0, 0, 0, 0.5);'};
  }
  &.inactiveCard {
    border-color: none;
  }
`;

export const CloseButton = styled.div`
  grid-area: 1/3;
  text-align: end;
  margin-top: -30px;
  margin-right: -5px;
`;

export const FrameCardLabel = styled.div`
  align-items: center;
  color: ${({ theme }) => (theme.darkMode ? '#F2F2F2' : '#1A1A1A')};
  grid-area: 1/2;
  font-weight: 'normal';
  font-weight: 600;
  gap: 10px 8px;
  padding-bottom: 8px;
`;

export const FrameCardHeader = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: min-content auto;
  font-weight: 'normal';
  font-weight: 600;
  min-width: 0;
  gap: 10px 8px;
  padding-bottom: 8px;
`;

export const ProductIcon = styled.div<{ iconColor: string }>`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  padding: 0.16rem;
  align-items: flex-start;
  background-color: ${({ iconColor }) => iconColor};
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  grid-area: 1/1;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
  letter-spacing: -0.06em;
  line-height: 18px;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-align: end;
  width: 48px;
`;

export const CardFooter = styled.div`
  align-items: center;
  color: ${({ theme }) => (theme.darkMode ? '#F2F2F2' : '#1A1A1A')};
  display: flex;
  fill: #fff;
  justify-content: space-between;
`;

export const EmptyCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row-reverse;
`;

export const CardTitle = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

export const WarningMessage = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  align-items: center;
  color: ${({ theme }) => theme.carbon.color.warning};
  display: flex;
  font-size: 12px;
  font-weight: 500;
`;

export const WarningIcon = styled(IconInfo)`
  fill:  ${({ theme }) => theme.carbon.color.warning};
  height: 16px;
  width: 16px;
  padding-right: 2px;
`;

export const IconWrap = styled.div`
    position: absolute;
    right: 24px;
    top: 10px;
    height: 20px;
    width: 20px;
    display: none;
    &:hover {
      display: block
    };
`;

export const LearnMoreDialog = styled(Dialog)`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  max-width: 30%;
  border: 0;
  padding: 0;
  margin: 0;
  @media (max-width: 1400px) {
    min-width: 400px;
  }
  @media (max-width: 470px) {
    max-width: 90%;
    width: 90vw;
    min-width: 90vw;
  }
`;
export const LearnMoreCard = styled.div`
  font-family: 'Montserrat', 'Roboto', 'sans-serif';
  background: ${({ theme }) => (theme.darkMode ? '#000' : '#fff')};
  backdrop-filter: blur(3px);
  display: flex;
  font-weight: 600;
  flex-direction: column;
  min-width: 0;
  max-height: 250px;
  justify-content: space-between;
`;

export const LearMoreDescription = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  margin-top: 12px;  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipse;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 8px 4px;
  `;

export const LearMoreDevider = styled(Divider)`
    font-family: 'Montserrat', 'Roboto', sans-serif;
    margin: 6px 0px;
  `;

export const DialogCloseButton = styled.div`
  grid-area: 1/3;
  top: 2px;
  right: 2px;
  position: absolute;
`;