import FeatureReleaseNotificationListHeader from './FeatureReleaseNotificationListHeader';
import { useEffect, useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import * as StyledComponents from '../StyledComponents';
import { GridApi, ColumnApi, ExcelStyle } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AG_GRID_LICENSE } from '../../../../constants';
import { AdminTableBodySkeleton } from '../../Blocks/AdminTableBodySkeleton';
import moment from 'moment';
import { useUnifyAdminStore } from '../../AdminStore';
import {
  strToDate,
  formattedTime
} from '../../utils';
import { DeleteConfirmDialog } from '../Elements/DeleteConfirmDialog';
import {useAllApplicationsStore, useAllWorkflowsStore} from '../../../../Store/index';
import { useGetAllNotifications,
   useRemoveQuery } from '../Store/useReleaseNotificationQuery';
import { Overlay } from './Overlay';
import {excelConfig, defaultColDef, getColumnDefs} from './FeatureReleaseGridConfig';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

export default function FeatureReleaseNotificationList(): JSX.Element {

  const setReleaseShowToastState = useUnifyAdminStore(
    (state) => state.setReleaseShowToastState
  );

  const setSideSheetData = useUnifyAdminStore(
    (state) => state.setReleaseSideSheetData
  );

  const sideSheetData = useUnifyAdminStore(
    (state) => state.releaseSideSheetData
  );

  const setFeatureReleaseSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setReleaseSideSheetCollapse
  );
  const setReleaseNotifications = useUnifyAdminStore(
    (state) => state.setReleaseNotifications
  );

  const deleteDialogOpen = useUnifyAdminStore(
    (state) => state.releaseDeleteDialogOpen
  );
  const setDeleteDialogOpen = useUnifyAdminStore(
    (state) => state.setReleaseDeleteDialogOpen
  );
  const searchValue = useUnifyAdminStore((state) => state.releaseSearchValue);

  const releaseSideSheetData = useUnifyAdminStore(
    (state) => state.releaseSideSheetData
  );
  const releasePermission = useUnifyAdminStore(
    (state) => state.permissions
  );

  const {mutation:deleteMutation} = useRemoveQuery({
    onSuccess: () => {
      setReleaseShowToastState(`Deleted ${sideSheetData.title}`, 'Success');
    },
    onError: () => {
      setReleaseShowToastState('Failed to delete a feature release', 'Error');
    }});

  const deleteRelease = () =>{
    deleteMutation.mutate({messageId:sideSheetData.messageId});
  }

  const applications = useAllApplicationsStore((state:any) => state.applications);
  const workflows = useAllWorkflowsStore((state:any) => state.workflows);
  const archiveSwitch = useUnifyAdminStore((state) => state.releaseArchiveSwitch);

  let columns = getColumnDefs(releasePermission,applications,workflows,archiveSwitch);

  const [gridApi, setGridApi] = useState<GridApi>();
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.setQuickFilter(searchValue);
    }
  }, [searchValue, gridApi]);

  const { data: listOfFeatureReleaseNotification, isFetching } =
  useGetAllNotifications(archiveSwitch);

  useEffect(()=>{
    if(listOfFeatureReleaseNotification){
      setReleaseNotifications(listOfFeatureReleaseNotification);
    }
  },[listOfFeatureReleaseNotification,setReleaseNotifications])

  const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
    setGridApi(params.api);
  };

  const cellClickedListener = useCallback((event: any) => {
    let data = { ...event.data };

    if (event.data.caseId) {
      data.outageType = 'Unplanned';
    } else {
      data.outageType = 'Planned';
    }

    if (data.createdDate) {
      const dateTime = strToDate(data.createdDate);
      data.createdTime =  formattedTime(dateTime);
      data.createdDate = moment(dateTime);
    }

    if (data.startDate) {
      const dateTime = strToDate(data.startDate);
      data.startTime =  formattedTime(dateTime);
      data.startDate = moment(dateTime);
    }

    if (data.endDate) {
      const dateTime = strToDate(data.endDate);
      data.endTime = formattedTime(dateTime);
      data.endDate = moment(dateTime);
    }

    if (event.data.removed) {
      data.status = 'Closed';
    } else {
      data.status = 'Open';
    }

    data.targetApplications = applications && applications[0]
      .filter((app: any) => event.data.target.application?.includes(app.appId))
      .map((app: any) => ({ id: app.appId, label: app.name, value: app.appId }));

    if (workflows && workflows[0]) {
      data.targetWorkflows = workflows[0]
        .filter((workflow: any) =>
          event.data.target.workflow?.includes(workflow.workflowId)
        )
        .map((workflow: any) => ({
          id: workflow.workflowId,
          label: workflow.name,
          value: workflow.workflowId,
        }));
    }

    data.targetEnterprises = event.data.target.enterpriseIds?.join(', ');
    data.linkToLearnMore = data.externalUrl;

    setSideSheetData(data);

    if (event.colDef.colId !== 'Delete') {
      setFeatureReleaseSideSheetCollapse(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FeatureReleaseNotificationListHeader gridRef={gridRef} />
      {deleteDialogOpen && (
        <DeleteConfirmDialog
          title={releaseSideSheetData.title}
          subject={releaseSideSheetData.subject}
          isOpen={true}
          onYes={() => {
            deleteRelease();
            setDeleteDialogOpen(false);
          }}
          onNo={() => {
            setDeleteDialogOpen(false);
          }}
        ></DeleteConfirmDialog>
      )}
      <Overlay isOpen={deleteDialogOpen} panelId="panel-4"/>
      {isFetching ? (
        <AdminTableBodySkeleton />
      ) : (
        <StyledComponents.StyledTableContainer>
          <StyledComponents.StyledTableWrapper className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={listOfFeatureReleaseNotification}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              animateRows={true}
              rowSelection="single"
              excludeHiddenColumnsFromQuickFilter={true}
              suppressDragLeaveHidesColumns={true}
              defaultExcelExportParams={excelConfig}
              excelStyles={excelConfig.excelStyles as ExcelStyle[]}
              onCellClicked={cellClickedListener}
            />
          </StyledComponents.StyledTableWrapper>
        </StyledComponents.StyledTableContainer>
      )}
    </>
  );
}
