import { useEffect, useState, useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DRIVE_TITLE_ID, UNIFY_ADMIN_ROUTERS, URL_ROUTES, REACT_APP_DWM_ID } from '../constants';
import ApplicationsTab from './ApplicationsTab/ApplicationsTab';
import MyUnifyTab from './MyUnifyTab/MyUnifyTab';
import WorkflowsTab from './WorkflowsTab/WorkflowsTab';
import CardSkeleton from './CardSkeleton/CardSkeleton';
import {
  BypassableRoute,
  BypassableLoginCallback,
} from './common/BypassableRoutes';
import styled from 'styled-components';
import ErrorHandlingDialog from './Dialogs/ErrorDialog';
import { Workflow, WorkflowViewMap } from '../types';
import { useSetDriveAccess } from '../contexts/AppStateContext';
import UnifyMainContainer from './UnifyAdministration/Containers/UnifyMainContainer';
import CMSAdminContainer from './UnifyAdministration/CampaignManagement/Containers/CMSAdminContainer';
import OutageAdminContainer from './UnifyAdministration/Notification/Containers/Outage/OutageAdminContainer';
import UnifyAdminContainer from './UnifyAdministration/Index';
import { useAnalytics } from 'dms-analytics';
import { AMPLITUDE_EVENTS } from '../constants';
import { useApplicationStore, useWorkflowsStore, useWorkflowsViewMapStore } from '../Store';

/**
 * @desc Routes Paths
 */

const enum ROUTES_PATHS {
  'MY_UNIFY' = 'myUnify',
  'APPLICATIONS' = 'applications',
  'WORKFLOWS' = 'workflows',
  'UNIFY_ADMIN' = 'admin',
}

const RouteContainer = styled.div`
  padding: 0 24px 24px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export default function Routes() {
  const applications = useApplicationStore((state:any) => state.applications);
  const apiStatus = useApplicationStore((state:any) => state.apiStatus);
  const isFetched = useApplicationStore((state:any) => state.isFetched);
  const [defaultRoute, setDefaultRoute] = useState<string>('/');
  const [showErrorDialog, toggleErrorDialog] = useState(false);
  const filteredApps = applications.filter((app: any) => app.isFavorite);
  const workflows: WorkflowViewMap  = useWorkflowsViewMapStore((state:any) => state.workflowsViewMap);
  const status = useWorkflowsViewMapStore((state:any) => state.apiStatus);
  const filteredWorkflows = Object.entries(workflows).filter(
    ([_applicationTitle, domain]) => {
      if (Array.isArray(domain)) {
        const filteredDomains = Object.entries(domain).filter(
          ([_domainTitle, _workflow]) => {
            return _workflow.isFavorite;
          }
        );
        return filteredDomains.length > 0;
      } else {
        const filteredDomains = Object.entries(domain).filter(
          ([domainTitle, _workflows]) => {
            return (_workflows as Workflow[]).some(
              (workflow: Workflow) => workflow.isFavorite
            );
          }
        );
        return filteredDomains.length > 0;
      }
    }
  );
  const myUnifyWorkflows = useWorkflowsViewMapStore((state:any) => state.favoriteWorkflowsViewMap);
  const { trackEvent } = useAnalytics();
  const userwWorkflows  = useWorkflowsStore((state:any) => state.workflows);
  const driveWorkflows  = userwWorkflows?.filter((workflow: any) => workflow.appId === REACT_APP_DWM_ID)
  const favoritedWorkflows = userwWorkflows?.filter((workflow: any) => workflow.isFavorite);

  const generateDefaultRoute = useCallback(() => {
    /* Note: Unify admin is rendering on a separate route and needs full refresh to update the window location and pathnam
     */
    if (window.location.pathname === '/admin') {
      return ROUTES_PATHS.UNIFY_ADMIN;
    }
    if (isFetched) {

      if(favoritedWorkflows?.length || filteredApps.length)
      {
      trackEvent(AMPLITUDE_EVENTS.VIEW_MY_UNIFY, {
        'Favorited Apps': filteredApps.length,
        'Favorited Workflows': favoritedWorkflows?.length,
        'Is Favorited': true ,
      });
    }

      return filteredWorkflows.length || filteredApps.length
        ? ROUTES_PATHS.MY_UNIFY :
        driveWorkflows.length ? 
        ROUTES_PATHS.WORKFLOWS : ROUTES_PATHS.APPLICATIONS;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, driveWorkflows.length]);

  const driveAccess = !!applications.find((app: any) => app.id === DRIVE_TITLE_ID);
  const setDriveAccess = useSetDriveAccess();
  setDriveAccess && setDriveAccess(driveAccess);

  useEffect(() => {
    toggleErrorDialog(apiStatus === 'error');
  }, [apiStatus]);

  useEffect(() => {
    if(generateDefaultRoute()){
      setDefaultRoute(generateDefaultRoute() as unknown as string);
    }
  }, [generateDefaultRoute]);

  if (apiStatus === 'error') {
    return (
      <RouteContainer>
        <ErrorHandlingDialog
          isOpen={showErrorDialog}
          onClose={() => toggleErrorDialog(false)}
          onReload={() => window.location.reload()}
        />
      </RouteContainer>
    );
  }
  return (
    <RouteContainer>
      {apiStatus === 'loading' ? (
        <>
          <BypassableRoute path="/" render={() => <CardSkeleton />} />
        </>
      ) : (
        <>
          <BypassableRoute
            path="/"
            render={() => <Redirect to={defaultRoute} />}
          />
          <BypassableRoute
            path={URL_ROUTES.applications}
            render={() => (
              <ApplicationsTab
                key="application-tab"
                type={Array}
                apps={applications}
              />
            )}
          />

          <BypassableRoute
            path={URL_ROUTES.workflows}
            render={() => (
              <WorkflowsTab
                key="workflow-tab"
                type={undefined}
                appList={applications}
                appStatus={apiStatus}
                workflowList={workflows}
                workflowStatus={status}
              />
            )}
          />
          <BypassableRoute
            path={URL_ROUTES.myunify}
            render={() => (
              <MyUnifyTab
                key="my-unify-tab"
                type={undefined}
                appList={applications}
                appStatus={apiStatus}
                workflowList={myUnifyWorkflows}
                workflowStatus={status}
              />
            )}
          />
          <BypassableRoute
            path={URL_ROUTES.unifyAdmin}
            render={() => <UnifyMainContainer />}
          />
          <BypassableRoute
            path={UNIFY_ADMIN_ROUTERS.applicationAdmin}
            render={() => <UnifyAdminContainer />}
          />
          <BypassableRoute
            path={UNIFY_ADMIN_ROUTERS.campaignAdmin}
            render={() => <CMSAdminContainer />}
          />
        <BypassableRoute
            path={UNIFY_ADMIN_ROUTERS.productOutageAdmin}
            render={() => <OutageAdminContainer />}
          />

          <Route
            exact
            path="/login/callback"
            component={BypassableLoginCallback}
          />
        </>
      )}
    </RouteContainer>
  );
}
