import {useUserProfile} from "../../../hooks/queries";
import find from 'lodash/find'
import get from 'lodash/get'

enum USER_ACCESS_TYPE {
    APP_READ = 'UNIFY_READ_ONLY',
    APP_UPDATE = 'UNIFY_UPDATE',
    APP_SUPER_ADMIN = 'UNIFY_ADMIN',
    CAMPAIGN_READ = 'CAMPAIGN_VIEW',
    CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE',
    CAMPAIGN_SUPER_ADMIN = 'CAMPAIGN_ADMIN',
    PRODUCT_ALERT_READ = 'OUTAGE_NOTIFICATION_VIEW',
    PRODUCT_ALERT_UPDATE = 'OUTAGE_NOTIFICATION_UPDATE',
    PRODUCT_ALERT_ADMIN = 'OUTAGE_NOTIFICATION_ADMIN',
    RELEASE_READ = 'RELEASE_NOTIFICATION_VIEW',
    RELEASE_UPDATE = 'RELEASE_NOTIFICATION_UPDATE',
    RELEASE_ADMIN = 'RELEASE_NOTIFICATION_ADMIN',
    NPV_EMBEDDED_INTELLIGENCE = 'NPV_EMBEDDED_INTELLIGENCE',
    CONVERSATIONAL_AI_INTERFACE = 'CONVERSATIONAL_AI_INTERFACE'
}

enum PERMISSIONS {
    hasAppReadOnlyPermissions = 'hasAppReadOnlyPermissions',
    hasAppUpdatePermissions = 'hasAppUpdatePermissions',
    hasAppSuperAdminPermissions = 'hasAppSuperAdminPermissions',
    hasCampaignReadOnlyPermissions = 'hasCampaignReadOnlyPermissions',
    hasCampaignUpdatePermissions = 'hasCampaignUpdatePermissions',
    hasCampaignSuperAdminPermissions = 'hasCampaignSuperAdminPermissions',
    hasProductAlertReadOnlyPermissions = 'hasProductAlertReadOnlyPermissions',
    hasProductAlertUpdatePermissions = 'hasProductAlertUpdatePermissions',
    hasProductAlertSuperAdminPermissions = 'hasProductAlertSuperAdminPermissions',
    hasReleaseReadOnlyPermissions = 'hasReleaseReadOnlyPermissions',
    hasReleaseUpdatePermissions = 'hasReleaseUpdatePermissions',
    hasReleaseSuperAdminPermissions = 'hasReleaseSuperAdminPermissions',
    hasEmbeddedIntelligencePermission = 'hasEmbeddedIntelligencePermission'
}

interface UseUnifyAdmin {
    isUnifyEnterpriseAdmin: boolean | string,
    permissions: Record<keyof typeof PERMISSIONS, boolean>,
    isLoading: boolean,
    userType: USER_ACCESS_TYPE
}

const UNIFY_ENTERPRISE_ADMIN = 'UNIFY_ADMIN'
export const useUnifyAdmin = (): Partial<UseUnifyAdmin> => {
    const {
        data: oktaUser,
        isLoading
    } = useUserProfile();

    const permissions = get(oktaUser, 'profile.entitlements', []);
   
    const hasAppReadOnlyPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.APP_READ));
    const hasAppUpdatePermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.APP_UPDATE))
    const hasAppSuperAdminPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.APP_SUPER_ADMIN));

    const hasCampaignReadOnlyPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.CAMPAIGN_READ));
    const hasCampaignUpdatePermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.CAMPAIGN_UPDATE))
    const hasCampaignSuperAdminPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.CAMPAIGN_SUPER_ADMIN));

    const hasProductAlertReadOnlyPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.PRODUCT_ALERT_READ));
    const hasProductAlertUpdatePermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.PRODUCT_ALERT_UPDATE))
    const hasProductAlertSuperAdminPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.PRODUCT_ALERT_ADMIN));

    const hasReleaseReadOnlyPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.RELEASE_READ));
    const hasReleaseUpdatePermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.RELEASE_UPDATE))
    const hasReleaseSuperAdminPermissions = permissions?.some((obj: any) => JSON.parse(obj).hasOwnProperty(USER_ACCESS_TYPE.RELEASE_ADMIN));

    const hasEmbeddedIntelligencePermission = permissions?.reduce((acc, item) => {  
        if(!acc) {
            acc = (JSON.parse(item)[USER_ACCESS_TYPE.NPV_EMBEDDED_INTELLIGENCE] as [])?.find(
                (prm) => prm === USER_ACCESS_TYPE.CONVERSATIONAL_AI_INTERFACE) === USER_ACCESS_TYPE.CONVERSATIONAL_AI_INTERFACE;
        }
        return acc }, false);

    return {
        isUnifyEnterpriseAdmin: !!find(get(oktaUser, 'profile.productCodes', []), (product) => product === UNIFY_ENTERPRISE_ADMIN),
        permissions: {
            hasAppReadOnlyPermissions,
            hasAppUpdatePermissions,
            hasAppSuperAdminPermissions,
            hasCampaignReadOnlyPermissions,
            hasCampaignUpdatePermissions,
            hasCampaignSuperAdminPermissions,
            hasProductAlertReadOnlyPermissions,
            hasProductAlertUpdatePermissions,
            hasProductAlertSuperAdminPermissions,
            hasReleaseReadOnlyPermissions,
            hasReleaseUpdatePermissions,
            hasReleaseSuperAdminPermissions,
            hasEmbeddedIntelligencePermission
        },
        isLoading
    }
}