import styled from 'styled-components';

export const ProfileFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 42px;
`;

export const ProfileBody = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  overflow: auto;
`;

export const LogoutButton = styled.button`
  font-size: 12px;
  height: 30px;
  border-radius: 8px;
  letter-spacing: 0.8px;
  font-weight: 600;
  color: #fff;
  background: #666;
  padding: 4px 12px;
  text-transform: uppercase;
  &:hover {
    background: #ccc;
    color: #000;
    cursor: pointer;
  }
`;

export const Titles = styled.div`
  background: #000;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 14px;
`;

export const ProfileImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 3px solid #fff;
  background-color: #ccc;
`;

export const ProfileHeader = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  flex-direction: row;
  background-color: #000;
  align-items: top;
  h2 {
    color: white;
    margin: 2px 0 0 20px;
    font-size: 18px;
  }
  h4 {
    color: white;
    font-weight: normal;
    font-size: 14px;
    margin: 4px 0 0 20px;
  }
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
`;

export const Label = styled.div`
  margin-top: 8px;
  font-size: 14px;
`;

export const Value = styled.div`
  margin-top: 8px;
  font-size: 14px;
  word-wrap: break-word;
`;

export const ProfileContent = styled.div`
  height: 100%;
  font-family: 'Montserrat', 'Roboto', sans-serif;
  h2 {
    margin-bottom: 4px;
  }
`;
