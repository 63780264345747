import React from 'react';
import { SideSheetContent } from '../constants';

interface ContextValue {
  sideSheetContent: SideSheetContent | null;
  setSideSheetContent: (val: SideSheetContent | null) => void;
}

export const SideSheetContext = React.createContext<null | ContextValue>(null);

export const useSideSheet = () => {
  const value = React.useContext(SideSheetContext);
  if (!value) {
    throw new Error('useSideSheet must be used inside SideSheetProvider');
  }
  return value;
};
