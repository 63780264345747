import { Divider, FailedToLoadArrowIllustration } from 'cdk-radial';
import * as Styles from './styles';
import { SUPPORT_LINK } from '../../constants';
import { useTranslation } from 'react-i18next';
type NoDataErrorProps = { headerText: string };

export function NoDataError({ headerText }: NoDataErrorProps) {
  const {t} = useTranslation();

  return (
    <Styles.NoDataContainer data-testid="no-data-container">
      <Styles.NoDataHeader>
        <Styles.WarningIcon />
        {headerText}
      </Styles.NoDataHeader>
      <Divider />
      <Styles.NoDataBody>
        <Styles.FailedToLoadArrowIllustration>
          <FailedToLoadArrowIllustration size={250} />
        </Styles.FailedToLoadArrowIllustration>
        <p>{ t('noDataError.message')}</p>
        <p>
          {t('noDataError.contact')}
          &nbsp;
          <Styles.SupportLink
            data-testid="support-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            CDK Support
          </Styles.SupportLink>
          &nbsp;
          {t('noDataError.text')}
        </p>
      </Styles.NoDataBody>
      <Styles.NoDataFooter>
        <Styles.ReloadButton
          data-testid="reload-button"
          text={t('noDataError.reloadPage')}
          onClick={() => window.location.reload()}
          variant="secondary"
        />
      </Styles.NoDataFooter>
    </Styles.NoDataContainer>
  );
}

export function Loader() {
  return (
    <Styles.LoaderContainer>
      <Styles.Loader />
    </Styles.LoaderContainer>
  );
}
