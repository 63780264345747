import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as Styles from '../StyledComponents/index';
import {useTheme} from '../../../../contexts/Theme';
import CampaignPreviewItem from './CampaignPreviewItem';
import {RoundedIconButton} from '../../../Buttons/IconButton';
import {IconExpandLess} from 'cdk-radial';
import {useUnifyAdminStore} from '../../AdminStore';

export default function CampaignPreviewBannerItem(props: { closetColor: Function}) {
    const {darkMode} = useTheme();
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);

    return (
        <>
            <Styles.CampaignBannerWrapper>
                <Styles.CampaignCarouselContainer data-testid="banner">
                    <Styles.CampaignCarouselCloseContainer>
                        <RoundedIconButton
                            darkMode={darkMode}
                            data-testid="closeIcon"
                            icon={<IconExpandLess/>}
                            isBannerButton
                            text=''
                        />
                    </Styles.CampaignCarouselCloseContainer>
                    {/*@ts-ignore*/}
                    {campaignItems && <CampaignPreviewItem closetColor={props.closetColor} bannerItems={[campaignItems,{ ...campaignItems, id: 'A' }]}/>}
                </Styles.CampaignCarouselContainer>
            </Styles.CampaignBannerWrapper>
        </>
    );
}
