import React from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const CdkuURLField = (): JSX.Element => {

    const permissions = useUnifyAdminStore(state => state.permissions);
    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const createNewApplication = useUnifyAdminStore(state => state.createNewApplication)
    const sideSheetSubWorkflowData = useUnifyAdminStore(
        (state) => state.sideSheetDataSubWorkflow
    );
    const isWorkflowSideSheetCollapse = useUnifyAdminStore(
        (state) => state.isWorkflowSideSheetCollapse
    );
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );

    /**
     * Local Component derived states
     */
    const label = createNewApplication 
        ? 'Application'
        : 'Workflow';

    const appCdkuURLValue = get(
        !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
        'cdkuUrl',
        ''
    );

    const handleURLUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <>
            <StyledComponents.StyledInput
                dataTestId={`${label}-cdku-url-input`}
                errorMessage="Error message"
                id={`${label}-cdku-url-input`}
                inputDivider="left"
                label={`CDKU URL`}
                name="cdkuUrl"
                onChange={handleURLUpdate}
                onInputClear={function noRefCheck() {
                }}
                placeholder={`CDKU URL`}
                value={appCdkuURLValue}
                maxLength="300"
                isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
            />
        </>
    );
};
