import {create} from "zustand";

interface HeaderState {
  headerIconClicked:boolean;
  setHeaderIconClicked: (headerIconClicked:any) => void;
}

export const useStore = create<HeaderState>((set) => ({
   headerIconClicked: false,
   setHeaderIconClicked: (headerIconClicked:any) => set({ headerIconClicked }),
 }));