import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextButton } from '../Buttons/TextButton';
import { RoundedIconButton } from '../Buttons/IconButton';
import {IconClose,IconChevronRight,IconChevronLeft} from 'cdk-radial';
import {
  StyledOutageNotificationBanner,
  StyledOutageNotificationHeading,
  StyledOutageNotificationSubject,
  StyledOutageNotificationPagination,
  StyledBanner,
  StyledNotificationTime
} from './styles';
import { INotification } from './Notification';
import moment from "moment";

const OutageNotificationBanner = (
  props: {
    items: INotification[],
    onClose: Function,
    onLearnMoreClick:Function
}
) => {
  
  const { t:translate } = useTranslation()
  const {items,onClose,onLearnMoreClick} = props;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * 1;
  const indexOfFirstItem = indexOfLastItem - 1;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = items.length;

  const handleNextClick = () => setCurrentPage(currentPage + 1);
  const handlePrevClick = () => setCurrentPage(currentPage - 1);
  const handleClose = () => onClose();
  const handleLearnMoreClick = () => onLearnMoreClick(currentItems[0]);

  return (
    <StyledBanner data-testid="notification-banner" >
      {
        currentItems.map((item: any) => (
          <StyledOutageNotificationBanner key={item}>
            <div style={{flexBasis:'15%'}}>
              <StyledOutageNotificationHeading>{translate('banner.cdk-outage-label')}</StyledOutageNotificationHeading>
            </div>
              <StyledOutageNotificationSubject>{item.subject}</StyledOutageNotificationSubject>
            <StyledNotificationTime>
            {moment.utc(
                        item.receivedDate
                    ).local().format('MM/DD/YYYY h:mm:ss A')}
            </StyledNotificationTime>
            <div style={{flexBasis:'9%'}}>
                  <TextButton
                  style={{border:"none"}}
                  onClick={handleLearnMoreClick}
                  size="standard"
                  text={translate('banner.learn_more')} />
          </div>
          <div >
            { items.length > 1 && 
                  <StyledOutageNotificationPagination>
                    { 
                      currentPage !== 1 &&
                        <RoundedIconButton
                              onClick={handlePrevClick}
                              data-testid="o-n-left"
                              icon={<IconChevronLeft/>}
                              isDisabled={currentPage === 1}
                              text="Previous"
                          />
                      }
                      { 
                      currentPage === 1 &&
                        <div style={{width:'36px'}}></div>
                      }
                          <span>{`${currentPage} of ${totalPages}`}</span>
                        {
                        currentPage !== totalPages &&
                        <RoundedIconButton
                                data-testid="o-n-right"
                                icon={<IconChevronRight/>}
                                onClick={handleNextClick}
                                isDisabled={currentPage === totalPages}
                                text="Next"
                            />
                        }
                        { 
                      currentPage === totalPages &&
                        <div style={{width:'36px'}}></div>
                      }
                      </StyledOutageNotificationPagination>
                    }
            </div>
            <div style={{flexBasis:'4.3%', right: '2px', position: "absolute"}}>
                <RoundedIconButton
                          data-testid="o-n-close-icon"
                          size="small"
                          icon={<IconClose/>}
                          onClick={handleClose}
                          text="BannerClose"
                      />
            </div>
        </StyledOutageNotificationBanner>
        ))}
    </StyledBanner>
  );
};

export default OutageNotificationBanner;