export interface IOutageModel {
    messageId: string;
    caseId: string;
    subject: string,
    title: string;
    linkToLearnMore: string;
    body: string;
    outageType: string;
    targetApplications: string[];
    targetWorkflows: string[];
    startTime: string,
    endTime: string,
    createdDate: any,
    createdTime: string,
    startDate: any,
    endDate: string,
    targetEnterprises: string,
    status: string
}

export interface IReleaseModel {
    messageId: string;
    subject: string,
    title: string;
    linkToLearnMore: string;
    body: string;
    outageType: string;
    targetApplications: string[];
    targetWorkflows: string[];
    startTime: string,
    endTime: string,
    createdDate: any,
    createdTime: string,
    startDate: any,
    endDate: any,
    targetEnterprises: string,
    status: string
}

export const defaultOutageModel = {
    caseId: '',
    title: '',
    subject: '',
    linkToLearnMore: '',
    body: '',
    targetApplications: [],
    targetWorkflows: [],
    targetEnterprises: '',
    outageType: 'Unplanned',
    startTime: '',
    endTime: '',
    createdDate: null,
    startDate: null,
    endDate: null,
    status: 'open'
};


export const defaultReleaseModel = {
    caseId: '',
    title: '',
    subject: '',
    linkToLearnMore: '',
    body: '',
    targetApplications: [],
    targetWorkflows: [],
    targetEnterprises: '',
    startTime: '',
    endTime: '',
    createdDate: null,
    startDate: null,
    endDate: null,
};
