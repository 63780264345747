import { BADGE_POSITIONS, withBadge,withTooltip,IconNotification } from 'cdk-radial';
import {useNotificationStore} from '../../Store';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
import { generateTooltipProps } from './Header';


const IconNotificationButton = (props: {
    onClick: Function
  }) => {
    const { t } = useTranslation();
  const HeaderNotificationIcon = withTooltip(Styles.HeaderButton, generateTooltipProps('Notifications',t('notification.toolTip')))

  const unreadCount = useNotificationStore((state:any) => state.unreadCount);
  let count = useNotificationStore((state:any) => state.count);

  const setshouldNotificationRefresh = useNotificationStore((state:any) => state.setshouldNotificationRefresh);
    
    const onClickHandler = (event:any) =>{
      setshouldNotificationRefresh(true);
      props.onClick(event);
    }

    const IconButtonComponent = () => { 
      return (<HeaderNotificationIcon
                  onClick={onClickHandler}
                  backgroundAppearance={'dark'}
                  icon={<IconNotification />}
                  text={t('header.notification')}
                  dataTestId="header-icon-notification"
        />)
    };

    const IconButtonWithBadge = withBadge(IconButtonComponent, {
      children: count > 99 ? '99+' : count,
      position: BADGE_POSITIONS.RIGHT,
      badgeColor: unreadCount > 0 ? '#EB0C00' : '#4D4D4D'
    });

      return (
        <>
            {count > 0 && <IconButtonWithBadge></IconButtonWithBadge> }
            {count === 0 &&<IconButtonComponent></IconButtonComponent> }
          </>
        );
  };

export default IconNotificationButton;