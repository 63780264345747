import styled from 'styled-components';
import { Button } from '../Buttons/Button';

export const HistoryContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const HistoryContentWrapper = styled.div`
  padding: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow-y: scroll;
`;

export const HistoryItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 108px;
  margin: 15px;
  cursor: pointer;
`;

export const CloseAllButton = styled(Button)`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 20px;
  font-size: 13px;
  right:0px;
  && {
    background-color: transparent;
    color: ${({ darkMode }) => (darkMode ? '#E6E6E6' : '#333')};
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      color: ${({ darkMode }) => (darkMode ? '#F2F2F2' : '#333')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
`;

export const CloseAllButtonContainer = styled.div`
  width: 100vw;
  margin-left: 75%;
`;


