import React from 'react';
import { PaneManagementContext } from '../../contexts/PaneManagementContext';

export default function PaneManagementProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isPaneExpaneded, setIsPaneExpanded] = React.useState(false);
  const [openPanes, setOpenPanes] = React.useState<string[]>(() => {
    let paneString = localStorage.getItem('openPanes') || '[]';
    const panes = JSON.parse(paneString);
    return panes as string[];
  });
  
  const [ctaPane, setCtaOpenPane] = React.useState<any>();
  const [cdkuPane, setCdkuOpenPane] = React.useState<any>(); 
  const [isCdkuUrl, setisCdkuUrl] = React.useState<any>(); 

  const [activePane, setActivePane] = React.useState<string | null>(() => {
    const active = localStorage.getItem('activePane');
    return active as string;
  });

  const toggleIsPaneExpanded = (isExpanded?: boolean) =>
    setIsPaneExpanded(isExpanded === undefined ? !isPaneExpaneded : isExpanded);

  React.useEffect(() => {
    localStorage.setItem('openPanes', JSON.stringify(openPanes));
    return () => {
      localStorage.removeItem('openPanes');
    };
  }, [openPanes]);

  const closePane = (paneId: string) => {
    setOpenPanes(openPanes.filter((pane) => paneId !== pane));
  };

  const clearOpenPanes = () => {
    setOpenPanes([]);
  };

  // making the assumption here that multiple instances of the same workflow are allowed

  const setActivePaneToLocalStorage = (pane: string) => {
    localStorage.setItem('activePane', JSON.stringify(pane));
  };

  const setActiveCdkuToLocalStorage = (url: string) => {
    localStorage.setItem('activeCdkuUrl', JSON.stringify(url));
  };

  const openPane = (pane: string) => {
    const updatedPanes = openPanes.filter((p) => p !== pane);
    const uniquePanes = new Set([...updatedPanes, pane]);
    setOpenPanes(Array.from(uniquePanes.values()));
    setActivePaneToLocalStorage(pane);
    setActiveCdkuToLocalStorage('');
    setCtaOpenPane(null);
    setCdkuOpenPane(null);
    setActivePane(pane);
    toggleIsPaneExpanded(true);
  };

  const openCdkuPane = (pane: string, url:string) => {
    const updatedPanes = openPanes.filter((p) => p !== pane);
    const uniquePanes = new Set([...updatedPanes, pane]);
    setOpenPanes(Array.from(uniquePanes.values()));
    setActivePaneToLocalStorage(pane);
    setActiveCdkuToLocalStorage(url);
    setCdkuOpenPane(url);
    setActivePane('CDKU');
    toggleIsPaneExpanded(true);
  }

  const openCtaPane = (url: string) => {
    setCtaOpenPane(url);
    setActivePane('ctapane');
    toggleIsPaneExpanded(true);
  };

  return (
    <PaneManagementContext.Provider
      value={{
        openPane,
        openPanes,
        clearOpenPanes,
        activePane,
        setActivePane,
        closePane,
        isPaneExpaneded,
        openCtaPane,
        ctaPane,
        toggleIsPaneExpanded,
        cdkuPane,
        openCdkuPane,
        isCdkuUrl,
        setisCdkuUrl,
      }}
    >
      {children}
    </PaneManagementContext.Provider>
  );
}
