import React from 'react';
import { Tabs } from './Tabs';

export const withTab =
  (Component: any) =>
  ({ ...props }: any) => {
    return (
      <>
        <Tabs />
        <Component {...props} />
      </>
    );
  };
