import React from 'react';
import { useEffect } from 'react';
import WorkflowList from '../WorkflowList/WorkflowList';
import * as Styles from '../styles';
import useAppsByAppName from '../../hooks/useAppsByAppName';
import CenteredSpinner from '../common/CenteredSpinner';
import { withTab } from '../Tabs/withTab';
import { useTranslation } from 'react-i18next';

function WorkflowsTabInit({appList, appStatus, workflowList, workflowStatus}: any) {
  const workflows= {data: workflowList, status: workflowStatus};
  const apps = useAppsByAppName(appList,appStatus );

  const isSuccess =
    workflows.status === 'success' && apps.apiStatus === 'success';
  const isLoading =
    workflows.status === 'loading' && apps.apiStatus === 'loading';
  const [isEmptyWorkflow, setIsEmptyWorkflow] = React.useState<boolean>(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (
      !isSuccess ||
      Object.keys(apps.data).length === 0 ||
      Object.keys(workflows.data).length === 0
    ) {
      setIsEmptyWorkflow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? <CenteredSpinner /> : null}
      {isSuccess && Object.keys(workflows.data).length !== 0 ? (
        <Styles.ContentContainer>
          <WorkflowList workflows={workflows.data} apps={apps.data} />
        </Styles.ContentContainer>
      ) : isEmptyWorkflow ? (
        <Styles.UserMessageContainer>
          <Styles.UserMessageHeader>
            {t('workflowTabs.noWfAssigned')}
          </Styles.UserMessageHeader>
          <Styles.UserMessageDescription>
            {t('workflowTabs.noWfAssignedDesc')}
          </Styles.UserMessageDescription>
        </Styles.UserMessageContainer>
      ) : null}
    </>
  );
}

const WorkflowsTab = withTab(WorkflowsTabInit);

export default WorkflowsTab;
