import styled from 'styled-components';
import { Content } from 'cdk-radial';

export const StyledWithLoader = styled.div`
    display: flex;
    margin-top: 100px;
`
export const Container = styled.div`
  display:flex;
  flex-direction:column;
  margin:0px 16px;
  overflow:auto;
`;

export const Subtitle = styled.h4`
  font-weight:600;
  font-size:18px;
`;

export const ProfileContainer = styled.div`
@media (max-width: 470px) {
  width: 90vw;
}
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AvatarContent = styled(Content)`
  color:white;
  font-weight:500;
  font-size:16px;
  margin-top:3px;
`;

export const Name = styled.div`
  font-weight:600;
  font-size:16px;
`;

export const PhoneAndEmailContainer = styled.div`
  display:grid;
  grid-template-columns:25% 75%;
  margin-bottom:20px;
`;

export const Job = styled.div`
`;

export const PhoneLabel = styled.div<{ darkMode: boolean }>`
  font-size:14px;
  font-weight:500;
  color: ${({ darkMode }) => (darkMode ? '#CCCCCC' : '#4D4D4D')};
`;

export const Phone = styled.div`
  font-size:14px;
  font-weight:500;
`;

export const EmailLabel = styled.div<{ darkMode: boolean }>`
  font-size:14px;
  font-weight:500;
  color: ${({ darkMode }) => (darkMode ? '#CCCCCC' : '#4D4D4D')};
`;

export const Email = styled.div`
  font-size:14px;
  font-weight:500;
  padding: 0px 4px 0px 4px;
  word-break: break-all;
`;
export const MarginLeftSpacing = styled.div`
  margin-left:16px;
`;
