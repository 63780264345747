import customProtocolCheck from '../util/lib/browser-portal-launcher.min.js';
import { isEdgeBrowser,detectBrowser } from "./DriveUtils";
import {v4 as uuidv4} from 'uuid';
import { insertNewRelicCustomMatrics } from './newRelicApi';

/**
 * function driveLaunchFallback
 * @param uri
 * @return @returns new Promise<{isProcessing:boolean,status:string, uri:string}>
 */

export const driveLaunchFallback = (uri:string) => {
    const CUSTOM_PORTAL = 'cdkdrive'
    const traceId = uuidv4();

    return new Promise((resolve, reject) => {
        let isProcessing = true;
        const response = { uri: '', status:{} };

        const callbackWhenCustomProtocolNotFound = () => {
            isProcessing = false;
            response.status = { message:'Custom protocol not found',result:false,uri};
            resolve({ isProcessing, response });
            /**
             * Injecting metrics to track behavior on New Relic
             */
            insertNewRelicCustomMatrics(traceId,'app-unify',{uri:`${!isEdgeBrowser() ? 'microsoft-edge:' : ''}${uri}`,result:false,browser:detectBrowser()}) // Protocol is not found

            console.log("Custom protocol not found.");
                window.open(`${!isEdgeBrowser() ? 'microsoft-edge:' : ''}${uri}`)

        };

        const callbackWhenCustomProtocolFound = () => {
            console.log("Custom protocol found and opened the file successfully.");
            isProcessing = false;
            response.status = {message:'Custom protocol found and file opened successfully',result:true,uri};
            resolve({ isProcessing, response });
            insertNewRelicCustomMatrics(traceId,'app-unify',{uri:`${CUSTOM_PORTAL}:${uri}`,result:true,browser:detectBrowser()}) // Protocol is  found

        };

        customProtocolCheck(`${CUSTOM_PORTAL}://${uri}`, callbackWhenCustomProtocolNotFound, callbackWhenCustomProtocolFound, 2000);
    });
};
