import { Button as RadialButton } from 'cdk-radial';
import styled from 'styled-components';

export const Button = styled(RadialButton)<{ darkMode: boolean }>`
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 20px;
  padding: 8px 16px;
  && {
    background-color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
    color: ${({ darkMode }) => (darkMode ? '#000' : '#fff')};
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#CCC' : '#333')};
      color: ${({ darkMode }) => (darkMode ? '#000' : '#fff')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#B3B3B3' : '#4D4D4D')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#CCC' : '#333')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
`;
