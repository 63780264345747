import React, { useEffect, useState } from 'react';
import BaseCard from '../../Cards/BaseCard';
import { useUnifyAdminStore } from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

interface KeyValuePair {
  label: string,
  value: string
}

interface Props {
  selectedCategory: KeyValuePair;
  categories: any;
}

export const AdminCardPreview = ({
  selectedCategory,
  categories}: Props): JSX.Element => {
  /**
   * Data Store
   */
  const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
  const showFrenchTranslation = useUnifyAdminStore((state) => state.showFrenchTranslation);
  const sideSheetSubWorkflowData = useUnifyAdminStore(
    (state) => state.sideSheetDataSubWorkflow
  );
  const isWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isWorkflowSideSheetCollapse
  );

  /**
   * Local Component derived states
   */
  const name = get(
    !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
    'name',
    ''
  );
  let [iconColor,setIconColor] = useState('');

  const description = get(
    !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
    'description'
  );
  const shouldIconDisplay = !!get(
    !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
    'appId',
    false
  );
  const iconText = get(sideSheetData, 'iconText', '');

  const updateSideSheetData = useUnifyAdminStore(
    (state) => state.updateSideSheetData
  );

  useEffect(() =>{
      if (categories && categories.appCategory){
        setIconColor(categories.appCategory[selectedCategory?.label]);
      }
  },[categories,selectedCategory]);


  useEffect(()=>{
    if(iconColor){
      updateSideSheetData({
        fieldName:'icon',
        value:iconColor
      })
    }
  },[iconColor,updateSideSheetData]);

  return (
    <StyledComponents.StyledAdminCardContainer>
      <StyledComponents.StyledHeading headingType="subtitle-2" color="#404040">
        Card Preview
      </StyledComponents.StyledHeading>
      <BaseCard
        key={name}
        name={name}
        description={showFrenchTranslation? description?.frCA : description?.enUS}
        isEnabled
        iconColor={iconColor}
        iconText={iconText}
        onOpenClick={function (): void {}}
        onFavoriteClick={function (): void {}}
        isIconRequired={shouldIconDisplay}
        isFavorite
        showFavoriteIcon
      />
    </StyledComponents.StyledAdminCardContainer>
  );
};
