import React from 'react';
import get from 'lodash/get';
import { useUnifyAdminStore } from '../AdminStore';
import * as StyledComponents from '../StyledComponents';

export const SimpleLabel = (): JSX.Element => {
  /**
   * Data store
   */
  const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
  const sideSheetSubWorkflowData = useUnifyAdminStore(
    (state) => state.sideSheetDataSubWorkflow
  );
  const isWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isWorkflowSideSheetCollapse
  );

  /**
   * local derived state
   */
  const workflowLabel = get(
    !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
    'applications[0].name',
    ''
  );

  return (
    <StyledComponents.StyledHeading headingType="subtitle-2">
      {workflowLabel}
    </StyledComponents.StyledHeading>
  );
};
