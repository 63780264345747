import * as StyledComponents from '../StyledComponents';
import { INPUT_SIZES, IconAdd, Divider } from 'cdk-radial';
import { useUnifyAdminStore } from '../../AdminStore';
import { SIDE_SHEET_TITLE_NAMES } from '../../Constants';
import { Switch } from 'cdk-radial';

export default function FeatureReleaseNotificationListHeader(
  props: Readonly<{ gridRef: any }>
): JSX.Element {
  const searchValue = useUnifyAdminStore((state) => state.releaseSearchValue);
  const archiveSwitch = useUnifyAdminStore((state) => state.releaseArchiveSwitch);

  const releasePermission = useUnifyAdminStore(
    (state) => state.permissions
  );
  const setReleaseSearchValue = useUnifyAdminStore(
    (state) => state.setReleaseSearchValue
  );
  const setArchiveSwitch = useUnifyAdminStore(
    (state) => state.setReleaseArchiveSwitch
  );
  const setSideSheetTitleName = useUnifyAdminStore(
    (state) => state.setSideSheetTitleName
  );
  const setReleaseSheetCollapse = useUnifyAdminStore(
    (state) => state.setReleaseSideSheetCollapse
  );
  const resetReleaseSideSheetState = useUnifyAdminStore(
    (state) => state.resetReleaseSideSheetState
  );
  const onExportDownloadButton = () => {
    props?.gridRef?.current?.api?.exportDataAsExcel({
      processRowGroupCallback: (params: any) => {
        return params.node.key;
      },
    });
  };

  const handleNewRelease = (): void => {
    setSideSheetTitleName(SIDE_SHEET_TITLE_NAMES.PRODUCT_ALERT);
    resetReleaseSideSheetState();
    setReleaseSheetCollapse(false);
  };

  return (
    <>
      <Divider />
      <StyledComponents.StyledHeaderContainer>

        <div style={{display:'flex'}}>
        <StyledComponents.StyledHeading
          headingType="subtitle-2"
          data-testid="outage-notification-list"
        >
          Feature Release
        </StyledComponents.StyledHeading>
        <div style={{display:'flex',marginLeft:'50px'}}>
          <Switch
                dataTestId="switch"
                id="archive"
                label="Show archived"
                labelPosition="before"
                name="archive"
                onChange={(event: any) => setArchiveSwitch(event.target.checked)}
                size="standard"
                checked={archiveSwitch}
              />
          </div>
        </div>
        <StyledComponents.StyledHeading
          headingType="subtitle-2"
          padding="0px 15px"
        >
          <StyledComponents.StyledIconFileDownload
            onClick={() => onExportDownloadButton()}
            data-testid="export-search-result-icon"
          />
        </StyledComponents.StyledHeading>
      </StyledComponents.StyledHeaderContainer>

      <StyledComponents.StyledSearchBoxContainer>
        <StyledComponents.StyledSearchBox
          dataTestId="outage-search-input"
          className="customize-search-input"
          clearButtonLabel="clearButtonLabel"
          id="outage-grid-search-input-expanded"
          isAlwaysExpanded
          name="outage-grid-search-input-expanded"
          onChange={(searchValue: string) => setReleaseSearchValue(searchValue)}
          placeholder="Search"
          size={INPUT_SIZES.STANDARD}
          value={searchValue}
        />
        {releasePermission?.hasReleaseUpdatePermissions && !archiveSwitch && (
            <StyledComponents.StyledButtonWithIcon
              onClick={() => handleNewRelease()}
              variant="secondary"
              text="Create Feature Release"
              icon={<IconAdd />}
            />
          )}
      </StyledComponents.StyledSearchBoxContainer>

    </>
  );
}
