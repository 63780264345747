import styled from 'styled-components';
import {
  Button,
  IconStatusWarningExclamation,
  IndeterminateProgressCircle,
} from 'cdk-radial';

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: calc(90vh - 62px);
  justify-content: center;
  width: 100%;
  margin-left: -24px;
  margin-top: -48px;
`;

export const Loader = styled(IndeterminateProgressCircle)`
  height: 96px;
  width: 96px;
  circle:nth-child(2) {
    stroke: #000;
  }
`;

export const FailedToLoadArrowIllustration = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
`;

export const WarningIcon = styled(IconStatusWarningExclamation)`
  fill: #fa9c19;
  height: 38px;
  margin-right: 8px;
  width: 42px;
`;

export const NoDataHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 72px;
  line-height: 24px;
  padding: 0 16px;
`;

export const NoDataBody = styled.div`
  margin: 16px;
`;

export const NoDataFooter = styled.div`
  align-self: end;
  justify-self: end;
  margin: 16px;
  width: 118px;
`;

export const SupportLink = styled.a`
  font-weight: 600;
  color: #000;
`;

export const ReloadButton = styled(Button)`
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #333333;
  && {
    color: #000;
    background-color: #fff;
    :hover {
      background-color: #e6e6e6;
      border: 1px solid #1a1a1a;
    }
    :active {
      color: #000;
      background-color: #cccccc;
      border: 1px solid #000;
    }
    :focus-visible {
      color: #000;
      background-color: #e6e6e6;
      border: 1px solid #1a1a1a;
      box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
    }
  }
`;
