import axios from 'axios';
import {REACT_APP_NEWRELIC_LICENSE} from '../constants';

const newRelichttpClient = axios.create({
  baseURL: 'https://log-api.newrelic.com/log/v1',
  headers : {
    'X-License-Key': REACT_APP_NEWRELIC_LICENSE,
    'Content-Type': 'application/json',
    'Accept': '*/*',
  }
});

export default newRelichttpClient;
