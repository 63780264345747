import axios from 'axios';
import { getBffUrl } from '../util/getBffUrl';

export const toBearerAuth = (token: string) => `Bearer ${token}`;

const httpClient = axios.create({
  baseURL: getBffUrl(),
});

export default httpClient;
