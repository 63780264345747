import React from 'react';
import { usePaneManagement } from '../../contexts/PaneManagementContext';
import { usePaneManagementStore } from './PaneStore';
import toTestId from '../../testUtils/toTestId';
import * as Styles from './styles';
import { useStore } from '../Header/HeaderStore';

export default function Pane({
  active,
  url,
  name,
  id,
  isExpanded,
  frameLoadHandler,
}: {
  active: boolean;
  url: string;
  name: string;
  id: string;
  isExpanded: boolean;
  frameLoadHandler?: Function;
}) {
  const { openPane, activePane } = usePaneManagement();
  const applicationCardMousePosition = usePaneManagementStore(
    (state) => state.applicationCardMousePosition
  );
  const sheetRef = React.useRef<HTMLDivElement>(null);
  const headerIconClicked = useStore((state: any) => state.headerIconClicked);
  const setHeaderIconClicked = useStore(
    (state: any) => state.setHeaderIconClicked
  );

  const handleInactivePaneClick = () => {
    if (activePane === 'ctapane') {
      return;
    }
    openPane(activePane as string);
  };

  return (
    <>
      <Styles.Sheet
        active={active}
        className={active && isExpanded ? 'open' : 'closed'}
        onClick={handleInactivePaneClick}
        ref={sheetRef}
        data-testid={`pane-${
          active && !isExpanded ? 'open' : 'closed'
        }-${toTestId(name)}`}
        appMinimizationFlag={true}
        clickX={`${applicationCardMousePosition.clickX}px`}
        clickY={`${applicationCardMousePosition.clickY}px`}
      >
        <Styles.FrameOverlayWrapper
          headerIconClicked={headerIconClicked}
          onClick={() => setHeaderIconClicked(false)}
        ></Styles.FrameOverlayWrapper>

        <Styles.Frame
          onLoad={(evt) => {
            frameLoadHandler && frameLoadHandler(evt);
          }}
          id={id}
          title={name}
          src={url}
          className={active && !isExpanded ? 'show' : 'hide'}
        />
      </Styles.Sheet>
    </>
  );
}
