import { RefObject, useEffect } from 'react';

export interface IHandleBackDrop {
  ref: RefObject<HTMLDivElement>;
  clickHandler: () => void;
}

const useHandleBackdropClose = ({ ref, clickHandler }: IHandleBackDrop) => {
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const element: HTMLElement = event.target as HTMLElement;
      const elementType = element.tagName.toLowerCase();
      /** Referencing the IconButton regard less of target is being 'button'/'path'/'svg' */
      const iconButton =
        elementType === 'button'
          ? element
          : elementType === 'path'
          ? element.parentElement?.parentElement
          : element.parentElement;
      const isHeaderButton =
        [
          'header-icon-history',
          'header-icon-settings',
          'header-icon-help',
          'header-icon-profile',
          'header-icon-notification',
        ].indexOf(iconButton?.getAttribute('data-testid') || '') >= 0;
      if (!ref.current?.contains(event.target as Node) && !isHeaderButton) {
        clickHandler();
      }
    };

    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [ref, clickHandler]);
};

export default useHandleBackdropClose;
