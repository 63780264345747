import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";
import * as Styles from '../StyledComponents/index';
import { Banner } from '../../../../types';
import { useTheme } from '../../../../contexts/Theme';
import { Button } from '../../../Buttons/Button';

export default function CampaignPreviewItem({
  bannerItems,
  closetColor
}: {
  bannerItems: any;
  closetColor: any;
}) {
  const { darkMode } = useTheme();

  const getIndicatorStyles = () => {
    return {
      background: darkMode ? '#CCCCCC' : '#4D4D4D',
      borderRadius: '16px',
      cursor: 'pointer',
      display: 'inline-block',
      height: '12px',
      margin: '0 8px',
      width: '12px',
    };
  };
  /* i18n translation*/
    const { t:translate } = useTranslation()
    const BANNER_TRANSLATE_KEY = 'banner'

  const theme = useTheme();
  const swipeStartHandler = (item: any) => {
    const bgColor = bannerItems[item].id === 'salty' ? '#42B5E1' : theme.darkMode ? '#1A1A1A' : '#f2f2f2';
    closetColor(bgColor);
  }


  const handleLearnMore = (carouselItem: Banner) => {
    window.open(carouselItem.link, '_blank')
  };
  return (
    <div data-testid="carouselContainer">
      <Carousel
        autoPlay
        infiniteLoop
        interval={150000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        onChange={swipeStartHandler}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                aria-label={`Selected: ${label} ${index + 1}`}
                style={{
                  ...getIndicatorStyles(),
                  background: darkMode ? '#4D4D4D' : '#CCCCCC',
                }}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              aria-label={`${label} ${index + 1}`}
              key={index}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              role="button"
              style={getIndicatorStyles()}
              tabIndex={0}
              title={`${label} ${index + 1}`}
              value={index}
            />
          );
        }}
      >
        {bannerItems.map((carouselCard: any) => {
            return (
            <Styles.CampaignBannerCardContainer key={carouselCard.id}>
              {carouselCard.showLogo &&
              <Styles.CampaignBannerImage
                id={carouselCard.id}
                url={darkMode ? carouselCard.darkLogo : carouselCard.logo}
              />}
              <Styles.CampaignBannerContent>
                <Styles.CampaignBannerIntro>
                  {carouselCard.name}
                </Styles.CampaignBannerIntro>
                <Styles.CampaignBannerDescription>
                {carouselCard.description.enUS}
                </Styles.CampaignBannerDescription>
                <Styles.CampaignBannerContentFooter>
                  <Button
                    darkMode={darkMode}
                    onClick={() => handleLearnMore(carouselCard)}
                    rel="noreferrer"
                    target="_blank"
                    text={translate(`${BANNER_TRANSLATE_KEY}.learn_more`)}
                  />
                </Styles.CampaignBannerContentFooter>
              </Styles.CampaignBannerContent>
            </Styles.CampaignBannerCardContainer>
          );
        })}
      </Carousel>
    </div>
  );
}
