import styled from 'styled-components';
import {
  DialogContent,
  Input,
  Switch,
  Avatar,
  TextArea,
  IconCancel,
  Dialog,
  TableContainer,
  SearchInput,
  Button,
  Heading,
  IconFileDownload,
  CustomScrollbarWrapper
} from 'cdk-radial';

export const StyledAdminTableContainer = styled(TableContainer)`
  height: 75vh;
  left: 0px;
  
`;

export const StyledCampaignTableWrapper = styled.div`
  width: 100%;
  height: 60vh !important;

  .ag-root-wrapper {
    border: none !important;
  }

  .ag-pivot-off .ag-header-cell {
    font-size: 17px;
  }

  .ag-cell-wrapper{
    overflow: hidden;
  }

  .ag-menu-option{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-icon{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-tabs-header{
      background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  }
  .ag-menu-header{
      background-color: ${({ theme }) => (theme.darkMode ?'#1A1A1A' : '#f2f2f2')};
  }

  .ag-column-select-column-label{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-header-row{
    height: 36px;
  } 

.ag-header-cell-comp-wrapper{
  height: 37px !important;
}

@media (max-height: 610px) {
  height: 45vh !important;
}
`

export const StyledCampaignContainerWrapper = styled.div`
  position: relative;
  width: 100%;
`
export const StyledDialogContentBodyWrapper = styled.div<{isEnabled?: boolean}>`
display: flex;
`

export const StyledCreateCampaignDialog = styled.div`
`

export const StyledCampaignPreviewDialogBodyWrapper = styled.div`
display: flex;
top: 16.8% !important;
width: 100%;
`

export const StyledCreateCampaignElementWrapper = styled.div`
  display: flex;
  width:100%;
  margin-top:0px;
`

export const StyledDialogContentBody = styled(DialogContent)`
  width: 100%;
  height: auto;
  padding: .15rem .30rem 0rem 0.25rem;
`
export const StyledInput = styled(Input)<{ customWidth?: string }>`
  width: ${props => props.customWidth};
  margin: 10px 15px 0px 0px;
`;

export const StyledLinkInput = styled(Input)<{ customWidth?: string }>`
  width: ${props => props.customWidth};
  margin: 10px 0px 0px 0px;
`;

export const StyledTextArea = styled(TextArea)<{ customWidth?: string }>`
  width: 100%;
  margin: 10px;
  height: 40px;
  overflow: hidden;
`

export const StyledNameAndLinkTextArea = styled(TextArea)<{ customWidth?: string }>`
  width: 100%;
  height: 20px;
  overflow: hidden;
`

export const StyledCampaignEnablementSwitch = styled(Switch)`
position: relative;
margin-bottom:9px;
margin-left: 30px;

.cqPPnZ{
  @media(max-width: 765px)
  {
  width: 55px;
  margin-bottom:5px;
  }
}

.kcJWlz.kcJWlz {
  font-size: 15px;
  font-weight: 400;
  @media(max-width: 765px)
  {
    padding-right: 0.1375rem;
  }
}
`

export const StyledCampaignShowLogoSwitch = styled(Switch)`
padding-left: 80px;
.cqPPnZ{
  @media(max-width: 765px)
  {
  width: 80px;
  }
`


export const StyledAvatar = styled(Avatar)`
  height: 50px;
  width: 50px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledAvatarContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
`;
export const StyledUploadLogoContainer = styled.section`
  display: flex;
`;

export const StyledPromotionLogoLabel = styled.div`
font-size: 12px;
font-weight: 500;
padding: 7px;
padding-left: 14px;
`;

export const StyledImageSizeLabel = styled.div`
font-size: 10px;
font-weight: 400;
color: red;
margin-left: 10px;
`;

export const StyledUploadContainer = styled.section`
  border: 1px solid Grey;border-radius: 8px;
  width: 100%;
  margin-top: 10px
`;

export const StyledLabel = styled.div`
  margin: auto 5px`

export const StyledImageContainer = styled.div`
  
`

export const Styledimage = styled.img`

  border-radius: 0px;
  max-width: 10rem;
  margin: auto 10px;
`

export const StyleDeleteIcon = styled(IconCancel)`
  fill-opacity: 0.6;
  fill: red;
  :hover {
    cursor: pointer;
  }
;
`


export const StyledPromoTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 35px;
  font-weight: 400;
  font-size: 24px;
`

export const CampaignWrapperSpacer = styled.div`
  background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  height: min-content;
  left: -16px;
  position: relative;
  top: -16px;
`
export const StyledDialog = styled(Dialog)`
  top: 180px;
  width: 100%; 
  border-radius: 0px;
  border: 0px;
  max-width: 100%;
  z-index: 105;
  border: ${({ theme }) => (theme.darkMode ? '.5px solid Grey' : '0px')};
  @media (max-width: 1080px) {
    top:260px;
  }
  `
  export const StyledCreateDialog = styled(Dialog)`
  width: 830px; 
  border-radius: 8px;
  z-index: 105;
  
  max-width: 100%;
  border: ${({ theme }) => (theme.darkMode ? '1px solid Grey' : '0px')};
  `

  export const CampaignPromotionWrapper = styled.div<{ expanded: boolean }>`
    display: flex;
    height: ${({ expanded }) => (expanded ? '162px' : '60px')}x;  
    width: 100%;
    flex-direction: column;
    max-width: 1280px;
    @media (max-width: 1080px) {
        height: ${({ expanded }) => (expanded ? '278px' : '60px')}x;
    }
`

export const CampaignBannerBody = styled.div`
    width: 100%;
`

export const CampaignPromotionBanner = styled.div<{ darkMode: boolean }>`
    display: flex;
    height: 122px;  
    width: 100%;    
    background-color: ${({ darkMode }) => (darkMode ? '#1A1A1A' : '#f2f2f2')};
    @media (max-width: 1080px) {
        height: 278px;
    }
`;

export const CampaignBannerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;



export const CampaignCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;


export const CampaignCarouselCloseContainer = styled.div`
  align-self: flex-end;
  margin: 8px;
  margin-right:18px;
  position: absolute;
  top: 0;
  z-index: 1;
`;
  
export const CampaignBannerCardContainer = styled.div`
  height: 122px;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  @media (max-width: 1080px) {
    flex-direction: column;
    width: 100%;
    height: 272px;
  }
`;

export const CampaignBannerImage = styled.div<{ url: string; id: string, theme:string }>`
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-attachment: fixed;
  background-color: ${({ id, theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  background-image: url(${({ url }) => url});
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  justify-content: center;
  margin-left:15px;
  min-width: ${({ url }) => (url ?  '222px' : '15px')};
  height: ${({ url }) => (url ?  '100%' : '0px')};
  @media (max-width: 1080px) {
    background-size: 120px;
    background-position: center;
    flex-direction: column;
    width: 100%;
    margin-left:0px;
  }
`;

export const CampaignBannerContent = styled.div`
  background-color: ${({ theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  color: ${({ theme }) => (theme.darkMode ? '#E6E6E6' : '#1A1A1A')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 122px;
  margin-right: 72px;
  text-align: start;
  @media (max-width: 1080px) {
    margin-bottom: 50px;
    margin-left: 12px;
    padding: 0 10px;
  }
`;

export const CampaignBannerContentFooter = styled.div`
  bottom: 12px;
  position: absolute;
  right: 80px;
  @media (max-width: 1080px) {
    right: 56px;
  }
  @media (max-width: 500px) {
    right: 32px;
  }
`;

export const CampaignBannerIntro = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0px 0 6px;
  padding-left: 0px;
`;

export const CampaignBannerDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 8px 6px 6px;
  padding-left: 0px;
`;

export const StyledSearchBox = styled(SearchInput)`
  width: 40%;
  left: 1px;
  top: 0px;

  & > input {
    height: 32px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: relative;
`

export const StyledCampaignHeading = styled(Heading)`
  margin: 10px 0;
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  color: ${({color}) => color};
  height: 44px;
  font-weight: 500;
  font-size: 20px;
`;

export const StyledSearchBoxCampaignContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.5rem 0rem 0rem;
  padding-left: 15px;
  height: 75px;
`;

export const StyledButtonWithIcon = styled(Button)`
  height: 32px;
  min-height: 32px;
  display: flex;
  flex-direction: row-reverse;
  padding-left: .8rem;
  padding-right: 0;
  max-width: 15%;
  margin: 9px 0px 0px 0px;
  margin-left:0px;

  svg {
    padding: 5px;
  }

  @media (max-width: 1370px) {
    min-width:165px;
`;
  
export const StyledIconFileDownload = styled(IconFileDownload)`
  cursor: pointer;
  padding-right: 15px;
  `

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)<{ isDialogOpened: boolean }>`
height: 100vh;
overflow-x: hidden;
width: 100%;
left: 0px;
position: fixed;
z-index: ${({ isDialogOpened }) => (isDialogOpened ? '1' : '104')};

@media (max-height: 710px) {
    height: 83vh;
}
`;

export const GreetingWrapperSpacer = styled.div`
  background-color: ${({ theme }) => (theme.darkMode ? '#000' : '#FFF')};
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  height: min-content;
`;

export const GreetingWrapper = styled.div<{ darkMode: boolean }>`
    display: flex;  
    height: 60px;  
    width: 100%; 
    align-items: center;
    max-width: 1280px;
`;

export const GreetingBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 1280px;
    font-weight: 600;
`;

export const GeetingMessage = styled.div`
    padding-left: 15px;
    @media (max-width: 1080px) {
        padding-left: 22px;
    }
`;


export const StyledInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledNameInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-right: 15px;
`;

export const StyledLinkInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
`;

export const StyledHelperLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
  color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
`;

export const StyledNameHelperLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10px;
  color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
`;

export const StyledNameCountLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 17%;
  color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  @media (max-width: 750px) {
    margin-left: 5%;
}
`;


export const StyledHelperLabelContainer = styled.div`
    margin-left: 94%;
    @media (max-width: 800px) {
      margin-left: 85%;
  }
  `;

  export const StyledNameHelperLabelContainer = styled.div`
  display: inline-flex;
  width: 75%;
  `;

  export const StyledPreviewButton = styled(Button)`
  padding-left: 0px;
  min-width: 70px;
  margin-top: 2px;
  font-size: 15px;
  font-weight: 500;
`;

