import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Styles from '../styles';
import { SideSheetHeader } from '../SideSheet/SideSheet';
import { IconHelp } from 'cdk-radial';
import { useSideSheet } from '../../contexts/SideSheet';
import { useTranslation } from 'react-i18next';
import { CustomScrollbarWrapper } from 'cdk-radial';
import { useUserProfile } from '../../hooks/queries';
export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100vh - 80px);
  overflow-x: hidden;
`;
const FeaturedHelpLink = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 24px;
  svg {
    width: 40px;
    height: 40px;
  }
`;
const HelpSheetContent = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  font-size: 1rem;
  // TODO: shouldn't need to do this but don't know where line height is inheriting from
  line-height: normal;
`;
function Help() {
  const { setSideSheetContent } = useSideSheet();
  const { t } = useTranslation();
  const { data } = useUserProfile();
  const toggle = () => {
    setSideSheetContent(null);
  };
  const changeLanguage = () => {
    let userLocale = data?.profile?.locale || '';
    userLocale = userLocale.replace('_', '-');
    userLocale = userLocale === 'en-US' ? '' : userLocale;
    window.WalkMeAPI.changeLanguage(userLocale);
  };
  useEffect(() => {
      let cloneElm = document.getElementById(
        'cdk-walkme-menu'
      ) as HTMLDivElement;
      if (window.WalkMeAPI) {
        changeLanguage();
      }
      if (cloneElm && window.WalkMePlayerAPI) {
        document.getElementById('help-cdk-walkme-menu')?.appendChild(cloneElm);
        window.WalkMePlayerAPI.toggleMenu();
        setTimeout(() => {
          cloneElm.style.display = 'block';
        }, 400);
      }
      return function cleanup() {
        if (cloneElm) {
          document.getElementById('walkme-pane')?.appendChild(cloneElm);
          cloneElm.style.display = 'none';
        }
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <HelpSheetContent>
      <SideSheetHeader
        name={t('help.name')}
        toggle={toggle}
        dataTestId="help-tab-header"
      />
      <StyledCustomScrollbarWrapper>
        <Styles.SideSheetBody>
          <FeaturedHelpLink>
            <IconHelp />
            <h4>{t('help.topHelpTopics')}</h4>
          </FeaturedHelpLink>
          <div style={{ margin: '-20px' }}>
            <div
              id="help-cdk-walkme-menu"
              style={{ position: 'inherit' }}
            ></div>
          </div>
        </Styles.SideSheetBody>
      </StyledCustomScrollbarWrapper>
    </HelpSheetContent>
  );
}
export default Help;
