import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components"
import CampaignPreviewBannerItem from './CampaignPreviewBannerItem';
import * as CampaignStyles from '../StyledComponents'

export default function CampaignPreviewBanner() {
    const { darkMode } = useTheme();
    const { t } = useTranslation();

    const closetColorHandler = (color: string) => {
        // TODO: based on PO applying this fix, this will be decided later based on UX and PO sync-up, 
        // setClosetColor(color);
    }

    return (
            <><CampaignStyles.CampaignWrapperSpacer data-testid="campaign-spacer">
            <CampaignStyles.CampaignPromotionWrapper expanded={true}>
                <CampaignStyles.CampaignPromotionBanner darkMode={darkMode}>
                    <CampaignStyles.CampaignBannerBody>
                            <CampaignPreviewBannerItem closetColor={closetColorHandler} />
                    </CampaignStyles.CampaignBannerBody>
                </CampaignStyles.CampaignPromotionBanner>
            </CampaignStyles.CampaignPromotionWrapper>
            </CampaignStyles.CampaignWrapperSpacer>
            {/* Using the same Greeting Compomponent Styles in Home page and Campaign Management Preview */}
            <CampaignStyles.GreetingWrapperSpacer data-testid="greeting-spacer">
                <CampaignStyles.GreetingWrapper darkMode={darkMode}>
                    <CampaignStyles.GreetingBody>
                        <CampaignStyles.GeetingMessage>{t('subHeader.title')}</CampaignStyles.GeetingMessage>
                    </CampaignStyles.GreetingBody>
                </CampaignStyles.GreetingWrapper>
                </CampaignStyles.GreetingWrapperSpacer>
                </>
    )
}
