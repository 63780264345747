import {
  StyledNotification,
  StyledSubject,
  StyledMessage,
  StyledTime,
  StyledNotificationTitle,
  StyledDefaultCDKIconText,
  StyledNotificationContainer,
  NotificationColumn2,
  NotificationColumn1,
  ProductContentIcon,
} from './styles';
import { getTimeDiffText } from '../../util/timeUtils';
import { useTranslation } from 'react-i18next';
import { Divider } from 'cdk-radial';
import { IconClose, IconButton } from 'cdk-radial';
/**
 * Interfaces
 */
export interface INotification {
  messageId?: string;
  title?: string;
  subject?: string;
  body?: string;
  createdDate?: string;
  receivedDate?: string;
  read?: boolean;
  externalUrl?: string;
  application?: string;
  source?: string;
  type?: string;
  deleted?: boolean;
  ctaUrl?: string;
}

const Notification = (props: {
  notification: INotification;
  applications: any;
  onSelect?: Function;
  onDelete?: Function;
}): JSX.Element => {
  const { notification, onSelect, onDelete, applications } = props;
  const { t } = useTranslation();
  const appIconColor = applications.find(
    (app: any) => app.name === notification.application
  )?.icon;
  const appIconText = applications.find(
    (app: any) => app.name === notification.application
  )?.iconText;
  const defaultCDKIconText = (
    <StyledDefaultCDKIconText fontSize="12px">
      {' '}
      {'CDK'}{' '}
    </StyledDefaultCDKIconText>
  );
  return (
    <StyledNotification
      data-testid="notification-item"
      onClick={() => onSelect && onSelect(notification)}
    >
      <StyledNotificationContainer>
        <NotificationColumn1>
          <StyledNotificationTitle>
            <ProductContentIcon iconColor={appIconColor} data-testid="app-icon">
              {appIconText || defaultCDKIconText}
            </ProductContentIcon>
            <StyledSubject read={notification.read!}>
              {notification.title}{' '}
            </StyledSubject>
          </StyledNotificationTitle>
          <StyledMessage>{notification.subject}</StyledMessage>
          <StyledTime>
            {getTimeDiffText(notification.receivedDate as string, t)}
          </StyledTime>
        </NotificationColumn1>
        {'outage' !== notification.type && (
          <NotificationColumn2>
            <IconButton
              dataTestId={'notification-item-delete'}
              hideText
              icon={<IconClose style={{ width: '18px' }} />}
              onClick={(e: any) => {
                e.stopPropagation();
                onDelete && onDelete(notification);
              }}
              text="Delete"
            />
          </NotificationColumn2>
        )}
      </StyledNotificationContainer>
      <Divider></Divider>
    </StyledNotification>
  );
};
export default Notification;
