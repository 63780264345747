import styled, { keyframes } from 'styled-components';
import { Button, IconButton, IndeterminateProgressCircle } from 'cdk-radial';

export const App = styled.div``;

export const Backplane = styled.div`
  color: ${({ theme }) => theme.carbon.color.element.backplane.color};
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.carbon.color.element.backplane.backgroundColor};
  background-image: ${({ theme }) =>
    theme.carbon.color.element.backplane.backgroundImage};
  background-repeat: ${({ theme }) =>
    theme.carbon.color.element.backplane.backgroundRepeat};
  
  position: relative;

  @media (prefers-color-scheme: dark) {
  }
`;

export const BackplaneBody = styled.div<{ isAdmin: boolean; margin: string }>`
  box-sizing: border-box;
  max-width: 1280px;
  min-width: ${(props) => (props.isAdmin ? '100%' : '500px')};
  width: 100%;
  margin: 5px 0px;
`;

export const BackplaneBodyWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Settings = styled.div`
  height: 100%;
`;

export const AppContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => (theme.darkMode ? '#333333' : '#e6e6e6')};
  padding: 16px;
  margin-bottom: 16px;
`;

export const TabItem = styled.div`
@media (max-width: 470px) {
  padding-left: 16px;
  padding-right: 16px;
}
@media (max-width: 450px) {
  padding-left: 20px;
  padding-right: 10px;
}
@media (max-width: 430px) {
  padding-left: 36px;
  padding-right: 26px;
}
@media (max-width: 400px) {
  padding-left: 40px;
  padding-right: 26px;
}
@media (max-width: 390px) {
  padding-left: 46px;
  padding-right: 46px;
}
`;

export const TabSubHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 0px;
`;

export const ContentContainer = styled.div`
  padding: 32px 0px;
  @media (max-width: 470px) {
    padding-left: 10px;
  }
  @media (max-width: 450px) {
    padding-left: 20px;
  }
  @media (max-width: 430px) {
    padding-left: 25px;
  }
  @media (max-width: 410px) {
    padding-left: 30px;
  }
  @media (max-width: 400px) {
    padding-left: 40px;
  }
  @media (max-width: 390px) {
  padding-left: 46px;
  padding-right: 46px;
}
`;

export const WorkflowSubHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  padding: 24px 0 16px 0;
`;

export const CardList = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(395px, 1fr));
  @media (min-width: 1440px) {
    grid-template-columns: repeat(auto-fill, minmax(329px, 1fr));
  }
`;

export const ApplicationCard = styled.div`
margin: 5% auto;
`
export const CloseButton = styled(IconButton)`
  &&& svg {
    fill: #e6e6e6;
  }
`;

export const DomainHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  padding: 15px 0;
  align-items: center;
`;

export const HelpIconDiv = styled.div`
  cursor: pointer;
`;


export const AppContainerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px 8px;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
`;

export const ProductIcon = styled.div<{ iconColor: string }>`
  align-items: flex-start;
  background-color: ${({ iconColor }) => iconColor};
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  font-size: 18px;
  font-weight: 400;
  height: 48px;
  justify-content: flex-start;
  letter-spacing: -0.06em;
  line-height: 18px;
  overflow-wrap: anywhere;
  overflow: hidden;
  padding: 2px;
  width: 48px;
`;

const openSheet = keyframes`
  from {
    right: -500px;
  }
  to {
    right: 8px;
  }
`;
const closeSheet = keyframes`
  from {
    right: 8px;
  }
  to {
    right: -500px;
  }
`;

export const SideSheet = styled.div`
  background: #fff;
  border-radius: 16px;
  border: 1px solid red;
  bottom: 8px;
  font-family: 'Montserrat', 'Roboto', 'sans-serif';
  position: absolute;
  right: 8px;
  top: 48px;
  width: 420px;
  z-index: 2000;

  &.open {
    animation: ${openSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  &.closed {
    animation: ${closeSheet} 0.15s ease-in-out 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  box-shadow: -5px 0px 15px -3px rgba(0, 0, 0, 0.5);
`;

export const SideSheetHeader = styled.div`
  align-items: center;
  background: #000;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', 'Roboto', sans-serif;
  height: 48px;
  justify-content: space-between;
  line-height: 20px;
  padding: 0 16px;
  z-index: 1000;
`;

export const SideSheetBody = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  padding: 16px;
  margin: 0px;
`;

export const ReloadButton = styled(Button)`
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #333333;

  && {
    color: #000;
    background-color: #fff;

    :hover {
      background-color: #e6e6e6;
      border: 1px solid #1a1a1a;
    }

    :active {
      color: #000;
      background-color: #cccccc;
      border: 1px solid #000;
    }

    :focus-visible {
      color: #000;
      background-color: #e6e6e6;
      border: 1px solid #1a1a1a;
      box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const AppMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3000;
  background-color: red;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2500;
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.unify.colors.white : theme.unify.colors.black};
  color: ${({ theme }) =>
    theme.darkMode ? theme.unify.colors.black : theme.unify.colors.white};
`;

export const UserMessageContainer = styled.div`
  margin-top: 32px;
  @media (max-width: 470px) {
    width: 80vw;
    margin-left: 10%;
  }
`;

export const UserMessageDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.carbon.color.element.card.description};
`;

export const UserMessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px 8px;
  font-weight: 600;
  padding-bottom: 8px;
`;

// TODO: Update size to 96x96 when RDS supports it and change track fill color to #000
export const Loader = styled(IndeterminateProgressCircle)``;
