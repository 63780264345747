import {create} from "zustand";
import { CNumber } from '../types';

export interface DriveCNumbersState {
    cNumbers: CNumber[];
    setCNumbers: (cNumbers:CNumber[]) => void;
    isFetched: boolean;
    setIsFetched: (isFetched:boolean) => void;
  }
  
export const useDriveCNumbersStore = create<DriveCNumbersState>((set) => ({
   cNumbers: [],
   setCNumbers: (cNumbers) => set(() => ({ cNumbers })),
   isFetched: false,
   setIsFetched: (isFetched) => set(() => ({ isFetched }))
 }));
