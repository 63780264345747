import {StatusIndicator} from 'cdk-radial';

enum StatusTypes {
    POSITIVE = 'positive',
    WARNING = 'warning',
}
export const CustomStatusRenderer = ({isActive}: any): JSX.Element => {
    const variant = isActive ? StatusTypes.POSITIVE : StatusTypes.WARNING;
    return (
        <StatusIndicator
            label={isActive ? 'Active' : 'Inactive'}
            variant={variant}
            showIcon
        />
    );
};