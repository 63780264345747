import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useUnifyAdminStore } from '..';
import useAuthToken from '../../../../hooks/useAuthToken';
import * as adminAPIs from './adminApis';
import { ISideSheetData, IWorkflow, IWorkflowApplication } from '../slices/types/types';
import { ENTITY_TYPE } from './types/types';

export function useGetAllApplications() {
    const token = useAuthToken();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    return useQuery(
        'getAllApplications',
        () => adminAPIs.fetchAdminApplications(token, selectedEnvironment.value),
    );
}

export function useSearchUserIds() {
    const token = useAuthToken();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    const searchQuery = useUnifyAdminStore(
        (state) => state.searchQuery
    );
    return useQuery(
        ['searchByUserId', searchQuery],
        () => adminAPIs.searchByUserId(token, selectedEnvironment.value, searchQuery),
        { enabled: !!searchQuery }
    );
}

export function useGetUserAppAndWorkflowNames() {
    const token = useAuthToken();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    const userId = useUnifyAdminStore(
        (state) => state.userId
    );
    return useQuery(
        ['getUserAppAndWorkflowNames', userId],
        () => adminAPIs.getUserAppAndWorkflowNames(token, selectedEnvironment.value, userId),
        { enabled: !!userId }
    );
}

export function useGetAllWorkflows() {
    const token = useAuthToken();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );

    return useQuery(
        'getAllWorkflows',
        () => adminAPIs.fetchAdminWorkflows(token, selectedEnvironment.value),
        {
            enabled: !!token,

        }
    );
}

export function useCreateNewApplication() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const queryClient = useQueryClient();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)
    const appSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as ISideSheetData

    /**
     * Request
     */
    const ReqBody = {
        appId: appSideSheetData.appId,
        name: appSideSheetData.name,
        url: appSideSheetData.url,
        description: appSideSheetData.description,
        icon: appSideSheetData.icon,
        iconText: appSideSheetData.iconText,
        isUnrestricted: !!appSideSheetData.unrestricted || true,
        isEnabled: appSideSheetData.isActive || true,
        appCategory: appSideSheetData.appCategory,
        cdkuUrl: appSideSheetData.cdkuUrl,
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => adminAPIs.createNewApplication(token,
            selectedEnvironment?.value,
            // @ts-ignore
            ReqBody), {
            onSuccess: () => {
                queryClient.invalidateQueries(['getAllApplications']);
                setAdminSideSheetCollapse(true)
                setCustomToastContent({ type: 'Success', message: 'Application has been created successfully!' })
                setShowCustomContentToast(true)
            },
            onError: (error: any) => {
                setAdminSideSheetCollapse(true)
                console.error('Failed to create a new application:', error);
                setCustomToastContent({
                    type: 'Error',
                    message: 'Failed to create a new application,check logs for more details'
                })
                setShowCustomContentToast(true)
            },
            mutationKey: 'createNewApplication',
        })

}

export function useCreateNewWorkflow() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const queryClient = useQueryClient();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    )
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)
    const appSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as ISideSheetData

    /**
     * Request
     */
    const ReqBody = {
        appId: appSideSheetData.appId,
        workflowId: appSideSheetData.workflowId,
        name: appSideSheetData.name,
        url: appSideSheetData.url,
        description: appSideSheetData.description,
        icon: appSideSheetData.icon,
        iconText: appSideSheetData.iconText,
        isUnrestricted: !!appSideSheetData.unrestricted,
        isEnabled: appSideSheetData.isActive || true,
        cdkuUrl: appSideSheetData.cdkuUrl,
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => adminAPIs.createNewWorkflow(token,
            selectedEnvironment?.value,
            // @ts-ignore
            ReqBody), {
            onSuccess: () => {
                queryClient.invalidateQueries(['getAllWorkflows']);
                setAdminSideSheetCollapse(true)
                setCustomToastContent({ type: 'Success', message: 'Workflow has been created successfully!' })
                setShowCustomContentToast(true)
            },
            onError: (error: any) => {
                setAdminSideSheetCollapse(true)
                console.error('Failed to create a new workflow:', error);
                setCustomToastContent({
                    type: 'Error',
                    message: 'Failed to create a new workflow,check logs for more details'
                })
                setShowCustomContentToast(true)
            },
            mutationKey: 'createNewWorkflow',
        })

}

export function useUpdateApplicationDetails() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const queryClient = useQueryClient();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    )
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)
    const appSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as ISideSheetData

    /**
     * Request
     */
    const updateReqBody = {
        name: appSideSheetData.name,
        url: appSideSheetData.url,
        description: appSideSheetData.description,
        icon: appSideSheetData.icon,
        iconText: appSideSheetData.iconText,
        isUnrestricted: !!appSideSheetData.unrestricted,
        isActive: appSideSheetData.isActive,
        logoutUrl: appSideSheetData.logoutUrl,
        appCategory: appSideSheetData.appCategory,
        cdkuUrl: appSideSheetData.cdkuUrl,
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => adminAPIs.updateAdminApplication(token,
            selectedEnvironment?.value,
            appSideSheetData.appId,
            updateReqBody), {
            onSuccess: () => {
                queryClient.invalidateQueries(['getAllApplications']);
                setAdminSideSheetCollapse(true)
                setCustomToastContent({ type: 'Success', message: 'Application Details have been updated successfully!' })
                setShowCustomContentToast(true)
            },
            onError: (error: any) => {
                setAdminSideSheetCollapse(true)
                console.error('Failed to update application:', error);
                setCustomToastContent({ type: 'Error', message: 'Failed to update application,check logs for more details' })
                setShowCustomContentToast(true)
            },
            mutationKey: 'updateAdminApplicationDetails',
        })

}

export function useUpdateWorkflowDetails() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const queryClient = useQueryClient();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    )
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const isWorkflowSideSheetCollapse = useUnifyAdminStore(state => state.isWorkflowSideSheetCollapse)
    const setWorkflowSideSheetCollapse = useUnifyAdminStore(state => state.setWorkflowSideSheetCollapse)
    const sideSheetDataSubWorkflow = useUnifyAdminStore(state => state.sideSheetDataSubWorkflow)
    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)
    const workflowSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as IWorkflow
        & Pick<IWorkflowApplication, 'icon' | 'iconText' | 'unrestricted' | 'isActive'>

    /**
     * Request
     */
    const updateReqBody = {
        name: !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.name : workflowSideSheetData.name,
        url: !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.url : workflowSideSheetData.url,
        description: !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.description : workflowSideSheetData.description,
        icon: workflowSideSheetData.icon,
        iconText: workflowSideSheetData.iconText,
        isUnrestricted: !!workflowSideSheetData.unrestricted,
        isActive: workflowSideSheetData.isActive,
        domain: !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.domain : workflowSideSheetData.domain,
        cdkuUrl: !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.cdkuUrl : workflowSideSheetData.cdkuUrl,
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => adminAPIs.updateAdminWorkflow(token,
            selectedEnvironment?.value,
            !isWorkflowSideSheetCollapse ? sideSheetDataSubWorkflow.workflowId : workflowSideSheetData.workflowId,
            // @ts-ignore
            updateReqBody), {
            onSuccess: () => {
                queryClient.invalidateQueries(['getAllWorkflows']);
                setAdminSideSheetCollapse(true)
                setCustomToastContent({ type: 'Success', message: 'Workflow Details have been updated successfully!' })
                setShowCustomContentToast(true)
                setWorkflowSideSheetCollapse(true)
            },
            onError: (error: any) => {
                setAdminSideSheetCollapse(true)
                console.error('Failed to update workflow:', error);
                setCustomToastContent({ type: 'Error', message: 'Failed to update workflow,check logs for more details' })
                setShowCustomContentToast(true)
            },
            mutationKey: 'useUpdateWorfklowDetails',
        })

}

export function useGetAdminPermissions() {
    const token = useAuthToken();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    return useQuery(
        'getAdminPermissions',
        () => adminAPIs.fetchAdminPermissions(token, selectedEnvironment.value),
    );
}

export function useUpdateAdminPermissions() {
    /**
     * Hooks
     */
    const token = useAuthToken();
    const queryClient = useQueryClient();
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    )
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setCustomToastContent = useUnifyAdminStore(state => state.setCustomToastContent)
    const setShowCustomContentToast = useUnifyAdminStore(state => state.setShowCustomContentToast)
    const appSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as ISideSheetData
    const workflowSideSheetData = useUnifyAdminStore(state => state.sideSheetData) as IWorkflow
        & Pick<IWorkflowApplication, 'icon' | 'iconText' | 'unrestricted' | 'isActive'>

    /**
     * Request
     */
    const appEnterprise = {
        include: appSideSheetData.permissions?.include_enterprise,
        exclude: appSideSheetData.permissions?.exclude_enterprise
    }
    const workflowEnterprise = {
        include: workflowSideSheetData.permissions?.include_enterprise,
        exclude: workflowSideSheetData.permissions?.exclude_enterprise
    }

    const updatePermissionReqBody = {
        type: appSideSheetData.appId ? 'APPLICATION' : 'WORKFLOW' as keyof typeof ENTITY_TYPE,
        enterprise: (appSideSheetData.appId ? appEnterprise : workflowEnterprise) || [],
        enterprises: (appSideSheetData.appId ? appSideSheetData.permissions?.enterprises : workflowSideSheetData?.permissions?.enterprises) || [],
        stores: (appSideSheetData.appId ? appSideSheetData.permissions?.stores : workflowSideSheetData.permissions?.stores) || [],
        roles: (appSideSheetData.appId ? appSideSheetData.permissions?.roles : workflowSideSheetData.permissions?.roles) || [],
        users: (appSideSheetData.appId ? appSideSheetData.permissions?.users : workflowSideSheetData.permissions?.users) || [],
        id: appSideSheetData.appId || workflowSideSheetData.workflowId
    }

    return useMutation<Record<any, any>[],
        Error,
        void,
        unknown>(() => adminAPIs.updateAdminPermissions(token,
            selectedEnvironment?.value,
            appSideSheetData.appId,
            workflowSideSheetData.workflowId,
            updatePermissionReqBody
        ), {
            onSuccess: () => {
                queryClient.invalidateQueries(['getAdminPermissions']);
                setAdminSideSheetCollapse(true)
                setCustomToastContent({ type: 'Success', message: `${appSideSheetData.appId ? "Application" : "Workflow"} details have been updated successfully!` })
                setShowCustomContentToast(true)
            },
            onError: (error: any) => {
                setAdminSideSheetCollapse(true)
                console.error('Failed to update application:', error);
                setCustomToastContent({ type: 'Error', message: 'Failed to update application details, check logs for more details' })
                setShowCustomContentToast(true)
            },
            mutationKey: 'useUpdateAdminPermissions',
        })

}
