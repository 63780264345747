import http, {toBearerAuth} from '../../../../../util/httpClient';

type ReqBody = {
    name: string
    link: string
    description: {enUS:string, frCA: string}
    isActive: boolean,
    darkLogo: string,
    logo: string
}

export const fetchListOfCampaigns = async (token: string) => {
    const res = await http.get<Record<any, any>[]>('/getCampaigns', {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};


export const createCampaign = async (token: string, campaignRequestBody: Partial<ReqBody>) => {
    const res = await http.post(
        '/createCampaigns',
        campaignRequestBody,
        { headers: { Authorization: toBearerAuth(token),'x-trace-id': "" } }
      );
      return res.data;
};

export const updateCampaign = async (token: string, campaignRequestBody: Partial<ReqBody>, campaignId : any) => {
    const res = await http.patch(
       `/updateCampaigns/${campaignId}`,
        campaignRequestBody,
        { headers: { Authorization: toBearerAuth(token),'x-trace-id': "" } }
      );
      return res.data;
};


