import {useUnifyAdminStore} from '../../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';
import {CampaignDescriptionInputWithHelperText} from '../Blocks/CampaignDescriptionInputWithHelperText'

export const CampaignFrDescriptionField = (): JSX.Element => {

    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore((state) => state.updateCreateCampaignData);
    const permissions = useUnifyAdminStore(state => state.permissions); 

    const handleUpdateDescriptionField = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        return updateCreateCampaignData({
          fieldName: 'description',
          value: {frCA: event.target.value, enUS: description?.enUS}, 
        });
      };

      const description = get(
        campaignItems,
        'description'
      );

    return (
        <StyledComponents.StyledCreateCampaignElementWrapper>
            <CampaignDescriptionInputWithHelperText
                dataTestId="campaign-fr-desc-abbr-inpu"
                errorMessage="Error message"
                id="campaign-fr-desc-abbr-input"
                inputDivider="left"
                label="Campaign French Description"
                name="description"
                onChange={handleUpdateDescriptionField}
                placeholder="Enter French campaign description"
                value={description?.frCA}
                customWidth={'100%'}
                isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
                customHeight={'50px'}
                maxLength={350}
                helperLabel={`${description?.frCA?.length || 0}/350`}
            />
        </StyledComponents.StyledCreateCampaignElementWrapper>
    );
};
