import {
  StyledNotificationSheet,
  StyledNotificationTitleContainer,
  StyledCustomScrollbarWrapper,
} from './styles';
import { useSideSheet } from '../../contexts/SideSheet';
import { SideSheetHeader } from '../SideSheet/SideSheet';
import { useTranslation } from 'react-i18next';
import NotificationList from './NotificationList';
import { NotificationDetail } from './NotificationDetail';
import { useState } from 'react';
import { INotification } from './Notification';
import {
  useUpdateNotification,
  useDeleteNotification,
} from '../../hooks/queries';
import { useNotificationStore } from './NotificationStore';
import { AMPLITUDE_EVENTS } from '../../constants';
import { useAnalytics } from 'dms-analytics';
import { usePaneManagement } from '../../contexts/PaneManagementContext';

export default function NotificationSheet() {
  const { setSideSheetContent } = useSideSheet();
  const { t } = useTranslation();
  const toggle = () => setSideSheetContent(null);
  const [selectedNotification, setSelectedNotification] =
    useState<INotification>();
  const [showDetail, setShowDetail] = useState(false);

  const { mutate: updateNotification } = useUpdateNotification();
  const { mutate: deleteNotification } = useDeleteNotification();
  const notifications = useNotificationStore(
    (state: any) => state.notifications
  );
  const read = useNotificationStore((state: any) => state.read);
  const count = useNotificationStore((state: any) => state.count);
  const remove = useNotificationStore((state: any) => state.remove);
  const { trackEvent } = useAnalytics();
  const { openCtaPane } = usePaneManagement();

  const title =
    count > 0
      ? `${t('notification.sheet.title')} (${count})`
      : t('notification.sheet.title');

  const detailBackHandler = () => {
    setShowDetail(false);
  };

  const onDeleteHandler = (notification: INotification) => {
    if (!notification.deleted) {
      remove(notification);
    }
    !notification.deleted && deleteNotification(notification);
  };

  const onSelectHandler = (notification: INotification) => {
    if (notification.ctaUrl) {
      setSideSheetContent(null);
      openCtaPane(notification.ctaUrl);
    } else {
      setShowDetail(true);
      setSelectedNotification(notification);
    }

    !notification.read && updateNotification(notification);
    if (!notification.read) {
      read(notification);
    }
    trackEvent(AMPLITUDE_EVENTS.VIEW_NOTIFICATION_DETAIL, {
      Title: notification?.title,
      Type: notification?.type
    });
  };

  const onDetailClose = () => {
    setShowDetail(false);
  };

  return (
    <StyledNotificationSheet data-testid="notification-sheet">
      <StyledNotificationTitleContainer data-testid="notification-title-container">
        <SideSheetHeader
          name={title}
          toggle={toggle}
          dataTestId="notification-tab-header"
        />
      </StyledNotificationTitleContainer>

      <StyledCustomScrollbarWrapper>
        {notifications && (
          <NotificationList
            notifications={notifications}
            onSelect={onSelectHandler}
            onDelete={onDeleteHandler}
            hide={showDetail}
          />
        )}
      </StyledCustomScrollbarWrapper>

      {showDetail && (
        <NotificationDetail
          onClose={onDetailClose}
          notification={selectedNotification!}
          backHandler={detailBackHandler}
        />
      )}
    </StyledNotificationSheet>
  );
}
