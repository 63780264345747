import { URL_ROUTES } from '../constants';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';

export function warnDefaultConfig(defaultEnv: string) {
  return function (key: string) {
    if (!process.env[key] && process.env.NODE_ENV === 'production') {
      console.warn(
        `Key ${key} was not set in environment. Defaulting to value for ${defaultEnv}.`
      );
    }
    return process.env[key];
  };
}

export async function restoreOriginalUri(
  _oktaAuth: OktaAuth,
  originalUri?: string
): Promise<void> {
  const url = toRelativeUrl(
    originalUri || URL_ROUTES.applications,
    window.location.origin
  );
  window.location.replace(url);
}
