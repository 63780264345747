import React from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const WorkflowIdIdField = (): JSX.Element => {
    /**
     * Hooks
     */

    const permissions = useUnifyAdminStore(state => state.permissions);

    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );
    const sideSheetSubWorkflowData = useUnifyAdminStore(
        (state) => state.sideSheetDataSubWorkflow
    );
    const isWorkflowSideSheetCollapse = useUnifyAdminStore(
        (state) => state.isWorkflowSideSheetCollapse
    );

    /**
     * Local Component derived states
     */
    const appIdFieldValue = get(
        !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
        'workflowId',
        ''
    );

    const handleUpdateNameField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <>
            <StyledComponents.StyledInput
                dataTestId={`workflowId-input`}
                helperText='*Workflow ID should follow WF_name, eg. WF_SPOKE007'
                errorMessage="Workflow ID is required to create a new spoke application"
                id={`workflowId-name-input`}
                inputDivider="left"
                label='Workflow Id'
                name="workflowId"
                isRequired
                onChange={handleUpdateNameField}
                onInputClear={function noRefCheck() {
                }}
                placeholder={`${appIdFieldValue} Workflow Id`}
                value={appIdFieldValue}
                isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
            />
        </>
    );
};
