import { Loader } from 'cdk-radial';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function CenteredSpinner() {
  return (
    <SpinnerContainer>
      <Loader hideLabel label="" />
    </SpinnerContainer>
  );
}
