import {
  BUTTON_VARIANTS,
} from 'cdk-radial';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from "../../contexts/Theme";
import { StyledDialogTitle, StyledDialogTitleContainer } from "../Dialogs/LogoutConfirmDialog";
import { StyledInput, StyledTextArea } from '../UnifyAdministration/StyledComponents';
import { StyledUserCardForm, StyledWarning, UserCardDialog } from "./UserCardStyles";

import { UserCard } from './UserCards';

export default function UserCardForm({ closeHandler, saveHandler, showDialog, cardData }: {
    closeHandler: Function,
    saveHandler: Function,
    showDialog: boolean,
    cardData?: UserCard
}) {
    const { darkMode } = useTheme();
    const {t} = useTranslation();
    const [name, setName] = useState(cardData?.name);
    const [url, setUrl] = useState(cardData?.url);
    const [description, setDescription] = useState(cardData?.description);
    const [formError, setFormError] = useState(false);

    const saveUserCard = () => {
        const iconText = name?.split(' ').reduce((acc, word) => { return acc + word.substring(0, 1) }, '').substring(0,2);
        const urlValid = validateUrl(url as string);
        if(!urlValid) {
            setFormError(true);
            return;
        }
        if(name && description && urlValid ){ 
            saveHandler({
                name,
                description,
                url,
                iconText,
                appId: cardData?.appId
            });
            clearForm();
        }
    }

    const validateUrl = (linkUrl:string) => {
		let cardUrl;
        let isValid = true;
		try { 
	      	cardUrl = new URL(linkUrl);
	    }
	    catch(e){ 
	      isValid = false; 
	    }
	    isValid = (cardUrl?.protocol === "https:");
        setFormError(!isValid);
        return isValid;
	}

    const clearForm = () => {
        setName('');
        setDescription('');
        setUrl('');
        setFormError(false);
    }

    useEffect(() => {
        if(cardData?.appId) {
            setUrl(cardData.url);
            setDescription(cardData.description);
            setName(cardData.name);
        }
    }, [cardData]);

    return (
         <UserCardDialog 
                isOpen={showDialog}
                onClose={() => { clearForm(); closeHandler() }}
                disableOverlayClicked
                dataTestId={'user-card-dialog'}
                buttonsProps={[
                    {
                        id: 'Cancel',
                        dataTestId: 'logout-dialog-cancel',
                        onClick: () => { clearForm(); closeHandler() },
                        text: t('logoutConfirmDialog.cancelBtn'),
                        variant: BUTTON_VARIANTS.TEXT,
                        style: {
                            color: darkMode ? 'white' : 'black'
                        },
                    },
                    {
                        id: 'logout-dialog-sign-out',
                        onClick: () => saveUserCard(),
                        text: cardData?.appId ? t('baseCard.menu.update') : t('baseCard.menu.create'),
                        variant: BUTTON_VARIANTS.PRIMARY,
                        style: {
                            color: darkMode ? 'black' : 'white',
                            backgroundColor: darkMode ? 'white' : 'black',
                            },
                    }]}
                title={<StyledDialogTitleContainer>
                            <StyledDialogTitle>
                            { cardData?.appId ? t('myUnifyTab.editCard')  : t('myUnifyTab.newCard')  }
                            </StyledDialogTitle>
                        </StyledDialogTitleContainer> } >
                    
                    <StyledUserCardForm>
                    
                    <StyledInput 
                        dataTestId="usr-card-name"
                        errorMessage={t('userCardForm.nameError')}
                        hideBorder="none"
                        iconPosition="trailing"
                        id="first-name-input"
                        inputDivider="left"
                        label={t('userCardForm.name')}
                        name="card-name"
                        onChange={ (evt: any) => setName(evt.target?.value) }
                        placeholder={t('userCardForm.nameHolder')}
                        size="standard"
                        maxLength="300"
                        isRequired
                        value={name || ''} />

                    <StyledTextArea 
                        dataTestId="usr-card-des"
                        errorMessage={t('userCardForm.desError')}
                        hideBorder="none"
                        iconPosition="trailing"
                        id="usr-card-des"
                        inputDivider="left"
                        label={t('userCardForm.description')}
                        name="usr-card-des"
                        onChange={ (evt: any) => setDescription(evt.target?.value) }
                        placeholder={t('userCardForm.desHolder')}
                        size="standard"
                        maxLength="300"
                        rows="3"
                        isRequired
                        value={description || ''} />

                    <StyledInput 
                        dataTestId="usr-card-url"
                        errorMessage={t('userCardForm.urlError')}
                        hideBorder="none"
                        iconPosition="trailing"
                        id="usr-card-url"
                        inputDivider="left"
                        label={t('userCardForm.url')}
                        name="usr-card-url"
                        onChange={ (evt: any) => { validateUrl(evt.target?.value); setUrl(evt.target?.value) }}
                        placeholder="https://link.com"
                        size="standard"
                        hasError={formError}
                        enableCustomValidation
                        isRequired
                        value={url || ''} />
                        <StyledWarning>{}</StyledWarning>
                    </StyledUserCardForm>
            </UserCardDialog>
    )
}