import {
    Toast,
    TOAST_POSITIONS,
    TOAST_VARIANTS,
    TOAST_VISIBILITY_DURATIONS,
    BUTTON_VARIANTS,
    BUTTON_ICON_COLORS,
    IconCancelOutlined,
  } from 'cdk-radial';

import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {useSSE} from './SSE';
import  {useNotificationStore} from '../../Store';
import {fetchNotifications} from '../../util/api';
import useAuthToken from '../../hooks/useAuthToken';

export const SSENotification = () => {

  const [event, setEvent] = useState();
  const token = useAuthToken();
  useSSE(token,(event:any) => setEvent(event));

  return (
    <NotificationReciever event={event}/>
  );

}

export const NotificationReciever = (props : {event:any}) => {

  const { event } = props;
  const token = useAuthToken();
  const [showToast, setShowToast] = useState(false);
  const [showToastMsg, setShowToastMsg] = useState<string>('');
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const updateNotifications= useNotificationStore((state:any) => state.updateNotifications);

  useEffect(()=>{
    try {
      if (event ){
        getNotificationUIMap().get(event.type)(event);
      } 
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[event]);

  const getNotification = async () => {
    try {
      let response = await fetchNotifications(token);
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const getNotificationUIMap = () =>{
    const map = new Map();
    map.set("notification",async ()=>{
      updateNotifications(await getNotification());
    });
    map.set("messages",(event:any)=>{
      updateNotifications([...event.response]);
    });
    map.set("profile",()=>{
      setShowToastMsg(t('home.userProfileCreated') as string);
      setShowToast(true);
    });
    map.set("workflowonly",()=>{
      setShowToastMsg(t('home.notificationsNewWflow') as string);
      setShowToast(true);
    });
    map.set("apponly",()=>{
      setShowToastMsg(t('home.notificationsNewApp') as string);
      setShowToast(true);
    });
    map.set("appandworkflow",()=>{
      setShowToastMsg(t('home.notificationsNewAppAndWflow') as string);
      setShowToast(true);
    });
    return map;
  }
 
    return(
        <>
          {showToast && (
              <Toast
              id="notification-toast"
              datatestid="notification-toast"
              position={TOAST_POSITIONS.FIXED}
              style={{
                  marginTop: '70px',
              }}
              variant={TOAST_VARIANTS.NEUTRAL}
              visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
              onVisibilityDurationEnd={() => {
                  setShowToast(false);
              }}
              actions={[
                  {
                  onClick: () => {
                      queryClient.invalidateQueries('applications');
                      queryClient.invalidateQueries('workflows');
                      queryClient.invalidateQueries('notifications');
                      setShowToast(false);
                  },
                  text: t('home.notificationsRefresh'),
                  },
                  {
                  onClick: () => {
                      setShowToast(false);
                  },
                  variant: BUTTON_VARIANTS.ICON,
                  icon: <IconCancelOutlined />,
                  iconColor: BUTTON_ICON_COLORS.GRAY,
                  text: '',
                  },
              ]}
              content={showToastMsg}
              />
          )}
      </>
    );
}