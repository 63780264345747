import { DealerNetworkAccessRequiredIllustration, Toast } from 'cdk-radial';
import styled from 'styled-components';
import React from 'react';
import * as StyledComponents from '../StyledComponents';
const StyledNoPermissionToAccess = styled(
  DealerNetworkAccessRequiredIllustration
)`
  .lock {
    fill: ${({ theme }) => theme?.color?.secondary?.fireballOrange[200]?.value};
  }
  min-width: 50%;
  height: 50%;
`;

export const NoPermissionToAccess = (): JSX.Element => (
  <>
    <Toast
      variant="negative"
      data-testid="no-permission-admin-toaster"
      content="You do not have permission to manage Unify Admin. Please contact Unify Team!"
    />
    <StyledComponents.StyledSVGContainer data-testid="no-access-svg-container">
      <StyledNoPermissionToAccess
        dataTestId="unify-admin-no-permission-page"
        svgTitle={`Oh! Perhaps you don't have permissions to manage Unify Apps and Users!`}
        size={'50vw'}
      />
    </StyledComponents.StyledSVGContainer>
  </>
);

export default NoPermissionToAccess;
