import FeatureReleaseNotificationList from '../../Blocks/FeatureReleaseNotificationList';
import * as StyledComponents from '../../StyledComponents';
import { AdminDataSideSheet } from '../../../Blocks/AdminDataSideSheet';
import { AdminDataSideSheetHeader } from '../../../Blocks/AdminDataSideSheetHeader';
import { SIDE_SHEET_TITLE_NAMES } from '../../../Constants';
import { FeatureReleaseSideSheetBody } from '../../Blocks/FeatureReleaseSideSheetBody';
import { useUnifyAdminStore } from '../../../AdminStore';
import {
  Toast,
  TOAST_POSITIONS,
  TOAST_VISIBILITY_DURATIONS,
  BUTTON_VARIANTS,
  BUTTON_ICON_COLORS,
  IconCancelOutlined,
} from 'cdk-radial';

export default function FeatureAdminContainer(): JSX.Element {
  const showToast = useUnifyAdminStore((state) => state.releaseShowToast);
  const resetToastState = useUnifyAdminStore(
    (state) => state.resetReleaseToastState
  );

  const toastMsg = useUnifyAdminStore((state) => state.releaseToastMsg);
  const toastType = useUnifyAdminStore((state) => state.releaseToastType);

  const setIsValidationState = useUnifyAdminStore(
    (state) => state.setIsReleaseValidationState
  );

  const setWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setAdminSideSheetCollapse
  );

  const isReleaseSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isReleaseSideSheetCollapse
  );

  const setApplicationSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setWorkflowSideSheetCollapse
  );

  const setReleaseSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setReleaseSideSheetCollapse
  );

  const resetReleaseSideSheetState = useUnifyAdminStore(
    (state) => state.resetReleaseSideSheetState
  );

  const handleCloseSideSheet = () => {
    setWorkflowSideSheetCollapse(true);
    setApplicationSideSheetCollapse(true);
    setReleaseSideSheetCollapse(true);
    resetReleaseSideSheetState();
  };

  return (
    <StyledComponents.StyledCustomScrollbarWrapper>
      <StyledComponents.StyledContainerWrapper data-testid="outage-notification-container">
        {showToast && (
          <Toast
            id="outage-notification-toast"
            datatestid="outage-notification-toast"
            position={TOAST_POSITIONS.FIXED}
            style={{
              marginTop: '70px',
              zIndex: 1003,
            }}
            variant={toastType === 'Error' ? 'negative' : 'positive'}
            visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
            onVisibilityDurationEnd={() => {
              resetToastState();
              setIsValidationState(false);
            }}
            actions={[
              {
                onClick: () => {
                  resetToastState();
                  setIsValidationState(false);
                },
                variant: BUTTON_VARIANTS.ICON,
                icon: <IconCancelOutlined />,
                iconColor: BUTTON_ICON_COLORS.GRAY,
                text: '',
              },
            ]}
            content={toastMsg}
          />
        )}

        {!isReleaseSideSheetCollapse && (
          <AdminDataSideSheet isCollapse={isReleaseSideSheetCollapse}>
            <AdminDataSideSheetHeader
              sideSheetHeaderTitle={SIDE_SHEET_TITLE_NAMES.FEATURE_RELEASE}
              handleSideSheetClose={handleCloseSideSheet}
            />
            <FeatureReleaseSideSheetBody />
          </AdminDataSideSheet>
        )}
        <FeatureReleaseNotificationList></FeatureReleaseNotificationList>
      </StyledComponents.StyledContainerWrapper>
    </StyledComponents.StyledCustomScrollbarWrapper>
  );
}
