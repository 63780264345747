export const defaultSideSheetDataSchema = {
    appId: '',
    name: '',
    url: '',
    description: { enUS: '', frCA: '' },
    icon: '',
    iconText: '',
    appCategory: '',
    updatedBy: '',
    updatedOn: '',
    versionNumber: '',
    isActive: '',
    createdOn: '',
    createdBy: '',
    logoutUrl: '',
    unrestricted: '',
    workflows: [
        {
            workflowId: '',
            name: '',
            url: '',
            description: { enUS: '', frCA: '' },
            domain: '',
            applications: [
                {
                    appId: '',
                    name: '',
                    url: '',
                    description: { enUS: '', frCA: '' },
                    icon: '',
                    iconText: '',
                    appCategory: '',
                    updatedBy: '',
                    updatedOn: '',
                    versionNumber: '',
                    isActive: '',
                    createdOn: '',
                    createdBy: '',
                    logoutUrl: '',
                    unrestricted: '',
                },
            ],
        },
    ],
};

export const defaultWorkflowSchema = {
    workflows: [
        {
            workflowId: '',
            name: '',
            url: '',
            description: { enUS: '', frCA: '' },
            domain: '',
            applications: [
                {
                    appId: '',
                    name: '',
                    url: '',
                    description: { enUS: '', frCA: '' },
                    icon: '',
                    iconText: '',
                    appCategory: '',
                    updatedBy: '',
                    updatedOn: '',
                    versionNumber: '',
                    isActive: '',
                    createdOn: '',
                    createdBy: '',
                    logoutUrl: '',
                    unrestricted: ''
                },
            ],
        },
    ],
    metaData: {},
};


export interface IWorkflowApplication {
    appId: string;
    name: string;
    url: string;
    description: { enUS: string, frCA: string };
    icon: string;
    iconText: string;
    appCategory: string;
    updatedBy: string;
    updatedOn: string;
    versionNumber: string;
    isActive: boolean;
    createdOn: string;
    createdBy: string;
    logoutUrl: string;
    unrestricted: boolean;
    cdkuUrl: string;
}

export interface IWorkflow {
    workflowId: string;
    name: string;
    url: string;
    description: { enUS: string, frCA: string };
    domain: string;
    applications: IWorkflowApplication[];
    permissions: Permission;
    cdkuUrl: string;
}

export interface ISideSheetData {
    appId: string;
    workflowId?: string,
    name: string;
    url: string;
    description: { enUS: string, frCA: string };
    icon: string;
    iconText: string;
    appCategory: string;
    updatedBy: string;
    updatedOn: string;
    versionNumber: string;
    isActive: boolean;
    createdOn: string;
    createdBy: string;
    logoutUrl: string;
    unrestricted: boolean;
    workflows: IWorkflow[];
    permissions: Permission;
    cdkuUrl: string;
}

export interface IEvent {
    fieldName: string;
    value: string;
}


export interface Permission {
    id: string,
    type: "APPLICATION" | "WORKFLOW"
    enterprises: string[],
    enterprise: any;
    include_enterprise: string[],
    exclude_enterprise: string[],
    stores: string[],
    users: string[],
    roles: string[],
    isActive: boolean
}