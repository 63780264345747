import * as StyledComponents from '../../StyledComponents';
import { FeatureReleaseSheetDetails } from './FeatureReleaseSheetDetails';

export const FeatureReleaseSideSheetBody = (): JSX.Element => {
  return (
    <StyledComponents.StyledSideSheetBodyContainer>
      <FeatureReleaseSheetDetails />
    </StyledComponents.StyledSideSheetBodyContainer>
  );
};
