import * as Styles from '../styles';
import {useHistory} from 'react-router-dom'
import ApplicationCard from '../Cards/ApplicationCard';
import DriveApplicationCard from '../Cards/DriveApplicationCard';
import { Application } from '../../types';
import { canOpenDrive } from '../../util/DriveUtils';
import { DRIVE_TITLE_ID, UNIFYADMIN_APP_ID } from '../../constants';
import React from 'react';
import { useEffect } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { usePaneManagement } from '../../contexts/PaneManagementContext';
import * as api from '../../util/api';
import useAuthToken from '../../hooks/useAuthToken';
import { useUnifyAdmin } from '../UnifyAdministration/Hooks/useUnifyAdmin';
import { useTranslation } from 'react-i18next';
import launchExternal from '../../util/launchExternal';
import { useUnifyAdminStore } from '../UnifyAdministration/AdminStore';

export default function ApplicationsList({
  applications,
}: {
  applications: Application[];
}) {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { openPane, openCdkuPane } = usePaneManagement();
  const {t} = useTranslation();
  const token = useAuthToken();
  const { isLoading: isLoadingAdmin, isUnifyEnterpriseAdmin: isUnifyEnterpriseAdminData } = useUnifyAdmin();
  const setIsUnifyEnterpriseAdmin = useUnifyAdminStore(state => state.setIsUnifyEnterpriseAdmin);
  const isUnifyEnterpriseAdmin = useUnifyAdminStore(state => state.isUnifyEnterpriseAdmin); 


  useEffect(() => {
    setIsUnifyEnterpriseAdmin(isUnifyEnterpriseAdminData);
  }, [setIsUnifyEnterpriseAdmin, isUnifyEnterpriseAdminData]);

  const closeDialog = () => {
    setErrorMessage(null);
  };

  const onCardOpen = async (id: string, name: string, url: string) => {
    const message = `${t('applicationList.troubleLaunchMsg')} ${name}.`;
    try {
      // will throw on: bad url, 500, 404
      await api.fetchUrlResponse(url, token);
      launchExternal(url) || openPane(id);
    } catch {
      setErrorMessage(message);
    }
  };

  const  onCdkuOpen = (url: string) => {
    openCdkuPane('CDKU', url);
  };

    return (
        <>
            {errorMessage && (
                <InfoDialog
                    title={t('applicationList.infoTitle')}
                    description={errorMessage}
                    isOpen
                    handleClose={() => closeDialog()}
                />
            )}
            {applications?.length ? (
                <Styles.CardList>
                    {applications.map((app) => {
                        return app.id === DRIVE_TITLE_ID ? (
                            <DriveApplicationCard
                                key={app.id}
                                name={app.name}
                                description={app.description}
                                url={app.url}
                                isFavorite={app.isFavorite}
                                isEnabled={canOpenDrive()}
                                iconColor={app.icon}
                                iconText={app.iconText}
                                id={app.id}
                                onCdkuOpen={onCdkuOpen}
                                 cdkuUrl={app.cdkuUrl || ''}
                            />
                        ) : app.id === UNIFYADMIN_APP_ID? (
                        !isLoadingAdmin &&
                            (isUnifyEnterpriseAdmin) &&
                            (
                                        <ApplicationCard
                                            key={app.id}
                                            name={app.name}
                                            description={app.description}
                                            onCardOpen={() => {
                                                history.push('/admin');
                                            }}
                                            url="www.placeholder.com"
                                            isEnabled={!!isUnifyEnterpriseAdmin}
                                            isFavorite={app.isFavorite}
                                            iconColor={app.icon}
                                            iconText={app.iconText}
                                            id={app.id}
                                            onCdkuOpen={onCdkuOpen}
                                            cdkuUrl={app.cdkuUrl || ''}
                                        />
                                  
                            ))
                        :(<ApplicationCard
                                key={app.id}
                                name={app.name}
                                description={app.description}
                                url={app.url}
                                isFavorite={app.isFavorite}
                                isEnabled
                                iconColor={app.icon}
                                onCardOpen={() => onCardOpen(app.id, app.name, app.url)}
                                iconText={app.iconText}
                                id={app.id}
                                onCdkuOpen={onCdkuOpen}
                                cdkuUrl={app.cdkuUrl || ''}
                            />
                        );
                    })}
                </Styles.CardList>
            ) : (
                <div>
                    <Styles.UserMessageHeader>
                        {t('applicationList.noApplnAssigned')}
                    </Styles.UserMessageHeader>
                    <Styles.UserMessageDescription>
                        {t('applicationList.noApplnAssignedDesc')}
                    </Styles.UserMessageDescription>
                </div>
            )}
        </>
    );
}
