import { create } from "zustand";
import { WorkflowViewMap } from '../types';

export interface WorkflowsViewMapState {
    workflowsViewMap: WorkflowViewMap;
    setWorkflowsViewMap: (workflowsViewMap: WorkflowViewMap) => void;
    favoriteWorkflowsViewMap: WorkflowViewMap;
    setFavoriteWorkflowsViewMap: (favoriteWorkflowsViewMap: WorkflowViewMap) => void;
    apiStatus: string;//"error" | "idle" | "loading" | "success"
    setApiStatus: (apiStatus: string) => void;
}

export const useWorkflowsViewMapStore = create<WorkflowsViewMapState>((set) => ({
    workflowsViewMap: {},
    setWorkflowsViewMap: (workflowsViewMap) => set(() => ({ workflowsViewMap })),
    favoriteWorkflowsViewMap: {},
    setFavoriteWorkflowsViewMap: (favoriteWorkflowsViewMap) => set(() => ({ favoriteWorkflowsViewMap })),
    apiStatus: "",
    setApiStatus: (apiStatus) => set(() => ({ apiStatus })),
}));
