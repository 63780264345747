import React from 'react';
import { useUnifyAdminStore } from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const LogoutURLField = (): JSX.Element => {

  const permissions = useUnifyAdminStore(state => state.permissions);
  /**
   * Data Store
   */
  const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );
  const logoutURL = get(sideSheetData, 'logoutUrl', '');

    const handleUpdateLogoutField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

  return (
    <>
      <StyledComponents.StyledInput
        dataTestId="logout-url-input"
        errorMessage="Error message"
        id="logout-url-input"
        inputDivider="left"
        label="Logout URL"
        name="logoutUrl"
        onChange={handleUpdateLogoutField}
        onInputClear={function noRefCheck() {}}
        placeholder="Logout URL"
        value={logoutURL}
        maxLength="300"
        isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}

      />
    </>
  );
};
