import React, {useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {INPUT_SIZES, IconAdd} from 'cdk-radial';
import * as StyledComponents from '../StyledComponents'
import {useUnifyAdminStore} from '../AdminStore';
import {defaultSideSheetDataSchema, ISideSheetData, IWorkflow} from '../AdminStore/slices/types/types'
import {SIDE_SHEET_TITLE_NAMES} from '../Constants';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk'

interface IAdminGridTableHeader{
    gridRef: React.RefObject<AgGridReact<any>>
}

export default function AdminGridTableHeader({gridRef}:IAdminGridTableHeader): JSX.Element {

    /**
     * Data Store
     */
    const searchedValue = useUnifyAdminStore((state) => state.adminGridSearchValue)
    const handleChangeSearchValue = useUnifyAdminStore((state) => state.setAdminGridSearchValue)
    const setSideSheetTitleName = useUnifyAdminStore(state => state.setSideSheetTitleName)
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setSideSheetData = useUnifyAdminStore(state => state.setSideSheetData)
    const setCreateNewApplication = useUnifyAdminStore(state=>state.setCreateNewApplication)
    /*
    * Flags
    * */
    const unifyAdminCreateDelete = useFeatureFlag('UNIFY_UI_ADMINISTRATION');


    const onExportDownloadButton = useCallback(() => {
        gridRef?.current?.api?.exportDataAsExcel({
            processRowGroupCallback: (params:any)  => {
                    return params.node.key;
                },
        });
    }, [gridRef]);

    const handleCreateNewApplication = (): void => {
        setSideSheetTitleName(SIDE_SHEET_TITLE_NAMES.CREATE_NEW_APPLICATION)
        setAdminSideSheetCollapse(false);
        setCreateNewApplication(true)
        setSideSheetData(defaultSideSheetDataSchema as unknown as ISideSheetData | IWorkflow)
    }
    const permissions = useUnifyAdminStore(state => state.permissions);

    return (
        <StyledComponents.StyledAdminTableContainer data-testid='admin-grid-table-header'>
            <StyledComponents.StyledHeaderContainer>
                <StyledComponents.StyledSubHeading headingType='subtitle-2' padding='10px'>
                    Application & Workflow
                </StyledComponents.StyledSubHeading>
                <StyledComponents.StyledHeading headingType='subtitle-2' padding='10px'>
                    <StyledComponents.StyledIconFileDownload onClick={()=>onExportDownloadButton()} data-testid='export-search-result-icon' />
                </StyledComponents.StyledHeading>
            </StyledComponents.StyledHeaderContainer>
            <StyledComponents.StyledSearchBoxContainer>
                <StyledComponents.StyledSearchBox
                    dataTestId="admin-search-input"
                    className="customize-search-input"
                    clearButtonLabel="clearButtonLabel"
                    id="admin-grid-search-input-expanded"
                    isAlwaysExpanded
                    name="admin-grid-search-input-expanded"
                    onChange={(e: string) => handleChangeSearchValue(e)}
                    placeholder="Search"
                    size={INPUT_SIZES.STANDARD}
                    value={searchedValue}
                />
            </StyledComponents.StyledSearchBoxContainer>
            {unifyAdminCreateDelete === true &&
            <StyledComponents.StyledButtonWithIcon
                onClick={() => handleCreateNewApplication()}
                variant='secondary'
                text='Create New Application'
                icon={<IconAdd/>}
                isDisabled={!permissions?.hasAppSuperAdminPermissions}
            />}
        </StyledComponents.StyledAdminTableContainer>

    );
}
