import { BrowserRouter } from 'react-router-dom';

import Home from './components/Home/Home';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import createOktaInstance from './config/okta';
import { restoreOriginalUri } from './config/helpers';
import { BypassableSecurity } from './components/common/BypassableRoutes';
import * as Styles from './components/styles';
import { AnalyticsProvider } from 'dms-analytics';
import getUserIdFromOkta from './util/getUserIdFromOkta';
import { useScriptInjector } from './hooks/useScriptInjector';
import { MessageProvider } from './components/MessageProvider/MessageProvider';
import { AppStateProvider } from './contexts/AppStateContext';
import { useUserProfile } from './hooks/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk'
import {REACT_APP_AHA_APPLICATION_ID, REACT_APP_HARNESS} from './constants';
const isScriptDefer = false;
const isAsync = true;

const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
const oktaAuth = createOktaInstance();

function App() {
  const userId = getUserIdFromOkta();
  const { data } = useUserProfile();
  const { i18n } = useTranslation();
  window.WalkMeLoginId =  data?.profile?.email;

  const userLocale = data?.profile?.locale || 'en_US';

  const enterpriseId = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')
      .accessToken
      ?.claims
      ?.enterpriseId || "";
  const email = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')
      .accessToken
      ?.claims
      ?.cdksid || "";

  const ahaScript = useScriptInjector(
    `/thirdParty/ahaideas.js`,
    isAsync,
    isScriptDefer
  );

  React.useEffect(()=>{
    i18n.changeLanguage(userLocale.substring(0, 2));
  },[i18n,userLocale]);

  React.useEffect(()=>{
    if(ahaScript === 'ready') {
     const aha = window.aha;
     data?.profile && aha('initialize', {
          account: 'cdk',
          applicationId: REACT_APP_AHA_APPLICATION_ID,
          user: {
            id: data?.profile?.email,
            name: `${data?.profile?.firstName} ${data?.profile?.lastName}`,
            email: data?.profile?.email,
          },
      });
    }

  },[data, ahaScript]);

  useScriptInjector(
    '/thirdParty/qualtrics.feedback.min.js',
    isAsync,
    isScriptDefer
  );

  useScriptInjector(
    `/thirdParty/newRelic.${process.env.REACT_APP_ENV}.min.js`,
    isAsync,
    isScriptDefer
  );




  return (
    <Styles.App>
      <AppStateProvider>
        <BrowserRouter>
          <AnalyticsProvider apiKey={amplitudeKey} userId={userId} sessionReplay={true}>
            <BypassableSecurity
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
            >
              <ThemeProvider>
                    <MessageProvider>
                    <FFContextProvider
                      async={true}
                      apiKey={REACT_APP_HARNESS}
                      target={{
                        identifier: "UNIFY",
                        attributes: { enterpriseId: enterpriseId, email: email },
                        name: "UNIFY"
                      }}
                      options={{
                        streamEnabled: true,
                      }}
                      >
                      <Home />
                      </FFContextProvider>
                    </MessageProvider>
                </ThemeProvider>
            </BypassableSecurity>
          </AnalyticsProvider>
        </BrowserRouter>
      </AppStateProvider>
     
    </Styles.App>
  );
}

export default App;
