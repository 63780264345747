import * as Styles from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  isApplication?: boolean;
  isUserCard: boolean;
}

export default function EmptyStateCard({ isApplication, isUserCard }: Props) {
  const {t} = useTranslation();
  const history = useHistory();
  const typeOfApp = isApplication ? t('baseCard.applications') : t('baseCard.workflows');
  const cardTitle = isUserCard ? t('userCardForm.emptyCardTitle') : t('emptyStateCard.name', {typeOfApp});
  const cardDesc= isUserCard ? t('userCardForm.emptyCardDesc') : t('emptyStateCard.desc',{typeOfApp});

  return (
    <Styles.EmptyCard userCard={isUserCard} data-testid={`card-empty`} >
      <div>
        <Styles.CardHeader>{cardTitle}</Styles.CardHeader>
        <Styles.CardDescription>{cardDesc}</Styles.CardDescription>
      </div>
      <Styles.EmptyCardFooter>
        { isUserCard || <Styles.Button
          data-testid={
            isApplication
              ? 'empty-card-go-to-Application'
              : 'empty-card-go-to-workflow'
          }
          onClick={() => {
            isApplication
              ? history.push(`/applications`)
              : history.push(`/workflows`);
          }}
        >
          {t('baseCard.continue')}
        </Styles.Button> }
      </Styles.EmptyCardFooter>
    </Styles.EmptyCard>
  );
}
