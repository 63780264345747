import * as CampaignFields from '../Elements'
import * as StyledComponents from '../StyledComponents'
import {CampaignEnablementStatusField} from '../Elements/CampaignEnablementStatusField';
import {useUnifyAdminStore} from '../../AdminStore/index';
import { BUTTON_VARIANTS} from 'cdk-radial';

const PreviewButton = (): JSX.Element => {
    const setIsPreviewCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsPreviewCampaignDialogOpen);
    const setIsCreateOrUpdatePreview = useUnifyAdminStore((state) => state.setIsCreateOrUpdatePreview);
    
    const onPreviewClick = () => {
        setIsPreviewCampaignDialogOpen(true);
        setIsCreateOrUpdatePreview(true);
    };

    return (
        <StyledComponents.StyledPreviewButton text="Preview" variant={BUTTON_VARIANTS.TEXT} onClick={onPreviewClick} />
    );
};

export const CreateCampaignDialogBody = (): JSX.Element => {
    return <StyledComponents.StyledDialogContentBodyWrapper>
        <StyledComponents.StyledDialogContentBody data-testid='create-campaign-dialog-content'>
        <StyledComponents.StyledCreateCampaignElementWrapper>
            <PreviewButton></PreviewButton>
        <CampaignEnablementStatusField/>
        </StyledComponents.StyledCreateCampaignElementWrapper>
            <StyledComponents.StyledCreateCampaignElementWrapper>
                <CampaignFields.CampaignNameField/>
                <CampaignFields.CampaignLinkField/>
            </StyledComponents.StyledCreateCampaignElementWrapper>
            <StyledComponents.StyledCreateCampaignElementWrapper>
                <CampaignFields.CampaignDescriptionField/>
            </StyledComponents.StyledCreateCampaignElementWrapper>
            <StyledComponents.StyledCreateCampaignElementWrapper>
                <CampaignFields.CampaignFrDescriptionField/>
            </StyledComponents.StyledCreateCampaignElementWrapper>
            <StyledComponents.StyledCreateCampaignElementWrapper>
                <CampaignFields.CampaignLogoImageUploadField/>
            </StyledComponents.StyledCreateCampaignElementWrapper>
        </StyledComponents.StyledDialogContentBody>
    </StyledComponents.StyledDialogContentBodyWrapper>
}