import {
  Dialog,
  BUTTON_VARIANTS,
  IconStatusWarningExclamation,
} from 'cdk-radial';
import styled from 'styled-components';
import { SUPPORT_LINK } from '../../constants';
import { useTranslation } from 'react-i18next';

export const StyledDialog = styled(Dialog)`
  min-width: 560px;
  max-width: 30%;
  margin-top: 60px;
  z-index: 9999;
`;

export const StyledDialogTitle = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  color: #000000;
  text-transform: capitalize;
`;

export const StyledDialogTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDialogContentText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const StyledIconAccountCircle = styled(IconStatusWarningExclamation)`
  fill: #fa9c19;
  height: 38px;
  width: 38px;
  margin-right: 16px;
`;

interface IErrorHandlingDialog {
  isOpen: boolean;
  onClose: () => void;
  onReload: () => void;
}

const ErrorHandlingDialog = ({
  isOpen,
  onReload,
  onClose,
}: IErrorHandlingDialog): JSX.Element => {
  const {t} = useTranslation();
  return (
    <div>
      <StyledDialog
        buttonsProps={[
          {
            id: 'error-dialog-close-button',
            onClick: onClose,
            text: t('errorDialog.dialogCloseBtn'),
            variant: BUTTON_VARIANTS.TEXT,
            style: {
              color: 'black',
            },
          },
          {
            id: 'error-dialog-reload-button',
            onClick: onReload,
            text: t('errorDialog.dialogReloadBtn'),
            variant: BUTTON_VARIANTS.PRIMARY,
            style: {
              color: 'black',
              backgroundColor: '#E8E8E9',
            },
          },
        ]}
        id="error-handling-alert"
        isOpen={isOpen}
        onClose={onClose}
        title={
          <StyledDialogTitleContainer>
            <StyledIconAccountCircle />
            <StyledDialogTitle>
              {t('errorDialog.unableToLoad')}
            </StyledDialogTitle>
          </StyledDialogTitleContainer>
        }
      >
        <StyledDialogContentText data-testid="errorDialogContent">
          {t('errorDialog.contentPart1')}
          <a href={SUPPORT_LINK} target="_blank" rel="noreferrer">
            CDK Support
          </a>
          {t('errorDialog.contentPart2')}
        </StyledDialogContentText>
      </StyledDialog>
    </div>
  );
};
export default ErrorHandlingDialog;
