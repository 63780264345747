import React from 'react';
import * as StyledComponents from '../StyledComponents';

interface IAdminDataSideSheet {
  children: React.ReactNode;
  isCollapse: boolean;
}

export const AdminDataSideSheet = ({
  children,
  isCollapse,
}: IAdminDataSideSheet): JSX.Element => {
  return (
    <StyledComponents.StyledSideSheetContainer
      data-testid="side-sheet"
      width="21.8vw"
      isCollapsed={isCollapse}
    >
      {children}
    </StyledComponents.StyledSideSheetContainer>
  );
};
