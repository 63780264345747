import React from 'react';
import { useUnifyAdminStore } from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import { AdminSideSheetDetails } from './AdminSideSheetDetails';

export const AdminApplicationSideSheetBody = (): JSX.Element => {
  /**
   * Data Store
   */
  const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
  return (
    <StyledComponents.StyledSideSheetBodyContainer>
      <AdminSideSheetDetails sideSheetData={sideSheetData} />
    </StyledComponents.StyledSideSheetBodyContainer>
  );
};

export const AdminWorkflowSideSheetBody = (): JSX.Element => {
  /**
   * Data Store
   */
  const sideSheetDataSubWorkflow = useUnifyAdminStore(
    (state) => state.sideSheetDataSubWorkflow
  );


  return (
    <StyledComponents.StyledSideSheetBodyContainer>
      <AdminSideSheetDetails sideSheetData={sideSheetDataSubWorkflow} />
    </StyledComponents.StyledSideSheetBodyContainer>
  );
};
