import {useUnifyAdminStore} from '../../AdminStore';
import * as StyledComponents from '../StyledComponents';
import {CampaignNameInputWithHelperText} from '../Blocks/CampaignNameInputWithHelperText'

export const CampaignNameField = (): JSX.Element => {

    /**
     * Data Store
     */
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore(
        (state) => state.updateCreateCampaignData
    );
    const permissions = useUnifyAdminStore(state => state.permissions);

    const handleUpdateNameField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateCreateCampaignData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <StyledComponents.StyledCreateCampaignElementWrapper>
            <CampaignNameInputWithHelperText
                dataTestId="campaign-name-input"
                errorMessage="Please enter Name"
                id="campaign-name-abbr-input"
                inputDivider="left"
                label="Campaign Name"
                name="name"
                onChange={handleUpdateNameField}
                placeholder="Enter campaign name"
                value={campaignItems.name}
                customWidth={'100%'}
                isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
                isRequired
                helperLabel="The text above the description"
                maxLength={100}
                textLength={`${campaignItems?.name?.length || 0}/100`}
            />
        </StyledComponents.StyledCreateCampaignElementWrapper>
    );
};
