import {StateCreator} from 'zustand';
import {ENVIRONMENTS} from '../../Constants';

type SelectedEnvOption = {
    label: string;
    value: keyof typeof ENVIRONMENTS;
};

enum MutationStatus {
    Error = 'Error',
    Success = 'Success',
    Idle = 'Idle'
}

export interface ICreateAdminEnvironmentSlice {
    selectedEnvironment: { label: string; value: keyof typeof ENVIRONMENTS };
    setSelectedEnvironment: (selectedEnv: SelectedEnvOption) => void;
    showToast: boolean;
    setShowToast: (show: boolean) => void;
    customToastContent: { type: keyof typeof MutationStatus |'', message: string }
    setCustomToastContent: (content: { type: keyof typeof MutationStatus, message: string }) => void,
    showCustomContentToast: boolean,
    setShowCustomContentToast: (show: boolean) => void,
    setPermissions:(permissions: any) => void;
    permissions:any;
    setIsUnifyEnterpriseAdmin:(isUnifyEnterpriseAdmin: any) => void;
    isUnifyEnterpriseAdmin:any;
    
}

export const createAdminEnvironmentSlice: StateCreator<ICreateAdminEnvironmentSlice,
    [['zustand/devtools', never]],
    [],
    ICreateAdminEnvironmentSlice> = (set) => {
    const environment = process.env.REACT_APP_ENV as keyof typeof ENVIRONMENTS

    const defaultEnv = {
        label: ENVIRONMENTS[environment],
        value:  environment,
    };

    return {
        selectedEnvironment: defaultEnv,
        setSelectedEnvironment: (selectedEnv) =>
            set(() => ({selectedEnvironment: selectedEnv})),
        showToast: false,
        setShowToast: (showToast) => set(() => ({showToast})),
        customToastContent: {type: '', message: ''},
        setCustomToastContent: (customToastContent) => set(() => ({customToastContent})),
        showCustomContentToast: false,
        setShowCustomContentToast: (showCustomContentToast) => set(() => ({showCustomContentToast})),
        setPermissions:(permissions: any) => set(() => ({ permissions })),
        permissions:{},
        setIsUnifyEnterpriseAdmin:(isUnifyEnterpriseAdmin: any) => set(() => ({ isUnifyEnterpriseAdmin })),
        isUnifyEnterpriseAdmin:false,
    };
};
