
interface IOverlay {
    isOpen: boolean;
    panelId:string
  }
  
export const Overlay = ({ isOpen,panelId }: IOverlay) => {
    const element = document.getElementById(panelId) as HTMLDivElement;
    if (element) {
      element.style.zIndex = isOpen ? '0' : '104';
    }
    const feedback = document.getElementById(
      'QSIFeedbackButton-btn'
    ) as HTMLButtonElement;
    if (feedback) {
      feedback.style.display = isOpen ? 'none' : 'block';
    }
    return <></>;
};