import http, { toBearerAuth } from './httpClient';
import {
  Workflow,
  Application,
  UserSettings,
  UserInfo,
  IAMUserInfo,
  Banner,
  UserMapping
} from '../types';
import { OKTA_ME_URLS } from '../config/okta';
import { APP_ENV } from '../constants';
import { UserDetailsResponse, CampaignsResponse } from './Interfaces/IUserDetailsResponse';
import { INotification } from '../components/Notifications/Notification';
import { iAppCategory } from '../components/UnifyAdministration/Blocks/AdminSideSheetDetails';
import { v4 as uuidv4 } from 'uuid';
import { insertNewRelicLog } from './newRelicApi';
import { CustomApps, UserCard } from '../components/UserCards/UserCards';

export enum Locales {
  en_US = 'en_US',
  fr_CA = 'fr_CA'
}

export function isTokenValid() {
  const expiryTime = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')
    .accessToken
    ?.claims
    ?.exp;
  const expiryTimeMilliseconds = expiryTime * 1000;
  const expiryDate = new Date(expiryTimeMilliseconds);
  const currentDate = new Date();
  return currentDate < expiryDate;
};

export const fetchWorkflows = async (token: string, locale: keyof typeof Locales, enterpriseId: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchWorkflows", traceId, token);
  const res = await http.get<Workflow[]>(`/getWorkflows/${enterpriseId}`, {
    headers: {
      Authorization: toBearerAuth(token),
      "x-trace-id": traceId,
      "Accept-Language": locale

    },
  });
  return res.data;
};

export const fetchApplications = async (token: string, locale: keyof typeof Locales, enterpriseId: string) => {
  if (!isTokenValid()) {
    const storedErrorCount = localStorage.getItem('tokenInvalidError');
    if (!storedErrorCount || storedErrorCount === "0") {
      localStorage.setItem('tokenInvalidError', "1");
      window.location.reload();
    }
    return;
  }
  localStorage.setItem('tokenInvalidError', "0");
  const traceId = uuidv4();
  insertNewRelicLog("fetchApplications", traceId, token);
  const res = await http
    .get<Application[]>(`/getApplications/${enterpriseId}`, {
      headers: {
        Authorization: toBearerAuth(token),
        "x-trace-id": traceId,
        'Accept-Language': locale
      },
    })
    .then(function (data) {
      return data.data;
    })
    .catch(function (error) {
      return error.response.data
        ? { error: error.response.data }
        : [{ error: error.message }];
    });
  return res;
};

export const fetchAllApplications = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchAllApplications", traceId, token);
  const res = await http
    .get<Application[]>(`/applications`, {
      headers: {
        Authorization: toBearerAuth(token),
        "x-trace-id": traceId,
      },
    })
    .then(function (data) {
      return data.data;
    })
    .catch(function (error) {
      return error.response.data
        ? { error: error.response.data }
        : [{ error: error.message }];
    });
  return res;
};

export const fetchAllWorkflows = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchAllWorkflows", traceId, token);
  const res = await http
    .get<Application[]>(`/workflows`, {
      headers: {
        Authorization: toBearerAuth(token),
        "x-trace-id": traceId,
      },
    })
    .then(function (data) {
      return data.data;
    })
    .catch(function (error) {
      return error.response.data
        ? { error: error.response.data }
        : [{ error: error.message }];
    });
  return res;
};

export const updateApplications = async (
  id: string,
  isFavorite: boolean,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("updateApplications", traceId, token);
  const res = await http.put(
    '/favoriteApplication',
    { appId: id, isFavorite },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const updateWorkflows = async (
  id: string,
  appId: string,
  isFavorite: boolean,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("updateWorkflows", traceId, token);
  const res = await http.put(
    '/favoriteWorkflow',
    {
      appId,
      workflowId: id,
      isFavorite,
    },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const fetchProfile = async () => {
  const res = await http.get<UserInfo>(OKTA_ME_URLS[APP_ENV as unknown as keyof typeof OKTA_ME_URLS], {
    withCredentials: true,
  });
  return res.data;
};

export const fetchIAMProfile = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchIAMProfile", traceId, token);
  const res = await http.get<IAMUserInfo>('/getUserProfile', {
    headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId },
  });
  return res.data;
};

export const fetchSettings = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchSettings", traceId, token);
  const res = await http.get<UserSettings>('/getUserPreferences', {
    headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId },
  });
  return res.data;
};

export const fetchUnifyProfile = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchUnifyProfile", traceId, token);
  const res = await http.get<UserMapping>('/getUnifyUserProfile', {
    headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId },
  });
  return res.data;
};

export const updateSettings = async (
  updatedSettings: UserSettings,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("updateSettings", traceId, token);
  const res = await http.put<UserSettings>(
    '/updateUserPreferences',
    updatedSettings,
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const fetchBanner = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchBanner", traceId, token);
  const res = await http.get<Banner[]>('/getBanner', {
    headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId },
  });
  return res.data;
};

export const fetchUrlResponse = async (url: string, token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchUrlResponse", traceId, token);
  const res = await http.post(
    '/getUrlResponse',
    { url },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const fetchCNumbers = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchCNumbers", traceId, token);
  const response = await http.get('/getLinkedDMS', {
    headers: {
      Authorization: toBearerAuth(token)
      , 'x-trace-id': traceId
    },
  });
  return response.data;
};

export const fetchDMSBoxMapping = async (token: string, enterpriseId: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchDMSBoxMapping", traceId, token);
  const response = await http.get(`/getDMSBoxMapping`, {
    headers: {
      Authorization: toBearerAuth(token),
      'x-trace-id': traceId,
      'x-enterprise-id': enterpriseId
    },
  });
  return response.data;
};

/**
 * Common DMS UI BFF API function calls
 */

export const fetchEnterpriseUserDetails = async (
  token: string
): Promise<UserDetailsResponse> => {
  const traceId = uuidv4();
  insertNewRelicLog("fetchEnterpriseUserDetails", traceId, token);
  const res = await http.get<UserDetailsResponse>('/getUserDetails', {
    headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId },
  });
  return res.data;
};

/**
 * Notification calls
 */

export const fetchNotifications = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchNotifications", traceId, token);
  const res = await http.get<INotification[]>('/getMessages', {
    headers: {
      Authorization: toBearerAuth(token), 'x-trace-id': traceId
    },
  });
  return res.data;
};

export const fetchApplicationCategory = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchApplicationCategory", traceId, token);
  const res = await http.get<iAppCategory>('/applicationCategories', {
    headers: {
      Authorization: toBearerAuth(token), 'x-trace-id': traceId
    },
  });
  return res.data;
};

export const updateUserReadMessage = async (
  messageId: string,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("updateUserReadMessage", traceId, token);
  const res = await http.put(
    '/updateUserReadMessage',
    { messageId },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const deleteUserMessage = async (
  messageId: string,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("deleteUserMessage", traceId, token);
  const res = await http.put(
    '/deleteUserMessage',
    { messageId },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};


export const updateUserLastLogin = async (
  lastLogin: Date,
  enterpriseId: string,
  token: string
) => {
  const traceId = uuidv4();
  insertNewRelicLog("updateUserLastLogin", traceId, token);
  const res = await http.put(
    '/updateUserLastLogin',
    { lastLogin, enterpriseId },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const fetchUnifyCampaigns = async (token: string, locale: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchUnifyCampaigns", traceId, token);
  const res = await http.get<CampaignsResponse[]>('/getCampaignsForHome', {
    headers: {
      Authorization: toBearerAuth(token),
      'x-trace-id': traceId,
      'Accept-Language': locale
    },
  });
  return res.data;
};

export const fetchMeetMyCDKContactProfile = async (token: string, enterpriseId: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchMeetMyCDKContactProfile", traceId, token);
  const res = await http.get<any[]>(`/getSFCDKContactDetails/${enterpriseId}`, {
    headers: {
      Authorization: toBearerAuth(token),
      'x-trace-id': traceId,
    },
  });
  return res.data;
};

export const fetchUserCards = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("fetchUserCards", traceId, token);
  const res = await http.get<CustomApps>('/getCustomApps', {
    headers: {
      Authorization: toBearerAuth(token), 'x-trace-id': traceId
    },
  });
  return res.data;
};


export const createUserCard = async (userCard: UserCard, token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("createUserCard", traceId, token);
  const res = await http.post(
    '/createCustomApp',
    userCard,
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const updateUserCard = async (userCard: UserCard, token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("updateCustomApp", traceId, token);
  const res = await http.patch(
    '/updateCustomApp',
    userCard,
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const deleteUserCard = async (userCard: UserCard, token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("deleteCustomApp", traceId, token);
  const res = await http.patch(
    '/deleteCustomApp',
    { appId: userCard.appId },
    { headers: { Authorization: toBearerAuth(token), 'x-trace-id': traceId } }
  );
  return res.data;
};

export const fetchEncryptParams = async (token: string) => {
  if (!isTokenValid()) {
    return;
  }
  const traceId = uuidv4();
  insertNewRelicLog("encryptParams", traceId, token);
  const res = await http.post<{data: string}>('/encryptParams', 
    {
      idToken: token
    },
    {
    headers: {
      Authorization: toBearerAuth(token), 'x-trace-id': traceId
    },
  });
  return res.data;
};
