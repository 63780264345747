import { useUpdateApplication } from '../../hooks/queries';
import { usePaneManagementStore } from '../PaneManager/PaneStore';
import BaseCard, { CardMenu } from './BaseCard';
import { useAnalytics } from 'dms-analytics';
import { AMPLITUDE_EVENTS } from '../../constants';

interface Props {
  id: string;
  isEnabled: boolean;
  isFavorite?: boolean;
  description: string;
  name: string;
  iconColor: string;
  iconText: string;
  url: string;
  onCardOpen: Function;
  onCdkuOpen?: Function;
  cdkuUrl?: string;
  menu?: CardMenu[];
  menuHandler?: Function;
}

export default function ApplicationCard({
  id,
  isFavorite,
  isEnabled,
  description,
  onCardOpen,
  name,
  iconText,
  iconColor,
  onCdkuOpen,
  cdkuUrl,
  menu,
  menuHandler
}: Props) {
  const { mutate } = useUpdateApplication();
  const setApplicationCardMousePosition = usePaneManagementStore(state => state.setApplicationCardMousePosition);

  const onFavoriteClick = () => {
    mutate({ id, isFavorite: !isFavorite });
    trackFavoriteChange(!isFavorite);
  };

  const trackFavoriteChange = (isFavorite: boolean) => {
    isFavorite &&
      trackEvent(AMPLITUDE_EVENTS.OPEN_APPLICATION, {
        'Favorite Application': name,
      });
  };

  const { trackEvent } = useAnalytics();
  const onCardClick = (e:MouseEvent) => {
    setApplicationCardMousePosition({clickX:e.clientX ,clickY:e.clientY})
    trackEvent(AMPLITUDE_EVENTS.OPEN_APPLICATION, {
      Application: name,
    });
    onCardOpen(id);
  };

  return (
    <>
      <BaseCard
        iconColor={iconColor}
        iconText={iconText}
        onOpenClick={(e) => onCardClick(e as unknown as MouseEvent)}
        description={description}
        name={name}
        isFavorite={isFavorite}
        isEnabled={isEnabled}
        onFavoriteClick={onFavoriteClick}
        isIconRequired={true}
        showDescription={false}
        onCdkuOpen={onCdkuOpen}
        cdkuUrl={cdkuUrl}
        menu={menu}
        menuHandler={menuHandler}
      />
    </>
  );
}
