import * as StyledComponents from '../StyledComponents';
import { INPUT_SIZES, IconAdd, Divider } from 'cdk-radial';
import { useUnifyAdminStore } from '../../AdminStore';
import { SIDE_SHEET_TITLE_NAMES } from '../../Constants';
import { Switch } from 'cdk-radial';

export default function OutageNotificationListHeader(
  props: Readonly<{ gridRef: any }>
): JSX.Element {
  const outagePermission = useUnifyAdminStore(
    (state) => state.permissions
  );
  const searchValue = useUnifyAdminStore(
    (state) => state.productOutageSearchValue
  );
  const archiveSwitch = useUnifyAdminStore((state) => state.productOutageArchiveSwitch);

  const setArchiveSwitch = useUnifyAdminStore(
    (state) => state.setProductOutageArchiveSwitch
  );

  const setProductOutageSearchValue = useUnifyAdminStore(
    (state) => state.setProductOutageSearchValue
  );
  const setSideSheetTitleName = useUnifyAdminStore(
    (state) => state.setSideSheetTitleName
  );
  const setProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setProductOutageSideSheetCollapse
  );
  const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
    (state) => state.resetOutageNotifcationSideSheetState
  );
  const onExportDownloadButton = () => {
    props?.gridRef?.current?.api?.exportDataAsExcel({
      processRowGroupCallback: (params: any) => {
        return params.node.key;
      },
    });
  };

  const handleNewOutage = (): void => {
    setSideSheetTitleName(SIDE_SHEET_TITLE_NAMES.PRODUCT_ALERT);
    resetOutageNotifcationSideSheetState();
    setProductOutageSideSheetCollapse(false);
  };

  return (
    <>
      <Divider />
      <StyledComponents.StyledHeaderContainer>
      <div style={{display:'flex'}}>
        <StyledComponents.StyledHeading
          headingType="subtitle-2"
          data-testid="outage-notification-list"
        >
          Product Alerts
        </StyledComponents.StyledHeading>
          <div style={{display:'flex',marginLeft:'50px'}}>
            <Switch
                  dataTestId="switch"
                  id="archive"
                  label="Show archived"
                  labelPosition="before"
                  name="archive"
                  onChange={(event: any) => setArchiveSwitch(event.target.checked)}
                  size="standard"
                  checked={archiveSwitch}
                />
            </div>
        </div>
        <StyledComponents.StyledHeading
          headingType="subtitle-2"
          padding="0px 15px"
        >
          <StyledComponents.StyledIconFileDownload
            onClick={() => onExportDownloadButton()}
            data-testid="export-search-result-icon"
          />
        </StyledComponents.StyledHeading>
      </StyledComponents.StyledHeaderContainer>

      <StyledComponents.StyledSearchBoxContainer>
        <StyledComponents.StyledSearchBox
          dataTestId="outage-search-input"
          className="customize-search-input"
          clearButtonLabel="clearButtonLabel"
          id="outage-grid-search-input-expanded"
          isAlwaysExpanded
          name="outage-grid-search-input-expanded"
          onChange={(searchValue: string) =>
            setProductOutageSearchValue(searchValue)
          }
          placeholder="Search"
          size={INPUT_SIZES.STANDARD}
          value={searchValue}
        />
        {outagePermission?.hasProductAlertUpdatePermissions && !archiveSwitch && (
          <StyledComponents.StyledButtonWithIcon
            onClick={() => handleNewOutage()}
            variant="secondary"
            text="Create Alert"
            icon={<IconAdd />}
          />
        )}
      </StyledComponents.StyledSearchBoxContainer>
    </>
  );
}
