import Notification, { INotification } from "./Notification";
import { StyledList, NDTitle } from './styles';
import { useTranslation } from 'react-i18next';
import {useApplicationStore} from '../../Store/index'

const NotificationList = (props: any): JSX.Element => {
    const { t } = useTranslation();
    const {  notifications, onSelect, onDelete } = props;
    const applications = useApplicationStore((state:any) => state.applications);
    const notificationList = Array.isArray(notifications) ? notifications : [];

    return (
        <StyledList data-testid="notification-list">
            { notificationList.length === 0 &&
            <NDTitle style={{padding: "16px"}}>{t('notification.sheet.nomessage')}</NDTitle>}
            {applications && notificationList &&
                notificationList.map((notification: INotification, indx: number) => {
                return (
                    !notification.deleted &&
                    <Notification
                        key={notification.messageId+"-"+indx}
                        data-testid={'notification-item-' + indx}
                        notification={notification}
                        applications={applications}
                        onSelect={onSelect!} 
                        onDelete={onDelete!} />)})
                }
        </StyledList>
    );
};
export default NotificationList;