import {useCallback, useEffect, useState, useRef, useMemo} from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import get from 'lodash/get'
import {StatusIndicator, Button, BUTTON_VARIANTS} from 'cdk-radial'
import * as StyledComponents from '../StyledComponents'
import {GridApi, ColumnApi, ExcelStyle} from 'ag-grid-community';
import {LicenseManager} from 'ag-grid-enterprise';
import {AG_GRID_LICENSE} from '../../../../constants';
import CampaginListHeader from './CampaginListHeader';
import {useGetAllCampaigns} from '../CampaignStore/DataQueries/useCampaignsQueries';
import {AdminTableBodySkeleton} from '../../Blocks/AdminTableBodySkeleton';
import moment from 'moment';
import {useUnifyAdminStore} from '../../AdminStore';

LicenseManager.setLicenseKey(
    AG_GRID_LICENSE);

// DefaultColDef sets props common to all Columns
const defaultColDef = {
    enablePivot: true,
    sortable: true,
    filter: true,
    flex: 1,
    autoHeight: true,
    minWidth: 100,
    resizable: true,
    refreshCells: true,
};

enum StatusTypes {
    POSITIVE = 'positive',
    WARNING = 'warning',
}

const CustomStatusRenderer = ({isActive}: any): JSX.Element => {
    const variant = isActive ? StatusTypes.POSITIVE : StatusTypes.WARNING;
    return (
        <StatusIndicator
            label={isActive ? 'Active' : 'Inactive'}
            variant={variant}
            showIcon
        />
    );
};

const CustomPreviewRenderer = (): JSX.Element => {
    const setIsPreviewCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsPreviewCampaignDialogOpen);
    const onPreviewClick = (event: React.MouseEvent, params: { api: GridApi; columnApi: ColumnApi }) => {
        setIsPreviewCampaignDialogOpen(true);
    };

    return (
        <Button text="Preview" variant={BUTTON_VARIANTS.TEXT} onClick={onPreviewClick}/>
    );
};

function dateValueFormatter(params: any) {
    return moment(
        params.value,
        'dddd, MMMM DD, YYYY at h:mm:ss A Z'
    ).format('MM/DD/YYYY');
};

// HELPER FOR DATE COMPARISON
function dateToNum(date: any) {
    if (date === undefined || date === null) {
        return null;
    }

    const dateObj = new Date(moment(
        date,
        'dddd, MMMM DD, YYYY at h:mm:ss A Z'
    ).format('MM/DD/YYYY'));

    const yearNumber = dateObj.getFullYear();
    const monthNumber = dateObj.getMonth();
    const dayNumber = dateObj.getDate();

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 22/05/2023 => 20230522
    return result;
}

// DATE COMPARATOR FOR SORTING
function dateComparator(date1: any, date2: any) {
    var date1Number = dateToNum(date1);
    var date2Number = dateToNum(date2);

    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
}

const columnDefinition = [
    {
        field: 'name',
        headerName: 'Campaign Name',
        width: 200,
        maxWidth: 300,
        cellClass: 'formattedColumn',
    },
    {
        field: 'description.enUS',
        headerName: 'Description',
        width: 250,
        sortable: true,
        cellClass: 'formattedColumn'
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 50,
        maxWidth: 250,
        sortable: true,
        cellClass: 'formattedColumn'
    },
    {
        cellRenderer: (params: any) => {
            return moment(
                params.data.createdOn,
                'dddd, MMMM DD, YYYY at h:mm:ss A Z'
            ).format('MM/DD/YYYY')
        },
        field: 'createdOn',
        headerName: 'Created Date',
        width: 50,
        maxWidth: 180,
        sortable: true,
        cellClass: 'formattedColumn',
        getQuickFilterText: (params: any) => {
            return '';
        },
        comparator: dateComparator,
        filterParams: {
            valueFormatter: dateValueFormatter,
        },
    },
    {
        field: 'isActive',
        cellRenderer: (params: any) => (
            <CustomStatusRenderer isActive={get(params, 'data.isActive', false)}/>
        ),
        headerName: 'Status',
        width: 20,
        maxWidth: 180,
        sortable: true,
        getQuickFilterText: (params: any) => {
            return '';
        },
    },
    {
        colId: "Preview",
        cellRenderer: (params: any) => (
            <CustomPreviewRenderer/>
        ),
        minWidth: 20,
        width: 20,
        maxWidth: 180,
        sortable: true,
        getQuickFilterText: (params: any) => {
            return '';
        },
    },

];


export default function CampaginList(): JSX.Element {
    /**
     * Data store
     * */
    const setCampaignItem = useUnifyAdminStore((state) => state.setCampaignItem);
    const setIsCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsCreateCampaignDialogOpen);
    const campaignSearchValue = useUnifyAdminStore((state) => state.campaignSearchValue);

    /**
     * Grid
     */
    const [gridApi, setGridApi] = useState<GridApi>();
    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
        setGridApi(params.api);
    };
    const {
        data: listOfCampaigns,
        isFetching: isCampaignsFetching,
    } = useGetAllCampaigns();

    const onFirstDataRendered = useCallback((params: any) => {
        // @ts-ignore
        gridApi?.current?.api?.sizeColumnsToFit();
    }, [gridApi]);

    const cellClickedListener = useCallback((event: any) => {
        setCampaignItem(event.data)
        if(event.colDef.colId !== "Preview" )
        {
        setIsCreateCampaignDialogOpen(true)
        }

    }, [setCampaignItem,setIsCreateCampaignDialogOpen]);

    useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(campaignSearchValue);
        }
    }, [campaignSearchValue, gridApi]);

    let currentDate = new Date().toJSON().slice(0, 10);
   const downloadCSVParams = {
    fileName: 'Campaigns_' + currentDate,
    allColumns: true,
    processCellCallback: function ({column, value}: any) {
        if (column.colId === 'isActive') {
            return value === true ? "Active" : "Inactive"
        } else if (column.colId === 'createdOn') {
            return moment(
                value,
                'dddd, MMMM DD, YYYY at h:mm:ss A Z'
            ).format('MM/DD/YYYY')
        } else {
            return value;
        }
    }
};

const excelStyles: ExcelStyle[] = useMemo(() => {
    return [
        {
            id: 'header',
            font: {
                bold: true,
                size: 15
            },
            interior: {
                color: '#bbb4bb',
                pattern: 'Solid',
            },
        },
    ];
}, []);

    return (
        <>
        <CampaginListHeader gridRef={gridRef} />
        {isCampaignsFetching ? (<AdminTableBodySkeleton/>)
            : (
            <StyledComponents.StyledAdminTableContainer>
            <StyledComponents.StyledCampaignTableWrapper className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={get(listOfCampaigns, "campaigns")}
                    columnDefs={columnDefinition}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    rowSelection="single" // Options - allows click selection of rows
                    excludeHiddenColumnsFromQuickFilter={true}
                    suppressDragLeaveHidesColumns={true}
                    onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    onFirstDataRendered={onFirstDataRendered}
                    defaultCsvExportParams={downloadCSVParams}
                    defaultExcelExportParams={downloadCSVParams}
                    excelStyles={excelStyles}
                />
            </StyledComponents.StyledCampaignTableWrapper></StyledComponents.StyledAdminTableContainer>)}
    </>)
}