import * as StyledComponents from '../StyledComponents'
import CampaignPreviewBanner from './CampaignPreviewBanner'
import {useUnifyAdminStore} from '../../AdminStore';

export const CampaignPreview = (): JSX.Element => {
    const isPreviewCampaignDialogOpen = useUnifyAdminStore((state) => state.isPreviewCampaignDialogOpen);
    const setIsPreviewCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsPreviewCampaignDialogOpen);
    const resetCampaignItem = useUnifyAdminStore((state) => state.setCampaignItem)
    const setIsCreateOrUpdatePreview = useUnifyAdminStore((state) => state.setIsCreateOrUpdatePreview);
    const isCreateOrUpdatePreview = useUnifyAdminStore((state) => state.isCreateOrUpdatePreview);
    

    const handleCloseDialog = () =>{
        setIsPreviewCampaignDialogOpen(false);
        if(!isCreateOrUpdatePreview)
        {
        resetCampaignItem(null);
        }
        else
        {
            setIsCreateOrUpdatePreview(false);
        }
    }

    return <StyledComponents.StyledCampaignPreviewDialogBodyWrapper id='preview-campaign-dialog-wrapper'>
        <StyledComponents.StyledDialog hideHeader
            id='preview-campaign-dialog'
            buttonsProps={[
                {
                    id: 'cancel-campaign-preview',
                    onClick: () => handleCloseDialog(),
                    text: 'Cancel',
                    variant: 'text'
                }
            ]}
            dataTestId="preview-campaign-dialog"
            isOpen={isPreviewCampaignDialogOpen}
            disableOverlayClicked>
            <CampaignPreviewBanner />
        </StyledComponents.StyledDialog>
    </StyledComponents.StyledCampaignPreviewDialogBodyWrapper>
}