import {StateCreator} from 'zustand';


/**Constants */

const defaultCampaignItem = {
    id: '',
    createdBy: '',
    name: '',
    createdOn: '',
    description: {enUS:'',frCA:''},
    logo: '',
    darkLogo: '',
    updatedBy: '',
    updatedOn: '',
    isActive: false,
    link: ''
}


/** Interfaces */

interface IEvent {
    fieldName: string;
    value: string | boolean |  {enUS?:string,frCA?:string};
}

export interface ICampaign {
    id?: string,
    createdBy: string;
    name: string;
    createdOn: string;
    description: {enUS:string,frCA:string};
    logo: string;
    darkLogo: string;
    updatedBy: string;
    updatedOn: string;
    isActive: boolean,
    showLogo: boolean,
    link: string
}


export interface ICreateCampaignSlice {
    isCreateCampaignDialogOpen: boolean;
    setIsCreateCampaignDialogOpen: (isCreateDialogOpen: boolean) => void;
    updateCreateCampaignData: (event: IEvent) => void;
    campaignItems: Partial<ICampaign>;
    setCampaignItem: (campaign?: Partial<ICampaign> | null) => void,
    campaignSearchValue: string,
    setCampaignSearchValue: (searchValue: string) => void
    isPreviewCampaignDialogOpen: boolean;
    setIsPreviewCampaignDialogOpen: (isCreateDialogOpen: boolean) => void;
    showDialogToast: boolean;
    setShowDialogToast: (showDialogToast: boolean) => void;
    dialogToastContent: string;
    setDialogToastContent: (dialogToastContent: string) => void;
    isCreateOrUpdatePreview: boolean;
    setIsCreateOrUpdatePreview: (showDialogToast: boolean) => void;
    setCampaignPermission:(campaignPermission: any) => void;
    campaignPermission:any;
}

export const createCampaignSlice: StateCreator<ICreateCampaignSlice,
    [['zustand/devtools', never]],
    [],
    ICreateCampaignSlice> = (set) => {

    return {
        isCreateCampaignDialogOpen: false,
        setIsCreateCampaignDialogOpen: (isCreateCampaignDialogOpen) => {
            return set(() => ({isCreateCampaignDialogOpen}))
        },
        campaignItems: defaultCampaignItem as unknown as ICampaign,
        campaignSearchValue: '',
        setCampaignSearchValue: (campaignSearchValue) => set((state) => ({campaignSearchValue})),
        setCampaignItem: (campaignItems) =>
            set((state) => {
                if (campaignItems?.id) {
                    return {
                        campaignItems
                    }
                }
                return {
                    campaignItems: defaultCampaignItem
                }
            }),
        updateCreateCampaignData: (event) =>
            set((state) => {
                return {
                    campaignItems: {
                        ...state.campaignItems,
                        [event.fieldName]: event.value
                    }
                }
            }),
            isPreviewCampaignDialogOpen: false,
            setIsPreviewCampaignDialogOpen: (isPreviewCampaignDialogOpen) => {
                return set(() => ({isPreviewCampaignDialogOpen}))
            },
        showDialogToast: false,
        setShowDialogToast: (showDialogToast) => {
            return set(() => ({ showDialogToast }))
        },
        dialogToastContent: "",
        setDialogToastContent: (dialogToastContent) => {
            return set(() => ({ dialogToastContent }))
        },
        isCreateOrUpdatePreview: false,
        setIsCreateOrUpdatePreview: (isCreateOrUpdatePreview) => {
            return set(() => ({ isCreateOrUpdatePreview }))
        },
        setCampaignPermission:(campaignPermission: any) => set(() => ({ campaignPermission })),
        campaignPermission:{},
    };
};
