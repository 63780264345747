import React from 'react';
import { SideSheetContext } from '../../contexts/SideSheet';
import { SideSheetContent } from '../../constants';

export default function SideSheetProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [sideSheetContent, setContent] =
    React.useState<SideSheetContent | null>(null);
  const setSideSheetContent = (content: SideSheetContent | null) => {
    // toggle tab when same value is clicked
    setContent(content === sideSheetContent ? null : content);
  };

  return (
    <SideSheetContext.Provider
      value={{
        sideSheetContent,
        setSideSheetContent,
      }}
    >
      {children}
    </SideSheetContext.Provider>
  );
}
