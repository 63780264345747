import { useEffect, useState } from 'react';

enum ScriptStatus {
  'loading',
  'idle',
  'ready',
  'error',
}

export const useScriptInjector = (
  srcPath: string,
  async: boolean,
  defer = false,
  config?: Function
): 'loading' | 'idle' | 'ready' | 'error' => {
  let [status, setStatus] = useState<keyof typeof ScriptStatus>('idle');

  useEffect(() => {
    if (!srcPath) {
      setStatus('idle');
      return;
    }
    let script: any = document.querySelector(`script[src="${srcPath}"]`);

    const handleScript = (e: any) => {
      setStatus(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.setAttribute('type', 'application/javascript');
      script.setAttribute('src', srcPath);
      script.async = async;
      script.defer = defer;
      document.head.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
      if(config){
        config();
      }
    }
    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      script?.removeEventListener('load', handleScript);
      script?.removeEventListener('error', handleScript);
    };
  }, [srcPath, defer, async,config]);

  return status;
};
