import OutageNotificationList from '../../Blocks/OutageNotificationList';
import * as StyledComponents from '../../StyledComponents';
import { AdminDataSideSheet } from '../../../Blocks/AdminDataSideSheet';
import { AdminDataSideSheetHeader } from '../../../Blocks/AdminDataSideSheetHeader';
import { SIDE_SHEET_TITLE_NAMES } from '../../../Constants';
import { OutageSideSheetBody } from '../../Blocks/OutageSideSheetBody';
import { useUnifyAdminStore } from '../../../AdminStore';
import {
  Toast,
  TOAST_POSITIONS,
  TOAST_VISIBILITY_DURATIONS,
  BUTTON_VARIANTS,
  BUTTON_ICON_COLORS,
  IconCancelOutlined,
} from 'cdk-radial';

export default function OutageAdminContainer(): JSX.Element {
  const showToast = useUnifyAdminStore((state) => state.productOutageShowToast);
  const resetToastState = useUnifyAdminStore(
    (state) => state.resetProductOutageToastState
  );

  const toastMsg = useUnifyAdminStore((state) => state.productOutageToastMsg);
  const toastType = useUnifyAdminStore((state) => state.productOutageToastType);

  const setIsValidationState = useUnifyAdminStore(
    (state) => state.setIsValidationState
  );

  const setWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setAdminSideSheetCollapse
  );

  const isProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isProductOutageSideSheetCollapse
  );

  const setApplicationSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setWorkflowSideSheetCollapse
  );

  const setProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setProductOutageSideSheetCollapse
  );

  const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
    (state) => state.resetOutageNotifcationSideSheetState
  );

  const handleCloseSideSheet = () => {
    setWorkflowSideSheetCollapse(true);
    setApplicationSideSheetCollapse(true);
    setProductOutageSideSheetCollapse(true);
    resetOutageNotifcationSideSheetState();
  };

  return (
    <StyledComponents.StyledCustomScrollbarWrapper>
      <StyledComponents.StyledContainerWrapper data-testid="outage-notification-container">
        {showToast && (
          <Toast
            id="outage-notification-toast"
            datatestid="outage-notification-toast"
            position={TOAST_POSITIONS.FIXED}
            style={{
              marginTop: '70px',
              zIndex: 1003,
            }}
            variant={toastType === 'Error' ? 'negative' : 'positive'}
            visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
            onVisibilityDurationEnd={() => {
              resetToastState();
              setIsValidationState(false);
            }}
            actions={[
              {
                onClick: () => {
                  resetToastState();
                  setIsValidationState(false);
                },
                variant: BUTTON_VARIANTS.ICON,
                icon: <IconCancelOutlined />,
                iconColor: BUTTON_ICON_COLORS.GRAY,
                text: '',
              },
            ]}
            content={toastMsg}
          />
        )}

        {!isProductOutageSideSheetCollapse && (
          <AdminDataSideSheet isCollapse={isProductOutageSideSheetCollapse}>
            <AdminDataSideSheetHeader
              sideSheetHeaderTitle={SIDE_SHEET_TITLE_NAMES.PRODUCT_ALERT}
              handleSideSheetClose={handleCloseSideSheet}
            />
            <OutageSideSheetBody />
          </AdminDataSideSheet>
        )}
        <OutageNotificationList data-testid="outage-notification-list" />
      </StyledComponents.StyledContainerWrapper>
    </StyledComponents.StyledCustomScrollbarWrapper>
  );
}
