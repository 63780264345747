import React from 'react';
import * as StyledComponents from '../StyledComponents';

export const TimeField = (props: any): JSX.Element => {
  const { handleChange, value, name, label, icon, isDisabled } = props;
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const removeSpacesAndColon = /[ :]/g;
  const valid24hrTimePattern1 = '^[1-9]$'; // 1-9
  const valid24hrTimePattern2 = '^1[0-9]$'; // 10-19
  const valid24hrTimePattern3 = '^2[0-3]$'; // 20-23
  const valid24hrTimePattern4 = '^[1-9][0-5][0-9]$'; // 1:00-9:59
  const valid24hrTimePattern5 = '^[0-1][0-9][0-5][0-9]$'; // 00:00-19:59
  const valid24hrTimePattern6 = '^2[0-3][0-5][0-9]$'; // 20:00-23:59
  const fullValidTimePattern = `${valid24hrTimePattern1}|${valid24hrTimePattern2}|${valid24hrTimePattern3}|${valid24hrTimePattern4}|${valid24hrTimePattern5}|${valid24hrTimePattern6}`;
  const validateTime = (value: any) => {
    const testValue = value.replace(removeSpacesAndColon, '');
    const validTime = new RegExp(fullValidTimePattern, 'gi');
    return validTime.test(testValue);
  };
  const handleFieldChange = (event: any) => {
    setHasError(false); // remove error styling on focus
    setErrorMessage('');
    handleChange(event.target.value);
  };
  const handleValidateTime = (event: any) => {
    const isTimeValid = validateTime(value);
    setHasError(!isTimeValid);
    if (!isTimeValid) {
      setErrorMessage('Enter valid time');
    }
  };

  return (
    <StyledComponents.StyledInput
      icon={icon}
      isRequired={true}
      errorMessage={errorMessage}
      enableCustomValidation
      hasError={hasError}
      helperText="hh:mm (24 hr format)"
      id={`${name}-time-24hr-input`}
      label={label}
      name={name}
      onChange={handleFieldChange}
      onBlur={handleValidateTime}
      value={value}
      placeholder="hh:mm"
      iconPosition={'leading'}
      isDisabled={isDisabled}
    />
  );
};
