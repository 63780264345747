import { usePaneManagement } from '../../contexts/PaneManagementContext';
import { useUpdateWorkflow } from '../../hooks/queries';
import { useAnalytics } from 'dms-analytics';
import BaseCard from './BaseCard';
import { AMPLITUDE_EVENTS } from '../../constants';
import launchExternal from '../../util/launchExternal';
interface Props {
  appId?: string;
  description: string;
  iconColor: string;
  iconText: string;
  id: string;
  isEnabled: boolean;
  isFavorite?: boolean;
  name: string;
  tabIndex?: number;
  url: string;
  cdkuUrl: string;
}

export default function WorkflowCard({
  appId = '',
  description,
  iconColor,
  iconText,
  id,
  isEnabled,
  isFavorite,
  name,
  tabIndex = 0,
  url,
  cdkuUrl,
}: Props) {
  const { mutate } = useUpdateWorkflow();
  const { openPane, openCdkuPane } = usePaneManagement();
  const onFavoriteClick = () => {
    mutate({ id, isFavorite: !isFavorite, appId });
    trackFavoriteChange(!isFavorite);
  };
  const { trackEvent } = useAnalytics();
  const trackFavoriteChange = (isFavorite: boolean) => {
    isFavorite &&
      trackEvent(AMPLITUDE_EVENTS.OPEN_WORKFLOW, {
        'Favorite Workflow': name,
      });
  };

  const onOpenClick = () => {
    trackEvent(AMPLITUDE_EVENTS.OPEN_WORKFLOW, {
      Workflow: name,
    });
    launchExternal(url) || openPane(id); 
  };

  return (
    <BaseCard
      iconColor={iconColor}
      iconText={iconText}
      onOpenClick={onOpenClick}
      description={description}
      name={name}
      isFavorite={isFavorite}
      isEnabled={isEnabled}
      onFavoriteClick={onFavoriteClick}
      isIconRequired={false}
      tabIndex={tabIndex}
      showDescription={true}
      onCdkuOpen={() => openCdkuPane('CDKU', cdkuUrl)}
      cdkuUrl={cdkuUrl}
    />
  );
}
