import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (max-width: 1080px) {
    margin-right: 24px;
    margin-left: 24px;
}
`;

export const CarouselCloseContainer = styled.div`
  align-self: flex-end;
  margin: 8px;
  margin-right:24px;
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const BannerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

            
export const PromotionWrapperSpacer = styled.div<{ expanded: boolean }>`
  background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  @media (max-width: 1080px) {
    height: ${({ expanded }) => (expanded ? '122px' : '0px')};
}
@media (max-width: 500px) {
    height: ${({ expanded }) => (expanded ? '175px' : '0px')};
}
@media (max-width: 470px) {
    height: ${({ expanded }) => (expanded ? '195px' : '0px')};
}
`;

export const PromotionWrapper = styled.div<{ expanded: boolean }>`
    display: flex;
    height: ${({ expanded }) => (expanded ? '122px' : '0px')}x;  
    width: 100%;
    flex-direction: column;
    max-width: 1280px;
    @media (max-width: 1080px) {
        height: ${({ expanded }) => (expanded ? '122px' : '60px')};
    }
`;

export const PromotionBanner = styled.div<{ darkMode: boolean }>`
    display: flex;
    height: 122px;  
    width: 100%;    
    background-color: ${({ darkMode }) => (darkMode ? '#1A1A1A' : '#f2f2f2')};
    @media (max-width: 1080px) {
        height: 278px;
    }
`;

export const LeftSpace = styled.div<{ closetColor: string | null }>`
    background-color: ${({ closetColor }) => (closetColor)};
    flex: 1;
    @media (max-width: 1080px) {
        flex: 0;
        width: 0px;
    }
`

export const RightSpace = styled.div`
    flex: 1;
    @media (max-width: 1080px) {
        flex: 0;
        width: 0px;
    }
`

export const BannerBody = styled.div`
    width: 100%;
`

export const GreetingWrapperSpacer = styled.div<{expanded: boolean }>`
  background-color: ${({ theme }) => (theme.darkMode ? '#000' : '#FFF')};
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  height: min-content;
  @media (max-width: 470px) {
    height: ${({ expanded }) => (expanded ? '45px' : '60px')};
}
`;

export const GreetingWrapper = styled.div<{ darkMode: boolean; expanded: boolean }>`
    display: flex;  
    height: 60px;  
    width: 100%; 
    align-items: center;
    max-width: 1280px;
    @media (max-width: 470px) {
        height: ${({ expanded }) => (expanded ? '30px' : '60px')};
        padding-top: ${({ expanded }) => (expanded ? '10px' : '0px')};
    }
`;

export const GreetingBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 1280px;
    font-weight: 600;
    @media (max-width: 500px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%; 
        margin-top: 10px;
        height: 30px;
    }
`;

export const GeetingMessage = styled.div`
    padding-left: 15px;
    @media (max-width: 1080px) {
        padding-left: 22px;
    }
    @media (max-width: 470px) {
        padding-left: 16px;
        height: 30px;
    }
`;

export const BannerOptions = styled.div<{darkMode: boolean}>`
    padding-right: 23px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    @media (max-width: 1080px) {
        padding-right: 32px;
    };
    @media (max-width: 500px) {
        padding-right: 16px;
        align-self: end;
        height: 36px;
    };
    path {
      fill: ${({ darkMode }) => (darkMode ? '#FFF' : '#000')};;
    };
`;

