
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components"
import Banner from "./Banner";
import { IconExpandMore } from 'cdk-radial';
import * as Styles from './styles';
import { useCampaigns } from '../../hooks/queries';

export default function BannerContainer() {
    const { data: campaignItems, status } = useCampaigns();
    const { darkMode } = useTheme();
    const color = darkMode ? '#1A1A1A' : '#f2f2f2';
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(true);

    const expandOptions = () => {
        setExpanded(true);
        closetColorHandler(color);
    }

    const hideBanner = () => {
        setExpanded(false);
    }

    const closetColorHandler = (color: string) => {
        // TODO: based on PO applying this fix, this will be decided later based on UX and PO sync-up, 
        // setClosetColor(color);
    }

    return (
            <>
            {status !== 'error' ?
            <Styles.PromotionWrapperSpacer data-testid="banner-container" expanded={expanded}>
            <Styles.PromotionWrapper expanded={expanded}>
                {expanded && (<Styles.PromotionBanner darkMode={darkMode}>
                    <Styles.BannerBody>
                            <Banner hideBanner={hideBanner} closetColor={closetColorHandler} bannerData={campaignItems} />
                    </Styles.BannerBody>
                </Styles.PromotionBanner>)}
            </Styles.PromotionWrapper>
            </Styles.PromotionWrapperSpacer>: null}
            <Styles.GreetingWrapperSpacer expanded={expanded}>
                <Styles.GreetingWrapper darkMode={darkMode} expanded={expanded}>
                    <Styles.GreetingBody>
                        <Styles.GeetingMessage>{t('subHeader.title')}</Styles.GeetingMessage>
                        {!expanded && <Styles.BannerOptions
                            darkMode={darkMode}
                            onClick={expandOptions}>
                            <div>{t('subHeader.discover')}</div>
                            <div><IconExpandMore darkMode={true} /></div>
                        </Styles.BannerOptions>}
                    </Styles.GreetingBody>
                </Styles.GreetingWrapper>
                </Styles.GreetingWrapperSpacer>
                </>
    )
}
