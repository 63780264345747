import moment from 'moment';

function dateToNum(date: any) {
    if (date === undefined || date === null) {
        return null;
    }

    const dateObj = new Date(moment(
        date
    ).format('MM/DD/YYYY'));

    const yearNumber = dateObj.getFullYear();
    const monthNumber = dateObj.getMonth();
    const dayNumber = dateObj.getDate();

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 22/05/2023 => 20230522
    return result;
}

// DATE COMPARATOR FOR SORTING
export function dateComparator(date1: any, date2: any) {
    var date1Number = dateToNum(date1);
    var date2Number = dateToNum(date2);

    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
}

export function dateValueFormatterWOTime(date: any) {
    return date ? moment.utc(
        date
    ).local().format('MM/DD/YYYY') : '';
};

export function dateValueFormatter(date: any) {
    return date ? moment.utc(
        date
    ).local().format('MM/DD/YYYY h:mm:ss A Z') : '';
};

export function dateValueFormatterWithHHMM(date: any) {
    return date ? moment.utc(
        date
    ).local().format('MM/DD/YYYY H:mm') : '';
};

export function strToDate(strDate: any) {
    var localStartDate = moment
        .utc(strDate)
        .local()
        .format('MM/DD/YYYY H:mm');
    return new Date(localStartDate);
}

export function formattedTime(dateTime: Date) {
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    return formattedTime;
}