import newRelichttpClient from './newRelicHttpClient';
import jwt_decode from 'jwt-decode';

export const insertNewRelicLog = async (action: string, traceId: string, token: any) => {
    try {
        const decoded: any = jwt_decode(token);
        const loginId = decoded.cdksid || decoded.sub;

        const logPayload = {
            _raw: {
                message: `UI Action: ${action} - User: ${loginId} - TraceId: ${traceId}`,
                mdc: {
                    'x-trace-id': traceId,
                    'x-user-id': loginId,
                },
                metrics: {
                    methodName: action,
                },
            },
            label_app: 'app-unify',
            env: process.env.REACT_APP_ENV,
        };

        newRelichttpClient.post("", JSON.stringify(logPayload));
    } catch (error) {
    }
};



export const insertNewRelicCustomMatrics =  (traceId?:string ,app_name?:string, status?:{uri:string,result:boolean,browser:string}) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')?.accessToken
    const enterpriseId = oktaTokenStorage?.claims?.enterpriseId
    const email = oktaTokenStorage?.claims?.cdksid || ""

    const APP_NAME = app_name || 'app-unify'
    try {

        const logPayload = {
            _raw: {
                message: `Drive Launch: Custom Protocol ${status?.result?'':'not'} found: User: ${email} - Enterprise: ${enterpriseId} - TraceId: ${traceId}`,
                mdc: {
                    'x-trace-id': traceId,
                    'x-user-id': email,
                    'x-enterprise-id':enterpriseId,
                    'x-drive-launched-uri':status?.uri,
                    'x-browser-type':status?.browser
                },
                metrics: {
                    driveCustomLaunched: status?.result,
                },
            },
            label_app: APP_NAME,
            env: process.env.REACT_APP_ENV,
        };

        return newRelichttpClient.post("", JSON.stringify(logPayload));

    } catch (error) {
    }
};

export const insertNewRelicError = async (action: string, user: string) => {
    try {
        const logPayload = {
            _raw: {
                message: `UI Action: ${action} `,
                mdc: {
                    'x-trace-id': "",
                    'x-user-id': user,
                },
                metrics: {
                    methodName: action,
                },
            },
            label_app: 'app-unify',
            env: process.env.REACT_APP_ENV,
        };

        newRelichttpClient.post("", JSON.stringify(logPayload));
    } catch (error) {
    }
};