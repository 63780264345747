import React, { useState } from 'react';
import { BYPASS_AUTH, APP_ENV, appUrlDiscovery } from '../../constants';
import * as OktaReact from '@okta/okta-react';
import { Route as RouterRoute } from 'react-router-dom';
import { Button, BUTTON_VARIANTS} from 'cdk-radial';
import {insertNewRelicError} from '../../util/newRelicApi';
import { useUserProfile } from '../../hooks/queries';
import {v4 as uuidv4} from 'uuid';

const shouldBypass = BYPASS_AUTH || process.env.NODE_ENV === 'test';

export function ErrorHandler(error: any) {
  const [isErrorShow, setIsErrorShow] = useState<boolean>(false);
  const onClick = () => setIsErrorShow(true);
  const traceId = uuidv4();
  const errorData = JSON.stringify(error);
  insertNewRelicError(`UIOKTAERROR: ${traceId} Error: ${errorData}`, "");
  const { data, isLoading, isError } = useUserProfile();

  const storedErrorCount = localStorage.getItem('errorCount');
  const errorCount = storedErrorCount ? parseInt(storedErrorCount, 10) : 0;
 
  React.useEffect(() => {
    if (!isError && !isLoading) {
      insertNewRelicError(`UIOKTAERROR-USER: ${traceId} Error: ${errorData}`, data?.profile?.email || "");
    }
  }, [isError, isLoading, data, traceId, errorData]);

  React.useEffect(() => {
    if (errorCount < 2 && !errorData.includes("The JWT was issued in the future")) {
      for (const key in localStorage) {
        if (key !== 'errorCount') {
          localStorage.removeItem(key);
        }
      }
      insertNewRelicError(`UIOKTAERROR-USER: ${traceId} ${errorCount} Error: ${errorData}`, "");
      localStorage.setItem('errorCount', (errorCount + 1).toString()); 
      window.location.href = appUrlDiscovery(APP_ENV);
    }
  }, [errorCount, traceId, errorData]);

  return (
    <>
      {errorData.includes("The JWT was issued in the future") ?
        <>
        <div style={{ marginBottom: "10px", marginLeft: "10px" }} data-testid="error-detail-one">The application didn't launch correctly.</div>
          <div style={{ marginBottom: "10px", marginLeft: "10px" }} data-testid="error-detail-two">Please ensure that your system's date and time settings are set to automatic.</div></>
          :<>
          <div style={{ marginBottom: "10px", marginLeft: "10px" }} data-testid="error-detail-one">The application didn't launch correctly.</div>
          <div style={{ marginBottom: "10px", marginLeft: "10px" }} data-testid="error-detail-two">Please refresh the browser.</div>
          <div style={{ marginLeft: "10px" }} data-testid="error-detail-three">If the problem still persists, please clear your browser cache and cookies, and try again.</div>
        </>}
      <Button text="Click for more details" variant={BUTTON_VARIANTS.TEXT} onClick={onClick} data-testid="error-button" />
      {isErrorShow ? <div data-testid="error-detail-four">{errorData}</div> : null}</>);
}

export function BypassableRoute(props: any) {
  return shouldBypass ? (
    <RouterRoute {...props} />
  ) : (
    <OktaReact.SecureRoute {...props}/>
  );
}

export function BypassableLoginCallback(props: any) {
  insertNewRelicError(`UIOKTADETAILS: BypassableLoginCallback Details: ${JSON.stringify(props)}`, "");
  return shouldBypass ? <div /> : <OktaReact.LoginCallback {...props} errorComponent= {ErrorHandler} />;
}

export function BypassableSecurity(props: any) {
  return shouldBypass ? (
    <div>{props.children}</div>
  ) : (
    <OktaReact.Security {...props} />
  );
}
