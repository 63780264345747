import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

// when you run "npm start" this always runs
if (process.env.REACT_APP_DEV_ENV === 'local') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

// when you run "npm build" this always runs
// use this to test new mock APIS before the actual endpoints are available
if (
  process.env.REACT_APP_DEV_ENV !== 'local' &&
  process.env.REACT_APP_DEV_ENV !== 'dev' &&
  process.env.REACT_APP_DEV_ENV !== 'dit' &&
  process.env.REACT_APP_DEV_ENV !== 'perf' &&
  process.env.REACT_APP_DEV_ENV !== 'prod'
) {
  const { productionWorker } = require('./mocks/browser');
  productionWorker.start();
}

const container = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// here is where I put useless changes so the build will get rerun because I still
// don't have permission for some reason

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
