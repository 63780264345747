import { useUserProfile } from '../../hooks/queries';
import { useSideSheet } from '../../contexts/SideSheet';
import * as Styles from './styles';
import { SideSheetHeader } from '../SideSheet/SideSheet';
import ProfileContent from './ProfileContent';
import { UseQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useIamUserProfileStore } from '../../Store';

export default function Profile() {
  const { data, isLoading, isError } = useUserProfile();
  const iamData = useIamUserProfileStore((state:any) => state.iamUserInfo);
  const iamLoading = useIamUserProfileStore((state:any) => state.isLoading);
  const iamError = useIamUserProfileStore((state:any) => state.isErrror);

  const { setSideSheetContent } = useSideSheet();
  const oktaUser = {
    data,
    isLoading,
    isError,
  } as UseQueryResult;

  const iamUser = {
    data: iamData,
    isLoading: iamLoading,
    isError: iamError,
  } as UseQueryResult;

  const { t } = useTranslation();
  const toggle = () => setSideSheetContent(null);

  return (
    <Styles.ProfileContent>
      <SideSheetHeader
        name={t('profile.text')}
        toggle={toggle}
        dataTestId="profile-tab-header"
      />
      <ProfileContent oktaUser={oktaUser} iamUser={iamUser}></ProfileContent>
    </Styles.ProfileContent>
  );
}
