import styled from 'styled-components';

export const BannerCardContainer = styled.div`
  height: 122px;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  margin-left: -10px;
  @media (max-width: 1080px) {
    width: 100%;
    height: 122px;
    padding-right: 96px;
    padding-left:24px;
    margin-left: 0px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 175px;
    padding-right: 96px;
    padding-left:24px;
  }
  @media (max-width: 470px) {
    width: 100%;
    height: 195px;
    padding-right: 88px;
    padding-left:16px;
  }
`;

export const BannerImage = styled.div<{ url: string; id: string, theme:string }>`
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  background-attachment: fixed;
  background-color: ${({ id, theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  background-image: url(${({ url }) => url});
  background-position: left;
  background-repeat: no-repeat;
  background-size: 70%;
  display: flex;
  justify-content: center;
  margin-left:24px;
  height: ${({ url }) => (url ?  '100%' : '0px')};
  min-width: ${({ url }) => (url ?  '200px' : '0px')};
  @media (max-width: 1080px) {
    background-size: 70%;
    background-position: left center;
    width: ${({ url }) => (url ?  '20%' : '0px')};
    margin-left:0px;
  }
  @media (max-width: 500px) {
    width: 0px;
    min-width: 0px;
  }
`;

export const BannerContent = styled.div`
  background-color: ${({ theme }) => (theme.darkMode ?  '#1A1A1A' : '#f2f2f2')};
  color: ${({ theme }) => (theme.darkMode ? '#E6E6E6' : '#1A1A1A')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 122px;
  margin-right: 72px;
  text-align: start;
  @media (max-width: 470px) {
    height: 127px;
  }
  @media (max-width: 500px) {
    margin-right: 42px;
  }
`;

export const BannerContentFooter = styled.div`
  bottom: 12px;
  position: absolute;
  right: 72px;
  @media (max-width: 1080px) {
    right: 72px;
  }
  @media (max-width: 700px) {
    right: 0px;
  }
`;

export const BannerIntro = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0px 0 6px;
  padding-left: 0px;
  @media (max-width: 700px) {
    width: 160%;
  }
`;

export const BannerDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 0px 6px 6px;
  padding-left: 0px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  white-space: normal;
  width: 100%;
  @media (max-width: 500px) {
    height: 80px;
    -webkit-line-clamp: 4; 
  }
  @media (max-width: 391px) {
    height: 100px;
    -webkit-line-clamp: 5; 
    width: 145% !important;
  }
  @media (max-width: 800px) {
    width: 110%;
  }
  @media (max-width: 700px) {
    width: 120%;
  }
`;
