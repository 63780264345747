import React from 'react';
import get from 'lodash/get';
import * as StyledComponents from '../StyledComponents';
import { PrimaryButton, IconSchedule, DatePicker } from 'cdk-radial';
import { useUnifyAdminStore } from '../../AdminStore';
import { TimeField } from '../Elements/TimeField';
import './notification.css';
import { MultiSelectDropdownMenu } from '../Elements/MultiSelectDropdownMenu';
import find from 'lodash/find';

import { createFeatureModel } from '../Store/modelTransformation';
import {useCreateQuery,useUpdateQuery} from '../Store/useReleaseNotificationQuery';

import styled from 'styled-components';
import { useTheme } from 'styled-components';
import moment from 'moment';
import {useAllApplicationsStore, useAllWorkflowsStore,} from '../../../../Store/index'
import CustomEditor from './CustomEditor';

type Positions = {
  top: string;
  bottom: string;
  left: string;
  right: string;
};

type ActionButtonsContainer = {
  children: React.ReactNode;
  position?: string;
  positionProps?: Partial<Positions>;
  background?: string;
};

const ActionButtonContainer = ({
  children,
  position,
  positionProps,
  background,
}: ActionButtonsContainer): JSX.Element => {
  return (
    <StyledComponents.StyledActionButtonContainer
      positionProps={positionProps}
      position={position}
      background={background}
    >
      {children}
    </StyledComponents.StyledActionButtonContainer>
  );
};

function validateURL(url: string) {
  if (
    //eslint-disable-next-line
    /^(http[s]?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      url
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export const StyledWrapperDatePicker = styled.div<{
  maxWidth: string;
  dayPickerLeft: string;
}>`
  max-width: ${(props) => props.maxWidth || '150px'};
  .DayPicker {
    left: ${(props) => props.dayPickerLeft || 'unset'};
    bottom: 0;
    margin-bottom: 55px;
    top: auto;
  }
`;

const applicationToOptions = (data: any) => {
  if (data && data[0]) {
    const applications = data[0];
    if (applications) {
      const filteredApps = applications.filter(
        (app: any) =>
          app.name !== 'Fortellis Marketplace Operations' &&
          app.name !== 'CDK University' &&
          app.name !== 'Customer Care' &&
          app.name !== 'Unify Administration'
      );
      let options = filteredApps.map((application: any) => {
        return {
          id: application.appId,
          label: application.name,
          value: application.appId,
        };
      });
      return options;
    }
  }
};

interface Option {
  id: string;
  label: string;
  value: string;
}

const filterWorkflowOptions = (applications: any, workflowsData: any) => {
  let filtered = [] as Option[];
  if (workflowsData) {
    const workflows = workflowsData[0];
    if (workflows) {
      workflows.forEach((workflow: any) => {
        if (
          workflow.applications &&
          workflow.applications[0] &&
          workflow.applications[0].appId
        ) {
          const applId = workflow.applications[0].appId;
          const found = find(applications, (app: any) => app.id === applId);
          if (found) {
            filtered.push({
              id: workflow.workflowId,
              label: workflow.name,
              value: workflow.workflowId,
            });
          }
        }
      });
    }
  }
  return filtered;
};

export const FeatureReleaseSheetDetails = (): JSX.Element => {
  const { darkMode } = useTheme();

  const sideSheetData = useUnifyAdminStore(
    (state) => state.releaseSideSheetData
  );

  const {mutation:createMutation} = useCreateQuery({
    onSuccess: () => {
      setReleaseShowToastState(`Created ${sideSheetData.title}`, 'Success');
      closeSideSheet()
    },
    onError: () => {
      setReleaseShowToastState('Failed to create a feature release alert', 'Error');
      closeSideSheet()
    }
  });

  const {mutation:updateMutation} = useUpdateQuery({
    onSuccess: () => {
      setReleaseShowToastState(`Updated ${sideSheetData.title}`, 'Success');
      closeSideSheet()
    },
    onError: () => {
      setReleaseShowToastState('Failed to delete a feature release', 'Error');
      closeSideSheet()
    }
  });

  const setWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setAdminSideSheetCollapse
  );
  const setApplicationSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setWorkflowSideSheetCollapse
  );
  const setProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setProductOutageSideSheetCollapse
  );
  const setReleaseSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setReleaseSideSheetCollapse
  );
  const resetReleaseSideSheetState = useUnifyAdminStore(
    (state) => state.resetReleaseSideSheetState
  );
  const archiveSwitch = useUnifyAdminStore((state) => state.releaseArchiveSwitch);

  const applications = useAllApplicationsStore((state:any) => state.applications);
  const workflows = useAllWorkflowsStore((state:any) => state.workflows);

  const applicationOptions = applicationToOptions(applications);
  const workflowOptions = filterWorkflowOptions(
    get(sideSheetData, 'targetApplications', []),
    workflows
  );

  const updateReleaseSideSheetData = useUnifyAdminStore(
    (state) => state.updateReleaseSideSheetData
  );
  const setReleaseShowToastState = useUnifyAdminStore(
    (state) => state.setReleaseShowToastState
  );
  const setIsValidationState = useUnifyAdminStore(
    (state) => state.setIsReleaseValidationState
  );
  const onApplicationSelect = (applications: any) => {
    updateReleaseSideSheetData({
      fieldName: 'targetApplications',
      value: applications,
    });
    const workflowOptions = filterWorkflowOptions(applications, workflows);

    //For removing selected workflows when their application deselect
    const selectedWorkflows = { ...sideSheetData.targetWorkflows };
    const updatedSelectedWorkflows = Object.values(selectedWorkflows).filter(
      (selectedWorkflow: any) =>
        workflowOptions.some(
          (workflowOption: any) =>
            workflowOption.id === selectedWorkflow.id
        )
    );
    updateReleaseSideSheetData({
      fieldName: 'targetWorkflows',
      value: updatedSelectedWorkflows,
    });
  };
  const onWorkflowSelect = (applications: any) => {
    updateReleaseSideSheetData({
      fieldName: 'targetWorkflows',
      value: applications,
    });
  };
  const onStartDateChange = (startDate: any) => {
    updateReleaseSideSheetData({
      fieldName: 'startDate',
      value: startDate,
    });
  };
  const onEndDateChange = (endDate: any) => {
    updateReleaseSideSheetData({ fieldName: 'endDate', value: endDate });
  };
  const onStartTimeChange = (startTime: any) => {
    updateReleaseSideSheetData({
      fieldName: 'startTime',
      value: startTime,
    });
  };
  const onEndTimeChange = (endTime: any) => {
    updateReleaseSideSheetData({ fieldName: 'endTime', value: endTime });
  };

  const onTitleChange = (e: any) => {
    updateReleaseSideSheetData({
      fieldName: 'title',
      value: e.target.value,
    });
  };

  const onShortDescChange = (e: any) => {
    updateReleaseSideSheetData({
      fieldName: 'subject',
      value: e.target.value,
    });
  };
  const onLongDescChange = (e: any) => {
    updateReleaseSideSheetData({
      fieldName: 'body',
      value: e.target.value,
    });
  };

  const onLearnMoreChange = (e: any) => {
    updateReleaseSideSheetData({
      fieldName: 'linkToLearnMore',
      value: e.target.value,
    });
  };
  const ontargetEnterpriseChange = (e: any) => {
    updateReleaseSideSheetData({
      fieldName: 'targetEnterprises',
      value: e.target.value,
    });
  };

  const closeSideSheet = () => {
    setWorkflowSideSheetCollapse(true);
    setApplicationSideSheetCollapse(true);
    setReleaseSideSheetCollapse(true);
    setProductOutageSideSheetCollapse(true);
    resetReleaseSideSheetState();
  };

  const handleUpdate = () =>{
    if (!handleValidation()) return;
    const model = [];
    model.push({messageId:sideSheetData.messageId});
    model.push(createFeatureModel(sideSheetData));
    updateMutation.mutate(model);
  }

  const handleSave = () => {
    if (!handleValidation()) return;
    createMutation.mutate(createFeatureModel(sideSheetData));
  };

  const handleCancel = () => {
    closeSideSheet();
  };

  const handleValidation = () => {
    if (!sideSheetData.title) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid title', 'Error');
      return false;
    }
    if (!sideSheetData.subject) {
      setIsValidationState(true);
      setReleaseShowToastState(
        'Please enter a valid short description',
        'Error'
      );
      return false;
    }
    if (!sideSheetData.body) {
      setIsValidationState(true);
      setReleaseShowToastState(
        'Please enter a valid detailed description',
        'Error'
      );
      return false;
    }

    const isValid =
      sideSheetData?.targetEnterprises
        ?.split(',')
        .map((enterprise) => enterprise.trim())
        .every(
          (enterprise) => /^E\d{6}$/.test(enterprise) || enterprise === ''
        ) ?? true;
    if (sideSheetData?.targetEnterprises && !isValid) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid enterprise id', 'Error');
      return false;
    }

    const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (!sideSheetData.startDate) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid start date', 'Error');
      return false;
    }

    if (!sideSheetData.startTime || !timeRegex.test(sideSheetData.startTime)) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid start time', 'Error');
      return false;
    }

    if (!sideSheetData.endDate) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid end date', 'Error');
      return false;
    }

    if (!sideSheetData.endTime || !timeRegex.test(sideSheetData.endTime)) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid end time', 'Error');
      return false;
    }

    const startDateV = moment(
      sideSheetData.startDate.format('MM/DD/YYYY') +
        'T' +
        sideSheetData.startTime,
      'MM/DD/YYYY hh:mm ZZ'
    );

    const endDateV = moment(
      sideSheetData.endDate.format('MM/DD/YYYY') + 'T' + sideSheetData.endTime,
      'MM/DD/YYYY hh:mm ZZ'
    );

    if(!sideSheetData.messageId){
      if (moment(new Date()).isAfter(startDateV)) {
        setIsValidationState(true);
        setReleaseShowToastState(
          'Start date should be future date and time',
          'Error'
        );
        return false;
      }
    }

    if (startDateV.isAfter(endDateV)) {
      setIsValidationState(true);
      setReleaseShowToastState(
        'End date should be greater than start date',
        'Error'
      );
      return false;
    }

    if (
      sideSheetData?.linkToLearnMore &&
      !validateURL(sideSheetData?.linkToLearnMore)
    ) {
      setIsValidationState(true);
      setReleaseShowToastState('Please enter a valid learn more link', 'Error');
      return false;
    }

    return true;
  };

  return (
    <>
      <StyledComponents.StyledInput
        dataTestId={`title-input`}
        errorMessage="Please enter a valid title"
        id={`title-name-input`}
        inputDivider="left"
        label={'Title'}
        name={'title'}
        onChange={onTitleChange}
        placeholder={'Enter title'}
        value={get(sideSheetData, 'title', '')}
        hasCharacterCount={true}
        maxLength={100}
        isRequired={true}
        isDisabled={archiveSwitch}
      />
      <StyledComponents.StyledInput
        dataTestId={`short-desc-input`}
        errorMessage="Please enter short description."
        id={`short-desc-name-input`}
        inputDivider="left"
        label={`Short Description`}
        name="subject"
        placeholder={'Enter short description'}
        onChange={onShortDescChange}
        value={get(sideSheetData, 'subject', '')}
        hasCharacterCount={true}
        maxLength={150}
        isRequired={true}
        isDisabled={archiveSwitch}
      />
      <div style={{ marginTop: '10px'}}>
        <StyledComponents.StyledCustomEditorLabel>Detailed Description</StyledComponents.StyledCustomEditorLabel>
          <CustomEditor 
            value={get(sideSheetData, 'body', '')} 
            onChange={onLongDescChange}>
          </CustomEditor>
          <StyledComponents.StyledLongDescCount>
            <StyledComponents.StyledCountLabel>
              {get(sideSheetData, 'body', '').length}/5000
            </StyledComponents.StyledCountLabel>
          </StyledComponents.StyledLongDescCount>
      </div>
      <MultiSelectDropdownMenu
        options={applicationOptions || []}
        name="targetApplications"
        onSelect={onApplicationSelect}
        value={get(sideSheetData, 'targetApplications', '')}
        label={'Target Applications'}
        placeholder={'Select applications'}
        isDisabled={archiveSwitch}
      ></MultiSelectDropdownMenu>
      <MultiSelectDropdownMenu
        options={workflowOptions || []}
        name="targetWorkflows"
        onSelect={onWorkflowSelect}
        value={get(sideSheetData, 'targetWorkflows', '')}
        label={'Target Workflows'}
        placeholder={'Select workflows'}
        isDisabled={archiveSwitch}
      ></MultiSelectDropdownMenu>
      <StyledComponents.StyledInput
        dataTestId={`target-enterprise-desc-input`}
        errorMessage="Error message"
        id={`target-enterprise-name-input`}
        inputDivider="left"
        label={`Target Enterprises`}
        name="targetEnterprises"
        placeholder={'Eg. E123221,E343212'}
        onChange={ontargetEnterpriseChange}
        value={get(sideSheetData, 'targetEnterprises', '')}
        hasCharacterCount={true}
        isDisabled={archiveSwitch}
      />
      <>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '180px' }}>
            <StyledWrapperDatePicker dayPickerLeft="0px" maxWidth="180px">
              <DatePicker
                dataTestId="start-date-picker"
                date={get(sideSheetData, 'startDate', null)}
                dateFormat="MM/DD/YYYY"
                dateInputProps={{
                  enableCustomValidation: false,
                  errorMessage: 'Please select start date.',
                  hasError: false,
                  isReadOnly: false,
                  isRequired: true,
                  isDisabled: archiveSwitch,
                  size: 'standard',
                  onError: () => {},
                }}
                id="start-date-picker"
                label="Start Date"
                maximumYear={2100}
                minimumYear={1900}
                name="start-date-picker"
                numberOfMonths={1}
                onDateChange={onStartDateChange}
                shortcutChipsPosition="none"
                allowDaysInPast={sideSheetData.messageId !=null} 
                highlightToday
              />
            </StyledWrapperDatePicker>
          </div>

          <div style={{ width: '180px' }}>
            <StyledWrapperDatePicker dayPickerLeft="-132px" maxWidth="180px">
              <DatePicker
                className="endDatePicker"
                dataTestId="end-date-picker"
                date={get(sideSheetData, 'endDate', null)}
                dateFormat="MM/DD/YYYY"
                dateInputProps={{
                  enableCustomValidation: false,
                  errorMessage: 'Please select end date.',
                  hasError: false,
                  isReadOnly: false,
                  isRequired: true,
                  isDisabled: archiveSwitch,
                  size: 'standard',
                  onError: () => {},
                }}
                id="end-date-picker"
                label="End Date"
                maximumYear={2100}
                minimumYear={1900}
                name="end-date-picker"
                numberOfMonths={1}
                onDateChange={onEndDateChange}
                shortcutChipsPosition="none"
              />
            </StyledWrapperDatePicker>
          </div>
        </div>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '180px' }}>
            <TimeField
              icon={<IconSchedule />}
              handleChange={onStartTimeChange}
              value={get(sideSheetData, 'startTime', '')}
              name={'startTime'}
              label={'Start Time'}
              isDisabled={archiveSwitch}
            />
          </div>
          <div style={{ width: '180px' }}>
            <TimeField
              handleChange={onEndTimeChange}
              value={get(sideSheetData, 'endTime', '')}
              name={'endTime'}
              label={'End Time'}
              isDisabled={archiveSwitch}
            />
          </div>
        </div>
      </>

      <div style={{ marginTop: '10px' }}>
        <StyledComponents.StyledTextAreaWithCustomHeight
          customHeight="60px"
          dataTestId={`learn-more-input`}
          errorMessage="Error message"
          id={`learn-more-name-input`}
          inputDivider="left"
          label={`Learn More`}
          name="learnMore"
          onChange={onLearnMoreChange}
          placeholder={`Eg. https://connectcdk.com/`}
          value={get(sideSheetData, 'linkToLearnMore', '')}
          maxLength={200}
          isDisabled={archiveSwitch}
        />
      </div>
      <ActionButtonContainer
        position="sticky"
        positionProps={{ bottom: '0px' }}
        background={
          darkMode
            ? 'linear-gradient(black, transparent)'
            : 'linear-gradient(white, transparent)'
        }
      >
        <div>

          <StyledComponents.StyledTextButton
            dataTestId="text-button"
            iconColor="primary"
            onClick={handleCancel}
            text="Cancel"
          />


          { 
              sideSheetData.messageId && !archiveSwitch && (
                <PrimaryButton
                  dataTestId="save-side-sheet-button"
                  iconColor="primary"
                  onClick={handleUpdate}
                  text={'Update'}
                  isDisabled={updateMutation.isLoading}
                />
              )}

            {!sideSheetData.messageId && (
              <PrimaryButton
                dataTestId="save-side-sheet-button"
                iconColor="primary"
                onClick={handleSave}
                text={'Save'}
                isDisabled={createMutation.isLoading}
              />
            )}
            
        </div>
      </ActionButtonContainer>
    </>
  );
};
