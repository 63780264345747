import { theme } from 'cdk-radial';
import unifyLogo from '../assets/UnifyLogo.png';
import * as Types from './types';

theme.carbon = {};
theme.carbon.element = {};

// Primary brands object

// TODO: type
export const themes: any = {};

const unify: Types.BaseTheme = {
  fontFamily: 'Montserrat',
  fontWeight: {
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  borderRadius: {
    sm: '20px',
    md: '32px',
  },
  spacing: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
  },
  fontSize: {
    md: '14px',
    lg: '20px',
  },
};

const typography: Types.Typography = {
  header: {
    fontFamily: unify.fontFamily,
    fontWeight: unify.fontWeight.semiBold,
    fontSize: unify.fontSize.lg,
  },
  heading: {
    fontFamily: unify.fontFamily,
    fontSize: unify.fontSize.md,
    fontWeight: unify.fontWeight.semiBold,
    padding: unify.spacing.lg,
  },
};

const colors = {
  white: '#fff',
  black: '#000',
};

// Default Carbon theme
themes.default = {};
themes.default.element = {};
// If there was an OEM brand
// themes.toyota = {};

themes.default = {
  text: {
    header: {
      font: 'Montserrat',
      size: '20px',
      weight: '600',
    },
    heading: {
      text: {
        family: 'Montserrat',
        weight: '600',
        case: 'uppercase',
        size: '14px',
      },
      padding: '16px',
      background: 'red',
    },
  },
  color: {
    light: {
      warning: theme.color.system.warning['900'].value,
      errorBoundary: {
        border: '#ff0000',
        text: '#ff0000',
      },
      element: {
        applicationCard: {
          background: 'rgba(18, 18, 18, 0.03)',
          color: '#000',
        },
        tabs: {
          container: {
            backgroundColor: '#000',
            color: '#fff',
            border: '2px solid #000',
            borderRadius: '32px',
          },
          activeTab: {
            backgroundColor: '#fff',
            color: '#000',
            border: '2px solid #000',
          },
        },
        card: {
          background: 'rgba(255,255,255,0.6)',
          color: '#000',
          border: 'rgba(0,0,0,0.3)',
          description: 'rgba(0,0,0,0.8)',
        },
        backplane: {
          backgroundColor: '#fff',
          backgroundImage: 'none',
          color: '#000',
          backgroundRepeat: 'no-repeat',
        },
        appName: {
          color: '#000',
        },
        appIcon: {
          color: '#fff',
        },
      }
    },
    dark: {
      warning: theme.color.system.warning['400'].value,
      errorBoundary: {
        border: '#ff0000',
        text: '#ff0000',
      },
      element: {
        applicationCard: {
          background: 'rgba(255, 255, 255, 0.05)',
          color: '#fff',
        },
        tabs: {
          container: {
            backgroundColor: '#fff',
            color: '#000',
          },
          activeTab: {
            backgroundColor: '#000',
            color: '#fff',
          },
        },
        card: {
          background: 'rgba(0,0,0,0.8)',
          color: 'rgba(255,255,255,0.8)',
          border: 'rgba(255,255,255,0.3)',
          description: 'rgba(255,255,255,0.8)',
        },
        backplane: {
          backgroundColor: '#000',
          color: '#fff',
        },
        appName: {
          color: '#fff',
        },
        appIcon: {
          color: '#fff',
        },
      },
    },
  },
  element: {
    sidebarTabs: {
      tab: {
        padding: '8px 16px',
      },
      activeTab: {
        backgroundColor: '#fff',
        color: '#000',
        border: '2px solid #000',
      },
      container: {
        padding: '2px',
        width: 'fit-content',
        margin: '0 auto',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        hoverCursor: 'pointer',

        backgroundColor: '#000',
        color: '#fff',
        border: '2px solid #000',
        borderRadius: '32px',
      },
    },
    appIcon: {
      text: {
        family: 'Montserrat',
        size: '20px',
        weight: 'normal',
      },
      width: '46px',
      height: '50px',
      padding: '2px;',
      radius: '2px;',
    },
    appSwitcher: {
      radius: '20px',
    },
    carbonHeader: {
      text: {
        family: 'Montserrat',
        weight: '600',
        case: 'uppercase',
        size: '14px',
      },
      padding: '18px 33px 18px 16px',
      height: '48px',
      background: 'black',
    },
    card: {
      border: {
        color: 'rgba(255,255,255,0.3)',
        width: '1px',
        radius: '4px',
        style: 'solid',
      },
      padding: '16px',
      width: {
        max: '600px',
        min: '300px',
      }
    },
    hero: {
      text: {
        family: 'Montserrat',
      },
    },
    settings: {
      text: {
        family: 'Montserrat',
        nameHeader: {
          margin: '16px 0 8px 0',
        },
      },
    },
    workflowCard: {
      background: 'rgba(0,0,0,0.6)',
    },
    tabs: {
      container: {
        borderRadius: '32px',
        padding: '4px',
        width: 'fit-content',
        margin: '0 auto',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        hoverCursor: 'pointer',
      },
    },
  },
};

theme.carbon = {
  app: {
    DRIVE: { color: '#315C2C' },
    'CDK Elead': { color: '#D34A3D' },
    TechView: { color: '#308AA2' },
    'CDK Service': { color: '#0374DD' },
    'Common Admin': { color: '#147DDF' },
    'Connected Lot': { color: '#428D85' },
    CVR: { color: '#244E70' },
    'Ford SMARTT': { color: '#2961DC' },
    'Neuron Live Vehicle Inventory': { color: '#707383' },
    'New Lot Intelligence': { color: '#773B81' },
    Roadster: { color: '#161616' },
    'Service Pricing': { color: '#1C81E0' },
    'Used Lot Intelligence': { color: '#B88FAF' },
    VehicleXchange: { color: '#40683C' },
  },
  color: themes.default.color.light,
  text: themes.default.text,
  element: {
    ...themes.default.element,
    carbonSubHeader: {
      height: '80px',

      logo: {
        backgroundColor: '#000',
        backgroundImage: `url(${unifyLogo})`,
        background: `linear-gradient(62.68deg, #F4EA02 11.4%, #F07D3E 24.85%, #ED3664 34.27%, #EC1B73 38.31%, #DE1C73 41%, #A02374 53.11%, #7A2775 61.85%, #6B2875 66.56%)`,
      },

      title: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    card: {
      description: {
        color: 'rgba(255, 255, 255, 0.8)',
      },
      border: {
        color: 'rgba(255,255,255,0.05)',
        width: '2px',
        radius: '4px',
        style: 'solid',
      },
      padding: '16px',
      width: {
        max: '600px',
        min: '300px',
      },
      background: {
        color: 'rgba(255, 255, 255, 0.05)',
      },
    },
  },
};

const unifyTheme: Types.UnifyTheme = {
  ...unify,
  colors,
  typography,
};

export const combinedTheme: Types.Theme = {
  ...theme,
  unify: unifyTheme,
};
