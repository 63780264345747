import React from 'react';
import styled from 'styled-components';
import { IconClose, SideSheetContainer, darkMode as RDSDarkMode,
  theme as RDSLightMode, } from 'cdk-radial';
import * as Styles from '../styles';
import AuthSpinner from '../common/AuthSpinner';
import { useSideSheet } from '../../contexts/SideSheet';
import { SIDESHEET_CONTENT } from '../../constants';
import Help from '../Help/Help';
import Profile from '../Profile/Profile';
import MeetMyTeamMenu from '../MeetMyTeam/MeetMyTeamMenu';
import toTestId from '../../testUtils/toTestId';
import useHandleBackdropClose from '../../hooks/useHandleBackdropClose';
import History from '../History/History';
import { RoundedIconButton } from '../Buttons/IconButton';
import { LOGOUT_STATUS } from '../MessageProvider/MessageProvider';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import NotificationSheet from '../Notifications/NotificationSheet';
import { useTheme } from '../../contexts/Theme';
import { ThemeProvider } from 'styled-components';

const StyledSideSheetContainer = styled(SideSheetContainer)`
  border-radius: 16px;
  border: ${({ theme }) => (theme.darkMode ? '2px solid gray' : 'none')}; 
  bottom: 16px;
  box-shadow: ${({ theme }) => (theme.darkMode ? 'none' : '0px 8px 25px 2px rgba(0, 0, 0, 0.35)')}; 
  font-family: 'Montserrat', 'Roboto', sans-serif;
  height: calc(100vh - 64px);
  margin-right: ${({ isCollapsed }) => (isCollapsed ? '-450px' : 0)};
  position: fixed;
  right: 8px;
  top: 56px;
  z-index: 1002;
  overflow:hidden;
  width:420px;
  @media (max-width: 470px) {
    width:100vw;
    border-radius: 0px;
    right:0px;
    top: 52px;
  }
`;

export function SideSheet() {
  const { sideSheetContent, setSideSheetContent } = useSideSheet();
  const sheetRef = React.useRef<HTMLDivElement>(null);
  const showProfile = sideSheetContent === SIDESHEET_CONTENT.PROFILE;
  const showHelp = sideSheetContent === SIDESHEET_CONTENT.HELP;
  const showHistory = sideSheetContent === SIDESHEET_CONTENT.HISTORY;
  const showNotification = sideSheetContent === SIDESHEET_CONTENT.NOTIFICATION;
  const showMeetMyCDKTeam = sideSheetContent === SIDESHEET_CONTENT.MEET_MY_CDK_TEAM;
  const showSideSheet = sideSheetContent !== null;
  const { logoutStatus } = useChannelMessage();
  const { darkMode } = useTheme();
  const theme = darkMode ? RDSDarkMode : RDSLightMode;

  const handleOutsideClick = () => {
    if (logoutStatus !== LOGOUT_STATUS.LOGOUT_USERCONFIRM) {
      setSideSheetContent(null);
    }
  };

  useHandleBackdropClose({
    ref: sheetRef,
    clickHandler: handleOutsideClick,
  });

  return (
    <ThemeProvider theme={theme}>
    <StyledSideSheetContainer
      data-testid="side-sheet"
      isCollapsed={!showSideSheet}
      ref={sheetRef}
    >
      <AuthSpinner>
        {showProfile && <Profile />}
        {showHelp && <Help />}
        {showHistory && <History />}
        {showNotification && <NotificationSheet />}
        {showMeetMyCDKTeam && <MeetMyTeamMenu /> }
      </AuthSpinner>
    </StyledSideSheetContainer>
    </ThemeProvider>
  );
}

export function SideSheetHeader({
  toggle,
  name,
  dataTestId,
}: {
  toggle: () => void;
  name: string;
  dataTestId: string;
}) {
  const testId = toTestId(`sidesheet-close-icon-${name}`);
  return (
    <Styles.SideSheetHeader>
      <div data-testid={`${dataTestId}-header-title`}>{name}</div>
      <RoundedIconButton
        dataTestId={testId}
        darkMode
        hideText
        icon={<IconClose />}
        onClick={toggle}
        id="sidesheet-close-button"
        text="Close"
      />
    </Styles.SideSheetHeader>
  );
}
