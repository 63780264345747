import * as StyledComponents from '../StyledComponents'


export default function UnifyAdminMainTitle(props: any): JSX.Element {

    const {  title } = props;

    return <StyledComponents.StyledUnifyAdminMainTitle data-testid='unify-admin-main-title-container'>
            Unify Administration {title}
    </StyledComponents.StyledUnifyAdminMainTitle>
}