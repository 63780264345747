import { useEffect } from "react";
import "./styles.css";
import { useSideSheet } from '../../contexts/SideSheet';

export default function AppStack({
    openedApps, 
    showStack, 
    activeApp, 
    frameLoadHandler,
    cdkuPane }:{ 
        openedApps: any[], 
        activeApp: string, 
        showStack:boolean,
        frameLoadHandler: Function,
        cdkuPane:string }) {

    const appStackEl:HTMLDivElement = document.getElementById('app-stack') as HTMLDivElement;
     const { setSideSheetContent } = useSideSheet();
    const createApp = ({ id, url, name, logoutUrl, cdkuUrl }: {
        id: string,
        url: string,
        name: string
        logoutUrl?: string,
        cdkuUrl: string
    }) => {
        const frmEl = document.getElementById(id);
        if(!frmEl) {
            const frameEl = document.createElement('iframe');
            frameEl.setAttribute('class', 'appFrame');
            frameEl.setAttribute('data-testid', id);
            frameEl.setAttribute('id', id);
            frameEl.setAttribute('allow', "camera; microphone");
            frameEl.setAttribute('title', name);
            frameEl.setAttribute('src', cdkuPane ? cdkuPane : url);
            frameEl.onload = (evt) => { frameLoadHandler({id, url, logoutUrl}, evt) }

            const frameWrap = document.createElement('div');
            frameWrap.className = 'maximized';
            frameWrap.setAttribute('id', id + '-warp');
            frameWrap.setAttribute('data-frmid', id);
            frameWrap.className = (id === activeApp || openedApps.length === 1) ? 'maximized' : 'minimized';
            frameWrap.appendChild(frameEl);
            appStackEl?.appendChild(frameWrap);
        }
        if(id === 'CDKU') {
            const el = document.getElementById(id);
            el?.getAttribute('src') !== (cdkuPane ? cdkuPane : url) && el?.setAttribute('src', cdkuPane ? cdkuPane : url);
        }
    }

    useEffect(() => {
        removeClosedApps();
        openedApps.forEach(app => createApp(app));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedApps]);

    useEffect(() => {
        showApp(activeApp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeApp]);

    useEffect(() => {
        const blurHandler = function(){		
            setSideSheetContent(null);
        }
        window.addEventListener('blur', blurHandler);
        return () => {
            window.removeEventListener('blur', blurHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const removeClosedApps = () => {
        const appStackEl = document.getElementById('app-stack');
        const closedApps: Element[] = []
        appStackEl && appStackEl.children && Array.from(appStackEl.children)
            .reduce(( acc: any, el:any) => { 
                const capp = openedApps.find(app => app.id === el.getAttribute('data-frmid'));
                capp || acc.push(el);
                return acc; }, closedApps);

        closedApps.length > 0 && closedApps.forEach( (el) => el.remove() );
        openedApps.length === 0 && closeAllApps();
    }

    const closeAllApps = () => {
        const appStackEl = document.getElementById('app-stack');
        appStackEl && (appStackEl.innerHTML = '');
    }

    const showApp = (id: string) => {
        const appStackEl = document.getElementById('app-stack');
        appStackEl && appStackEl.children && Array.from(appStackEl.children)
            .forEach((el:any) => el.className = el.getAttribute('data-frmid') === id ? 'maximized' : 'minimized');
    }

    return (<>
        { <div className="appStack" style={ { display: (showStack && activeApp) ? 'block' : 'none' } } id="app-stack"></div> }
    </>);
}