import {
  SKELETON_SHAPES,
  SkeletonLoader,
  darkMode as RDSDarkMode,
  theme as RDSLightMode,
} from 'cdk-radial';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from '../../contexts/Theme';

const SkeletonContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-rows: 1fr;
  margin-top: 16px;
  grid-template-columns: repeat(auto-fill, minmax(329px, 1fr));
`;

function CardSkeleton() {
  const { darkMode } = useTheme();
  const theme = darkMode ? RDSDarkMode : RDSLightMode;
  return (
    <ThemeProvider theme={theme}>
      <SkeletonContainer data-testid="card-skeleton-container">
        <SkeletonLoader
          variant={SKELETON_SHAPES.RECTANGLE}
          width={'auto'}
          height={'200px'}
          count={6}
        />
      </SkeletonContainer>
    </ThemeProvider>
  );
}
export default CardSkeleton;
