import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {createPaneManagementSlice, ICreatePaneManagementSlice} from './slices/createPaneManagementSlice'


/**
 * Make sure to enforce type for each slice
 */

// Todo: move usePaneManagement to usePaneManagementStore, later!

export const usePaneManagementStore = create<ICreatePaneManagementSlice>()(
    devtools((...a) => ({
        ...createPaneManagementSlice(...a),
    }))
);
