import * as StyledComponents from '../StyledComponents';

export const CampaignDescriptionInputWithHelperText = ({
  helperLabel,
  ...props
}: any): JSX.Element => {
  return (
    <StyledComponents.StyledInputContainer>
      <StyledComponents.StyledTextArea {...props} />
      <StyledComponents.StyledHelperLabelContainer>
        <StyledComponents.StyledHelperLabel>
          {helperLabel}
        </StyledComponents.StyledHelperLabel>
      </StyledComponents.StyledHelperLabelContainer>
    </StyledComponents.StyledInputContainer>
  );
};
