
import { StatusDecorator ,targetAppDecorator, targetWorkflowDecorator } from './StatusDecorator';
import {
    dateComparator,
    dateValueFormatterWithHHMM,
    dateValueFormatter,
  } from '../../utils';
import { CustomStatusRenderer } from '../../renderer';
import {CustomReleaseDeleteRenderer} from './CustomDeleteRenderer';

export const defaultColDef = {
    enablePivot: true,
    sortable: true,
    filter: true,
    flex: 1,
    autoHeight: true,
    minWidth: 100,
    resizable: true,
    refreshCells: true,
    headerHeight: '150px',
};

export const excelConfig = {
    excelStyles: [
        {
            id: 'header',
            font: {
                bold: true,
                size: 15,
            },
            interior: {
                color: '#bbb4bb',
                pattern: 'Solid',
            },
        },
    ],
    fileName: 'Feature_Release_' + new Date().toJSON().slice(0, 10),
    allColumns: true,
    processCellCallback: function ({ column, value }: any) {
        return StatusDecorator(column.colId, value);
    },
};

export const getColumnDefs = (permissions: any,applications:any,workflows:any,archiveSwitch:any) => {
    let columnDefinition = [
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
        maxWidth: 300,
        cellClass: 'formattedColumn',
      },
      {
        field: 'target.application',
        headerName: 'Target Apps',
        width: 200,
        maxWidth: 300,
        cellClass: 'formattedColumn',
        cellRenderer: (params: any) =>
        targetAppDecorator(params.data.target.application,applications),
      },
      {
        field: 'target.workflow',
        headerName: 'Target Workflows',
        width: 200,
        maxWidth: 300,
        cellClass: 'formattedColumn',
        cellRenderer: (params: any) =>
        targetWorkflowDecorator(params.data.target.workflow,workflows),
      },
      {
        field: 'target.enterpriseIds',
        headerName: 'Target Enterprises',
        width: 200,
        maxWidth: 300,
        cellClass: 'formattedColumn',
      },
      {
        cellRenderer: (params: any) =>
          dateValueFormatterWithHHMM(params.data.createdDate),
        cellClass: 'formattedColumn',
        field: 'createdDate',
        headerName: 'Created Date/Time',
        width: 50,
        maxWidth: 180,
        sortable: true,
        comparator: dateComparator,
        filterParams: {
          valueFormatter: dateValueFormatter,
        },
      },
      {
        cellRenderer: (params: any) =>
          dateValueFormatterWithHHMM(params.data.startDate),
        field: 'startDate',
        headerName: 'Start Date/Time',
        width: 50,
        maxWidth: 180,
        sortable: true,
        cellClass: 'formattedColumn',
        comparator: dateComparator,
        filterParams: {
          valueFormatter: dateValueFormatter,
        },
      },
      {
        cellRenderer: (params: any) =>
          dateValueFormatterWithHHMM(params.data.endDate),
        field: 'endDate',
        headerName: 'End Date/Time',
        width: 50,
        maxWidth: 180,
        sortable: true,
        cellClass: 'formattedColumn',
        comparator: dateComparator,
        filterParams: {
          valueFormatter: dateValueFormatter,
        },
      },
      {
        field: 'active',
        cellRenderer: (params: any) => (
          <CustomStatusRenderer isActive={params.data.active} />
        ),
        headerName: 'Status',
        width: 20,
        maxWidth: 150,
        sortable: true,
        colId: 'Status',
      },
      {
        colId: 'Delete',
        cellRenderer: (params: any) => <CustomReleaseDeleteRenderer />,
        minWidth: 20,
        width: 20,
        maxWidth: 100,
        sortable: true,
        getQuickFilterText: (params: any) => {
          return '';
        },
      },
    ];
  
    if (!permissions?.hasReleaseUpdatePermissions || archiveSwitch === true) {
      columnDefinition = columnDefinition.filter((col) => col.colId !== 'Delete');
    }

    if (archiveSwitch === true) {
      columnDefinition = columnDefinition.filter((col) => col.colId !== 'Status');
    }
  
    return columnDefinition;
};
  