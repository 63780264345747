import React, { useRef, useState } from 'react';
import { MenuListItem, PanelChevron } from 'cdk-radial';
import * as StyledComponents from '../StyledComponents';
import { ENVIRONMENTS, UNIFY_ENVIRONMENTS } from '../Constants';
import { useUnifyAdminStore } from '../AdminStore';
import {UserSearch} from './UserSearch';

type SelectedEnvOption = {
  label: string;
  value: keyof typeof ENVIRONMENTS;
};

const options = [
  { label: 'DIT', value: 'us-dit' },
  { label: 'Staging', value: 'us-stage' },
  { label: 'Performance', value: 'us-perf' },
];

const EnvironmentSelector = (): JSX.Element => {
  // @ts-ignore
  const targetRef = useRef<HTMLDivElement>([]);

  /**
   * Data store for Admin
   */
  const selectedEnvironment = useUnifyAdminStore(
    (state) => state.selectedEnvironment
  );
  const setSelectedEnvironment = useUnifyAdminStore(
    (state) => state.setSelectedEnvironment
  );
  const setShowToast = useUnifyAdminStore((state) => state.setShowToast);

  /**
   * Local derived States
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = (event: Event) => {
    setIsOpen(false);
    targetRef.current.focus();
  };

  const handleOpen = (event: Event) => {
    setIsOpen(true);
  };

  const handleSelect = (option: Record<string, string>) => {
    setIsOpen(false);
    setSelectedEnvironment(option as SelectedEnvOption);
    setShowToast(true);
    targetRef.current.focus();
  };
  return (
    <>
      <StyledComponents.StyledButtonWithIcon
        variant="secondary"
        text={`${selectedEnvironment?.label} Environment`}
        ref={targetRef}
        icon={<PanelChevron isOpen={isOpen} />}
      />
      <StyledComponents.StyledMenu
        dataTestId="environment-menu-container"
        labelRef={targetRef}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {options.map((option, ind) => (
          <MenuListItem
            dataTestId={`environment-menu-item-${ind}`}
            isSelected={selectedEnvironment?.label === option?.label}
            key={ind}
            onClick={() => handleSelect(option)}
            hideSelectedItemCheckmark={false}
          >
            {`${option?.label} Environment`}
          </MenuListItem>
        ))}
      </StyledComponents.StyledMenu>
    </>
  );
};

export const AdminPageHeader = (): JSX.Element => {
  /**
   * Local Component derived states
   */
  const environment = process.env.REACT_APP_ENV as keyof typeof ENVIRONMENTS;
  const title = `Unify Admin Console (${
    environment !== 'us-prod' ? 'Non - Production' : 'Production'
  })`;

  return (
    <StyledComponents.StyledAdminPageHeader>
      <StyledComponents.StyledAdminPageTitle>
        <StyledComponents.StyledHeading level="1" headingType="heading-2">
          {title}
        </StyledComponents.StyledHeading>
        {environment !== UNIFY_ENVIRONMENTS['us-prod'] && (
          <EnvironmentSelector/>
        )}
      </StyledComponents.StyledAdminPageTitle>
      <StyledComponents.StyledAdminPageUserSearch>
        <UserSearch/>
      </StyledComponents.StyledAdminPageUserSearch>

    </StyledComponents.StyledAdminPageHeader>
  );
};

export default AdminPageHeader;
