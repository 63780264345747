import { StateCreator } from 'zustand';
import { IOutageModel, defaultOutageModel } from '../slices/types/types';

export interface ICreateProductOutageSlice {
  productOutageSearchValue: string;
  setProductOutageSearchValue: (productOutageSearchValue: string) => void;
  productOutageArchiveSwitch: boolean;
  setProductOutageArchiveSwitch: (archiveSwitch: boolean) => void;
  productOutageSideSheetData: IOutageModel;
  setProductOutageSideSheetData: (productOutageSideSheetData: IOutageModel) => void;
  updateProductOutageSideSheetData: (event: any) => void;
  resetOutageNotifcationSideSheetState: () => void;
  setProductOutageShowToastState: (toastMsg: string, toastType: string) => void;
  resetProductOutageToastState: () => void;
  productOutageToastMsg: string,
  productOutageToastType: string,
  productOutageShowToast: boolean
  setDeleteDialogOpen: (deleteDialogOpen: boolean) => void;
  deleteDialogOpen: boolean
  setIsValidationState: (isValidationState: boolean) => void;
  isValidationState: boolean;
  setOutagePermission: (outagePermission: any) => void;
  outagePermission: any;
  outageNotifications: any,
  setOutageNotifications: (outageNotifications: any) => void;
}

const resetOutageNotifcationSideSheetState = (set: any) => () =>
  set((state: any) => {
    return {
      productOutageSideSheetData: defaultOutageModel
    };
  });

const setProductOutageShowToastState = (set: any) => (toastMsg: string, toastType: string) =>
  set((state: any) => {
    return {
      productOutageToastMsg: toastMsg,
      productOutageToastType: toastType,
      productOutageShowToast: true
    };
  });

const resetProductOutageToastState = (set: any) => () =>
  set((state: any) => {
    return {
      productOutageToastMsg: '',
      productOutageToastType: '',
      productOutageShowToast: false
    };
  });

const _setoutageNotifications = (set: any) => (notifications: any) =>
  set((state: any) => {
    return {
      outageNotifications: [...notifications]
    };
  });

export const createProductOutageSlice: StateCreator<ICreateProductOutageSlice,
  [['zustand/devtools', never]],
  [],
  ICreateProductOutageSlice> = (set) => {
    return {
      productOutageToastMsg: '',
      productOutageShowToast: false,
      productOutageToastType: '',
      productOutageSearchValue: '',
      productOutageArchiveSwitch: false,
      outageNotifications: [],
      setProductOutageArchiveSwitch: (productOutageArchiveSwitch: boolean) => set(() => ({ productOutageArchiveSwitch })),
      setOutageNotifications: _setoutageNotifications(set),
      setProductOutageSearchValue: (productOutageSearchValue: any) => set(() => ({ productOutageSearchValue })),
      productOutageSideSheetData: defaultOutageModel as unknown as | IOutageModel,
      resetOutageNotifcationSideSheetState: resetOutageNotifcationSideSheetState(set),
      setProductOutageShowToastState: setProductOutageShowToastState(set),
      resetProductOutageToastState: resetProductOutageToastState(set),
      setProductOutageSideSheetData: (productOutageSideSheetData) => set(() => ({ productOutageSideSheetData })),
      setDeleteDialogOpen: (deleteDialogOpen) => set(() => ({ deleteDialogOpen })),
      deleteDialogOpen: false,
      setIsValidationState: (isValidationState) => set(() => ({ isValidationState })),
      isValidationState: false,
      setOutagePermission: (outagePermission: any) => set(() => ({ outagePermission })),
      outagePermission: {},
      updateProductOutageSideSheetData: (event) =>
        set((state: any) => {
          return {
            productOutageSideSheetData: {
              ...state.productOutageSideSheetData,
              [event.fieldName]: event.value,
            }
          };
        }),

    };
  };



