import CampaginList from "../Blocks/CampaginList";
import CreateCampaignDialog from "../Blocks/CreateCampaignDialog";
import * as StyledComponents from '../StyledComponents';
import {CampaignPreview}from '../Blocks/CampaignPreview';
import {useUnifyAdminStore} from '../../AdminStore'

export default function CMSAdminContainer(): JSX.Element {

    const isCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.isCreateCampaignDialogOpen);
    const isPreviewCampaignDialogOpen = useUnifyAdminStore((state) => state.isPreviewCampaignDialogOpen);

    return <StyledComponents.StyledCustomScrollbarWrapper isDialogOpened={isCreateCampaignDialogOpen || isPreviewCampaignDialogOpen}>
         <StyledComponents.StyledCampaignContainerWrapper  data-testid='cms-main-container'>
        <CampaginList data-testid='campaign-list'/>
        <CreateCampaignDialog/>
        <CampaignPreview/>
    </StyledComponents.StyledCampaignContainerWrapper>
    </StyledComponents.StyledCustomScrollbarWrapper>
}