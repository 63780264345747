import { useEffect, useState } from 'react';
import { getBffUrl } from '../../util/getBffUrl';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {SSEEventListener} from './SSEEventListener';

export const useSSE = (token:string,handler:Function) => {

  const sseClient = SSEEventListener();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (token) {
      const toBearerAuth = (token: string) => `Bearer ${token}`;
      let eventSource = new EventSourcePolyfill(`${getBffUrl()}/subscribe`, {
        headers: { Authorization: toBearerAuth(token) },
      });
      sseClient.connect(eventSource, handler);
      setConnected(true);
      return () => {
        sseClient.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return connected;
}
