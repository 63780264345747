import { Button as RadialButton } from 'cdk-radial';
import styled from 'styled-components';

export const TextButton = styled(RadialButton)<{ darkMode: boolean }>`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 20px;
  padding: 8px 16px;
  && {
    background-color: transparent;
    color: ${({ darkMode }) => (darkMode ? '#E6E6E6' : '#333')};
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      color: ${({ darkMode }) => (darkMode ? '#F2F2F2' : '#333')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
  @media (max-width: 470px) {
    padding: 0px 0px 0px 20px;
  }
  @media (max-width: 418px) {
    width: 100px;
    font-size: .77rem
  }
  
`;

export const TextButtonFooter = styled(RadialButton)<{ darkMode: boolean }>`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 20px;
  padding: 8px 16px;
  && {
    background-color: transparent;
    color: ${({ darkMode }) => (darkMode ? '#E6E6E6' : '#333')};
    :hover {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      color: ${({ darkMode }) => (darkMode ? '#F2F2F2' : '#333')};
    }
    :active {
      background-color: ${({ darkMode }) => (darkMode ? '#4D4D4D' : '#CCC')};
    }
    :focus-visible {
      background-color: ${({ darkMode }) => (darkMode ? '#333' : '#E6E6E6')};
      box-shadow: ${({ darkMode }) =>
        darkMode
          ? '0px 0px 0px 4px rgba(255, 255, 255, 0.5)'
          : '0px 0px 0px 4px rgba(0, 0, 0, 0.5)'};
    }
  }
  @media (max-width: 470px) {
    padding: 0px 0px 0px 20px;
  }
  @media (max-width: 418px) {
    width: 220px;
  }
  
`;
