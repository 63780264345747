import React from 'react';
import { IconButton } from 'cdk-radial';
import * as StyledComponents from '../StyledComponents';
import toTestId from '../../../testUtils/toTestId';

interface IAdminDataSideSheetHeader {
  shouldDisplayBackIcon?: boolean;
  handleSideSheetClose: () => void;
  sideSheetHeaderTitle: string;
  handleBackIconClick?: () => void;
}

export const AdminDataSideSheetHeader = ({
  shouldDisplayBackIcon,
  handleSideSheetClose,
  sideSheetHeaderTitle,
  handleBackIconClick,
}: IAdminDataSideSheetHeader): JSX.Element => {
  const testId = toTestId(`admin-sidesheet-close-icon-${sideSheetHeaderTitle}`);
  return (
    <StyledComponents.SideSheetHeader>
      <StyledComponents.StyledIconContainer>
        {shouldDisplayBackIcon && (
          <StyledComponents.StyledIconChevronLeft
            onClick={handleBackIconClick}
          />
        )}
        <div data-testid={`application-header-title`}>
          {sideSheetHeaderTitle}
        </div>
      </StyledComponents.StyledIconContainer>
      <IconButton
        dataTestId={testId}
        hideText
        icon={<StyledComponents.StyledCloseIcon color="white" />}
        onClick={handleSideSheetClose}
        text="Close"
      />
    </StyledComponents.SideSheetHeader>
  );
};
