import { StateCreator } from 'zustand';
import { defaultSideSheetDataSchema, defaultWorkflowSchema, ISideSheetData, IWorkflow } from './types/types';


export interface ICreateAdminSideSheetSlice {
  isAdminSideSheetCollapse: boolean;
  setAdminSideSheetCollapse: (sideSheetCollapse: boolean) => void;
  isWorkflowSideSheetCollapse: boolean;
  setWorkflowSideSheetCollapse: (workflowSheetCollapse: boolean) => void;
  isProductOutageSideSheetCollapse: boolean;
  setProductOutageSideSheetCollapse: (productOutageSheetCollapse: boolean) => void;
  isReleaseSideSheetCollapse: boolean;
  setReleaseSideSheetCollapse: (releaseSheetCollapse: boolean) => void;
  sideSheetTitleName: string;
  setSideSheetTitleName: (sideSheetTitleName: string) => void;
  sideSheetData: ISideSheetData | IWorkflow;
  setSideSheetData: (sideSheetData: ISideSheetData | IWorkflow) => void;
  // @ts-ignore
  updateSideSheetData: (event: IEvent) => void;
  sideSheetDataSubWorkflow: IWorkflow;
  setSideSheetDataSubWorkflow: (expandedWorkflow: IWorkflow) => void;
  createNewApplication: boolean,
  setCreateNewApplication: (createNewApplication: boolean) => void
  createNewWorkflow: boolean,
  showFrenchTranslation: boolean,
  setShowFrenchTranslation: (value: boolean) => void,
  setCreateNewWorkflow: (createNewApplication: boolean) => void
}

export const createAdminSideSheetSlice: StateCreator<
  ICreateAdminSideSheetSlice,
  [['zustand/devtools', never]],
  [],
  ICreateAdminSideSheetSlice
> = (set) => {
  return {
    showFrenchTranslation: false,
    setShowFrenchTranslation: (showFrenchTranslation) => set(() => ({ showFrenchTranslation })),
    createNewWorkflow: false,
    setCreateNewWorkflow: (createNewWorkflow) => set(() => ({ createNewWorkflow })),
    createNewApplication: false,
    setCreateNewApplication: (createNewApplication) => set(() => ({ createNewApplication })),
    isAdminSideSheetCollapse: true,
    setAdminSideSheetCollapse: (isAdminSideSheetCollapse) =>
      set(() => ({ isAdminSideSheetCollapse })),
    isWorkflowSideSheetCollapse: true,
    setWorkflowSideSheetCollapse: (isWorkflowSideSheetCollapse) =>
      set(() => ({ isWorkflowSideSheetCollapse })),
    isProductOutageSideSheetCollapse: true,
    setProductOutageSideSheetCollapse: (isProductOutageSideSheetCollapse: boolean) =>
      set(() => ({ isProductOutageSideSheetCollapse })),
    isReleaseSideSheetCollapse: true,
    setReleaseSideSheetCollapse: (isReleaseSideSheetCollapse: boolean) =>
      set(() => ({ isReleaseSideSheetCollapse })),
    sideSheetTitleName: '',
    setSideSheetTitleName: (sideSheetTitleName) =>
      set(() => ({ sideSheetTitleName })),
    sideSheetData: defaultSideSheetDataSchema as unknown as
      | ISideSheetData
      | IWorkflow,
    setSideSheetData: (sideSheetData) => set(() => {
      let sideSheet = { ...sideSheetData };
      if (sideSheet.permissions) {
        sideSheet.permissions.include_enterprise = sideSheetData.permissions?.enterprise?.include;
        sideSheet.permissions.exclude_enterprise = sideSheetData.permissions?.enterprise?.exclude;
      }
      return { sideSheetData: sideSheet }
    }

    ),
    // Subworkflow Data
    sideSheetDataSubWorkflow: defaultWorkflowSchema as unknown as IWorkflow,
    setSideSheetDataSubWorkflow: (sideSheetDataSubWorkflow) =>
      set(() => ({ sideSheetDataSubWorkflow })),
    updateSideSheetData: (event) =>
      set((state: any) => {
        if (!state.isWorkflowSideSheetCollapse) {
          return {
            sideSheetDataSubWorkflow: {
              ...state.sideSheetDataSubWorkflow,
              [event.fieldName]: event.value
            }
          }
        }
        /**
         * Needs enhancement once we decide on roles , store and permission based control over applications and workflows.
         * eg. extracting out the state related to permissions and manage it separately more in modular pattern
         */
        if (event.fieldName.indexOf('include_enterprise') !== -1) {
          const updatedEnterpriseIds = event.value.split(',').map((id: string) => id.trim());
          let permission = state.sideSheetData.permissions;
          if (!permission) {
            permission = { enterprise: { include: [], exclude: [] } }
          }
          const permissions = {
            ...permission,
            enterprise: {
              ...permission.enterprise,
              include: updatedEnterpriseIds,
            },
            include_enterprise: updatedEnterpriseIds
          }
          return {
            sideSheetData: {
              ...state.sideSheetData,
              permissions,
            }
          };
        }
        else if (event.fieldName.indexOf('exclude_enterprise') !== -1) {
          const updatedEnterpriseIds = event.value.split(',').map((id: string) => id.trim())
          let permission = state.sideSheetData.permissions;
          if (!permission) {
            permission = { enterprise: { include: [], exclude: [] } }
          }
          const permissions = {
            ...permission,
            enterprise: {
              ...permission.enterprise,
              exclude: updatedEnterpriseIds,
            },
            exclude_enterprise: updatedEnterpriseIds
          }
          return {
            sideSheetData: {
              ...state.sideSheetData,
              permissions,
            }
          };
        }

        return {
          sideSheetData: {
            ...state.sideSheetData,
            [event.fieldName]: event.value,
          }
        };
      }),
  };
};
