import http, { toBearerAuth } from '../../../../util/httpClient';

type ReqBody = {
    body: string,
    caseId: string,
    title: string,
    target: {
        workflow: any,
        enterpriseIds: string[] | null,
        application: string[] | null,
    },
    externalUrl: string,
    subject: string,
    createdDate: any,
    startDate: any,
    endDate: any,
    type: string,
    removed: boolean,
    source: string,
    application: string,
}

export const fetchListOfOutageNotification = async (token: string) => {
    const res = await http.get<Record<any, any>[]>('/getOutageNotifications', {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const fetchListOfFeatureReleaseNotification = async (token: string) => {
    const res = await http.get<Record<any, any>[]>('/getFeatureReleaseNotifications', {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const fetchListOfArchiveOutageNotification = async (token: string) => {
    const res = await http.get<Record<any, any>[]>('/getArchiveOutageNotifications', {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const fetchListOfArchiveFeatureReleaseNotification = async (token: string) => {
    const res = await http.get<Record<any, any>[]>('/getArchiveFeatureReleaseNotifications', {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const createNotification = async (token: string, requestBody: Partial<ReqBody>) => {
    const res = await http.post(
        '/createNotification',
        requestBody,
        { headers: { Authorization: toBearerAuth(token), 'x-trace-id': "" } }
    );
    return res.data;
};

export const removeNotification = async (token: string, requestBody: any) => {
    const res = await http.post(
        '/removeNotification',
        requestBody,
        { headers: { Authorization: toBearerAuth(token), 'x-trace-id': "" } }
    );
    return res.data;
};

export const updateNotification = async (token: string, requestBody: any) => {
    const res = await http.post(
        '/updateNotification',
        requestBody,
        { headers: { Authorization: toBearerAuth(token), 'x-trace-id': "" } }
    );
    return res.data;
};
