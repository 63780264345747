import { StyledFooter, StyledDialog, StyledDialogContent } from './styles';
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import { useSideSheet } from '../../contexts/SideSheet';
import { SIDESHEET_CONTENT } from '../../constants';
import {useNotificationStore} from './NotificationStore';
import { useApplications } from '../../hooks/queries';
import { TextButton } from '../Buttons/TextButton';
import { useTheme } from '../../contexts/Theme';

const customFooter = (linkAll: string, notificationHandler: Function) => () => {
  const { darkMode } = useTheme();
  return (
    <StyledFooter style={{
      padding: '16px'
    }}>
      <div />
      <TextButton darkMode={darkMode} text={linkAll} onClick={notificationHandler} />
    </StyledFooter>)
}

const NotificationsDialog = (props: {
  isOpen: boolean,
  onClose: Function,
}): JSX.Element => {
  const { t } = useTranslation();
  const { setSideSheetContent } = useSideSheet();
  const { data:applications } = useApplications();
  const notifications = useNotificationStore((state:any) => state.notifications);
  const firstNotification = 0;
  return (
    <div>
      <StyledDialog
        isOpen={props.isOpen}
        id="notification-dialog"
        data-testid="notification-dialog"
        onClose={props.onClose}
        title={
          t('notification.dialog.title')
        }
        renderCustomFooterComponent={customFooter(t('notification.dialog.linkAll'), () => {
          setSideSheetContent(SIDESHEET_CONTENT.NOTIFICATION)
          props.onClose();
        })}
      >
        <StyledDialogContent>
          <Notification
                    data-testid={'notification-item'}
                    applications={applications}
                    notification={notifications[firstNotification]} />
        </StyledDialogContent>
      </StyledDialog>
    </div>
  );
};
export default NotificationsDialog;
