export function canOpenDrive() {
  return navigator.userAgent.toLowerCase().indexOf('win') !== -1;
}

export function isEdgeBrowser() {
  return navigator.userAgent.toLowerCase().indexOf('edg/') !== -1;
}

export function detectBrowser() {
  const ua = navigator.userAgent;
  const vendor = navigator.vendor;

  if (/Chrome/.test(ua) && /Google Inc/.test(vendor)) return "Google Chrome";
  if (/Firefox/.test(ua)) return "Mozilla Firefox";
  if (/Safari/.test(ua) && /Apple Computer/.test(vendor)) return "Apple Safari";
  if (ua.toLowerCase().indexOf('edg/') !==-1) return "Microsoft Edge";
  if (/MSIE|Trident/.test(ua)) return "Internet Explorer";
  if (/Opera|OPR/.test(ua)) return "Opera";

  return "Unknown Browser";
}
