import React from 'react';

interface ContextValue {
  openPanes: string[];
  clearOpenPanes: () => void;
  activePane: string | null;
  closePane: (pane: string) => void;
  ctaPane: string | null;
  openCtaPane: (url: string) => void;
  openPane: (pane: string) => void;
  setActivePane: (pane: string | null) => void;
  isPaneExpaneded: boolean;
  toggleIsPaneExpanded: (isPaneExpaneded?: boolean) => void;
  cdkuPane: string | null;
  openCdkuPane: (pane: string, url: string) => void;
  isCdkuUrl: string;
  setisCdkuUrl: (url: string) => void;
}

export const PaneManagementContext = React.createContext<null | ContextValue>(
  null
);

export const usePaneManagement = () => {
  const value = React.useContext(PaneManagementContext);
  if (!value) {
    throw new Error(
      'usePaneManagement must be used inside PaneManagementProvider'
    );
  }
  return value;
};
