import { create } from "zustand";
import { Workflow } from '../types';

export interface WorkflowsState {
    workflows: Workflow[];
    setWorkflows: (workflows: Workflow[]) => void;
    apiStatus: string;//"error" | "idle" | "loading" | "success"
    setApiStatus: (apiStatus: string) => void;
    isFetched: boolean;
    setIsFetched: (isFetched: boolean) => void;
}

export const useWorkflowsStore = create<WorkflowsState>((set) => ({
    workflows: [],
    setWorkflows: (workflows) => set(() => ({ workflows })),
    apiStatus: "",
    setApiStatus: (apiStatus) => set(() => ({ apiStatus })),
    isFetched: false,
    setIsFetched: (isFetched) => set(() => ({ isFetched }))
}));
