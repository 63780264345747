import './walkme-modal.css';
import { useScriptInjector } from '../../hooks/useScriptInjector';
import { AMPLITUDE_EVENTS } from '../../constants';
import { useAnalytics } from 'dms-analytics';


const LoadWalkmeScript = ({afterScriptLoad}: {afterScriptLoad:Function}) => {
  const isScriptDefer = false;
  const isAsync = true;

  useScriptInjector(
    `/thirdParty/walkme.${process.env.REACT_APP_ENV}.min.js`,
    isAsync,
    isScriptDefer,
    () => { 
      window._walkmeConfig = { smartLoad: true }
      setTimeout(()=>{
        afterScriptLoad();
      },300)
     }
  );
  return (<></>)
}

const Walkme = () => {
  const { trackEvent } = useAnalytics();

  const handleHelpLink = (topic: string) => {
    trackEvent(AMPLITUDE_EVENTS.OPEN_HELP, { 'Help topic': topic });
  };

  const afterScriptLoad = () =>{
    const element = document.getElementById("walkme-main");
    element?.setAttribute("style","box-shadow: none !important");
    window.walkme_event = function(eventData: any) {
      if (eventData.Type === 'MenuBuilt'){
        setTimeout(()=>{
          document.querySelectorAll(".walkme-deployable-row").forEach(function(el) {
            el.addEventListener('click',function(e : any) {handleHelpLink(e.target.parentElement.innerText)});
          })
        },100);
      }
    }
  }
  
  return (
        <div style={{display:'none'}}>
                <LoadWalkmeScript afterScriptLoad={afterScriptLoad}></LoadWalkmeScript>
                <div id="cdk-walkme-menu" className="cdk-walkme-menu" ></div>
        </div>
        )
  }
export default Walkme; 