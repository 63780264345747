import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import CenteredSpinner from './CenteredSpinner';

export default function AuthSpinner({
  children,
}: {
  children: React.ReactNode;
}) {
  const { authState } = useOktaAuth() ?? {};
  const { isAuthenticated } = authState ?? {};

  return isAuthenticated ? <>{children}</> : <CenteredSpinner />;
}
