import {
  useQuery,
  useQueryClient,
  useMutation,
  UseQueryResult,
} from 'react-query';
import sortBy from 'lodash/fp/sortBy';
import path from 'lodash/fp/path';
import get from 'lodash/get'
import * as Map from '../util/Map';
import * as api from '../util/api';
import { UserSettings, Application, Workflow, CNumber, DMSBoxMapping } from '../types';
import useAuthToken from './useAuthToken';
import { UserDetailsResponse } from '../util/Interfaces/IUserDetailsResponse';
import { UserInfo } from './../types';
import { INotification } from '../components/Notifications/Notification';
import { useApplicationStore, useWorkflowsStore } from '../Store';
import { UserCard } from '../components/UserCards/UserCards';

const sortByName = sortBy(path('name'));

export function useWorkflowsViewMap(onlyFavorites?: boolean) {
  const token = useAuthToken();
  const oktaUser = useUserProfile();
  const oktaUserInfo = (oktaUser.data || {}) as UserInfo;
  const locale = get(oktaUserInfo, "profile.locale") as 'en_US' | 'fr_CA'
  const enterpriseId = get(oktaUserInfo, "profile.enterpriseId", "")

  const { data = [], status } = useQuery(
    'workflows',
    () => api.fetchWorkflows(token, locale, enterpriseId),
    {
      select: (data) =>
        sortByName(
          data?.filter((workflow) =>
            onlyFavorites ? workflow.isFavorite : true
          )
        ),
      enabled: !!token && !!locale
    }
  );
  return { data: Map.workflowListToWorkflowViewMap(data), status };
}

export function useGetAllApplications() {
  const token = useAuthToken();
  const {
    data = [],
  } = useQuery('allApplications', () => api.fetchAllApplications(token), {
    select: sortByName,
    enabled: !!token,
  });
  return {
    data
  };
};

export function useGetAllWorkflows() {
  const token = useAuthToken();
  const {
    data = [],
  } = useQuery('allWorkflows', () => api.fetchAllWorkflows(token), {
    select: sortByName,
    enabled: !!token,
  });
  return {
    data
  };
};

export function useApplications() {
  const token = useAuthToken();
  const oktaUser = useUserProfile();
  const oktaUserInfo = (oktaUser.data || {}) as UserInfo;
  const locale = get(oktaUserInfo, "profile.locale") as 'en_US' | 'fr_CA'
  const enterpriseId = get(oktaUserInfo, "profile.enterpriseId", "")

  const {
    data = [],
    status,
    isFetched,
  } = useQuery('applications', () => api.fetchApplications(token, locale, enterpriseId), {
    select: sortByName,
    enabled: !!token && !!locale,
  });

  let apiStatus = status;
  if (
    data &&
    data.length > 0 &&
    data[0] &&
    Object.keys(data[0]).includes('error')
  ) {
    //This condition is considered as the Success state and handling it in ApplicationList.tsx and WorkflowsTab.tsx
    if (
      oktaUserInfo &&
      oktaUserInfo.profile &&
      Object.keys(oktaUserInfo?.profile as object).includes('login') &&
      data[0].error.includes(
        `User ${(oktaUserInfo.profile as any).login} does not exist`
      )
    ) {
      data.splice(0, data.length);
    } else {
      apiStatus = 'error';
    }
  }
  return {
    data,
    apiStatus,
    isFetched,
  };
}

export function useWorkflows() {
  const token = useAuthToken();
  const oktaUser = useUserProfile();
  const oktaUserInfo = (oktaUser.data || {}) as UserInfo;
  const enterpriseId = get(oktaUserInfo, "profile.enterpriseId", "")
  const locale = get(oktaUserInfo, "profile.locale") as 'en_US' | 'fr_CA'
  const { data } = useQuery('workflows', () => api.fetchWorkflows(token, locale as 'en_US' | 'fr_CA', enterpriseId), {
    enabled: !!token && !!locale,
  });

  return { data };
}

export function useAllWorkflowsAndApplications() {
  const workflows = useWorkflowsStore((state: any) => state.workflows);
  const applications = useApplicationStore((state: any) => state.applications);

  return {
    data: [
      ...workflows.map(Map.workflowOrApplicationToPaneData),
      ...applications.map(Map.workflowOrApplicationToPaneData),
    ],
  };
}

export function useAllWorkflowsAndApplicationsById(ids: string[]) {
  const workflows = useWorkflowsStore((state: any) => state.workflows);
  const applications = useApplicationStore((state: any) => state.applications);

  const result = [...ids].reverse().map((id) => {
    const workflow = workflows.find((item: any) => item.id === id);
    if (workflow) {
      return Map.workflowOrApplicationToPaneData(workflow);
    }
    const application = applications.find((item: any) => item.id === id);
    if (application) {
      return Map.workflowOrApplicationToPaneData(application);
    }
    return null;
  });
  return result.filter((item) => item !== null) as (Workflow | Application)[];
}

export function useUpdateApplication() {
  const token = useAuthToken();
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation<
    string,
    unknown,
    { id: string; isFavorite: boolean },
    { cachedList: Application[] }
  >(
    ({ id, isFavorite }: { id: string; isFavorite: boolean }) =>
      api.updateApplications(id, isFavorite, token),
    {
      onMutate: async ({ id }: { id: string }) => {
        await queryClient.cancelQueries('applications');
        const cachedList =
          (queryClient.getQueryData('applications') as Application[]) || [];

        queryClient.setQueryData(
          'applications',
          (oldList: Application[] = []) => {
            return oldList.map((app) => {
              return app.id === id
                ? { ...app, isFavorite: !app.isFavorite }
                : app;
            });
          }
        );

        return { cachedList };
      },

      onError: (_error, _data, context) => {
        queryClient.setQueryData('applications', context?.cachedList);
      },
    }
  );

  return { data, mutate };
}

export function useUpdateWorkflow() {
  const token = useAuthToken();
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation<
    string,
    unknown,
    { id: string; isFavorite: boolean; appId: string },
    { cachedList: Workflow[] }
  >(
    ({ id, isFavorite, appId }) =>
      api.updateWorkflows(id, appId, isFavorite, token),
    {
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries('workflows');
        const cachedList =
          (queryClient.getQueryData('workflows') as Workflow[]) || [];
        queryClient.setQueryData('workflows', (oldList: Workflow[] = []) => {
          return oldList.map((app) => {
            return app.id === id
              ? { ...app, isFavorite: !app.isFavorite }
              : app;
          });
        });

        return { cachedList };
      },

      onError: (_error, _data, context) => {
        queryClient.setQueryData('workflows', context?.cachedList);
      },
    }
  );

  return { data, mutate };
}

export function useUserProfile() {
  const {
    data = null,
    isLoading = true,
    isError = false,
    refetch,
    status,
  } = useQuery('/profile', api.fetchProfile, { retry: 3 });

  return { data, isLoading, isError, refetch, status };
}

export function useApplicationCategory() {
  const token = useAuthToken();
  const { data } = useQuery('applicationCategories', () => api.fetchApplicationCategory(token), {
    enabled: !!token,
  });
  return { data };
}

export function useIAMUserProfile() {
  const token = useAuthToken();
  const {
    data = null,
    isLoading = true,
    isError = false,
  } = useQuery('/iam-profile', () => api.fetchIAMProfile(token), {
    retry: false,
    enabled: !!token,
  });

  return { data, isLoading, isError };
}

export function useUserSettings() {
  const token = useAuthToken();
  const { data = null, status } = useQuery(
    'settings',
    () => api.fetchSettings(token),
    { enabled: !!token }
  );

  return { data, status };
}

export function useUnifyUserProfile() {
  const token = useAuthToken();
  const { data = null, status } = useQuery(
    'unifyProfile',
    () => api.fetchUnifyProfile(token),
    { enabled: !!token }
  );

  return { data, status };
}

export function useUpdateUserSettings() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (settings: UserSettings) => api.updateSettings(settings, token),
    {
      onMutate: async (settings: UserSettings) => {
        await queryClient.cancelQueries('settings');
        const cachedSettings = queryClient.getQueryData(
          'settings'
        ) as UserSettings;
        queryClient.setQueryData('settings', settings);
        return { cachedSettings };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('settings', context?.cachedSettings);
      },
      onSettled: () => {
        queryClient.invalidateQueries('settings');
      },
    }
  );
  return { data, mutate };
}

export function useBanner() {
  const token = useAuthToken();
  const {
    data = null,
    isLoading = true,
    isError = false,
  } = useQuery('/getBanner', () => api.fetchBanner(token), {
    enabled: !!token,
  });

  return { data, isLoading, isError };
}

export const useFetchDMS = (): UseQueryResult<CNumber[]> => {
  const authToken = useAuthToken();
  // @Ashif - should implement persistent cache on queryClient with cacheTime? I reckon!
  return useQuery('iam/fetchCNumbers', () => api.fetchCNumbers(authToken), {
    enabled: !!authToken,
  });
};
export const useFetchDMSBoxMapping = (): UseQueryResult<DMSBoxMapping> => {
  const authToken = useAuthToken();
  const enterpriseId = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}')
    .accessToken
    ?.claims
    ?.enterpriseId
  return useQuery('iam/fetchDMSBoxMapping', () => api.fetchDMSBoxMapping(authToken, enterpriseId), {
    enabled: !!authToken,
  });
};

/**
 * Common BFF User Queries
 */

export const useFetchEnterpriseUserDetails =
  (): UseQueryResult<UserDetailsResponse> => {
    const authToken = useAuthToken();

    return useQuery(
      'commonBff/user/details',
      () => api.fetchEnterpriseUserDetails(authToken),
      {
        enabled: !!authToken,
      }
    );
  };

/**
 * Notification Queries
 */

export function useNotifications() {
  const token = useAuthToken();
  const { data } = useQuery('notification', () => api.fetchNotifications(token), {
    enabled: !!token,
  });
  return { data };
}

export function useUpdateUserLastLogin() {
  const queryClient = useQueryClient();
  const token = useAuthToken();
  const { mutate } = useMutation(
    (data: { lastLogin: any, enterpriseId: any }) => api.updateUserLastLogin(data.lastLogin, data.enterpriseId, token), {
    onSettled: () => {
      queryClient.invalidateQueries('unifyProfile');
    }
  }
  );
  return { mutate };
}

export function useUpdateNotification() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (notification: INotification) => api.updateUserReadMessage(notification.messageId!, token),
    {
      onMutate: async (notification: INotification) => {
        await queryClient.cancelQueries({ queryKey: ['notification'] });
        const cachedNotification = queryClient.getQueryData(
          'notification'
        ) as INotification;
        queryClient.setQueryData('notification', cachedNotification);
        return { cachedNotification };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('notification', context?.cachedNotification);
      },
      onSettled: () => {
        queryClient.invalidateQueries('notification');
      },
    }
  );
  return { data, mutate };
}

export function useDeleteNotification() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (notification: INotification) => api.deleteUserMessage(notification.messageId!, token),
    {
      onMutate: async (notification: INotification) => {
        await queryClient.cancelQueries({ queryKey: ['notification'] });
        const cachedNotification = queryClient.getQueryData(
          'notification'
        ) as INotification;
        queryClient.setQueryData('notification', cachedNotification);
        return { cachedNotification };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('notification', context?.cachedNotification);
      },
      onSettled: () => {
        queryClient.invalidateQueries('notification');
      },
    }
  );
  return { data, mutate };
}

export function useCampaigns() {
  const { data: oktaUser } = useUserProfile();
  const oktaUserInfo = (oktaUser || {}) as UserInfo;
  const locale = get(oktaUserInfo, "profile.locale") ?? '';
  const token = useAuthToken();
  const { data = null, status } = useQuery(
    'campaigns',
    () => api.fetchUnifyCampaigns(token, locale),
    { enabled: !!token && !!locale }
  );

  return { data, status };
}

export function useMeetMyCDKContactProfile() {
  const { data: oktaUser, } = useUserProfile();
  const oktaUserInfo = (oktaUser || {}) as UserInfo;
  const token = useAuthToken();
  const enterpriseId = get(oktaUserInfo, "profile.enterpriseId", "")
  const { data = null, status, isLoading = true,
    isError = false } = useQuery(
      'meet-my-cdk-team',
      () => api.fetchMeetMyCDKContactProfile(token, enterpriseId),
      { enabled: !!token }
    );

  return { data, status, isLoading, isError };
}
export function useUserCards() {
  const token = useAuthToken();
  const { data } = useQuery('userCards', () => api.fetchUserCards(token), {
    enabled: !!token,
  });
  return { data };
}

export function useCreateUserCard() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (userCard: UserCard) => api.createUserCard(userCard, token),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey: ['createUserCard'] });
        const cachedUserCard = queryClient.getQueryData(
          'createUserCard'
        ) as UserCard;
        queryClient.setQueryData('createUserCard', cachedUserCard);
        return { cachedUserCard };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('createUserCard', context?.cachedUserCard);
      },
      onSettled: () => {
        queryClient.invalidateQueries('createUserCard');
      },
    }
  );
  return { data, mutate };
}

export function useUpdateUserCard() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (userCard: UserCard) => api.updateUserCard(userCard, token),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey: ['updateUserCard'] });
        const cachedUserCard = queryClient.getQueryData(
          'updateUserCard'
        ) as UserCard;
        queryClient.setQueryData('updateUserCard', cachedUserCard);
        return { cachedUserCard };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('updateUserCard', context?.cachedUserCard);
      },
      onSettled: () => {
        queryClient.invalidateQueries('updateUserCard');
      },
    }
  );
  return { data, mutate };
}

export function useDeleteUserCard() {
  const queryClient = useQueryClient();
  const token = useAuthToken();

  const { data, mutate } = useMutation(
    (userCard: UserCard) => api.deleteUserCard(userCard, token),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey: ['deleteUserCard'] });
        const cachedUserCard = queryClient.getQueryData(
          'deleteUserCard'
        ) as UserCard;
        queryClient.setQueryData('deleteUserCard', cachedUserCard);
        return { cachedUserCard };
      },
      onError: (_error, _data, context) => {
        queryClient.setQueryData('deleteUserCard', context?.cachedUserCard);
      },
      onSettled: () => {
        queryClient.invalidateQueries('deleteUserCard');
      },
    }
  );
  return { data, mutate };
}


export function useEncryptParams() {
  const token = useAuthToken();
  const { data } = useQuery('encryptParams', () => api.fetchEncryptParams(token), {
    enabled: !!token,
  });
  return { data };
}

