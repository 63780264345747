import axios from 'axios';
import { toBearerAuth } from '../../../../util/httpClient';
import { ENVIRONMENTS } from "../../Constants";
import { v4 as uuidv4 } from 'uuid';
import { insertNewRelicLog } from '../../../../util/newRelicApi';
import * as adminTypes from './types/types'

export enum SERVICES {
    'us-dev' = 'https://app-unify.app-dev.connectcdk.com/api',
    'us-dit' = 'https://app-unify.app-dit.connectcdk.com/api',
    'us-stage' = 'https://app-unify.app-stage.connectcdk.com/api',
    'us-perf' = 'https://app-unify.app-perf.connectcdk.com/api',
    'us-prod' = 'https://app-unify.app.connectcdk.com/api',
    'localhost' = 'http://localhost:8080/api'
}


export const serviceDiscovery = (environment: keyof typeof ENVIRONMENTS): string => SERVICES[environment]

export const getHttpClient = (environment: keyof typeof ENVIRONMENTS) => {
    return {
        httpClient: axios.create({
            baseURL: serviceDiscovery(environment),
        })
    }
}

export const fetchAdminApplications = async (token: string, environment: keyof typeof ENVIRONMENTS) => {
    const traceId = uuidv4();
    insertNewRelicLog("fetchAdminApplications", traceId, token);
    const { httpClient } = getHttpClient(environment)
    const res = await httpClient.get<Record<any, any>[]>('/applications', {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        },
    });
    return res.data;
};

export const createNewApplication = async (token: string, environment: keyof typeof ENVIRONMENTS, applicationRequestBody: Partial<adminTypes.adminReqBody>) => {
    const traceId = uuidv4();
    insertNewRelicLog("createNewApplication", traceId, token);
    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.post<Record<any, any>[]>(`/applications`, applicationRequestBody, {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        }
    });
    return res.data;
};

export const createNewWorkflow = async (token: string, environment: keyof typeof ENVIRONMENTS, workflowRequestBody: Partial<adminTypes.adminReqBody>) => {
    const traceId = uuidv4();
    insertNewRelicLog("createNewWorkflow", traceId, token);

    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.post<Record<any, any>[]>(`/workflows`, workflowRequestBody, {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        }
    });
    return res.data;
};

export const updateAdminApplication = async (token: string, environment: keyof typeof ENVIRONMENTS, appId: string, applicationRequestBody: Partial<adminTypes.adminReqBody>) => {
    const traceId = uuidv4();
    insertNewRelicLog("updateAdminApplication", traceId, token);

    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.patch<Record<any, any>[]>(`/applications/${appId}`, applicationRequestBody, {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        }
    });
    return res.data;
};

export const updateAdminWorkflow = async (token: string, environment: keyof typeof ENVIRONMENTS, workflowId: string, applicationRequestBody: Partial<adminTypes.adminReqBody>) => {
    const traceId = uuidv4();
    insertNewRelicLog("updateAdminWorkflow", traceId, token);

    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.patch<Record<any, any>[]>(`/workflows/${workflowId}`, applicationRequestBody, {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        }
    });
    return res.data;
};

export const fetchAdminWorkflows = async (token: string, environment: keyof typeof ENVIRONMENTS) => {
    const traceId = uuidv4();
    insertNewRelicLog("fetchAdminWorkflows", traceId, token);

    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.get<Record<any, any>[]>('/workflows', {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        },
    });
    return res.data;
};

export const searchByUserId = async (token: string, environment: keyof typeof ENVIRONMENTS, searchQuery: string) => {
    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.get<Record<any, any>[]>(`/search/user/${searchQuery}`, {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const getUserAppAndWorkflowNames = async (token: string, environment: keyof typeof ENVIRONMENTS, userId: string) => {
    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.get<Record<any, any>[]>(`/app-workflow/names/${userId}`, {
        headers: {
            Authorization: toBearerAuth(token),
        },
    });
    return res.data;
};

export const fetchAdminPermissions = async (token: string, environment: keyof typeof ENVIRONMENTS) => {
    const traceId = uuidv4();
    insertNewRelicLog("fetchAdminPermissions", traceId, token);
    const { httpClient } = getHttpClient(environment)
    const res = await httpClient.get<Record<any, any>[]>('/permissions', {
        headers: {
            Authorization: toBearerAuth(token),
            'x-trace-id': traceId
        },
    });
    return res.data;
};

export const updateAdminPermissions = async (token: string, environment: keyof typeof ENVIRONMENTS, appId: string, workflowId: string, permissionRequestBody: Partial<adminTypes.Permission>) => {
    const traceId = uuidv4();
    insertNewRelicLog("updateAdminPermissions", traceId, token);

    const { httpClient } = getHttpClient(environment)

    const res = await httpClient.patch<Record<any, any>[]>(`/permissions/${appId || workflowId}`, permissionRequestBody, {
        headers: {
            Authorization: toBearerAuth(token), 'x-trace-id': traceId
        }
    });
    return res.data;
};