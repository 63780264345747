import styled from 'styled-components';

import {
  Dialog,
  TextButton,
} from 'cdk-radial';

export const UserCardsHeader = styled.div`
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 16px;
    width: 100%;
`;

export const StyledWarning = styled.div`
    color: ${({ theme }) => theme.carbon.color.warning};
    padding: 4px;
`;

export const UserCardsContainer = styled.div`
    margin-bottom: 14px;
    margin-top: 14px;
`

export const StyledUserCardForm = styled.div`
    display: flex;
    flex-direction: column;
    > {
        margin 20px 10px;
    }
`;

export const CardButton = styled(TextButton)`
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #000;
`;

export const UserCardDialog = styled(Dialog)`
  min-width: 500px;
  max-width: 30%;
  z-index: 9999;
  @media (max-width: 500px) {
    min-width: 96%;
    width: 96%;
  }
`;

