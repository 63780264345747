export enum ENVIRONMENTS {
  'us-dit' = 'DIT',
  'us-stage' = 'Staging',
  'us-perf' = 'Performance',
  'us-prod' = 'Production',
  'localhost' = 'localhost',
}

export enum SIDE_SHEET_TITLE_NAMES {
  APPLICATION_DETAILS = 'Application Details',
  WORKFLOW_DETAILS = 'Workflow Details',
  CREATE_NEW_WORKFLOW = 'Create New Workflow',
  CREATE_NEW_APPLICATION = 'Create New Application',
  CHANGE_LOG = 'Change Log',
  CREATE_NEW_PRODUCT_OUTAGE='Create New Product Alert',
  PRODUCT_ALERT='Product Alert',
  UPDATE_PRODUCT_OUTAGE='Update Product Alert',
  FEATURE_RELEASE='Feature Release'
}

export enum UNIFY_ENVIRONMENTS {
  'us-dev' = 'us-dev',
  'us-dit' = 'us-dit',
  'us-stage' = 'us-stage',
  'us-perf' = 'us-perf',
  'us-prod' = 'us-prod',
  'localhost' = 'localhost',
}

