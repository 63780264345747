import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  AdminGridTableHeader,
  AdminPageHeader,
  AdminTableBody,
} from '../Blocks';
import * as StyledComponents from '../StyledComponents';
import { AdminDataSideSheet } from '../Blocks/AdminDataSideSheet';
import {
  AdminApplicationSideSheetBody,
  AdminWorkflowSideSheetBody,
} from '../Blocks/AdminSideSheetBody';
import { AdminDataSideSheetHeader } from '../Blocks/AdminDataSideSheetHeader';
import { useUnifyAdminStore } from '../AdminStore';
import { SIDE_SHEET_TITLE_NAMES } from '../Constants';
import { Divider } from 'cdk-radial';

export default function UnifyAdminContainer(): JSX.Element {

  /**
   * Data Store
   */
  const isAdminSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isAdminSideSheetCollapse
  );
  const isWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isWorkflowSideSheetCollapse
  );
  const setWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setWorkflowSideSheetCollapse
  );
  const setApplicationSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setAdminSideSheetCollapse
  );
  const setProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setProductOutageSideSheetCollapse
  );
  const sideSheetTitleName = useUnifyAdminStore(
    (state) => state.sideSheetTitleName
  );

  const setCreateNewApplication = useUnifyAdminStore(
    (state) => state.setCreateNewApplication
  );

  /**
   * Ref hooks
   */
  const gridRef = useRef<AgGridReact>(null);

  const handleCloseBothAppWorkflowSideSheet = () => {
    setWorkflowSideSheetCollapse(true);
    setApplicationSideSheetCollapse(true);
    setProductOutageSideSheetCollapse(true);
    setCreateNewApplication(false); // reset create app switch
  };

  return (
    <StyledComponents.StyledCustomScrollbarWrapper>
      <StyledComponents.StyledAdminWrapper id="admin-wrapper">
        <StyledComponents.StyledAdminContainer data-testid="admin-main-container">
          <>
            <Divider />
            <AdminPageHeader />
            <Divider data-testid="admin-page-header-divider" />
            <StyledComponents.StyledAdminTableContainer data-testid="admin-table-container">
              <AdminGridTableHeader gridRef={gridRef} />
              <AdminTableBody gridRef={gridRef} />
              {/*Application Detail Side Sheet */}
              {!isAdminSideSheetCollapse && (
                <AdminDataSideSheet isCollapse={isAdminSideSheetCollapse}>
                  <AdminDataSideSheetHeader
                    sideSheetHeaderTitle={sideSheetTitleName}
                    handleSideSheetClose={handleCloseBothAppWorkflowSideSheet}
                  />
                  <AdminApplicationSideSheetBody />
                </AdminDataSideSheet>
              )}

              {/* Workflow Side Sheet*/}
              {!isWorkflowSideSheetCollapse && (
                <AdminDataSideSheet isCollapse={isWorkflowSideSheetCollapse}>
                  <AdminDataSideSheetHeader
                    sideSheetHeaderTitle={
                      SIDE_SHEET_TITLE_NAMES.WORKFLOW_DETAILS
                    }
                    handleSideSheetClose={handleCloseBothAppWorkflowSideSheet}
                    shouldDisplayBackIcon
                    handleBackIconClick={() =>
                      setWorkflowSideSheetCollapse(true)
                    }
                  />
                  <AdminWorkflowSideSheetBody />
                </AdminDataSideSheet>
              )}
            </StyledComponents.StyledAdminTableContainer>
          </>
        </StyledComponents.StyledAdminContainer>
      </StyledComponents.StyledAdminWrapper>
    </StyledComponents.StyledCustomScrollbarWrapper>
  );
}
