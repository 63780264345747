import { MouseEvent, useRef } from 'react';
import { IconButton, IconFavorite, IconInfo, IconDemo,  IconMoreVert, IconFavoriteBorder, Menu, MenuListItem } from 'cdk-radial';
import * as Styles from './styles';
import toTestId from '../../testUtils/toTestId';
import { useTranslation } from 'react-i18next';
import { useShowDialog } from '../../contexts/AppStateContext';
import launchExternal from '../../util/launchExternal';

interface Props {
  isEnabled: boolean;
  isFavorite?: boolean;
  description: string;
  name: string;
  iconText: string;
  onOpenClick: (e:MouseEvent) => void;
  onFavoriteClick: () => void;
  iconColor: string;
  isIconRequired: boolean;
  showFavoriteIcon?: boolean;
  tabIndex?: number;
  cardHieght?: number;
  showDescription?: boolean;
  onCdkuOpen?: Function;
  cdkuUrl?: string;
  menu?: CardMenu[];
  menuHandler?: Function;
}

export interface CardMenu {
  key: string;
  label: string;
  icon?: any;
}

export default function BaseCard({
  isFavorite,
  isEnabled,
  description,
  name,
  iconText,
  iconColor,
  onOpenClick,
  onFavoriteClick,
  isIconRequired,
  showFavoriteIcon = true,
  tabIndex = 0,
  cardHieght = 80,
  showDescription = false,
  onCdkuOpen,
  cdkuUrl,
  menu,
  menuHandler,
}: Props) {
  const {t} = useTranslation();
  const Icon = isFavorite ? IconFavorite : IconFavoriteBorder;

  /*** Card Menu */

  const moreRef = useRef();
  const showDialog = useShowDialog();

  const menuOptions = menu || [
    { label: isFavorite ? t('baseCard.menu.removeFromMyUnify')  : t('baseCard.menu.addToMyUnify') , key: 'MY_UNIFY', icon: <Icon /> },
    { label: t('baseCard.menu.learnMore'), key:'LEARN_MORE',  icon: <IconInfo /> }
  ]
  cdkuUrl && menuOptions.push( { label: t('baseCard.menu.cdku'), key:'CDKU',  icon: <IconDemo /> });

  const onMoreClick = (event:MouseEvent) => {
    event.stopPropagation();
    return false;
  }
  const onMenuClick = (option:CardMenu) => {
    if(menuHandler) return menuHandler(option);
    switch(option.key){
      case 'MY_UNIFY':
        onFavoriteClick();
        break;

      case 'LEARN_MORE':
        showDialog('LEARN_MORE', {
          iconColor,
          iconText,
          name,
          description,
          isEnabled,
          showDescription: true,
        });
        break;

      case 'CDKU':
        launchExternal(cdkuUrl as string) || ( onCdkuOpen && onCdkuOpen(cdkuUrl) );
        break;

    }
  }

  // Radial menu click is causing card click by default, so ignoring menu click
  let clickByMenu = false;
  const onCardClick = (e:MouseEvent) => {
    !clickByMenu && onOpenClick(e);
    clickByMenu = false;
  }

  return (
    <>
      <Styles.Card
        data-testid={`card-${toTestId(name)}`}
        onClick={(e) => isEnabled && onCardClick(e)}
        tabIndex={tabIndex}
      >
        <div>
          <Styles.CardHeader>
            {isIconRequired && (
              <Styles.ProductIcon iconColor={iconColor} data-testid="app-icon">
                {iconText || ''}
              </Styles.ProductIcon>
            )}
            <Styles.CardTitle>
              {name}
              {!isEnabled && (
                <Styles.WarningMessage>
                  <Styles.WarningIcon />{t('baseCard.driveWarningMsg')}
                </Styles.WarningMessage>
                )}
            </Styles.CardTitle>
          </Styles.CardHeader>
          { showDescription && <Styles.CardDescription>{description}</Styles.CardDescription> }
        </div>
        <Styles.IconWrap data-id='icon-card-more'>
          <IconButton ref={moreRef}
            dataTestId="bc-more-btn"
            icon={<IconMoreVert />}
            onClick={onMoreClick}
          />
        </Styles.IconWrap>
        <Menu labelRef={moreRef} dataTestId="bc-menu-more">
          {menuOptions.map((option, ind) => (
            <MenuListItem
              key={ind}
              isSelected={false}
              dataTestId={`lm-mn-${ind}`} 
              graphicContent={option.icon}
              onClick={(e:MouseEvent) => { clickByMenu=true; onMenuClick(option); }}
            >
              {option.label}
            </MenuListItem>
          ))}
        </Menu>
      </Styles.Card>
    </>
  );
}
