import {StateCreator} from 'zustand';


type MouseCordinates = { clickX: string | number, clickY: string | number }


export interface ICreatePaneManagementSlice {
    applicationCardMousePosition: { clickX: string | number, clickY: string | number }
    setApplicationCardMousePosition: (mouseCordinates: MouseCordinates) => void
}


export const createPaneManagementSlice: StateCreator<ICreatePaneManagementSlice,
    [['zustand/devtools', never]],
    [],
    ICreatePaneManagementSlice> = (set) => {

    return {
        applicationCardMousePosition: {clickX: '', clickY: ''},
        setApplicationCardMousePosition: (mouseCordinates) => set(() => ({applicationCardMousePosition: mouseCordinates})),
    };
};