import { CardList } from "../styles";
import ApplicationCard from "../Cards/ApplicationCard";
import { CardMenu } from "../Cards/BaseCard";
import { CustomApps } from "./UserCards";
import EmptyStateCard from "../Cards/EmptyStateCard";

export function UserCardsList( { userCardList, menuOptions, menuHandler } : { 
                userCardList: CustomApps, 
                menuOptions: CardMenu[],
                menuHandler: Function}) {
    return (
        <>
            <CardList>
                { 
                    userCardList?.apps?.length === 0 ? 
                        <EmptyStateCard isUserCard /> :
                        userCardList?.apps?.map((app) => <ApplicationCard
                            key={app.appId}
                            name={app.name || ''}
                            description={app.description || ''}
                            url={app.url || ''}
                            isEnabled
                            iconColor="#000"
                            onCardOpen={() => window.open(app.url, "_blank")}
                            iconText={app.iconText || ''}
                            id={app.appId || ''}
                            menu={menuOptions}
                            menuHandler={ (option:CardMenu) => { menuHandler(option, { ...app })} }
                    />)
                }
            </CardList>

        </>
    )
}