import { useMutation, useQuery, useQueryClient } from "react-query";
import * as notificationApi from './notificationApi'
import useAuthToken from "../../../../hooks/useAuthToken";
import { useUnifyAdminStore } from '../../AdminStore';

export function useGetAllNotifications(isArchive: boolean) {
    const token = useAuthToken();
    return useQuery(
        !isArchive ? 'getAllOutageNotifications' : 'getAllArchiveOutageNotifications',
        () => !isArchive ?
            notificationApi.fetchListOfOutageNotification(token)
            : notificationApi.fetchListOfArchiveOutageNotification(token),
    )
}

export const useCreateQuery = ({ onSuccess, onError }: any) => {
    const token = useAuthToken();
    const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
        (state) => state.resetOutageNotifcationSideSheetState
    );
    const queryClient = useQueryClient();

    const mutation = useMutation((model: any) =>
        notificationApi.createNotification(token, model), {
        onError: (error: any) => {
            onError();
            console.error(error);
        },
        onSuccess: (data: any) => {
            onSuccess();
            queryClient.invalidateQueries(['getAllOutageNotifications']);
            resetOutageNotifcationSideSheetState();
        },
    });

    return { mutation };
};


export const useUpdateQuery = ({ onSuccess, onError }: any) => {
    const token = useAuthToken();
    const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
        (state) => state.resetOutageNotifcationSideSheetState
    );
    const queryClient = useQueryClient();

    const mutation = useMutation((model: any) =>
        notificationApi.updateNotification(token, model), {
        onError: (error: any) => {
            onError();
            console.error(error);
        },
        onSuccess: (data: any) => {
            onSuccess();
            queryClient.invalidateQueries(['getAllOutageNotifications']);
            resetOutageNotifcationSideSheetState();
        },
    });

    return { mutation };
};


export const useRemoveQuery = ({ onSuccess, onError }: any) => {
    const token = useAuthToken();
    const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
        (state) => state.resetOutageNotifcationSideSheetState
    );
    const queryClient = useQueryClient();

    const mutation = useMutation((model: any) =>
        notificationApi.removeNotification(token, model), {
        onError: (error: any) => {
            onError();
            console.error(error);
        },
        onSuccess: (data: any) => {
            onSuccess();
            queryClient.invalidateQueries(['getAllOutageNotifications']);
            resetOutageNotifcationSideSheetState();
        },
    });

    return { mutation };
};
