import { useRef, useState, useEffect } from 'react';
import {
  Menu,
  MenuListItem,
  Input,
  IconArrowDropUp,
  IconArrowDropDown,
} from 'cdk-radial';
import styled from 'styled-components';
import { Option } from './types';
import * as StyledComponents from '../../StyledComponents';

const StyledInput = styled(Input)`
  input {
    caret-color: transparent;
  }
  svg {
    cursor: pointer;
  }
`;

export const MultiSelectDropdownMenu = (props: any) => {
  const { options, onSelect, placeholder, label, name, value, isDisabled } = props;
  const targetRef = useRef();
  const dimensionRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(value);

  const isAnySelected = selectedOptions.length > 0;
  const isAllSelected =
    selectedOptions.length > 0 && selectedOptions.length === options.length;
  const isIndeterminate = !isAllSelected && isAnySelected;

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleSelect = (option: Option) => {
    const selection = selectedOptions.find(
      (opt: any) => opt.label === option.label
    )
      ? selectedOptions.filter((opt: any) => opt.label !== option.label)
      : [...selectedOptions, option];

    setSelectedOptions(selection);
    onSelect(selection);
  };

  const handleAllSelect = () => {
    if (!isAnySelected) {
      const optionsExcludingDisabled = options.filter(
        (option: any) => !option.isDisabled
      );
      setSelectedOptions(optionsExcludingDisabled);
      onSelect(optionsExcludingDisabled);
      return;
    }
    setSelectedOptions([]);
    onSelect([]);
  };

  const handleOpen = () => setIsOpen((isOpen) => !isOpen);

  const getIsOptionSelected = (option: Option) =>
    selectedOptions.some((opt: any) => opt.value === option.value);

  const getValue = () => {
    if (isIndeterminate) {
      return selectedOptions.length === 1
        ? selectedOptions[0].label
        : `${selectedOptions.length} selected`;
    }
    if (isAllSelected) {
      return 'All selected';
    }
    return '';
  };

  return (
    <>
      <StyledComponents.MultiSelectDropdownInput>
        <StyledInput
          icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
          id={`${name}-id`}
          label={label}
          labelRef={dimensionRef}
          name={name}
          onChange={() => {}} // onChange is required but in this case we don't need it
          placeholder={placeholder}
          ref={targetRef}
          value={getValue() || ''}
          isDisabled={isDisabled}
        />
      </StyledComponents.MultiSelectDropdownInput>
      {options.length > 0 && (
        <Menu
          dimensionRef={dimensionRef}
          isAuto={false}
          labelRef={targetRef}
          multi
          onClose={handleOpen}
          onOpen={handleOpen}
          style={{ zIndex: 1000 }}
        >
          <MenuListItem
            onClick={handleAllSelect}
            isSelected={isAllSelected || isIndeterminate}
            indeterminate={isIndeterminate}
            showDivider
            hideSelectedItemCheckmark={false}
          >
            Select All
          </MenuListItem>
          {options.map((option: any, index: any) => {
            return (
              <MenuListItem
                key={`${option.label}-${index}-1`}
                isSelected={getIsOptionSelected(option)}
                onClick={() => handleSelect(option)}
                hideSelectedItemCheckmark={false}
              >
                {option.label}
              </MenuListItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};
