import { WorkflowViewMap, Workflow, Application, PaneData } from '../types';
import pick from 'lodash/fp/pick';

export function workflowListToWorkflowViewMap(
  workflows: Workflow[]
): WorkflowViewMap {
  // parse out appNames from list and domains from appNames
  return workflows.reduce((map, workflow) => {
    const domain = workflow.domain;
    // using first appName for now
    // assuming appName to be a required property could validate upstream
    const { appName } = workflow;

    // if there's no domain the array of workflows is stored at map[appName]
    if (!domain) {
      map[appName] = [...(map[appName] || []), workflow];
      // otherwise it's stored at map[appName][domain]
    } else {
      if (!map[appName]) map[appName] = {};
      map[appName][domain] = [...(map[appName][domain] || []), workflow];
    }
    return map;
  }, {} as any);
}

export const workflowOrApplicationToPaneData = pick<
  Application | Workflow,
  keyof PaneData
>(['id', 'name', 'url', 'iconText', 'icon', 'logoutUrl', 'appId']);

export function appsToAppsByName(apps: Application[]) {
  return apps.reduce((acc: Record<string, Application>, curr: Application) => {
    return {
      ...acc,
      [curr.name]: curr,
    };
  }, {});
}
