import { useState, useEffect } from 'react';
import { UnifyAdminContainer } from '../Containers/index';
import CMSAdminContainer from '../CampaignManagement/Containers/CMSAdminContainer';
import OutageAdminContainer from '../Notification/Containers/Outage/OutageAdminContainer';
import FeatureAdminContainer from '../Notification/Containers/Feature/FeatureAdminContainer';
import { useUnifyAdminStore } from '../AdminStore';
import * as StyledComponents from '../StyledComponents/index';
import { NoPermissionToAccess } from '../CommonComponents/NoPermissionToAccess';

interface TabItem {
  id: string;
  panelId: string;
  renderPanel: any;
  renderTab: any;
}

export default function AdminContainerTab(): JSX.Element {
  const permissions = useUnifyAdminStore(state => state.permissions); 
  const [tabItems, setTabItems] = useState<TabItem[]>([]);

  useEffect(() => {
    let tabs: TabItem[] = [];
    if (
      permissions?.hasAppReadOnlyPermissions ||
      permissions?.hasAppUpdatePermissions ||
      permissions?.hasAppSuperAdminPermissions
    ) {
      tabs.push({
        id: 'tab-1',
        panelId: 'panel-1',
        renderPanel: (props: any) => (
          <StyledComponents.StyledTabPanel {...props}>
            <UnifyAdminContainer />
          </StyledComponents.StyledTabPanel>
        ),
        renderTab: (props: any) => (
          <StyledComponents.StyledTab
            label="Applications & Workflows"
            {...props}
          />
        ),
      });
    }
    if (
      permissions?.hasCampaignReadOnlyPermissions ||
      permissions?.hasCampaignUpdatePermissions ||
      permissions?.hasCampaignSuperAdminPermissions
    ) {
      tabs.push({
        id: 'tab-2',
        panelId: 'panel-2',
        renderPanel: (props: any) => (
          <StyledComponents.StyledTabPanel {...props}>
            <CMSAdminContainer />
          </StyledComponents.StyledTabPanel>
        ),
        renderTab: (props: any) => (
          <StyledComponents.StyledTab
            label="Campaigns"
            {...props}
          ></StyledComponents.StyledTab>
        ),
      });
    }

    if (
      permissions?.hasProductAlertReadOnlyPermissions ||
      permissions?.hasProductAlertUpdatePermissions ||
      permissions?.hasProductAlertSuperAdminPermissions
    ) {
      tabs.push({
        id: 'tab-3',
        panelId: 'panel-3',
        renderPanel: (props: any) => (
          <StyledComponents.StyledTabPanel {...props}>
            <OutageAdminContainer />
          </StyledComponents.StyledTabPanel>
        ),
        renderTab: (props: any) => (
          <StyledComponents.StyledTab
            label="Product Alerts"
            {...props}
          ></StyledComponents.StyledTab>
        ),
      });
    }

    if (
      permissions?.hasReleaseReadOnlyPermissions ||
      permissions?.hasReleaseUpdatePermissions ||
      permissions?.hasReleaseSuperAdminPermissions
    ) {
      tabs.push({
        id: 'tab-4',
        panelId: 'panel-4',
        renderPanel: (props: any) => (
          <StyledComponents.StyledTabPanel {...props}>
            <FeatureAdminContainer />
          </StyledComponents.StyledTabPanel>
        ),
        renderTab: (props: any) => (
          <StyledComponents.StyledTab
            label="Feature Release"
            {...props}
          ></StyledComponents.StyledTab>
        ),
      });
    }

    setTabItems(tabs);
    // eslint-disable-next-line
  }, [permissions]);

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  return tabItems.length ? (
    <StyledComponents.StyledTabs
      activeItemIndex={activeItemIndex}
      alignment="left"
      items={tabItems}
      onActiveItemChange={setActiveItemIndex}
      tabsLabel="Unify Administrator"
    />
  ) : (
    <NoPermissionToAccess/>
  );
}
