import React, {useState} from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const EnterpriseExcludePermissionField = (): JSX.Element => {
    const permissions = useUnifyAdminStore(state => state.permissions);
    const [isValidEnterpriseId, setIsValidEnterpriseId] = useState<Boolean>(true)
    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );

    const enterprisesStr = get(sideSheetData, 'permissions.exclude_enterprise', ['']).join(',')

    const handleUpdatePermissionField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value
        });
    };
    const handleFieldBlur = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        const {value} = event.target;
        const isValid = /^E\d{6}(,E\d{6})*$/.test(value) || event.target.value === '';
        setIsValidEnterpriseId(isValid)
    };


    return (
        <StyledComponents.StyledPermissionContainer>
            <StyledComponents.StyledPermission
                dataTestId="exclude-permission-field-input"
                errorMessage="Enterprise Id starts with E and followed by 6 numbers"
                id="exclude-permission-field-input"
                inputDivider="left"
                label="Exclude"
                name="exclude_enterprise"
                onChange={handleUpdatePermissionField}
                onInputClear={function noRefCheck() {
                }}
                onBlur={handleFieldBlur}
                placeholder="Eg. E123221,E3422134"
                value={enterprisesStr}
                enableCustomValidation
                hasError={!isValidEnterpriseId}
                maxLength={80000}
                isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
            />
        </StyledComponents.StyledPermissionContainer>
    );
};
