
import AutocompleteDropdown from './AutocompleteDropdown';
import {
    useSearchUserIds,
    useGetUserAppAndWorkflowNames
  } from '../AdminStore/DataQueries/useAdminQueries';
import {useEffect, useState} from 'react';
import { useUnifyAdminStore } from '../AdminStore';
import UserDetailDialog from './UserDetailDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type DropdownValue = {
    label: string;
    value: string;
  };

export const createUserIdDropdownOptions = (
    data: any
  ): DropdownValue[] => {
    const options: any = [];
    data?.forEach((element : any) =>{
        options.push({
            label: element,
            value: element
          });
    });
    return options;
  };

  export const DEFAULT_OPTION = {
    label: '',
    value: ''
  };

const UserSearch = () => {

    const {data, isLoading } = useSearchUserIds();
    const {data:detail, isLoading:detailLoading} = useGetUserAppAndWorkflowNames();
    const setSearchQuery = useUnifyAdminStore((state) => state.setSearchQuery);
    const setUserId = useUnifyAdminStore((state) => state.setUserId);
    const [showDialog, setShowDialog] = useState(false);
    const [userList, setUserList] = useState<DropdownValue[]>([]);
    const [user, setUser] = useState<DropdownValue>({label:'',value:''});
    const [userDetail,setUserDetail] = useState<any>([]);
    const { t } = useTranslation();

    const onSearchQueryChange = (query: any) =>{
        setSearchQuery(query);
    }

    const onDropdownChange = (event: any) =>{
        setUser(event);
        setSearchQuery(event.value);
        setShowDialog(true);
        setUserId(event.value);
    }

    useEffect(() => {
        if (data) {
            setUserList(createUserIdDropdownOptions(data));
        }
    },[data]);

    useEffect(() => {
      if (detail) {
          setUserDetail(detail);
      }
  },[detail]);

    return (
        <div style={{ width: '320px' }} data-testid="autocomplete-inline">
        <AutocompleteDropdown
        id="user-search-dropdown"
        dataTestId="user-search-dropdown"
        onChangeInput={onSearchQueryChange}
        options={
            userList?.length
              ? userList
              : [DEFAULT_OPTION]
          }
        value={user}
        onChange={onDropdownChange}
        isLoading={isLoading}
        minChars={3}
        inputProps={
            {
              label:t('admin.user-search.userid'),
              placeholder:t('admin.user-search.placeholder')
            }
        }
        noRecordMsg={t('admin.user-search.no-user') as string}
        isHighlightRequired
      />
      { userDetail && 
          <UserDetailDialog 
          detail={userDetail}
          detailLoading={detailLoading}
          isOpen={showDialog} 
          userId={user.label} 
          onClose={()=>setShowDialog(false)}/>
      }
      </div>
    );
}


export {UserSearch};