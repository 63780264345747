import React from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const AbbreviationField = (): JSX.Element => {
    const permissions = useUnifyAdminStore(state => state.permissions);
    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );

    const appAbbrValue = get(sideSheetData, 'iconText', '');

    const handleUpdateAbbreviationField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <>
            <StyledComponents.StyledInput
                dataTestId="app-dr-abbr-input"
                errorMessage="Error message"
                id="app-dr-abbr-input"
                inputDivider="left"
                label="Icon Abbreviation "
                name="iconText"
                onChange={handleUpdateAbbreviationField}
                onInputClear={function noRefCheck() {
                }}
                placeholder="Icon Abbreviation"
                value={appAbbrValue}
                maxLength="3"
                isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}

            />
        </>
    );
};
