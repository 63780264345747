import { useSideSheet } from '../../contexts/SideSheet';
import { SideSheetHeader } from '../SideSheet/SideSheet';
import MeetMyTeamContainer from './MeetMyTeamContainer';
import { useTranslation } from 'react-i18next';

const MeetMyTeamMenu = () => {
  
  const { setSideSheetContent } = useSideSheet();
  const { t } = useTranslation();
  const toggle = () => setSideSheetContent(null);

  return (
    <>
      <SideSheetHeader
        name={t('meetmycdkteam.title')}
        toggle={toggle}
        dataTestId="meetmyteam-tab-header"
      />
      <MeetMyTeamContainer></MeetMyTeamContainer>
    </>
  );
}

export default MeetMyTeamMenu;