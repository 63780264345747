import styled from "styled-components";
import {
  Dialog,
  CustomScrollbarWrapper
} from 'cdk-radial';
import { IconButton } from '../Buttons/IconButton';
import { TextButton } from '../Buttons/TextButton';

export const StyledDefaultCDKIconText = styled.div<{ fontSize: string }>`
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ theme }) => theme.darkMode ? "black" : "white"};
`

export const StyledTextButton = styled(TextButton)`
    color: ${({ theme }) => theme.darkMode ? "black" : "white"};
`

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100% - 50px);
  overflow-x: hidden;
`;

export const StyledDialog = styled(Dialog)`
  min-width: 560px;
  max-width: 30%;
  margin-top: 60px;
  z-index: 9999;
  height: auto;
  max-height: 400px;
  @media (max-width: 470px) {
    min-width: 10vw;
    max-width: 100%;
    width: 92vw;
    top: 310px;
  }
  @media (max-width: 570px) {
    min-width: 10vw;
    max-width: 100%;
    width: 92vw;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDialogContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-height: 50px;
  max-height: 250px
  padding: 0px 10px
  @media (max-width: 470px) {
    padding-left: 0px;
  }
`;

export const StyledNotification = styled.div<{ hide?: boolean }>`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  min-height: auto;
  display: flex;
  row-gap: 6px;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
  display: ${props => props.hide ? 'none' : 'flex'};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.darkMode ? "#333" : "#F2F2F2"};
  }
  :active {
    background-color:${({ theme }) => theme.darkMode ? "#333" : "#E6E6E6"};
  }
  @media (max-width: 470px) {
    padding-left: 0px;
  }
`;

export const StyledNotificationContainer = styled.div`
  display:flex;
  justify-content:space-between;
`;

export const StyledNotificationDetail = styled.div<{ hide?: boolean }>`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  display: ${props => props.hide ? 'none' : 'block'}
`;

export const StyledSubject = styled.div<{ read: boolean }>`
  font-weight: ${props => props.read ? 400 : 600};
  font-size: 1rem;
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const StyledDetailSubject = styled.div<{ read: boolean }>`
  font-weight: ${props => props.read ? 400 : 600};
  font-size: 1rem;
`;

export const StyledMessage = styled.div`
  font-size: 14px;
  padding-bottom: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top:8px;
`;

export const StyledDetailMessage = styled.div`
  padding-bottom: 2px;
`;

export const StyledMessageDetail = styled.div`
  height: 90%;
  padding-bottom: 2px;
`;

export const StyledTime = styled.div`
  height: 1.2rem;
  font-size: 12px;
  margin-top: 8px;
  color: ${({ theme }) => (theme.darkMode ? '#B3B3B3' : '#666666')}; 
  @media (max-width: 800px) {
    display: none;
  }
`;



export const ProductContentIcon = styled.div<{ iconColor: string }>`
  display: flex;
  padding: 1.5px;
  align-items: flex-start;
  background-color: ${({ iconColor, theme }) => iconColor ? iconColor : theme.darkMode ? "white" : "black"};
  border-radius: 2px;
  box-sizing: border-box;
  color:white;
  border-color:"groove";
  grid-area: 1/1;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  font-size: '18px';
  font-weight: 400;
  letter-spacing: -0.06em;
  line-height: 18px;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-align: end;
  min-width: 2rem;
  fontSize :14px;
  width: 36px;
  height: 36px;
`;

export const StyledNotificationSheet = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  h2 {
    margin-bottom: 4px;
  };
  height:100%;
`;

export const StyledList = styled.div`
font-family: 'Montserrat', 'Roboto', sans-serif;
@media (max-width: 470px) {
  padding-left: 1rem;
}
`;

export const StyledNotificationTitle = styled.div`
  font-family: 'Montserrat', 'Roboto', sans-serif;
  display: flex;
  grid-gap: 8px;
  align-items: center;
  font-size: 16px;
`

export const StyledOutageNotificationBanner = styled.div`
  height: 37px;
  display: flex;
  background: rgb(254, 240, 220);
  border: 1px 0 1px 0;
  backdrop-filter: blur(3px);
  align-items: center;
  color: black;
  margin-right: 22px;
  @media (max-width: 1080px) {
    margin-left: 8px;
  }
`;

export const StyledOutageNotificationHeading = styled.div`
  height: 20px;
  font-weight: 700;
  margin-left: 15px;
  text-wrap: nowrap;
  @media (max-width: 470px) {
    margin-left: 5px;
  }
`;

export const StyledOutageNotificationSubject = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-basis: 40%;
  margin-left: 20px;
  @media (max-width: 500px) {
    margin-left: 10px;
  }
  @media (max-width: 470px) {
    display: none;
  }
`;

export const StyledOutageNotificationPagination = styled.div`
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  height: min-content;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 1000px) {
    margin-right: 35px;
  }
  @media (max-width: 470px) {
    width: 78px;
    margin-left: 10px;
  }
`;

export const StyledOutageNotificationContainerSpacer = styled.div`
  background: rgb(254, 240, 220);
  width: 100vw;
  justify-content: center;
  height: min-content;
  display: flex;
`;

export const StyledOutageNotificationContainer = styled.div`
  margin-top: 0px;
  max-width: 1280px;
  @media (max-width: 1280px) {
    max-width: -webkit-fill-available;
  }
  @media (max-width: 470px) {
    max-width: 99%;
  }
`;

export const StyledNoOutageNotificationBanner = styled.div`
min-width: 1280px;
`;

export const StyledNotificationTitleContainer = styled.div`
`

export const StyledNotificationTime = styled.div`
  flex-basis: 15%;
  margin-left: 20px;
  text-wrap: nowrap;
  @media (max-width: 800px) {
    display: none
  }
`;

export const StyledBanner = styled.div`
  @media (max-width: 800px) {
  }
`;


export const DeleteButton = styled(IconButton)`
width:12px;
height:12px; 
  &&& svg {
    width:18px;
  }
`;

export const NotificationColumn2 = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
`;

export const NotificationColumn1 = styled.div`
`;




export const NDContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  border-radius: 16px;
  border: ${({ theme }) => (theme.darkMode ? '2px solid #999999' : 'none')};
  bottom: 16px;
  box-shadow: ${({ theme }) => (theme.darkMode ? 'none' : '0px 8px 25px 2px rgba(0, 0, 0, 0.35)')}; 
  font-family: 'Montserrat', 'Roboto', sans-serif;
  height: auto;
  margin-right: 0px;
  position: fixed;
  top: 56px;
  right:8px;
  z-index: 1002;
  width: ${({ theme }) => (theme.darkMode ? '416px' : '420px')};
  background: ${({ theme }) => (theme.darkMode ? "black" : "white")};
  height: calc(100% - 65px);
  @media (max-width: 470px) {
    width:100vw;
    border-radius: 0px;
    right:0px;
    top: 52px;
  }
`;

export const NDHeader = styled.div`
  border-top-left-radius: 16px 16px;
  border-top-right-radius: 16px 16px;
  align-items: center;
  background: #000;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', 'Roboto', sans-serif;
  min-height: 48px;
  justify-content: space-between;
  line-height: 20px;
  padding: 0 4px;
  position: sticky;
  top: 0;
  z-index: 5;
  @media (max-width: 470px) {
    border-radius: 0px;
  }
`;

export const NDTitle = styled.div`
font-weight: 600;
font-size: 18px;
font-family: 'Montserrat', 'Roboto', sans-serif;
margin-top: 10px;
word-wrap: break-word;
color: ${({ theme }) => (theme.darkMode ? '#F2F2F2' : '#1A1A1A')}; 
`;

export const NDSubject = styled.div`
font-size: 14px;
font-family: 'Montserrat', 'Roboto', sans-serif;
word-wrap: break-word;
color: ${({ theme }) => (theme.darkMode ? '#E6E6E6' : '#333333')}; 
`;

export const NDSubTitle = styled.div`
font-family: 'Montserrat', 'Roboto', sans-serif;
font-size: 12px;
margin-top: 5px;
word-wrap: break-word;
color: ${({ theme }) => (theme.darkMode ? '#B3B3B3' : '#666666')}; 
`;

export const NDBodyContent = styled.div`
font-family: 'Montserrat', 'Roboto', sans-serif;
margin-top: 16px;
word-wrap: break-word;
`;

export const NDProductTitle = styled.div`
font-family: 'Montserrat', 'Roboto', sans-serif;
margin-top: 30px;
font-size: 18px;
font-weight: 600;
`;

export const NDFooter = styled.div`
font-family: 'Montserrat', 'Roboto', sans-serif;
display: flex;
justify-content: flex-end;
`;


export const NDProductContentIcon = styled.div<{ iconColor: string }>`
  display: flex;
  padding: 1.5px;
  align-items: flex-start;
  background-color: ${({ iconColor, theme }) => iconColor ? iconColor : theme.darkMode ? "white" : "black"};
  border-radius: 2px;
  box-sizing: border-box;
  color:white;
  border-color:"groove";
  grid-area: 1/1;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  font-size: '18px';
  font-weight: 400;
  font-family: 'Montserrat', 'Roboto', sans-serif;
  letter-spacing: -0.06em;
  line-height: 18px;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-align: end;
  min-width: 48px;
  fontSize :18px;
  width: 48px;
  height: 48px;
`;

export const NDBodyContainer = styled.div`
    font-family: 'Montserrat', 'Roboto', sans-serif;
    display:flex;
    flex-direction:column;
    margin:16px;
    justify-content:space-between;
    height:calc(100vh - 150px);;
`;

export const NDStyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100% - 50px);
  overflow-x: hidden;
  border-radius: 16px;
`;
