import * as Styles from './styles';
import { IconButton, IconClose } from 'cdk-radial';
import toTestId from '../../testUtils/toTestId';
import { useTheme } from '../../contexts/Theme';
import * as HStyles from '../History/styles';

interface Props {
  isActive: boolean;
  name: string;
  iconText: string;
  onOpenClick: () => void;
  onCloseClick: () => void;
  iconColor: string;
}

export default function OpenFramesCard({
  name,
  iconColor,
  iconText,
  isActive,
  onOpenClick,
  onCloseClick,
}: Props) {
  const { darkMode } = useTheme();
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCloseClick();
  };

  return (
    <HStyles.HistoryItemContent
      onClick={onOpenClick}
      data-testid={`history-item-${name}`}
    >
      <Styles.FrameCard
        className={isActive ? 'activeCard' : 'inactiveCard'}
        data-testid={`openFrameCard-${toTestId(name)}`}
      >
        <Styles.FrameCardHeader>
          <Styles.ProductIcon iconColor={iconColor}>
            {iconText || ''}
          </Styles.ProductIcon>
          <Styles.FrameCardLabel>{name}</Styles.FrameCardLabel>
          <Styles.CloseButton>
            <IconButton
              onClick={handleCloseClick}
              icon={<IconClose />}
              backgroundAppearance={darkMode ? 'dark' : 'light'}
            />
          </Styles.CloseButton>
        </Styles.FrameCardHeader>
        <Styles.CardFooter>{name}</Styles.CardFooter>
      </Styles.FrameCard>
    </HStyles.HistoryItemContent>
  );
}
