import * as StyledComponents from '../../StyledComponents';
import { OutageSideSheetDetails } from './OutageSideSheetDetails';

export const OutageSideSheetBody = (): JSX.Element => {
  return (
    <StyledComponents.StyledSideSheetBodyContainer>
      <OutageSideSheetDetails />
    </StyledComponents.StyledSideSheetBodyContainer>
  );
};
