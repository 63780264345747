import { StateCreator } from 'zustand';

export interface ICreateAdminGridSearchSlice {
  adminGridSearchValue: string;
  setAdminGridSearchValue: (searchValue: string) => void;
}

export const createAdminGridSearchSlice: StateCreator<
  ICreateAdminGridSearchSlice,
  [['zustand/devtools', never]],
  [],
  ICreateAdminGridSearchSlice
> = (set) => {
  return {
    adminGridSearchValue: '',
    setAdminGridSearchValue: (adminGridSearchValue) => {
      set((state) => ({
        adminGridSearchValue,
      }));
    },
  };
};
