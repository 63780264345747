import * as Styles from './styles';
import toTestId from '../../testUtils/toTestId';
import { useTranslation } from 'react-i18next';
import { IconButton, IconClose } from 'cdk-radial';
import { useShowDialog } from '../../contexts/AppStateContext';

interface Props {
     cardData: {
        isEnabled?: boolean;
        isFavorite?: boolean;
        description?: string;
        name?: string;
        iconText?: string;
        iconColor?: string;
        isIconRequired?: boolean;
        showDescription?: boolean;
        isOpen?: boolean;
        onClose?: Function
    }
}

export default function CardMoreDialog( { cardData }: Props) {
  const {t} = useTranslation();
  const { isOpen, name, iconColor, iconText, description, isEnabled, onClose } = cardData;
  const showDialog = useShowDialog();
  return (
    <Styles.LearnMoreDialog 
        isOpen={isOpen} 
        onClose={ onClose }
        hideHeader 
        disableOverlayClicked={false}>

        <Styles.LearnMoreCard
            data-testid={`more-card-${toTestId(name || '')}`}>
        <div>
            <Styles.CardHeader>
                {cardData.isIconRequired && (
                    <Styles.ProductIcon iconColor={iconColor || ''} data-testid="app-icon">
                    {iconText || ''}
                    </Styles.ProductIcon>
                )}
                <Styles.CardTitle>
                    {name}
                    {!isEnabled && (
                    <Styles.WarningMessage>
                        <Styles.WarningIcon />{t('baseCard.driveWarningMsg')}
                    </Styles.WarningMessage>
                    )}
                    <Styles.DialogCloseButton>
                        <IconButton icon={<IconClose />} onClick={() => showDialog('', {})} data-testid="close-button" />
                    </Styles.DialogCloseButton>
                </Styles.CardTitle>
            </Styles.CardHeader>
            <Styles.LearMoreDescription><Styles.LearMoreDevider data-testid="divider" />{description}</Styles.LearMoreDescription> 
        </div>
        </Styles.LearnMoreCard>
    </Styles.LearnMoreDialog>
  );
}
