import { Dialog, BUTTON_VARIANTS, BUTTON_SIZES } from 'cdk-radial';
import styled from 'styled-components';
import { SUPPORT_LINK } from '../../constants';
import { useTranslation,Trans } from 'react-i18next';

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  handleClose: () => void;
}

const StyledDialog = styled(Dialog)`
  max-width: 450px;
`;

export default function InfoDialog({
  isOpen,
  title,
  description,
  handleClose,
}: Props) {
  const {t} = useTranslation();
  return (
    <div data-testid="info-dialog-container">
      <StyledDialog
        buttonsProps={[
          {
            id: 'info-dialog-close',
            'data-testid': 'info-dialog-close-button',
            onClick: handleClose,
            text: t('dialog.ok'),
            iconColor: 'gray',
            variant: BUTTON_VARIANTS.PRIMARY,
            size: BUTTON_SIZES.LARGE,
          },
        ]}
        id="info-dialog"
        data-testid="info-dialog"
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
      >
        {description}
        <br />

        <Trans i18nKey="dialog.message">
          Please contact <a href={SUPPORT_LINK} target="_blank" rel="noreferrer"> CDK Support </a> to resolve the issue.
        </Trans>
        
      </StyledDialog>
    </div>
  );
}
