import * as StyledComponents from '../StyledComponents'
import {INPUT_SIZES, Divider, IconAdd} from 'cdk-radial';
import {useUnifyAdminStore} from '../../AdminStore';

const CreateCampaign = (): JSX.Element => {
    
    const setIsCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsCreateCampaignDialogOpen);

    return <>
     <StyledComponents.StyledButtonWithIcon
                onClick={() => setIsCreateCampaignDialogOpen(true)}
                variant='secondary'
                text='Create Campaign'
                icon={<IconAdd/>}
            />
    </>
}

export default function CampaginListHeader(props: {gridRef: any }): JSX.Element {

    /**
     * Data store
     */
    const searchValue = useUnifyAdminStore(state=>state.campaignSearchValue)
    const setCampaignSearchValue = useUnifyAdminStore(state=>state.setCampaignSearchValue)
    const permissions = useUnifyAdminStore(state => state.permissions) 

    const onExportDownloadButton = () => {
        props?.gridRef?.current?.api?.exportDataAsExcel({
            processRowGroupCallback: (params:any)  => {
                    return params.node.key;
                },
        });
    };

    return <>
    <Divider/>
        <StyledComponents.StyledHeaderContainer>
            <StyledComponents.StyledCampaignHeading headingType='subtitle-2' data-testid='campaign-list'>
                Campaigns
            </StyledComponents.StyledCampaignHeading>
            <StyledComponents.StyledCampaignHeading headingType='subtitle-2' padding='0px 15px'>
                    <StyledComponents.StyledIconFileDownload onClick={()=>onExportDownloadButton()} data-testid='export-search-result-icon' />
                </StyledComponents.StyledCampaignHeading>
        </StyledComponents.StyledHeaderContainer>
        
        <StyledComponents.StyledSearchBoxCampaignContainer>
            <StyledComponents.StyledSearchBox
                dataTestId="admin-search-input"
                className="customize-search-input"
                clearButtonLabel="clearButtonLabel"
                id="admin-grid-search-input-expanded"
                isAlwaysExpanded
                name="admin-grid-search-input-expanded"
                onChange={(searchValue: string) => setCampaignSearchValue(searchValue)}
                placeholder="Search"
                size={INPUT_SIZES.STANDARD}
                value={searchValue}
            />
            {permissions?.hasCampaignSuperAdminPermissions && <CreateCampaign/>}
        </StyledComponents.StyledSearchBoxCampaignContainer>

    </>
}