import React, {Dispatch, SetStateAction,useState} from 'react';
import {  useEncryptParams, useFetchDMSBoxMapping } from '../../hooks/queries';
import styled from 'styled-components';
import {IOktaContext} from '@okta/okta-react/bundles/types/OktaContext';
import {useOktaAuth} from '@okta/okta-react';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import {
    DropdownMenu,
    Dialog,
    IconOpenInNew,
    Heading,
    HEADING_TYPES,
    Loader
} from 'cdk-radial';
import {CNumber} from '../../types';
import {driveLaunchFallback} from '../../util/driveLaunchFallback'
import { getDMSProxyFirewallUrl, getDMSProxyFirewallUrlEncrypted} from '../../util/getDMSProxyUrls';
import { useAnalytics } from 'dms-analytics';
import { AMPLITUDE_EVENTS } from '../../constants';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

const StyledDMSTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDialog = styled(Dialog)``;

const StyledDropDownMenu = styled(DropdownMenu)`
  max-width: 100%;
`;

const StyledIconOpen = styled(IconOpenInNew)`
  margin-right: 0.6rem;
`;

const StyledWithLoader = styled.div`
    display: flex;
`

interface IMultipleDMSDialog {
    shouldDialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    dmsCNumbers: CNumber[];
    isFetching: boolean;
}

const DMSDialogTitle = (): JSX.Element => {
    const {t} = useTranslation();
    return (
        <StyledDMSTitleContainer>
            <>
                <StyledIconOpen height="36" width="36"/>
            </>
            <Heading headingType={HEADING_TYPES.SUBTITLE_1}>
                {t('multipleDMSDialog.heading')}
            </Heading>
        </StyledDMSTitleContainer>
    );
};

export const MultipleDMSDialog = ({shouldDialogOpen, setDialogOpen, dmsCNumbers, isFetching }: IMultipleDMSDialog): JSX.Element => {
    /** Internal State*/
     const [isLaunchingDrive, setIsLaunchdingDrive] = useState<boolean>(false)
    /**
     * Hooks
     */
    const [selectedDMS, setSelectedDMS] = React.useState<Partial<CNumber>>();
    const session: IOktaContext = useOktaAuth();
    const {t} = useTranslation();
    const { data: dmsBoxes } = useFetchDMSBoxMapping();
    const authToken = get(session, 'authState.idToken.idToken', '');
    const { trackEvent } = useAnalytics();
    const ipAddressFlag = useFeatureFlag('UNIFY_ENCRYPT_IPADDRESS');
    const { data: encryptedParams } = useEncryptParams();

    const remapDMSData = () => {
        return dmsCNumbers?.map((cNumber) => {
            const dmsBoxAliasName =  dmsBoxes?.dmsBoxMappings
                .find(eachBoxDetails=>(eachBoxDetails?.cnumber as string)
                    .toLowerCase() === (cNumber.cNumber).toLowerCase()) // IKR, but EIS is sending in uppercase so equalizing to make is consisitent
                ?.alias || ''
            return {
                'data-testid': cNumber.cNumber,
                label: `${cNumber.cNumber} ${dmsBoxAliasName && `- ${dmsBoxAliasName}`}`,
                value: cNumber.cNumber,
                dmsUserId: cNumber.dmsUserId,
                dmsBoxAliasName: dmsBoxAliasName.toLowerCase()
            };
        });
    }

    const withSortByAliasNameRemapDMSData = () =>{
        let dmsData = remapDMSData();
        dmsData?.sort((a:any, b:any) => a.dmsBoxAliasName?.localeCompare(b.dmsBoxAliasName));
        return dmsData;
    }

    const handleSelectDMS = (selectedValue: Partial<CNumber[]>) => setSelectedDMS(selectedValue as unknown as CNumber);

    const handleOnOpenDMSClick =  async () => {
        const {url: dmsProxyUrl} = getDMSProxyFirewallUrl({
            cNumber: selectedDMS?.value,
            driveToken: authToken,
        });

        trackEvent(AMPLITUDE_EVENTS.OPEN_DRIVE, {
            'C-Number': selectedDMS?.value, 'Name' : selectedDMS?.label
          });

        setIsLaunchdingDrive(true)
        /**
         * Logic at Drive side to open it via custom Portal, hence assume that Registry edit is in place and
         *  cdkdrive://url will invoke the Drive installed or launch it.
         *  func driveLaunchFallback accepts the dms proxy url
         *  @returns new Promise<{isProcessing:boolean,status:string, uri:string}>
         */
        try{
            const { url: encryptedUrl} = getDMSProxyFirewallUrlEncrypted({ driveToken: encryptedParams?.data });
            const driveLaunchResponse = await driveLaunchFallback( (ipAddressFlag && encryptedUrl) ? encryptedUrl : dmsProxyUrl);
            console.log(driveLaunchResponse,':drive launch')
        }
        catch (e){
            setIsLaunchdingDrive(true)
        }
        finally {
            setIsLaunchdingDrive(false)
            return setDialogOpen(false)
        }
    };

    return (
        <>
            <StyledDialog
                dataTestId="multiple-dms-dialog"
                id="multiple-dms-dialog"
                onClose={() => setDialogOpen(false)}
                title={<DMSDialogTitle/>}
                buttonsProps={[
                    {
                        id: 'multiple-dms-dialog-cancel-btn',
                        onClick: () => setDialogOpen(false),
                        text: t('common.cancel'),
                        variant: 'text',
                    },
                    {
                        id: 'multiple-dms-dialog-open-dms-btn',
                        onClick: handleOnOpenDMSClick,
                        text: isFetching
                            ? t('multipleDMSDialog.fetching')
                            : t('multipleDMSDialog.openDMS'),
                        variant: 'text',
                        isDisabled: !selectedDMS || isLaunchingDrive,
                    },
                ]}
                isOpen={shouldDialogOpen}
            >
                <StyledWithLoader data-testid='multi-dms-dialog-container'>
                    <StyledDropDownMenu
                        dataTestId="dms-version-drop-down"
                        errorMessage={t('multipleDMSDialog.dropDownError')}
                        inputId="exposed-dropdown"
                        label={t('multipleDMSDialog.dropDownDMSVersion')}
                        onChange={(val: Partial<CNumber[]>) => handleSelectDMS(val)}
                        options={withSortByAliasNameRemapDMSData()}
                        placeholder={
                            isFetching
                                ? t('multipleDMSDialog.dropDownDMSFetching')
                                : t('multipleDMSDialog.dropDownDMSSelect')
                        }
                        value={selectedDMS}
                        menuPortalTarget={document.body} // dialog opens in portal , portal takes zIndex override , hence this.
                        //cdk-radial has open ticket on this
                        styles={{
                            menuPortal: (base: any) => ({...base, zIndex: 9999}), // dialog is always a prime focus hence max z-index
                        }}
                    />
                    { isLaunchingDrive && <Loader label='Launching Drive...' /> }
                </StyledWithLoader>
            </StyledDialog>
        </>
    );
};

export default MultipleDMSDialog;

