import OutageNotificationBanner from './OutageNotificationBanner';
import {useNotificationStore} from './NotificationStore';
import {StyledOutageNotificationContainer,StyledNoOutageNotificationBanner, StyledOutageNotificationContainerSpacer} from './styles';
import {useState} from 'react';
import {NotificationDetail} from './NotificationDetail';
import {useSideSheet} from '../../contexts/SideSheet';
import { INotification } from './Notification';
import { SIDESHEET_CONTENT } from '../../constants';
import { NotificationState } from './NotificationState';

const OutageNotificationContainer = (): JSX.Element => {
    const { setSideSheetContent } = useSideSheet();

    const outageNotifications = useNotificationStore((state:NotificationState) => state.outageNotifications);
    const closeOutageBanner = useNotificationStore((state:NotificationState) => state.closeOutageBanner);

    const [showDetail, setShowDetail] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<INotification>();

    const onSideSheetClose = () => setShowDetail(false);

    const onLearnMoreClick=(notification:INotification) =>{
        setShowDetail(true);
        setSelectedNotification(notification)
    }

    const onbackHandler = () => {
        setShowDetail(false);
        setSideSheetContent(SIDESHEET_CONTENT.NOTIFICATION);
    }

    return (
    <StyledOutageNotificationContainerSpacer>
        <StyledOutageNotificationContainer data-testid="outage-notification-container">
                { outageNotifications.length > 0 && <StyledNoOutageNotificationBanner/>}
                { outageNotifications &&
                    outageNotifications.length > 0 && 
                    <OutageNotificationBanner 
                        onClose={closeOutageBanner} 
                        onLearnMoreClick={onLearnMoreClick}
                        items={outageNotifications}/> }
                {showDetail && 
                    <NotificationDetail
                        onClose={onSideSheetClose}
                        notification={selectedNotification!}
                        backHandler={onbackHandler} /> }
    
            </StyledOutageNotificationContainer>
            </StyledOutageNotificationContainerSpacer>
 )
    };
                export default OutageNotificationContainer;
