import React,{ useCallback, useState, useMemo } from 'react';
import {createBrowserHistory} from 'history';
import {useOktaAuth} from '@okta/okta-react';
import { Menu, MenuItem, MenuDivider, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {useAnalytics} from 'dms-analytics';
import * as Styles from './styles';
import {useSideSheet} from '../../contexts/SideSheet';
import {useIamUserProfileStore} from '../../Store';

import {
    SIDESHEET_CONTENT,
    SideSheetContent,
    AMPLITUDE_EVENTS,
    REACT_APP_AHA_IDEA_WIDGET,
    LOGOUT_URL
} from '../../constants';
import {
    withTooltip,
    IconHelp,
    IconLogout,
    IconPeople,
    IconAccountCircle,
    THEMES,
    AvatarWrapper,
    AVATAR_SIZES,
    Content,
    createIconWithPath
} from 'cdk-radial';
import {usePaneManagement} from '../../contexts/PaneManagementContext';
import {useUpdateUserSettings, useUserProfile} from '../../hooks/queries';
import {useTranslation} from 'react-i18next';
import IconNotificationButton from './IconNotificationButton';
import {useStore} from './HeaderStore';
import ThemeProvider from '../ThemeProvider/ThemeProvider';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import { LOGOUT_STATUS } from '../MessageProvider/MessageProvider';
import { useTheme } from '../../contexts/Theme';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk'

const history = createBrowserHistory({forceRefresh: true});

export const generateTooltipProps = (iconName:string, displayName:string) => ( 
    {id:`header-tooltip-${iconName}`,text:displayName, theme:THEMES.LIGHT,styles:{ zIndex:1002, display: window.innerWidth < 501 && 'none' }} )
    
export default function Header() {
  const { setSideSheetContent, sideSheetContent } = useSideSheet();
  const { activePane, openPanes, toggleIsPaneExpanded ,isPaneExpaneded} = usePaneManagement();
  const { refetch, isError } = useUserProfile();
  const { oktaAuth } = useOktaAuth();
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation();
  const meetMyTeamFlag = useFeatureFlag('UNIFY_UI_MEET_MY_TEAM');
  const uiAha = useFeatureFlag('UNIFY_UI_AHA');
  const setHeaderIconClicked = useStore((state:any) => state.setHeaderIconClicked);
  const HeaderHelpIcon =  withTooltip(Styles.HeaderButton, generateTooltipProps('Help',t('help.toolTip')));
  const HeaderHistoryIcon = useMemo(() => {
    const tooltipProps = generateTooltipProps('History', t('history.toolTip'));
          return withTooltip(Styles.HeaderButton, tooltipProps);
      }, [t]);
  const CDKGlobalLogo = withTooltip(Styles.Logo, generateTooltipProps('CDK Global Logo',t('cdklogo.toolTip')));
  const { data:oktaUser } = useUserProfile();
  const [ systemTheme, setSystemTheme ] =  useState(localStorage.getItem('use-system-theme') === 'yes');
  const nameAbbr = (oktaUser?.profile?.firstName || '').substring(0,1) + (oktaUser?.profile?.lastName || '').substring(0,1);
  const { setLogoutStatus } = useChannelMessage()
  const { mutate } = useUpdateUserSettings();
  const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const { darkMode } = useTheme();
  const useSystemTheme =  localStorage.getItem('use-system-theme') === 'yes';
  const iamUserInfo = useIamUserProfileStore((state:any) => state.iamUserInfo);

  const adminRole = iamUserInfo.fullUser && iamUserInfo.fullUser.roles && 
                    iamUserInfo.fullUser.roles.find(
                        (role:any) => role.roleName.toLowerCase() === "enterprise administrator");

  // Custom Theme Icon SVG path, 
  const ThemeIcon = createIconWithPath([{
    d: "M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z",
    fillRule: "evenodd",
  }]);

  const BulbIcon = createIconWithPath([{
    d: "M9 21C9 21.55 9.45 22 10 22H14C14.55 22 15 21.55 15 21V20H9V21ZM12 2C8.14 2 5 5.14 5 9C5 11.38 6.19 13.47 8 14.74V17C8 17.55 8.45 18 9 18H15C15.55 18 16 17.55 16 17V14.74C17.81 13.47 19 11.38 19 9C19 5.14 15.86 2 12 2ZM14.85 13.1L14 13.7V16H10V13.7L9.15 13.1C7.8 12.16 7 10.63 7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 10.63 16.2 12.16 14.85 13.1Z",
    fillRule: "evenodd"
  }]);

  React.useEffect(() => {
        if (isError && oktaAuth) {
            const idToken = oktaAuth.getIdToken();
            oktaAuth.tokenManager.clear();
            localStorage.clear();
            oktaAuth.signOut({
                postLogoutRedirectUri: `${LOGOUT_URL}?source=unify&id_token_hint=${idToken}`
            });
            window.location.reload();
        }
    }, [isError, oktaAuth, openPanes, sideSheetContent, activePane]);
    const toggleTheme = useCallback((theme: 'light' | 'dark') => {
        mutate({ theme });
    }, [mutate]);
    React.useEffect(() => {
        const sysThemeChangeListener = (e:{matches: boolean}) => {
            toggleTheme( e.matches ? "dark" : "light");
        };
        systemTheme && themeMediaQuery?.addEventListener('change', sysThemeChangeListener);
        return () => {
            themeMediaQuery?.removeEventListener('change', sysThemeChangeListener);
        }
    }, [systemTheme, themeMediaQuery, toggleTheme]);

    const handlePane = () => {
        const adminPath = /^\/admin/;
        if (adminPath.test(window.location.pathname)) {
            return history.push('/')
        }
        toggleIsPaneExpanded();
        activePane && trackEvent(AMPLITUDE_EVENTS.OPEN_PREVIOUS);
        refetch();
    };

    const genSideSheetHandler = (content: SideSheetContent) => () => {
        content === 'profile' && trackEvent(AMPLITUDE_EVENTS.OPEN_PROFILE);
        content === 'meet_my_cdk_team' && trackEvent(AMPLITUDE_EVENTS.OPEN_MEET_MY_CDK_TEAM);
        content === 'notification' && trackEvent(AMPLITUDE_EVENTS.VIEW_NOTIFICATIONS);
        setSideSheetContent(content);
        if (content && activePane) {
            refetch();
        }
        setHeaderIconClicked(true);
    };
    const Count = openPanes?.length || 0;
    const MenuHandler = (menuAction:string) => {
        switch(menuAction) {
            case 'PROFILE':
                genSideSheetHandler(SIDESHEET_CONTENT.PROFILE)();
                break;
            case 'MEET_MY_CDK_TEAM':
                genSideSheetHandler(SIDESHEET_CONTENT.MEET_MY_CDK_TEAM)();
                break;
            case 'THEME_LIGHT':
            case 'THEME_DARK':
            case 'THEME_SYSTEM':
                handleThemeChange(menuAction);
                break;
            case 'SHARE_AN_IDEA':
                handleAhaWidget();
                break;
            case 'SIGNOUT':
                setLogoutStatus(LOGOUT_STATUS.LOGOUT_INIT);
                break;
        }
    }

    const handleAhaWidget = () => {
        const aha = window.aha;
        aha && aha('open', REACT_APP_AHA_IDEA_WIDGET);
    };

    const handleThemeChange = (theme: string) => {
        // Remove system theme change handler if already listening
        localStorage.setItem('use-system-theme', 'no');
        let isDarkMode = theme === 'THEME_DARK';
        if(theme === 'THEME_SYSTEM') { 
            isDarkMode = themeMediaQuery?.matches;
            setSystemTheme(true);
            localStorage.setItem('use-system-theme', 'yes');
        }
        else {
            setSystemTheme(false);
        }
        toggleTheme( isDarkMode ? 'dark' : 'light' );
        trackEvent(AMPLITUDE_EVENTS.OPEN_PROFILE, {
            'Dark Mode': isDarkMode
        })
    };

    const prifileTitle = t('profile.toolTip');

    return (
        <Styles.PageHeader>
            <ThemeProvider>
            <div>
                {
                    isPaneExpaneded && Count ? 
                    <CDKGlobalLogo
                    height="32"
                    viewBox="0 0 300 74"
                    width="116"
                    text={t('cdklogo.tooltip')}
                    data-testid="global-header-logo"
                    onClick={handlePane}
                    onTouch={handlePane}
                   />
                 :
                 <Styles.Logo
                    data-testid="global-header-logo"
                    onClick={handlePane}
                    height="32"
                    viewBox="0 0 300 74"
                    width="116"
                />
                }
            </div>
                <Styles.PageHeaderIcons>
                        <HeaderHistoryIcon
                            onClick={genSideSheetHandler(SIDESHEET_CONTENT.HISTORY)}
                            backgroundAppearance={'dark'}
                            icon={<Styles.ApplicationAnimatedIcon/>}
                            text={Count}
                            className={!isPaneExpaneded && 'animated-apps-icon'}
                            dataTestId="header-icon-history"
                        />
                    <HeaderHelpIcon
                        onClick={genSideSheetHandler(SIDESHEET_CONTENT.HELP)}
                        backgroundAppearance={'dark'}
                        icon={<IconHelp/>}
                        text={t('header.help')}
                        dataTestId="header-icon-help"
                    />
                        <IconNotificationButton onClick={genSideSheetHandler(SIDESHEET_CONTENT.NOTIFICATION)}/>
                        <Menu 
                            boundingBoxPadding={'0 4px 0 0'} 
                            portal={true} align='start' 
                            id="szh-menu" 
                            transition
                            onItemClick={ (e) => MenuHandler(e.value)}
                            menuButton={ <div data-testid="header-menu-profile-icon"><AvatarWrapper title={ prifileTitle } size={AVATAR_SIZES.SMALL}
                                            style={{ backgroundColor: '#F2F2F2' }}>
                                            <Content style={{
                                                    color: 'black',
                                                    cursor: 'pointer',
                                                }}>{nameAbbr}</Content>
                                        </AvatarWrapper></div>} >
                        <MenuItem data-testid="header-menu-profile" value="PROFILE" ><IconAccountCircle/> <Styles.MenuText>{ t('header.viewProfile') }</Styles.MenuText></MenuItem>
                        
                        <SubMenu label={<><ThemeIcon style={ { transform: 'rotate(180deg)' } } /> <Styles.MenuText data-testid="header-menu-theme">{ t('header.theme') }</Styles.MenuText></>}>
                            <MenuItem  value="THEME_LIGHT">{  (!useSystemTheme && !darkMode ) && <Styles.CheckIcon />}<Styles.SubMenuText data-testid="header-menu-theme-light">{ t('header.light') }</Styles.SubMenuText></MenuItem>
                            <MenuItem  value="THEME_DARK">{  (!useSystemTheme && darkMode ) && <Styles.CheckIcon /> }<Styles.SubMenuText data-testid="header-menu-theme-dark">{ t('header.dark') }</Styles.SubMenuText></MenuItem>
                            <MenuItem  value="THEME_SYSTEM">{ useSystemTheme && <Styles.CheckIcon /> } <Styles.SubMenuText data-testid="header-menu-theme-system">{ t('header.system') }</Styles.SubMenuText></MenuItem>
                        </SubMenu>
                       {uiAha && <MenuItem data-testid="header-menu-idea" value="SHARE_AN_IDEA" ><BulbIcon /> <Styles.MenuText>{ t('header.submitIdea') }</Styles.MenuText></MenuItem>}
                        
                        {   
                        meetMyTeamFlag && adminRole && 
                                <MenuItem  value="MEET_MY_CDK_TEAM"><IconPeople /><Styles.MenuText data-testid="meetmyteam-menu">{ t('header.meetMyCdkTeam') }</Styles.MenuText></MenuItem>
                            }
                        <MenuDivider />    
                        <MenuItem  value="SIGNOUT"><IconLogout /><Styles.MenuText data-testid="header-menu-signout">{ t('header.signout') }</Styles.MenuText></MenuItem>
                    </Menu>
                </Styles.PageHeaderIcons>
            </ThemeProvider>
        </Styles.PageHeader>
    );
}
