/**
 * Drive IE compatibility has been removed , hence now can open drive with driver invokation with windows dll
 * example : {Drive full URL/bin/start/wsStart.application}/?ID_TOKEN=${driveToken}
 */

// const dmsProxyDNS = 'dms.drive.connectcdk.com';
const dmsProxyDNS = 'dms.drive.connectcdk.com/bin/start/wsStart.application';
export const  CUSTOM_PORTAL = 'cdkdrive';


type MakeDMSProxyFirewallUrl = {
  cNumber: string;
  driveToken: string;
};

type ReturnUseDMSProxyUrl = {
  url: string;
};

export const getDMSProxyFirewallUrl = ({
  cNumber = '',
  driveToken = ''
}: Partial<MakeDMSProxyFirewallUrl>): ReturnUseDMSProxyUrl => {
  const url = `https://${cNumber}-${dmsProxyDNS}?ID_TOKEN=${driveToken}`;
  return { url };
};

export const getDMSProxyFirewallUrlEncrypted = ({
  cNumber = '',
  driveToken = ''
}: Partial<MakeDMSProxyFirewallUrl>): ReturnUseDMSProxyUrl => {
  const url = `https://${cNumber}-${dmsProxyDNS}?PARAMS=${driveToken}`
  return { url };
};
