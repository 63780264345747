import React from 'react'
import {
    TextButton,
    IconOtherReports,
    PrimaryButton,
    IconContentCopy,
    INPUT_ICON_POSITIONS,
    IconAdd
} from 'cdk-radial'

import * as StyledComponents from '../StyledComponents'
import {useUnifyAdminStore} from '../AdminStore'
import get from 'lodash/get'
import {
    useUpdateApplicationDetails,
    useUpdateWorkflowDetails,
    useCreateNewApplication,
    useCreateNewWorkflow,
    useUpdateAdminPermissions
} from '../AdminStore/DataQueries/useAdminQueries'
import {SIDE_SHEET_TITLE_NAMES} from '../Constants'
import {defaultSideSheetDataSchema, IWorkflow} from '../AdminStore/slices/types/types'
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk'

type Positions = {
    top: string, bottom: string, left: string, right: string
}
type ActionButtonsContainer = {
    children: React.ReactNode,
    position?: string,
    positionProps?: Partial<Positions>,
    background?: string
}


const BaseViewLogsAndCopyActions = (): JSX.Element => {
    const permissions = useUnifyAdminStore(state => state.permissions); 
    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore(state => state.sideSheetData);
    const sideSheetSubWorkflowData = useUnifyAdminStore(state => state.sideSheetDataSubWorkflow);
    const isWorkflowSideSheetCollapse = useUnifyAdminStore(state => state.isWorkflowSideSheetCollapse);
    const createNewApplication = useUnifyAdminStore(state => state.createNewApplication);
    const createNewWorkflow = useUnifyAdminStore(state => state.createNewWorkflow);

    /*
    * Flags
    * */
    const unifyAdminCreateDelete = useFeatureFlag('UNIFY_UI_ADMINISTRATION');

    const shouldApplicationDisplay = !!get(!isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData, 'appId', false) && !createNewApplication && !createNewWorkflow
    return <>
        {unifyAdminCreateDelete && <TextButton icon={<IconOtherReports/>} text="View Change Log"/>}
        {shouldApplicationDisplay && unifyAdminCreateDelete &&
            <TextButton icon={<IconContentCopy/>} text="Copy to a New Environment"
                        isDisabled={!permissions?.hasAppSuperAdminPermissions || !permissions?.hasAppUpdatePermissions}
            />}
    </>
}

const BaseCreateNewWorkflowAction = (): JSX.Element => {

    const permissions = useUnifyAdminStore(state => state.permissions); 
    /**
     * Data hooks
     */
    const setSideSheetTitleName = useUnifyAdminStore(state => state.setSideSheetTitleName)
    const setAdminSideSheetCollapse = useUnifyAdminStore(state => state.setAdminSideSheetCollapse)
    const setCreateNewWorkflow = useUnifyAdminStore(state => state.setCreateNewWorkflow)
    const setSideSheetData = useUnifyAdminStore(state => state.setSideSheetData)
    /*
     * Flags
     * */
    const unifyAdminCreateDelete = useFeatureFlag('UNIFY_UI_ADMINISTRATION');
    const sideSheetData = useUnifyAdminStore(
        (state) => state.sideSheetData
    );

    const handleCreateNewWorkflow = (): void => {
        setSideSheetTitleName(SIDE_SHEET_TITLE_NAMES.CREATE_NEW_WORKFLOW)
        setAdminSideSheetCollapse(false);
        setCreateNewWorkflow(true)
        setSideSheetData({ ...defaultSideSheetDataSchema, appId: get(sideSheetData, 'appId') } as unknown as IWorkflow)
    }

    return <>
        <StyledComponents.StyledHeading headingType='subtitle-2' color='#404040'>Associated
            Workflows</StyledComponents.StyledHeading>
        {unifyAdminCreateDelete &&
            <StyledComponents.StyledSecondaryIconButton
                dataTestId="create-new-workflow-button"
                iconColor="primary"
                onClick={handleCreateNewWorkflow}
                text="Create a New Workflow"
                icon={<IconAdd/>}
                iconPosition={INPUT_ICON_POSITIONS.TRAILING}
                isDisabled={!permissions?.hasAppSuperAdminPermissions}

            />}
    </>
}

const BaseAdminSideSheetFooterAction = (): JSX.Element => {

    /*
    * Flags
    * */
    const unifyAdminCreateDelete = useFeatureFlag('UNIFY_UI_ADMINISTRATION');

    /**
     * hooks
     */

    const {mutate: handleSaveApplication} = useUpdateApplicationDetails()
    const {mutate: handleSavePermissions} = useUpdateAdminPermissions()
    const {mutate: handleSaveWorkflow} = useUpdateWorkflowDetails()
    const {mutate: handleCreateNewApplication} = useCreateNewApplication()
    const {mutate: handleCreateNewWorkflow} = useCreateNewWorkflow()
    const permissions = useUnifyAdminStore(state => state.permissions); 
    /**
     * Data Store
     */
    const setAdminSideSheetCollapse = useUnifyAdminStore((state) => state.setAdminSideSheetCollapse)
    const createNewApplication = useUnifyAdminStore((state) => state.createNewApplication)
    const createNewWorkflow = useUnifyAdminStore((state) => state.createNewWorkflow)
    const sideSheetTitleName = useUnifyAdminStore((state) => state.sideSheetTitleName)
    const setWorkflowSideSheetCollapse = useUnifyAdminStore((state) => state.setWorkflowSideSheetCollapse)
    const isWorkflowSideSheetCollapse = useUnifyAdminStore((state) => state.isWorkflowSideSheetCollapse)
    const permission = useUnifyAdminStore((state) => state.sideSheetData.permissions);

    const handleCancel = () => {
        setAdminSideSheetCollapse(true)
        setWorkflowSideSheetCollapse(true)
    }

    const handleSaveDetails = () => {
       const includeEnterpriseIsValid = permission?.include_enterprise?.every(enterprise => /^E\d{6}$/.test(enterprise) || enterprise === '') ?? true;
       const excludeEnterpriseIsValid = permission?.exclude_enterprise?.every(enterprise => /^E\d{6}$/.test(enterprise) || enterprise === '') ?? true;
       if(!includeEnterpriseIsValid || !excludeEnterpriseIsValid)
       return;

        if (sideSheetTitleName === SIDE_SHEET_TITLE_NAMES.APPLICATION_DETAILS && isWorkflowSideSheetCollapse) {
            handleSavePermissions()
            return handleSaveApplication()
        }
        if (createNewWorkflow) return handleCreateNewWorkflow()
        if (createNewApplication) return handleCreateNewApplication()
        handleSavePermissions()
        return handleSaveWorkflow()
    }


    return <>
        {unifyAdminCreateDelete ?
            <StyledComponents.StyledTextButton
                dataTestId="delete-text-button"
                iconColor="primary"
                onClick={function noRefCheck() {
                }}
                text="Delete Application"
                isDisabled={!permissions?.hasAppSuperAdminPermissions || createNewApplication}
            /> : <div></div>}
        <div>
            <StyledComponents.StyledTextButton
                dataTestId="text-button"
                iconColor="primary"
                onClick={() => handleCancel()}
                text="Cancel"
            />
            <PrimaryButton
                dataTestId="save-side-sheet-button"
                iconColor="primary"
                onClick={() => handleSaveDetails()}
                text="Save"
                isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
            />

        </div>
    </>
}

const ActionButtonContainer = ({
                                   children,
                                   position,
                                   positionProps,
                                   background
                               }: ActionButtonsContainer): JSX.Element => {
    return <StyledComponents.StyledActionButtonContainer positionProps={positionProps} position={position}
                                                         background={background}>
        {children}
    </StyledComponents.StyledActionButtonContainer>
}


export const ViewLogsAndCopyActions = (): JSX.Element => {
    return <ActionButtonContainer>
        <BaseViewLogsAndCopyActions/>
    </ActionButtonContainer>
}

export const CreateNewWorkflowAction = (): JSX.Element => {
    return <ActionButtonContainer><BaseCreateNewWorkflowAction/></ActionButtonContainer>
}

export const AdminSideSheetFooterActions = (): JSX.Element => {

    return <ActionButtonContainer
        position='sticky'
        positionProps={{bottom: '0px'}}
        background={'linear-gradient(rgb(255 255 255 / 42%),#ffff)'}
    >
        <BaseAdminSideSheetFooterAction/>
    </ActionButtonContainer>
}