import {useState, useEffect} from 'react';
import { BUTTON_VARIANTS} from 'cdk-radial';
import {useUnifyAdminStore} from '../../AdminStore';
import {useCreateCampaign, useUpdateCampaign} from '../CampaignStore/DataQueries/useCampaignsQueries';
import * as StyledComponents from '../StyledComponents'
import {CreateCampaignDialogBody} from './CampaignDialogBody';
import * as AdminStyle from '../../StyledComponents/index';

const CreatePromotionTitle = (): JSX.Element => {
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);

    return <StyledComponents.StyledPromoTitle>
        <div>{campaignItems.id ? "Update Campaign" : "Create Campaign"}</div>
    </StyledComponents.StyledPromoTitle>
}

interface ButtonProps {
    id: string,
    onClick: Function,
    text: string,
    variant: string
}


export default function CreateCampaignDialog(): JSX.Element {
    /**
     * Data Store
     */
    const isCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.isCreateCampaignDialogOpen);
    const setIsCreateCampaignDialogOpen = useUnifyAdminStore((state) => state.setIsCreateCampaignDialogOpen);
    const campaignItem = useUnifyAdminStore((state) => state.campaignItems)
    const resetCampaignItem = useUnifyAdminStore((state) => state.setCampaignItem)
    const permissions = useUnifyAdminStore(state => state.permissions) 
    const setShowDialogToast = useUnifyAdminStore((state) => state.setShowDialogToast);
    const showDialogToast = useUnifyAdminStore((state) => state.showDialogToast);
    const setDialogToastContent = useUnifyAdminStore((state) => state.setDialogToastContent);
    const dialogToastContent = useUnifyAdminStore((state) => state.dialogToastContent);

    /**
     * mutations
     */
    const {mutate: createCampaign} = useCreateCampaign()
    const {mutate: updateCampaign} = useUpdateCampaign()

    const handleCreatePromotion = () => {
        if (!campaignItem.name) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign name.");
            return;
        }
        if (!campaignItem.link) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign link.");
            return;
        }
        const urlRegex = /^(http|https):\/\/[^ "]+$/;
        const isLinkValid = urlRegex.test(campaignItem.link);
        if (!isLinkValid) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter a valid campaign link.");
            return;
        }

        if (!campaignItem.description?.enUS) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign description.");
            return;
        }
        if (campaignItem.showLogo && !campaignItem.logo && !campaignItem.darkLogo) {
            setShowDialogToast(true);
            setDialogToastContent("Please upload logo or disable Show Logo.");
            return;
        }
        setShowDialogToast(false);
        return createCampaign();
    }

    const handleUpdatePromotion = () => {
        if (!campaignItem.name) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign name.");
            return;
        }
        if (!campaignItem.link) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign link.");
            return;
        }
        const urlRegex = /^(http|https):\/\/[^ "]+$/;
        const isLinkValid = urlRegex.test(campaignItem.link);
        if (!isLinkValid) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter a valid campaign link.");
            return;
        }
        if (!campaignItem.description?.enUS) {
            setShowDialogToast(true);
            setDialogToastContent("Please enter campaign description.");
            return;
        }
        if (campaignItem.showLogo && !campaignItem.logo && !campaignItem.darkLogo) {
            setShowDialogToast(true);
            setDialogToastContent("Please upload logo or disable Show logo.");
            return;
        }
        setShowDialogToast(false);
        return updateCampaign();
    }

    const handleCloseDialog = () =>{
        setShowDialogToast(false);
        setIsCreateCampaignDialogOpen(false)
        resetCampaignItem(null)
    }

    const [buttonProps, setButtonProps] = useState<ButtonProps[]>([]);
    useEffect(() => {
        let buttonProps: ButtonProps[] = [];
        buttonProps.push({
            id: 'cancel-campaign',
            onClick: () => handleCloseDialog(),
            text: 'Cancel',
            variant: 'text'
        });

        if (campaignItem.id && (permissions?.hasCampaignUpdatePermissions || permissions?.hasCampaignSuperAdminPermissions)) {
            buttonProps.push({
                id: 'create-campaign',
                onClick: () => handleUpdatePromotion(),
                text: 'Update',
                variant: BUTTON_VARIANTS.PRIMARY,
            });
        }
        else if (!campaignItem.id) {
            buttonProps.push({
                id: 'create-campaign',
                onClick: () => handleCreatePromotion(),
                text: 'Create',
                variant: BUTTON_VARIANTS.PRIMARY,
            });
        }
        setButtonProps(buttonProps);
        // eslint-disable-next-line
    }, [campaignItem]);

    return <StyledComponents.StyledCreateCampaignDialog data-testid='create-campaign-dialog-container'>
        <StyledComponents.StyledCreateDialog
            id='create-campaign-dialog'
            buttonsProps={buttonProps}
            dataTestId="create-campaign-dialog"
            onClose={() => handleCloseDialog()}
            title={<><CreatePromotionTitle/></>}
            isOpen={isCreateCampaignDialogOpen}
            disableOverlayClicked>
            {showDialogToast && (
                <AdminStyle.StyledToast
                    variant={'negative'}
                    position="fixed"
                    content={dialogToastContent}
                    visibilityDuration={6000}
                    onVisibilityDurationEnd={() => setShowDialogToast(false)}
                />
            )}
            <CreateCampaignDialogBody/>
        </StyledComponents.StyledCreateDialog>
    </StyledComponents.StyledCreateCampaignDialog>
}