import { Loader } from 'cdk-radial';
import * as Styles from './styles';
import { UseQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import {MeetMyTeamList} from './MeetMyTeamList';
import { useMeetMyCDKContactProfile } from '../../hooks/queries';

const MeetMyTeamContainer = () => {
  const { data, isLoading, isError } = useMeetMyCDKContactProfile();
  const { t } = useTranslation();

  const contacts = {
    data,
    isLoading,
    isError,
  } as UseQueryResult;

  const MeetMyCDKContact = (
    <Styles.Container>
      <Styles.Subtitle>{t('meetmycdkteam.subTitle')}</Styles.Subtitle>
      <MeetMyTeamList data={data}/>
    </Styles.Container>
  )

  return (
    <>
      {contacts.isError ? (
        <div>Not Available</div>
      ) : contacts.isLoading ? (
          <Styles.StyledWithLoader>
              <Loader label='Fetching CDK contact details...' />
          </Styles.StyledWithLoader>
      ) : (
        MeetMyCDKContact
      )}
    </>
  );
}

export default MeetMyTeamContainer;