import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {GridApi, ColumnApi, ExcelStyle} from 'ag-grid-community';
import moment from 'moment';
import pick from 'lodash/pick';
import get from 'lodash/get';
import {StatusIndicator, CONTENT_TYPES} from 'cdk-radial';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {AdminTableBodySkeleton} from './AdminTableBodySkeleton';
import * as StyledComponents from '../StyledComponents';
import {useUnifyAdminStore} from '../AdminStore';
import {SIDE_SHEET_TITLE_NAMES} from '../Constants';
import {AG_GRID_LICENSE} from '../../../constants'
import {
    useGetAllApplications,
    useGetAllWorkflows,
    useGetAdminPermissions
} from '../AdminStore/DataQueries/useAdminQueries';
import 'ag-grid-enterprise';
import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
    AG_GRID_LICENSE);

enum StatusTypes {
    POSITIVE = 'positive',
    WARNING = 'warning',
}

type Status = {
    isActive: String;
};

export const CustomStatusRenderer = ({isActive}: Status): JSX.Element => {
    const variant = isActive === 'Active' ? StatusTypes.POSITIVE : StatusTypes.WARNING;
    return (
        <StatusIndicator
            label={isActive}
            variant={variant}
            showIcon
        />
    );
};


export const CustomApplicationNameRenderer = ({
                                                  isParentApp,
                                                  value,
                                              }: {
    isParentApp: boolean;
    value: string;
}) => {
    const contentType = isParentApp ? CONTENT_TYPES.BODY_2 : CONTENT_TYPES.BODY_1;
    return (
        <StyledComponents.StyledDataCellText
            type={contentType}
            lineHeight={isParentApp ? '46px' : '20px'}
        >
            {value}
        </StyledComponents.StyledDataCellText>
    );
};

// DefaultColDef sets props common to all Columns
const defaultColDef = {
    enablePivot: true,
    sortable: true,
    filter: true,
    flex: 1,
    autoHeight: true,
    minWidth: 100,
    resizable: true,
    refreshCells: true,
};

let currentDate = new Date().toJSON().slice(0, 10);
const downloadCSVParams = {
    fileName: 'List of Unify Apps and worklfows_' + currentDate,
    allColumns: true,
    processCellCallback: function ({column, value}: any) {
        if (column.colId === 'isActive') {
            return value === true ? "Active" : "Inactive"
        } else if (column.colId === 'updatedOn') {
            return moment(
                value,
                'dddd, MMMM DD, YYYY at h:mm:ss A Z'
            ).format('MM/DD/YYYY')
        } else {
            return value;
        }
    }
};

const columnDefinition = [
    {
        cellRenderer: (params: any) => {
            const updatedDate = get(
                params,
                'data.updatedOn',
                get(params.data, 'applications[0].updatedOn')
            );
            return (
                <CustomApplicationNameRenderer
                    isParentApp={!!params.data.appId}
                    value={moment(
                        updatedDate,
                        'dddd, MMMM DD, YYYY at h:mm:ss A Z'
                    ).format('MM/DD/YYYY')}
                />
            );
        },
        field: 'updatedOn',
        headerName: 'Last Edited',
        minWidth: 100,
        width: 400,
        getQuickFilterText: (params: any) => {
            return '';
        },
        sortable: true,
        comparator: dateComparator,
        cellClass: 'formattedColumn',
        filterParams: {
            valueFormatter: dateValueFormatter,
        },
    },
    {
        cellRenderer: (params: any) => (
            <CustomStatusRenderer isActive={get(params, 'data.enablementStatus', 'InActive')}/>
        ),
        field: 'enablementStatus',
        headerName: 'Enablement Status',
        minWidth: 50,
        width: 400,
        getQuickFilterText: (params: any) => {
            return params.data.isActive;
        },
        sortable: true,
        cellClass: 'formattedColumn'
    },
    {
        valueGetter: (params: any) => params.data.permissions?.enterprise?.include?.join(','),
        field: 'include',
        headerName: 'Include',
        minWidth: 50,
        width: 400,
        sortable: true,
        cellClass: 'formattedColumn'
    },
    {
        valueGetter: (params: any) => params.data.permissions?.enterprise?.exclude?.join(','),
        field: 'exclude',
        headerName: 'Exclude',
        minWidth: 50,
        width: 400,
        sortable: true,
        cellClass: 'formattedColumn'
    },
];

function dateValueFormatter(params: any) {
    return moment(
        params.value,
        'dddd, MMMM DD, YYYY at h:mm:ss A Z'
    ).format('MM/DD/YYYY');
};

// DATE COMPARATOR FOR SORTING
function dateComparator(date1: any, date2: any) {
    var date1Number = dateToNum(date1);
    var date2Number = dateToNum(date2);

    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
}

// HELPER FOR DATE COMPARISON
function dateToNum(date: any) {
    if (date === undefined || date === null) {
        return null;
    }

    const dateObj = new Date(moment(
        date,
        'dddd, MMMM DD, YYYY at h:mm:ss A Z'
    ).format('MM/DD/YYYY'));

    const yearNumber = dateObj.getFullYear();
    const monthNumber = dateObj.getMonth();
    const dayNumber = dateObj.getDate();

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 22/05/2023 => 20230522
    return result;
}

const findSideSheetTitleName = (data: { appId: string }) => {
    return data.appId
        ? SIDE_SHEET_TITLE_NAMES.APPLICATION_DETAILS
        : SIDE_SHEET_TITLE_NAMES.WORKFLOW_DETAILS;
};


export default function AdminTableBody({gridRef}: any): JSX.Element {
    /**
     * Data Queries
     */
    const {
        data: applications,
        isFetching: isApplicationFetching,
        refetch: refetchAllApplications,
    } = useGetAllApplications();
    const {
        data: workflows,
        isFetching: isWorkflowFetching,
        refetch: refetchAllWorkflows,
        isLoading,
    } = useGetAllWorkflows();

    const {
        data: permissions,
        refetch: refetchPerissions,
    } = useGetAdminPermissions();

    
    /**
     * Data Store
     */
    const gridSearchValue = useUnifyAdminStore(
        (state) => state.adminGridSearchValue
    );
    const setSideSheetdata = useUnifyAdminStore(
        (state) => state.setSideSheetData
    );
    const setSideSheetTitleName = useUnifyAdminStore(
        (state) => state.setSideSheetTitleName
    );
    const setAdminSideSheetCollapse = useUnifyAdminStore(
        (state) => state.setAdminSideSheetCollapse
    );
    const selectedEnvironment = useUnifyAdminStore(
        (state) => state.selectedEnvironment
    );
    const setCreateNewApplication = useUnifyAdminStore((state) => state.setCreateNewApplication);
    const setCreateNewWorkflow = useUnifyAdminStore((state) => state.setCreateNewWorkflow);

    /**
     * Local States
     */

    const [gridApi, setGridApi] = useState<GridApi>();
    const showSkeleton = isApplicationFetching && isWorkflowFetching;

    /**
     * Grid API
     */
    const [rowData, setRowData] = useState<any>();

    const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
        setGridApi(params.api);
    };

    const cellClickedListener = useCallback((event: any) => {
        setAdminSideSheetCollapse(false);
        setSideSheetTitleName(findSideSheetTitleName(event.data));
        setSideSheetdata(event.data);
        setCreateNewApplication(false)
        setCreateNewWorkflow(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Component lifecycles
     */

    useEffect(() => {
        refetchAllApplications();
        refetchAllWorkflows();
        refetchPerissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEnvironment]);

    useEffect(() => {
        // @ts-ignore
        const applicationsWithWorkflows = applications?.applications.map(
            (application: { appId: any }) => {
                // @ts-ignore
                const workflowsForApplication = workflows?.workflows.filter(
                    (workflow: { applications: any[] }) => {
                        return workflow.applications.some(
                            (app: any) => app.appId === application.appId
                        );
                    }
                );
                
                return {
                    ...application,
                    workflows: workflowsForApplication,
                };
            }
        );

        /**
         * Processing data to show in Treeview inside AGGrid-Start
         */
        const resultArray: any = [];
        if (applicationsWithWorkflows) {
            applicationsWithWorkflows.forEach((app: any) => {
                if (app && app.workflows) {

                    let parentAppName = app.name;
                    const existingItemCount = resultArray.filter((item: any) => item.name === app.name).length;
                    if (existingItemCount > 0) {
                        parentAppName = app.name + " ".repeat(existingItemCount);
                    }

                    app.workflows.forEach((workflow: any) => {
                        resultArray.push({
                            workflowId: workflow.workflowId,
                            name: workflow.name,
                            description: workflow.description,
                            nameArray: [parentAppName, workflow.name],
                            updatedOn: app.updatedOn,
                            isActive: workflow.isActive,
                            enablementStatus: workflow.isActive ? 'Active' : 'Inactive',
                            url: workflow.url,
                            domain: workflow.domain,
                            cdkuUrl: workflow.cdkuUrl,
                        });
                    });
                    delete app.workflows;
                    app.nameArray = [parentAppName]
                    app.enablementStatus = app.isActive ? 'Active' : 'Inactive'
                    resultArray.push(app);
                }
            });
        }
        /**
         * Processing data to show in Treeview inside AGGrid-End
         */
        const adminTableRowData = resultArray?.flatMap(
            (app: any, key: any, self: any) => {
                const picked = get(pick(app, 'workflows'), 'workflows', []);
                return [...[app], ...picked];
            }
        );

        /**
         * injecting respective permissions from Permissions state
         */
        const adminTableRowDataWithPermissions = adminTableRowData.map((rowData: any) => {
            const respectivePermissions = permissions?.find(permission => {
                return rowData.appId === permission.id || rowData.workflowId === permission.id
            })
            return {...rowData, permissions: respectivePermissions}
        })
        setRowData(adminTableRowDataWithPermissions);
    }, [applications, workflows,permissions]);

    useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(gridSearchValue);
        }
    }, [gridSearchValue, gridApi]);

    const getIndentClass = (params: any) => {
        var indent = 0;
        var node = params.node;
        while (node && node.parent) {
            indent++;
            node = node.parent;
        }
        return 'indent-' + indent;
    };

    //Treeview Column Definition
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: 'Application Name',
            cellClass: getIndentClass,
            minWidth: 300,
            cellRendererParams: {
                suppressCount: true,
                innerRenderer: (params: any) => (
                    <CustomApplicationNameRenderer
                        isParentApp={!!params.data.appId}
                        value={params.data.name}
                    />
                ),
            },
            sortable: true,
            comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: any) => {
                if (nodeA.data.name.toLowerCase() === nodeB.data.name.toLowerCase()) return 0;
                return (nodeA.data.name.toLowerCase() > nodeB.data.name.toLowerCase()) ? 1 : -1;
            },
            getQuickFilterText: (params: any) => {
                return params.data.name;
            },
        };
    }, []);

    const getDataPath = useMemo(() => {
        return (data: any) => {
            return data.nameArray;
        };
    }, []);

    const excelStyles: ExcelStyle[] = useMemo(() => {
        return [
            {
                id: 'indent-1',
                alignment: {
                    indent: 1,
                },
                dataType: 'String',
                font: {
                    size: 15
                },
            },
            {
                id: 'indent-2',
                alignment: {
                    indent: 5,
                },
                font: {
                    size: 14
                },
                dataType: 'String',
            },
            {
                id: 'formattedColumn',
                font: {
                    size: 15
                }
            },
            {
                id: 'header',
                font: {
                    bold: true,
                    size: 15
                },
                interior: {
                    color: '#bbb4bb',
                    pattern: 'Solid',
                },
            },
        ];
    }, []);


    return (
        <>
            {showSkeleton || isLoading ? (
                <AdminTableBodySkeleton/>
            ) : (
                <StyledComponents.StyledTableBodyWrapper className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefinition}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        rowSelection="single" // Options - allows click selection of rows
                        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                        excludeHiddenColumnsFromQuickFilter={true}
                        autoGroupColumnDef={autoGroupColumnDef}
                        getDataPath={getDataPath}
                        treeData={true}
                        defaultCsvExportParams={downloadCSVParams}
                        defaultExcelExportParams={downloadCSVParams}
                        excelStyles={excelStyles}
                        suppressDragLeaveHidesColumns={true}
                    />
                </StyledComponents.StyledTableBodyWrapper>
            )}
        </>
    );
}
