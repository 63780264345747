import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableContainer,
  SimpleTableRow,
} from 'cdk-radial';
import React from 'react';
import {
  StyledUserDetailDialog,
  ApplicationTableData,
  WorkflowTableData,
  UserDetailHeading,
  UserDetailDivider,
  UserDetailNotFoundDiv
} from '../StyledComponents';
import { useTranslation } from 'react-i18next';

const UserDetailDialog = (props: {
  isOpen: boolean,
  onClose: Function,
  userId: string,
  detail: any,
  detailLoading: boolean
}): JSX.Element => {

  const { t } = useTranslation();
  const title = `${t('admin.user-search.userid')}: ${props.userId}`;
  return (
    <div>
      <StyledUserDetailDialog
        isOpen={props.isOpen}
        data-testid="user-detail-dialog"
        id="user-detail-dialog"
        onClose={props.onClose}
        title={title}
      >
        <UserDetailHeading>
          {t('admin.user-detail.dialog.heading')}
        </UserDetailHeading>
        <UserDetailDivider></UserDetailDivider>

        {
          props.detail?.apps?.length === 0 ?  
          <UserDetailNotFoundDiv>
            {t('admin.user-detail.dialog.no-assignment')}
          </UserDetailNotFoundDiv>
          : <></>
        }

          <SimpleTableContainer>
            <SimpleTable style={{marginTop:'15px'}}>
              <SimpleTableBody>
                {
                  props.detail?.apps?.map((app:any) =>(
                  <>
                    { 
                      <SimpleTableRow key={app.name}> 
                          <ApplicationTableData>{app.name}</ApplicationTableData> 
                        </SimpleTableRow>
                      }
                    { 
                      app.workflows && app.workflows.map((wflow:any) =>(
                        <SimpleTableRow key={wflow.name}>
                          <WorkflowTableData>{wflow.name}</WorkflowTableData>
                        </SimpleTableRow>
                    ))}
                  </>
                  ))
                }
              </SimpleTableBody>
            </SimpleTable>
          </SimpleTableContainer>
      </StyledUserDetailDialog>
    </div>
  );
};
export default UserDetailDialog;
