import React, { useRef, useState, useEffect } from 'react';
import {
  MenuListItem,
  Input,
  IconArrowDropUp,
  IconArrowDropDown,
  BaseInputField,
} from 'cdk-radial';
import styled from 'styled-components';
import * as StyledComponents from '../StyledComponents';
import { useUnifyAdminStore } from '../AdminStore';

type Options = {
  label: string;
  value: string | boolean | any;
};

interface IAdminCommonSelector {
  options: Options[];
  selectedOption: Options;
  placeholder: string;
  label: string;
  name:string,
  onSelect:any
}

const StyledInput = styled(Input)`
  ${BaseInputField} {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

export const CommonDropdown = ({options,label,name,placeholder,selectedOption,onSelect}:Partial<IAdminCommonSelector>) => {
  
  /**
     * Data hooks
     */
  const permissions = useUnifyAdminStore(state => state.permissions); 
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );

    /**
     * Lifecycle hooks
     */
    const targetRef = useRef();
    const dimensionRef = useRef();

    /**
     * local state hooks
     */
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [dropdownSelectedOption, setDropdownSelectedOption] = useState(selectedOption);

    /**
     * Side effects
     */
    const handleOpen = () => setIsOpen((isOpen) => !isOpen);

    const handleSelect = (option:Options,name:string) => {
        setDropdownSelectedOption(option);
        updateSideSheetData({
            fieldName:name,
            value:option.value
        })
        onSelect && onSelect(option);
    };
  
    useEffect(() => {
      setDropdownSelectedOption(selectedOption);
    }, [selectedOption]);


    return (
        <>
            <StyledComponents.StyledDropDownContainer >
                <StyledInput
                    dataTestId={`${label}-select-menu-input`}
                    icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
                    id={`${label}-select-dropdown`}
                    label={label}
                    labelRef={dimensionRef}
                    name={label}
                    placeholder={placeholder}
                    ref={targetRef}
                    value={dropdownSelectedOption?.label || ''}
                    style={{ caretColor: 'transparent' }}
                    isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
                />
            </StyledComponents.StyledDropDownContainer>
            <StyledComponents.StyledMenu
                dataTestId="menu-container"
                dimensionRef={dimensionRef}
                labelRef={targetRef}
                onClose={handleOpen}
                onOpen={handleOpen}
                isAuto={false}
            >
                {options?.map((option:any, ind:any) => (
                    <MenuListItem
                     dataTestId={`menu-item-${ind}`}
                     isDisabled={option?.disabled}
                     isSelected={option.value === dropdownSelectedOption?.value}
                     key={ind}
                     onClick={() => handleSelect(option,name as string)}
                     hideSelectedItemCheckmark={false}
                    >
                     {option.label}
                    </MenuListItem>
                ))}
            </StyledComponents.StyledMenu>
        </>
    );
};
