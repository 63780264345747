import {useRef, useState, useEffect} from 'react';
import {Button, BUTTON_VARIANTS} from 'cdk-radial';
import {useUnifyAdminStore} from '../../AdminStore';
import * as StyledComponents from '../StyledComponents';
import {CampaignShowLogoStatusField} from '../Elements/CampaignShowLogoStatusField';

export const CampaignLogoImageUploadField = (): JSX.Element => {

    /**
     * Data Store
     */
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore((state) => state.updateCreateCampaignData);
    const permissions = useUnifyAdminStore(state => state.permissions); 
    const setShowDialogToast = useUnifyAdminStore((state) => state.setShowDialogToast);
    const setDialogToastContent = useUnifyAdminStore((state) => state.setDialogToastContent);

    const [imageData, setImageData] = useState<string | ArrayBuffer | null>();
    const [darkImageData, setDarkImageData] = useState<string | ArrayBuffer | null>();

    // To manage file upload we are using an input element with a ref to control the onChange callback from the Radial Avatar component.
    const hiddenFileInput = useRef(null);
    const hiddenDarkFileInput = useRef(null);
    
    const handleChange = (event: any) => {
        const fileInput = event.target;
        const file = event.target.files[0];
        fileInput.value = '';
        const reader = new FileReader();
        
        if (file && file.size < 102400) {
            reader.onloadend = () => {
                const base64String = reader.result;
                setImageData(base64String);
            };
            // @ts-ignore
            reader.readAsDataURL(file);
        }
        else{
            setShowDialogToast(true);
            setDialogToastContent("The image has not been uploaded, Please upload an image having size less than 100KB.");
        }
    };

    const handleDarkImageChange = (event: any) => {
        const fileInput = event.target;
        const file = event.target.files[0];
        fileInput.value = '';
        const reader = new FileReader();

        if (file && file.size < 102400) {
            reader.onloadend = () => {
                const base64String = reader.result;
                setDarkImageData(base64String);
            };
            // @ts-ignore
            reader.readAsDataURL(file);
        }
        else{
            setShowDialogToast(true);
            setDialogToastContent("The image has not been uploaded, Please upload an image having size less than 100KB.");
        }
    };

    useEffect(() => {
        if (imageData !== undefined) 
        {
        updateCreateCampaignData({
            fieldName: 'logo',
            value: imageData as unknown as string,
        })}
        if (darkImageData !== undefined) 
        { 
        updateCreateCampaignData({
            fieldName: 'darkLogo',
            value: darkImageData as unknown as string,
        })}
    }, [imageData, darkImageData, updateCreateCampaignData])


    return (<StyledComponents.StyledUploadContainer>
        <StyledComponents.StyledPromotionLogoLabel>Promotion Logo</StyledComponents.StyledPromotionLogoLabel>
        <StyledComponents.StyledUploadLogoContainer data-testid='promotion-logo-container'>
            <input
                // Per MDN documentation, this string is a comma separated list of unique file type specifiers: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
                accept="image/png, image/jpeg"
                onChange={(e) => handleChange(e)}
                ref={hiddenFileInput}
                style={{display: 'none'}}
                type="file"
            />

{/*retrieval time*/}
{(campaignItems.logo && !imageData) && <StyledComponents.StyledImageContainer data-testid='image-viewer'>
                <StyledComponents.Styledimage src={campaignItems && campaignItems.logo} alt="Uploaded"/>
                {(permissions?.hasCampaignUpdatePermissions || permissions?.hasCampaignSuperAdminPermissions) &&
                <StyledComponents.StyleDeleteIcon onClick={() => setImageData('')}/>}
            </StyledComponents.StyledImageContainer>}

            {/*create campaign time*/}
            {imageData &&
                <StyledComponents.StyledImageContainer data-testid='image-viewer'>
                    {/*@ts-ignore*/}
                   <StyledComponents.Styledimage src={imageData} alt="Uploaded"/>
                    <StyledComponents.StyleDeleteIcon onClick={() => setImageData('')}/>
                </StyledComponents.StyledImageContainer>
                 } 

            <StyledComponents.StyledAvatarContainer data-testid='logo-upload-container'>
                
                <Button text="Upload Light Theme Logo" variant={BUTTON_VARIANTS.TEXT} 
                 // @ts-ignore
                 onClick={() => hiddenFileInput?.current.click()}
                 isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
                />
            </StyledComponents.StyledAvatarContainer>
            <CampaignShowLogoStatusField></CampaignShowLogoStatusField>
        </StyledComponents.StyledUploadLogoContainer>
        <StyledComponents.StyledUploadLogoContainer data-testid='promotion-logo-container'>
        <input
            // Per MDN documentation, this string is a comma separated list of unique file type specifiers: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
            accept="image/png, image/jpeg"
            onChange={(e) => handleDarkImageChange(e)}
            ref={hiddenDarkFileInput}
            style={{display: 'none'}}
            type="file"
        />

{/*retrieval time*/}
{(campaignItems.darkLogo && !darkImageData) && <StyledComponents.StyledImageContainer data-testid='image-viewer'>
            <StyledComponents.Styledimage src={campaignItems && campaignItems.darkLogo} alt="Uploaded"/>
            {(permissions?.hasCampaignUpdatePermissions || permissions?.hasCampaignSuperAdminPermissions) &&
            <StyledComponents.StyleDeleteIcon onClick={() => setDarkImageData('')}/>}
        </StyledComponents.StyledImageContainer>}

        {/*create campaign time*/}
        {darkImageData &&
            <StyledComponents.StyledImageContainer data-testid='image-viewer'>
                {/*@ts-ignore*/}
                <StyledComponents.Styledimage src={darkImageData} alt="Uploaded"/>
                <StyledComponents.StyleDeleteIcon onClick={() => setDarkImageData('')}/>
            </StyledComponents.StyledImageContainer>
         } 
        <StyledComponents.StyledAvatarContainer data-testid='logo-upload-container'>
            
            <Button text="Upload Dark Theme Logo" variant={BUTTON_VARIANTS.TEXT} 
             // @ts-ignore
             onClick={() => hiddenDarkFileInput?.current.click()}
             isDisabled={campaignItems.id
                && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
            />
        </StyledComponents.StyledAvatarContainer>
    </StyledComponents.StyledUploadLogoContainer>
    <StyledComponents.StyledImageSizeLabel>* Maximum image upload size 100KB</StyledComponents.StyledImageSizeLabel>
    </StyledComponents.StyledUploadContainer>
    );
};