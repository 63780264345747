import useAppsByAppName from '../../hooks/useAppsByAppName';
import ApplicationsList from '../ApplicationsList/ApplicationsList';
import EmptyStateCard from '../Cards/EmptyStateCard';
import * as Styles from '../styles';
import WorkflowList from '../WorkflowList/WorkflowList';
import { withTab } from '../Tabs/withTab';
import { useTranslation } from 'react-i18next';
import { UserCards } from '../UserCards/UserCards';

function MyUnifyTabInit({appList, appStatus, workflowList, workflowStatus}: any) {
  const workflows = workflowList;
  const applications = appList;
  const {t} = useTranslation();
  const filteredApps = applications.filter((app: any) => app.isFavorite);
  const showApps = filteredApps.length > 0;
  const showWorkflows = Object.keys(workflows).length > 0;
  const apps = useAppsByAppName(appList, appStatus);

  return (
    <Styles.TabItem>
      <div>
        <UserCards />
      </div>
      <div>
        <Styles.TabSubHeader>
            {t('myUnifyTab.myApplications')}
        </Styles.TabSubHeader>
        {showApps ? (
          <ApplicationsList applications={filteredApps} />
        ) : (
          <EmptyStateCard isApplication={true} isUserCard={false}/>
        )}
      </div>
      <div>
        <Styles.WorkflowSubHeader>
          {t('myUnifyTab.myWorkflows')}
        </Styles.WorkflowSubHeader>
        {showWorkflows ? (
          <WorkflowList workflows={workflows} apps={apps.data} />
        ) : (
          <EmptyStateCard isApplication={false} isUserCard={false}/>
        )}
      </div>
    </Styles.TabItem>
  );
}

const MyUnifyTab = withTab(MyUnifyTabInit);

export default MyUnifyTab;
