import { EventSourcePolyfill } from 'event-source-polyfill';

export const SSEEventListener = () => {

    const eventListener = new Map();
    let eventSource:EventSourcePolyfill;
  
    const connect = (eSource:EventSourcePolyfill,handler:Function) => {
      eventSource = eSource;
      listenEvent('open',openEvent);
      listenEvent('error',errorEvent);
      listen(handler);
    }
  
    const listen = (handler: any) => {
      listenEvent('message',(e:any) => {
        console.log(`SSE - ${e.data}`);
        const event = JSON.parse(e.data);
        handler(event);
      });
    }

    const openEvent = () => console.log('SSE - Connection opened!');
    const errorEvent = (e:any) =>  console.log('SSE : ', e);
  
    const listenEvent = (type:string, listener: any) => {
      eventSource.addEventListener(type, listener);
      eventListener.set(type,listener);
    }
  
    const close = () => {
      eventListener.forEach((value, key) => {
        eventSource.removeEventListener(key,value);
      });
      eventSource.close();
      eventListener.clear();
    }
  
    return {
      connect,
      listen,
      close,
    }
  
  };