import {
  Dialog,
  BUTTON_VARIANTS,
  IconStatusWarningExclamation,
} from 'cdk-radial';
import styled from 'styled-components';
import { useDriveAccess } from '../../contexts/AppStateContext';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../contexts/Theme';

export const StyledDialog = styled(Dialog)`
  min-width: 560px;
  max-width: 30%;
  margin-top: 60px;
  z-index: 9999;
  @media (max-width: 570px) {
    min-width: 10vw;
    max-width: 100%;
    width: 92vw;
  }
`;

export const StyledDialogTitle = styled.div`
  fontweight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  text-transform: capitalize;
  @media (max-width: 570px) {
    font-size: 1.1rem;
  }
  @media (max-width: 510px) {
    font-size: 1rem;
  }
  @media (max-width: 490px) {
    font-size: .9rem;
  }
  @media (max-width: 450px) {
    font-size: .8rem;
  }
  @media (max-width: 400px) {
    font-size: .7rem;
  }
`;

export const StyledDialogTitleContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const StyledDialogContentText = styled.div``;
export const StyledDriveText = styled.div`
  font-size: 1rem;
  padding-top: 10px;
`;

export const StyledIconAccountCircle = styled(IconStatusWarningExclamation)`
  fill: #fa9c19;
  height: 38px;
  width: 38px;
  margin-right: 5px;
`;

interface ILogoutConfirmDialog {
  isOpen: boolean;
  onCancel: () => void;
  onLogout: () => void;
}

const LogoutConfirmDialog = ({
  isOpen,
  onLogout,
  onCancel,
}: ILogoutConfirmDialog): JSX.Element => {
  const driveAccess = useDriveAccess();
  const { t } = useTranslation();
  const { darkMode } = useTheme();

  return (
    <div>
      <StyledDialog
        buttonsProps={[
          {
            id: 'Cancel',
            dataTestId: 'logout-dialog-cancel',
            onClick: onCancel,
            text: t('logoutConfirmDialog.cancelBtn'),
            variant: BUTTON_VARIANTS.TEXT,
            style: {
              color: darkMode ? 'white' : 'black'
            },
          },
          {
            id: 'logout-dialog-sign-out',
            onClick: onLogout,
            text: t('logoutConfirmDialog.signOutBtn'),
            variant: BUTTON_VARIANTS.PRIMARY,
            style: {
              color: darkMode ? 'black' : 'white',
              backgroundColor: darkMode ? 'white' : 'black',
            },
          },
        ]}
        id="logout-dialog-signout-alert"
        data-testid="logout-dialog-signout-alert"
        isOpen={isOpen}
        onClose={onCancel}
        title={
          <StyledDialogTitleContainer>
            <StyledIconAccountCircle />
            <StyledDialogTitle>
              {t('logoutConfirmDialog.logoutTitle')}
            </StyledDialogTitle>
          </StyledDialogTitleContainer>
        }
      >
        <StyledDialogContentText>{t('logoutConfirmDialog.logoutMsg')}</StyledDialogContentText>
        {driveAccess && (
          <StyledDriveText>
            {t('logoutConfirmDialog.logoutConfirmDrive')}
          </StyledDriveText>
        )}
      </StyledDialog>
    </div>
  );
};
export default LogoutConfirmDialog;
