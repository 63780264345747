import { useSideSheet } from '../../contexts/SideSheet';
import * as Styles from './styles';
import { SideSheetHeader } from '../SideSheet/SideSheet';
import HistoryContent from './HistoryContent';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { CustomScrollbarWrapper } from 'cdk-radial';

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100% - 50px);
  overflow-x: hidden;
`;

export default function History() {
  const {t} = useTranslation();
  const { setSideSheetContent } = useSideSheet();
  const toggle = () => setSideSheetContent(null);

  return (
    <Styles.HistoryContent>
      <SideSheetHeader
        name={t('history.name')}
        toggle={toggle}
        dataTestId="history-tab-header"
      />
      <StyledCustomScrollbarWrapper>
        <HistoryContent />
      </StyledCustomScrollbarWrapper>
    </Styles.HistoryContent>
  );
}
