import { 
    BtnBold, 
    BtnItalic, 
    BtnUnderline,
    BtnNumberedList,
    Editor, 
    EditorProvider, 
    Toolbar
  } from 'react-simple-wysiwyg';

  const MAX_LENGTH = 5000;

  export default function CustomEditor({ value, onChange}: any) {

    const handleChange = (event:any) => {
      const newValue = event.target.value;
      if (newValue.length > MAX_LENGTH) {
        return;
      }
      onChange(event);
    };

    return (
      
      <EditorProvider>
            
        <Editor value={value} onChange={handleChange} 
          containerProps={{
            onPaste:(event:any) =>{
              event.preventDefault();
              if (event.clipboardData) {
                const clipboardData = event.clipboardData ;
                const plainText = clipboardData.getData('Text');
                const newLength = value.length + plainText.length;
                if (newLength > MAX_LENGTH) {
                  return;
                }
                const selection = window.getSelection() as any;
                if (!selection.rangeCount) return;

                const range = selection.getRangeAt(0);
                range.deleteContents();

                const textNode = document.createTextNode(plainText);
                range.insertNode(textNode);

                // Move the cursor to the end of the pasted text
                range.setStartAfter(textNode);
                range.setEndAfter(textNode);
                selection.removeAllRanges();
                selection.addRange(range);

                }
              }
            }}>
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnNumberedList/>
          </Toolbar>
        </Editor>
        
      </EditorProvider>
    );
  }