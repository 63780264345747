import React from 'react';
import {useUnifyAdminStore} from '../AdminStore';
import * as StyledComponents from '../StyledComponents';
import get from 'lodash/get';

export const NameField = (): JSX.Element => {
   
    /**
     * Data Store
     */
    const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
    const createNewApplication = useUnifyAdminStore(state => state.createNewApplication)
    const createNewWorkflow = useUnifyAdminStore((state) => state.createNewWorkflow)
    const updateSideSheetData = useUnifyAdminStore(
        (state) => state.updateSideSheetData
    );
    const sideSheetSubWorkflowData = useUnifyAdminStore(
        (state) => state.sideSheetDataSubWorkflow
    );
    const isWorkflowSideSheetCollapse = useUnifyAdminStore(
        (state) => state.isWorkflowSideSheetCollapse
    );

    /**
     * Local Component derived states
     */
    const nameFieldValue = get(
        !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
        'name',
        ''
    );
    const nameFieldLabel = createNewApplication 
        ? 'Application'
        : 'Workflow';


    const handleUpdateNameField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateSideSheetData({
            fieldName: event.target.name,
            value: event.target.value,
        });
    };

    return (
        <>
            <StyledComponents.StyledInput
                dataTestId={`${nameFieldLabel}-input`}
                errorMessage="Error message"
                id={`${nameFieldLabel}-name-input`}
                inputDivider="left"
                label={`Name`}
                name="name"
                onChange={handleUpdateNameField}
                onInputClear={function noRefCheck() {
                }}
                placeholder={`Name`}
                value={nameFieldValue}
                isDisabled={!createNewApplication && !createNewWorkflow}
            />
        </>
    );
};
