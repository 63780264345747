import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as Styles from './styles';
import {useTheme} from '../../contexts/Theme';
import BannerItems from '../BannerItems/BannerItems';
import {RoundedIconButton} from '../Buttons/IconButton';
import {IconExpandLess} from 'cdk-radial';

export default function Banner(props: { closetColor: Function, hideBanner: Function, bannerData: any }) {
    const {darkMode} = useTheme();

    return (
        <><Styles.BannerWrapper>
                <Styles.CarouselContainer data-testid="banner">
                    <Styles.CarouselCloseContainer>
                        <RoundedIconButton
                            darkMode={darkMode}
                            data-testid="closeIcon"
                            icon={<IconExpandLess/>}
                            onClick={props.hideBanner}
                            size={"small"}
                            iconSize={"small"}
                            text=''
                        />
                    </Styles.CarouselCloseContainer>
                    {/*@ts-ignore*/}
                    {props?.bannerData?.length && <BannerItems closetColor={props.closetColor} bannerItems={props.bannerData}/>}
                </Styles.CarouselContainer>
            </Styles.BannerWrapper>
        </>
    );
}
