import { createContext, useContext } from 'react';
import { ProductWindow } from './MessageProvider';

interface ChannelMessage {
  logoutProducts: Function;
  addProduct: Function;
  removeProduct: Function;
  setLogoutStatus: Function;
  setSloMode: Function;
  logoutStatus: string;
  openedProducts: ProductWindow[];
}

export const MessageContext = createContext<ChannelMessage | null>(null);

export const useChannelMessage = (): ChannelMessage => {
  const context = useContext(MessageContext);
  if (context === null)
    throw new Error(
      'useChannelMessage must be used within a <MessageProvider>'
    );
  return context;
};
