import React from 'react';
import { SkeletonLoader } from 'cdk-radial';
import * as StyledComponents from '../StyledComponents';

export const AdminTableBodySkeleton = (): JSX.Element => {
  return (
    <StyledComponents.StyledSkeletonContainer>
      {[...Array(17)].map((item, key) => (
        <SkeletonLoader
            key={key}
            style={{
            margin: '1.5rem 0',
          }}
            variant="text"
        />
      ))}
    </StyledComponents.StyledSkeletonContainer>
  );
};
