import * as Log from '../util/logging';

const ENVIRONMENTS = {
  LOCAL: 'develop',
  STAGE: 'us-stage',
  DEV: 'us-dev',
  DIT: 'us-dit',
  PERF: 'us-perf',
  PROD: 'us-prod',
} as const;

// Axios defaults to localhost without a port if it's not passed an absolute
// url
const axiosDefaultBaseUrl = 'http://localhost';

export function getDeploymentEnv() {
  const deploymentEnv = process.env.REACT_APP_ENV;
  const bffEnvironmentMapping = {
    [ENVIRONMENTS.LOCAL]: 'not used',
    [ENVIRONMENTS.STAGE]: 'stage',
    [ENVIRONMENTS.PERF]: 'perf',
    [ENVIRONMENTS.DEV]: 'dev',
    [ENVIRONMENTS.DIT]: 'dit',
    [ENVIRONMENTS.PROD]: 'prod',
  } as const;
  const env = bffEnvironmentMapping[deploymentEnv];

  if (!env) {
    Log.warn(`
      Environmental variable REACT_APP_ENV not set or was set to an unexpected value.
      Network calls will likely fail.
    `);
  }

  return env;
}

export function getBffUrl() {
  const isLocal =
    process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development';
  const localBffUrl = process.env.REACT_APP_BFF || axiosDefaultBaseUrl;

  return isLocal ? localBffUrl : `/api`;
}
