import {StateCreator} from 'zustand';

export interface ICreateAdminUserSearchSlice {
    searchQuery : string;
    setSearchQuery:(query: string ) => void;
    userId : string;
    setUserId:(userId: string) => void;
}

export const createAdminUserSearchSlice: StateCreator<ICreateAdminUserSearchSlice,
    [['zustand/devtools', never]],
    [],
    ICreateAdminUserSearchSlice> = (set) => {
    return {
        searchQuery: '',
        setSearchQuery: (query) => set(() => ({searchQuery: query})),
        userId : '',
        setUserId:(userId: string) => set(() => ({userId: userId}))
    };
};
