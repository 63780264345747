import { RoundedIconButton } from "../Buttons/IconButton";
import {IconClose,IconChevronLeft, darkMode as RDSDarkMode,
  theme as RDSLightMode} from 'cdk-radial';
import { INotification } from "./Notification";
import { getTimeDiffText } from '../../util/timeUtils';
import { useTranslation } from "react-i18next";
import {
  NDContainer,NDHeader,NDTitle,NDSubTitle,NDSubject,
  NDBodyContent,NDFooter,
  NDProductTitle,NDProductContentIcon,NDBodyContainer,NDStyledCustomScrollbarWrapper,
  StyledDefaultCDKIconText
 } from './styles';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '../../contexts/Theme';
import { TextButtonFooter } from '../Buttons/TextButton';
import {useApplicationStore} from '../../Store/index';
import parse from 'html-react-parser';

const NotificationHeader = (props :{ title:string ,backHandler: Function,onClose : Function}) =>{
  return (<NDHeader>
          <div style={{display:'flex'}}>
            <RoundedIconButton
                  dataTestId={"detail-go-back"}
                  darkMode
                  icon={<IconChevronLeft />}
                  onClick={props.backHandler}
                  text="Go Back"
              />
              <div style={{marginTop:'8px'}}>{props.title}</div>
          </div>
            <div>
            <RoundedIconButton
                style={{marginRight:'12px'}}
                dataTestId={"detail-close"}
                darkMode
                icon={<IconClose />}
                onClick={props.onClose}
                text="Close Detail"
            />
            </div> 
        </NDHeader>)
}

const NotificationFooter = (props:{name:string,onClick:Function,darkMode:any}) =>{
  return (<NDFooter>
            <TextButtonFooter darkMode={props.darkMode} data-testid="detail-back-to-all" text={props.name} onClick={props.onClick} />
        </NDFooter>)
}

const NotificationLink = (props:{externalUrl:string,name:string,title:string,darkMode:any}) =>{
  const openLinkInWindow = () => window.open(props.externalUrl);
  return (
    <>
      {
        props.externalUrl &&
        <div>
          <div style={{marginTop:'32px',fontSize:'18px',fontWeight:'600'}}>
              {props.title}
          </div>
          <div style={{margin:'10px'}}>
            <TextButtonFooter data-testid="detail-link-to-learn" text={props.name} darkMode={props.darkMode} onClick={openLinkInWindow} />
          </div>
        </div>
      }
    </>
  )
}

const NotificationAffectedProduct = (props:{
  label:string,
  application:string,
  company:string
}) =>{
  const applications = useApplicationStore((state:any) => state.applications);

    const appIconColor = applications.find((app:any) => app.name === props.application)?.icon
    const appIconText = applications.find((app:any) => app.name === props.application)?.iconText

    const defaultCDKIconText = <StyledDefaultCDKIconText fontSize='18px'> {"CDK" } </StyledDefaultCDKIconText>

    return (
    <>
      <NDProductTitle>{props.label}</NDProductTitle>
        <div style={{display:'flex',marginTop:'14px'}}>
           <NDProductContentIcon iconColor={appIconColor} data-testid="app-icon">{appIconText || defaultCDKIconText}</NDProductContentIcon>
            <div style={{display:'flex',flexDirection:'column', marginLeft:'14px', justifyContent:'space-between'}}>
                <div style={{fontWeight:'600',fontSize:'18px'}}>{props.application}</div>
                <div style={{fontWeight:'400',fontSize:'14px'}}>{props.company}</div>
            </div>
        </div>
    </>
  )
}


export const NotificationDetail = (props: {
    notification: INotification,
    backHandler: Function,
    onClose: Function,
}): JSX.Element => {

  const {notification,backHandler,onClose} = props;
  const { t } = useTranslation();
  const clickToLearnMore=t('notification.dialog.learnMore');
  const backToAllNotifications=t('notification.detail.backToAll'); 
  const affectedProducts=t('notification.detail.affectedProducts');
  const link=t('notification.detail.link');
  const company = "CDK Global LLC.";
  const { darkMode } = useTheme();
  const theme = darkMode ? RDSDarkMode : RDSLightMode;


  return (
    <ThemeProvider theme={theme}>
      <NDContainer data-testid="notification-detail" >
        <NotificationHeader 
            title = {t('notification.sheet.title')}
            backHandler={backHandler}
            onClose={onClose}/>
       <NDStyledCustomScrollbarWrapper>
          <NDBodyContainer>
          <div>
              <NDTitle>{notification.title}</NDTitle>
              <NDSubject>{notification.subject}</NDSubject>
              <NDSubTitle>
              {getTimeDiffText(
                      notification.receivedDate as string, t)}
                  </NDSubTitle>
              <NDBodyContent>{parse(notification.body as string)}</NDBodyContent>
              <NotificationAffectedProduct
                application={notification.application!}
                company={company}
                label={affectedProducts}
              />
              <NotificationLink
                externalUrl={notification.externalUrl!}
                title={link}
                name={clickToLearnMore}
                darkMode={darkMode}  />
          </div>
          <NotificationFooter
            name={backToAllNotifications}
            onClick={backHandler} 
            darkMode={darkMode} />
          </NDBodyContainer>
        </NDStyledCustomScrollbarWrapper>
      </NDContainer>
      </ThemeProvider>
  );
};

