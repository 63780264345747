import styled from 'styled-components';
import {
  TableContainer,
  IconFileDownload,
  Heading,
  Button,
  SearchInput,
  Input,
  TextButton,
  TextArea,
  CustomScrollbarWrapper,
  IconCancel,
  ChoiceChips,
  SelectChip
} from 'cdk-radial';

export const StyledTableContainer = styled(TableContainer)`
  height: 75vh;
  left: 0px;
  margin: 10px;
`;

export const StyledLongDescCount = styled.div`
  margin-top:3px;
  font-family:Roboto, sans-serif;
  font-weight:400;
  display:flex;
  justify-content:end;
`;

export const StyledCustomEditorLabel = styled.div`
   font-family:Roboto, sans-serif;
   font-size:1rem;
   color:${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)')}; 
`;

export const StyledCountLabel = styled.span`
   color:${({ theme }) => (theme.darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)')}; 
  font-size: 0.75rem
`;

export const StyledTableWrapper = styled.div`
  width: 100%;
  height: 60vh !important;

  .ag-root-wrapper {
    border: none !important;
  }

  .ag-pivot-off .ag-header-cell {
    font-size: 17px;
  }

  .ag-cell-wrapper{
    overflow: hidden;
  }

  .ag-menu-option{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-icon{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-tabs-header{
      background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  }
  .ag-menu-header{
      background-color: ${({ theme }) => (theme.darkMode ? '#1A1A1A' : '#f2f2f2')};
  }

  .ag-column-select-column-label{
    color: ${({ theme }) => (theme.darkMode ? '#f2f2f2' : '#1A1A1A')};
  }

  .ag-header-row{
    height: 36px;
  } 

.ag-header-cell-comp-wrapper{
  height: 37px !important;
}

@media (max-height: 850px) {
  height: 50vh !important;
}

@media (max-height: 710px) {
  height: 65vh !important;
}

@media (max-height: 610px) {
  height: 45vh !important;
}
`

export const StyledContainerWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledInput = styled(Input) <{ customWidth?: string }>`
  width: ${props => props.customWidth};
  margin: 10px 15px 0px 0px;
`;

export const StyledLinkInput = styled(Input) <{ customWidth?: string }>`
  width: ${props => props.customWidth};
  margin: 10px 0px 0px 0px;
`;

export const StyledTextArea = styled(TextArea) <{ customWidth?: string }>`
  width: 100%;
  margin: 10px;
  height: 40px;
  overflow: hidden;
`
export const StyledTextAreaWithCustomHeight = styled(TextArea) <{ customHeight?: string }>`
  width: 100%;
  margin: 10px 0px 0px 0px;
  height: ${props => props.customHeight};
`

export const StyledNameAndLinkTextArea = styled(TextArea) <{ customWidth?: string }>`
  width: 100%;
  height: 20px;
  overflow: hidden;
`

export const StyledLabel = styled.div`
  margin: auto 5px`

export const StyledImageContainer = styled.div`
`

export const StyleDeleteIcon = styled(IconCancel)`
  fill-opacity: 0.6;
  fill: red;
  :hover {
    cursor: pointer;
  }
;
`

export const StyledSearchBox = styled(SearchInput)`
  width: 40%;
  left: 1px;
  top: 0px;

  & > input {
    height: 32px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  left: 15px;
  position: relative;
`

export const StyledHeading = styled(Heading)`
  margin: 10px 0;
  padding: ${(props) => props.padding};
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  height: 44px;
  font-weight: 500;
  font-size: 20px;
`;

export const StyledSearchBoxContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.5rem 0rem 0rem;
  padding-left: 15px;
  height: 75px;
`;

export const StyledButtonWithIcon = styled(Button)`
  height: 32px;
  min-height: 32px;
  display: flex;
  flex-direction: row-reverse;
  padding-left: .8rem;
  padding-right: 0;
  max-width: 15%;
  margin: 9px 0px 0px 0px;
  margin-left:0px;

  svg {
    padding: 5px;
  }

  @media (max-width: 1370px) {
    min-width:165px;
`;

export const StyledIconFileDownload = styled(IconFileDownload)`
  cursor: pointer;
  padding-right: 15px;
  `

export const StyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
height: 100vh;
overflow-x: hidden;
width: 100%;
left: 0px;
position: fixed;

@media (max-height: 710px) {
    height: 75vh;
}
`;

export const StyledInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledNameInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-right: 15px;
`;

export const StyledLinkInputContainer = styled.div`
  min-height: auto;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
`;

export const StyledHelperLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledNameHelperLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 10px;
`;

export const StyledNameCountLabel = styled.span`
  width: 100%;
  height: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 17%;

  @media (max-width: 750px) {
    margin-left: 10%;
}
`;


export const StyledHelperLabelContainer = styled.div`
    margin-left: 94%;
    @media (max-width: 800px) {
      margin-left: 90%;
  }
  `;

export const StyledNameHelperLabelContainer = styled.div`
  display: inline-flex;
  width: 75%;
  `;


export const StyledActionButtonContainer = styled.div<{
  position?: string;
  positionProps?: Partial<{
    top: string;
    bottom: string;
    left: string;
    right: string;
  }>;
  background?: string;
}>`
display: flex;
justify-content: flex-end;
margin: 5px 0;
position: ${({ position }) => position};
bottom: ${({ positionProps }) => positionProps?.bottom};
background: ${({ background }) => background};
// CTA buttons
:last-child {
  margin-top: auto;
}

`;

export const StyledTextButton = styled(TextButton)`
  margin: 5px;
`;

export const StyledChoiceChips = styled(ChoiceChips)`
  margin: 5px;
`;


export const StyledSelectChip = styled(SelectChip)`
`;

export const OutageStyledCustomScrollbarWrapper = styled(CustomScrollbarWrapper)`
  height: calc(100% - 50px);
  overflow-x: hidden;
  border-radius: 16px;
`;