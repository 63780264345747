import React, { useEffect ,useState} from 'react';
import get from 'lodash/get';
import * as StyledComponents from '../StyledComponents';
import { PrimaryButton, IconSchedule, DatePicker } from 'cdk-radial';
import { useUnifyAdminStore } from '../../AdminStore';
import { TimeField } from '../Elements/TimeField';
import './notification.css';
import {
  useGetAllApplications,
  useGetAllWorkflows,
} from '../../../../hooks/queries';
import { MultiSelectDropdownMenu } from '../Elements/MultiSelectDropdownMenu';
import find from 'lodash/find';
import {  createOutageModel,
  updateOutageModel } from '../Store/modelTransformation';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import {useCreateQuery,useUpdateQuery} from '../Store/useOutageNotificationQuery';
import CustomEditor from './CustomEditor';

type Positions = {
  top: string;
  bottom: string;
  left: string;
  right: string;
};

type ActionButtonsContainer = {
  children: React.ReactNode;
  position?: string;
  positionProps?: Partial<Positions>;
  background?: string;
};

const ActionButtonContainer = ({
  children,
  position,
  positionProps,
  background,
}: ActionButtonsContainer): JSX.Element => {
  return (
    <StyledComponents.StyledActionButtonContainer
      positionProps={positionProps}
      position={position}
      background={background}
    >
      {children}
    </StyledComponents.StyledActionButtonContainer>
  );
};

function validateURL(url: string) {
  if (
    //eslint-disable-next-line
    /^(http[s]?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      url
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export const StyledWrapperDatePicker = styled.div<{
  maxWidth: string;
  dayPickerLeft: string;
}>`
  max-width: ${(props) => props.maxWidth || '150px'};
  .DayPicker {
    left: ${(props) => props.dayPickerLeft || 'unset'};
    bottom: 0;
    margin-bottom: 55px;
    top: auto;
  }
`;

const applicationToOptions = (data: any) => {
  if (data && data[0]) {
    const applications = data[0];
    if (applications) {
      const filteredApps = applications.filter(
        (app: any) =>
          app.isActive === true &&
          app.name !== 'Fortellis Marketplace Operations' &&
          app.name !== 'CDK University' &&
          app.name !== 'Customer Care' &&
          app.name !== 'Unify Administration'
      );
      let options = filteredApps.map((application: any) => {
        return {
          id: application.appId,
          label: application.name,
          value: application.appId,
        };
      });
      return options;
    }
  }
};

interface Option {
  id: string;
  label: string;
  value: string;
}

const filterWorkflowOptions = (applications: any, workflowsData: any) => {
  let filtered = [] as Option[];
  if (workflowsData) {
    const workflows = workflowsData[0];
    if (workflows) {
      workflows.forEach((workflow: any) => {
        if (
          workflow.applications &&
          workflow.applications[0] &&
          workflow.applications[0].appId &&
          workflow.isActive === true
        ) {
          const applId = workflow.applications[0].appId;
          const found = find(applications, (app: any) => app.id === applId);
          if (found) {
            filtered.push({
              id: workflow.workflowId,
              label: workflow.name,
              value: workflow.workflowId,
            });
          }
        }
      });
    }
  }
  return filtered;
};

export const OutageSideSheetDetails = (): JSX.Element => {
  const { darkMode } = useTheme();

  const sideSheetData = useUnifyAdminStore(
    (state) => state.productOutageSideSheetData
  );
  const archiveSwitch = useUnifyAdminStore((state) => state.productOutageArchiveSwitch);

  const {mutation:createMutation} = useCreateQuery({
    onSuccess: () => {
      setProductOutageShowToastState(`Created ${sideSheetData.title}`, 'Success');
      closeSideSheet()
    },
    onError: () => {
      setProductOutageShowToastState('Failed to create a product alert', 'Error');
      closeSideSheet()
    }
  });

  const {mutation:updateMutation} = useUpdateQuery({
    onSuccess: () => {
      setProductOutageShowToastState(`Updated ${sideSheetData.title}`, 'Success');
      closeSideSheet()
    },
    onError: () => {
      setProductOutageShowToastState('Failed to update a product alert', 'Error');
      closeSideSheet()
    },
  });

  const setWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setAdminSideSheetCollapse
  );
  const setApplicationSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setWorkflowSideSheetCollapse
  );
  const setProductOutageSideSheetCollapse = useUnifyAdminStore(
    (state) => state.setProductOutageSideSheetCollapse
  );

  const resetOutageNotifcationSideSheetState = useUnifyAdminStore(
    (state) => state.resetOutageNotifcationSideSheetState
  );

  const { data: applications } = useGetAllApplications();
  const { data: workflows } = useGetAllWorkflows();

  const outagePermission = useUnifyAdminStore(
    (state) => state.permissions
  );
  const applicationOptions = applicationToOptions(applications);
  const workflowOptions = filterWorkflowOptions(
    get(sideSheetData, 'targetApplications', []),
    workflows
  );

  const updateProductOutageSideSheetData = useUnifyAdminStore(
    (state) => state.updateProductOutageSideSheetData
  );
  const setProductOutageShowToastState = useUnifyAdminStore(
    (state) => state.setProductOutageShowToastState
  );
  const setIsValidationState = useUnifyAdminStore(
    (state) => state.setIsValidationState
  );

  const [status,setStatus] = useState('open');

  useEffect(()=>{
    if(sideSheetData.caseId &&
        sideSheetData.endDate !=null){
      setStatus('close');
    }
  },[sideSheetData]);

  const onApplicationSelect = (applications: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'targetApplications',
      value: applications,
    });
    const workflowOptions = filterWorkflowOptions(applications, workflows);

    //For removing selected workflows when their application deselect
    const selectedWorkflows = { ...sideSheetData.targetWorkflows };
    const updatedSelectedWorkflows = Object.values(selectedWorkflows).filter(
      (selectedWorkflow: any) =>
        workflowOptions.some(
          (workflowOption: any) =>
            workflowOption.id === selectedWorkflow.id
        )
    );
    updateProductOutageSideSheetData({
      fieldName: 'targetWorkflows',
      value: updatedSelectedWorkflows,
    });
  };
  const onWorkflowSelect = (applications: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'targetWorkflows',
      value: applications,
    });
  };
  const onStartDateChange = (startDate: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'startDate',
      value: startDate,
    });
  };
  const onEndDateChange = (endDate: any) => {
    updateProductOutageSideSheetData({ fieldName: 'endDate', value: endDate });
  };
  const onStartTimeChange = (startTime: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'startTime',
      value: startTime,
    });
  };
  const onEndTimeChange = (endTime: any) => {
    updateProductOutageSideSheetData({ fieldName: 'endTime', value: endTime });
  };
  const onCreatedTimeChange = (createdTime: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'createdTime',
      value: createdTime,
    });
  };
  const onCreatedDateChange = (createdDate: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'createdDate',
      value: createdDate,
    });
  };
  const onTitleChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'title',
      value: e.target.value,
    });
  };
  const onCaseIdChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'caseId',
      value: e.target.value,
    });
  };
  const onShortDescChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'subject',
      value: e.target.value,
    });
  };
  const onLongDescChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'body',
      value: e.target.value,
    });
  };

  const onTypeChange = (type: any) => {
    updateProductOutageSideSheetData({ fieldName: 'outageType', value: type });
  };
  const onLearnMoreChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'linkToLearnMore',
      value: e.target.value,
    });
  };
  const ontargetEnterpriseChange = (e: any) => {
    updateProductOutageSideSheetData({
      fieldName: 'targetEnterprises',
      value: e.target.value,
    });
  };

  const onStatusChange = (e: any) => {
    setStatus(e);
    if (e === 'open'){
      updateProductOutageSideSheetData({ fieldName: 'endDate', value: null });
      updateProductOutageSideSheetData({ fieldName: 'endTime', value: '' });
    }
  };

  const closeSideSheet = () => {
    setWorkflowSideSheetCollapse(true);
    setApplicationSideSheetCollapse(true);
    setProductOutageSideSheetCollapse(true);
    resetOutageNotifcationSideSheetState();
  };

  const handleSave = () => {
    if (!handleValidation()) return;
    createMutation.mutate(createOutageModel(sideSheetData));
  };

  const handleUpdate = () => {
    if (!handleValidation()) return;
      const model = [];
      model.push({messageId:sideSheetData.messageId});
      model.push(updateOutageModel(sideSheetData));
      updateMutation.mutate(model);
  };

  const handleCancel = () => {
    closeSideSheet();
  };

  const handleValidation = () => {
    if (!sideSheetData.title) {
      setIsValidationState(true);
      setProductOutageShowToastState('Please enter a valid Title', 'Error');
      return false;
    }
    if (!sideSheetData.subject) {
      setIsValidationState(true);
      setProductOutageShowToastState(
        'Please enter a valid Short Description',
        'Error'
      );
      return false;
    }
    if (!sideSheetData.body) {
      setIsValidationState(true);
      setProductOutageShowToastState(
        'Please enter a valid Detailed Description',
        'Error'
      );
      return false;
    }

    const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (sideSheetData.outageType === 'Unplanned') {
      if (!sideSheetData.caseId) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid Incident Number',
          'Error'
        );
        return false;
      }

      if (!sideSheetData.createdDate) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid Created Date',
          'Error'
        );
        return false;
      }

      if (
        !sideSheetData.createdTime ||
        !timeRegex.test(sideSheetData.createdTime)
      ) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid Created Time',
          'Error'
        );
        return false;
      }

      if (status === 'close'){
        if (!sideSheetData.endDate) {
          setIsValidationState(true);
          setProductOutageShowToastState(
            'Please enter a valid End Date',
            'Error'
          );
          return false;
        }
  
        if (!sideSheetData.endTime || !timeRegex.test(sideSheetData.endTime)) {
          setIsValidationState(true);
          setProductOutageShowToastState(
            'Please enter a valid End Time',
            'Error'
          );
          return false;
        }
      }

    }

    if (sideSheetData.outageType === 'Planned') {
      if (!sideSheetData.startDate) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid Start Date',
          'Error'
        );
        return false;
      }

      if (
        !sideSheetData.startTime ||
        !timeRegex.test(sideSheetData.startTime)
      ) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid Start Time',
          'Error'
        );
        return false;
      }

      if (!sideSheetData.endDate) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid End Date',
          'Error'
        );
        return false;
      }

      if (!sideSheetData.endTime || !timeRegex.test(sideSheetData.endTime)) {
        setIsValidationState(true);
        setProductOutageShowToastState(
          'Please enter a valid End Time',
          'Error'
        );
        return false;
      }
    }

    const isValid =
      sideSheetData?.targetEnterprises
        ?.split(',')
        .map((enterprise) => enterprise.trim())
        .every(
          (enterprise) => /^E\d{6}$/.test(enterprise) || enterprise === ''
        ) ?? true;
    if (sideSheetData?.targetEnterprises && !isValid) {
      setIsValidationState(true);
      setProductOutageShowToastState(
        'Please enter a valid Enterprise details',
        'Error'
      );
      return false;
    }

    if (
      sideSheetData?.linkToLearnMore &&
      !validateURL(sideSheetData?.linkToLearnMore)
    ) {
      setIsValidationState(true);
      setProductOutageShowToastState(
        'Please enter a valid Learn More link',
        'Error'
      );
      return false;
    }

    return true;
  };

  return (
    <>
      <StyledComponents.StyledInput
        dataTestId={`title-input`}
        errorMessage="Please enter a valid Title"
        id={`title-name-input`}
        inputDivider="left"
        label={'Title'}
        name={'title'}
        onChange={onTitleChange}
        placeholder={'Enter title'}
        value={get(sideSheetData, 'title', '')}
        hasCharacterCount={true}
        maxLength={100}
        isRequired={true}
        isDisabled={archiveSwitch}
      />
      <StyledComponents.StyledInput
        dataTestId={`short-desc-input`}
        errorMessage="Please enter short description."
        id={`short-desc-name-input`}
        inputDivider="left"
        label={`Short Description`}
        name="subject"
        placeholder={'Enter short description'}
        onChange={onShortDescChange}
        value={get(sideSheetData, 'subject', '')}
        maxLength={150}
        hasCharacterCount={true}
        isRequired={true}
        isDisabled={archiveSwitch}
      />
      <div style={{ marginTop: '10px'}}>
        <StyledComponents.StyledCustomEditorLabel>Detailed Description</StyledComponents.StyledCustomEditorLabel>
          <CustomEditor 
            value={get(sideSheetData, 'body', '')} 
            onChange={onLongDescChange} >
          </CustomEditor>
          <StyledComponents.StyledLongDescCount>
            <StyledComponents.StyledCountLabel>
              {get(sideSheetData, 'body', '').length}/5000
            </StyledComponents.StyledCountLabel>
          </StyledComponents.StyledLongDescCount>
      </div>
      
      <StyledComponents.StyledChoiceChips
        style={{ marginLeft: '0px' }}
        dataTestId={`choice-chips`}
        onClick={onTypeChange}
        id={`type-name-input`}
        name="outageType"
        options={[
          { label: 'UnPlanned', value: 'Unplanned' },
          { label: 'Planned', value: 'Planned' },
        ]}
        value={get(sideSheetData, 'outageType')}
        isDisabled={sideSheetData.messageId ? true : false}
      />
      {get(sideSheetData, 'outageType') === 'Unplanned' && (
        <>
        <StyledComponents.StyledInput
          dataTestId={`caseid-input`}
          errorMessage="Please enter Incident number."
          id={`caseid-name-input`}
          inputDivider="left"
          label={`Incident No.`}
          name="caseId"
          onChange={onCaseIdChange}
          placeholder={`Enter incident no./case no. `}
          value={get(sideSheetData, 'caseId', '')}
          isRequired={true}
          isDisabled={sideSheetData.messageId ? true : false}
        />
      <div style={{ marginTop: '10px' }}>
          {
            sideSheetData.messageId &&
                      <StyledComponents.StyledSelectChip
                        defaultSelectedValue={status}
                        defaultLabel="Select"
                        style={{ zIndex: '1000' }}
                        onSelect={onStatusChange}
                        options={[
                          {
                            id: 'open',
                            label: 'Open',
                            value: 'open',
                          },
                          {
                            id: 'closed',
                            label: 'Close',
                            value: 'close',
                          },
                        ]}
                        prefix="Status :"
                        isDisabled={(sideSheetData.messageId && !archiveSwitch) ? false : true}
                      />
          }

          {
            (status === 'close') && (
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
            <div style={{ width: '180px',marginTop: '10px' }}>
              <DatePicker
                dataTestId="up-end-date-picker"
                date={get(sideSheetData, 'endDate', null)}
                dateFormat="MM/DD/YYYY"
                dateInputProps={{
                  enableCustomValidation: false,
                  errorMessage: 'Error message',
                  hasError: false,
                  isReadOnly: false,
                  isRequired: false,
                  isDisabled:archiveSwitch,
                  size: 'standard',
                }}
                id="up-end-date-picker"
                label="End Date"
                maximumYear={2100}
                minimumYear={1900}
                name="up-end-date-picker"
                numberOfMonths={1}
                onDateChange={onEndDateChange}
                shortcutChipsPosition="none"
              />
            </div>
            <div style={{ width: '180px' }}>
              <TimeField
                handleChange={onEndTimeChange}
                value={get(sideSheetData, 'endTime', '')}
                name={'endTime'}
                label={'End Time'}
                isDisabled={archiveSwitch}
              />
            </div>
          </div>
            )
          }
          </div>
          </>
      )}
      <MultiSelectDropdownMenu
        options={applicationOptions || []}
        name="targetApplications"
        onSelect={onApplicationSelect}
        value={get(sideSheetData, 'targetApplications', '')}
        label={'Target Applications'}
        placeholder={'Select applications'}
        isDisabled={archiveSwitch}
      ></MultiSelectDropdownMenu>
      <MultiSelectDropdownMenu
        options={workflowOptions || []}
        name="targetWorkflows"
        onSelect={onWorkflowSelect}
        value={get(sideSheetData, 'targetWorkflows', '')}
        label={'Target Workflows'}
        placeholder={'Select workflows'}
        isDisabled={archiveSwitch}
      ></MultiSelectDropdownMenu>
      <StyledComponents.StyledInput
        dataTestId={`target-enterprise-desc-input`}
        errorMessage="Error message"
        id={`target-enterprise-name-input`}
        inputDivider="left"
        label={`Target Enterprises`}
        name="targetEnterprises"
        placeholder={'Eg. E123221,E343212'}
        onChange={ontargetEnterpriseChange}
        value={get(sideSheetData, 'targetEnterprises', '')}
        hasCharacterCount={true}
        isDisabled={archiveSwitch}
      />
      {get(sideSheetData, 'outageType') === 'Unplanned' && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '170px', marginTop: '10px' }}>
            <StyledWrapperDatePicker dayPickerLeft="0px" maxWidth="180px">
              <DatePicker
                dataTestId="create-date-picker"
                date={get(sideSheetData, 'createdDate', null)}
                dateFormat="MM/DD/YYYY"
                dateInputProps={{
                  enableCustomValidation: false,
                  errorMessage: 'Please select Created Date.',
                  hasError: false,
                  isReadOnly: false,
                  isRequired: true,
                  isDisabled: archiveSwitch,
                  size: 'standard',
                  onError: () => {},
                }}
                allowDaysInPast={true}
                id="create-date-picker"
                label="Created Date"
                maximumYear={2100}
                minimumYear={1900}
                name="create-date-picker"
                numberOfMonths={1}
                onDateChange={onCreatedDateChange}
                shortcutChipsPosition="none"
              />
            </StyledWrapperDatePicker>
          </div>
          <div style={{ width: '180px' }}>
            <TimeField
              handleChange={onCreatedTimeChange}
              value={get(sideSheetData, 'createdTime', '')}
              name={'createdTime'}
              label={'Created Time'}
              isDisabled={archiveSwitch}
            />
          </div>
        </div>
      )}
      {get(sideSheetData, 'outageType') === 'Planned' && (
        <>
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '180px' }}>
              <StyledWrapperDatePicker dayPickerLeft="0px" maxWidth="180px">
                <DatePicker
                  dataTestId="start-date-picker"
                  date={get(sideSheetData, 'startDate', null)}
                  dateFormat="MM/DD/YYYY"
                  dateInputProps={{
                    enableCustomValidation: false,
                    errorMessage: 'Please select Start Date.',
                    hasError: false,
                    isDisabled:archiveSwitch,
                    isReadOnly: false,
                    isRequired: true,
                    size: 'standard',
                    onError: () => {},
                  }}
                  id="start-date-picker"
                  label="Start Date"
                  maximumYear={2100}
                  minimumYear={1900}
                  name="start-date-picker"
                  numberOfMonths={1}
                  onDateChange={onStartDateChange}
                  shortcutChipsPosition="none"
                />
              </StyledWrapperDatePicker>
            </div>

            <div style={{ width: '180px' }}>
              <StyledWrapperDatePicker dayPickerLeft="-132px" maxWidth="180px">
                <DatePicker
                  className="endDatePicker"
                  dataTestId="end-date-picker"
                  date={get(sideSheetData, 'endDate', null)}
                  dateFormat="MM/DD/YYYY"
                  dateInputProps={{
                    enableCustomValidation: false,
                    errorMessage: 'Please select End Date.',
                    hasError: false,
                    isDisabled:archiveSwitch,
                    isReadOnly: false,
                    isRequired: true,
                    size: 'standard',
                    onError: () => {},
                  }}
                  id="end-date-picker"
                  label="End Date"
                  maximumYear={2100}
                  minimumYear={1900}
                  name="end-date-picker"
                  numberOfMonths={1}
                  onDateChange={onEndDateChange}
                  shortcutChipsPosition="none"
                />
              </StyledWrapperDatePicker>
            </div>
          </div>

          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '180px' }}>
              <TimeField
                icon={<IconSchedule />}
                handleChange={onStartTimeChange}
                value={get(sideSheetData, 'startTime', '')}
                name={'startTime'}
                label={'Start Time'}
                isDisabled={archiveSwitch}
              />
            </div>
            <div style={{ width: '180px' }}>
              <TimeField
                handleChange={onEndTimeChange}
                value={get(sideSheetData, 'endTime', '')}
                name={'endTime'}
                label={'End Time'}
                isDisabled={archiveSwitch}
              />
            </div>
          </div>
        </>
      )}
      <div style={{ marginTop: '10px' }}>
        <StyledComponents.StyledTextAreaWithCustomHeight
          customHeight="20px"
          dataTestId={`learn-more-input`}
          errorMessage="Error message"
          id={`learn-more-name-input`}
          inputDivider="left"
          label={`Learn More`}
          name="learnMore"
          onChange={onLearnMoreChange}
          placeholder={`Eg. https://connectcdk.com/`}
          value={get(sideSheetData, 'linkToLearnMore', '')}
          maxLength={200}
          isDisabled={archiveSwitch}
        />
      </div>
      <ActionButtonContainer
        position="sticky"
        positionProps={{ bottom: '0px' }}
        background={
          darkMode
            ? 'linear-gradient(black, transparent)'
            : 'linear-gradient(white, transparent)'
        }
      >
        <div>
          <StyledComponents.StyledTextButton
            dataTestId="text-button"
            iconColor="primary"
            onClick={handleCancel}
            text="Cancel"
          />

          {outagePermission?.hasProductAlertUpdatePermissions &&
            sideSheetData.messageId &&  !archiveSwitch && (
              <PrimaryButton
                dataTestId="save-side-sheet-button"
                iconColor="primary"
                onClick={handleUpdate}
                text={'Update'}
                isDisabled={updateMutation.isLoading}
                
              />
            )}

          {!sideSheetData.messageId && (
            <PrimaryButton
              dataTestId="save-side-sheet-button"
              iconColor="primary"
              onClick={handleSave}
              text={'Save'}
              isDisabled={createMutation.isLoading}
            />
          )}
        </div>
      </ActionButtonContainer>
    </>
  );
};
