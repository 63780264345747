import styled from 'styled-components';
import { Dialog } from 'cdk-radial';
import { useTheme } from '../../../../contexts/Theme';
import { BUTTON_VARIANTS } from 'cdk-radial';
import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
} from 'cdk-radial';

export const StyledDialog = styled(Dialog)`
  min-width: 715px;
  max-width: 30%;
  margin-top: 60px;
  z-index: 9999;
`;

export const StyledDialogTitle = styled.div`
  fontweight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  text-transform: capitalize;
`;

export const StyledDialogTitleContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const StyledSimpleTable = styled(SimpleTable)`
  width: 580px;
  margin-top: 20px;
`;
const StyledTableColumn = styled(SimpleTableData)`
  width: 180px;
`;

export const StyledDialogContentText = styled.div``;

interface IDeleteConfirmDialog {
  isOpen: boolean;
  onYes: () => void;
  onNo: () => void;
  title: string;
  subject: string;
}
export const DeleteConfirmDialog = ({
  isOpen,
  onYes,
  onNo,
  title,
  subject,
}: IDeleteConfirmDialog): JSX.Element => {
  const { darkMode } = useTheme();
  return (
    <div>
      <StyledDialog
        buttonsProps={[
          {
            id: 'Cancel',
            dataTestId: 'delete-dialog-cancel',
            onClick: onNo,
            text: 'Cancel',
            variant: BUTTON_VARIANTS.TEXT,
            style: {
              color: darkMode ? 'white' : 'black',
            },
          },
          {
            id: 'delete-dialog-yes',
            onClick: onYes,
            text: 'Delete',
            variant: BUTTON_VARIANTS.PRIMARY,
            style: {
              color: darkMode ? 'black' : 'white',
              backgroundColor: darkMode ? 'white' : 'black',
            },
          },
        ]}
        id="delete-dialog-no"
        isOpen={isOpen}
        onClose={onNo}
        title={
          <StyledDialogTitleContainer>
            <StyledDialogTitle>Confirm Delete</StyledDialogTitle>
          </StyledDialogTitleContainer>
        }
      >
        <StyledDialogContentText>
          The following product alert will be deleted:
        </StyledDialogContentText>
        <StyledSimpleTable>
          <SimpleTableHead>
            <SimpleTableRow>
              <SimpleTableHeader>Title</SimpleTableHeader>
              <SimpleTableHeader>Subject</SimpleTableHeader>
            </SimpleTableRow>
          </SimpleTableHead>
          <SimpleTableBody>
            <SimpleTableRow>
              <StyledTableColumn>{title}</StyledTableColumn>
              <SimpleTableData>{subject}</SimpleTableData>
            </SimpleTableRow>
          </SimpleTableBody>
        </StyledSimpleTable>
      </StyledDialog>
    </div>
  );
};
