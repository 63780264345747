import {useUnifyAdminStore} from '../../AdminStore';
import * as StyledComponents from '../StyledComponents';
import {SWITCH_LABEL_POSITIONS} from 'cdk-radial';

export const CampaignEnablementStatusField = (): JSX.Element => {

    /**
     * Data Store
     */
    const campaignItems = useUnifyAdminStore((state) => state.campaignItems);
    const updateCreateCampaignData = useUnifyAdminStore(
        (state) => state.updateCreateCampaignData
    );
    const permissions = useUnifyAdminStore(state => state.permissions); 

    const handleUpdateEnablementField = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        return updateCreateCampaignData({
            fieldName: event.target.name,
            value: event.target.checked
        });
    };

    return (
            <StyledComponents.StyledCampaignEnablementSwitch
                data-testId="campaign-status-switch"
                id="campaign-status-switch"
                label="Publish"
                name="isActive"
                onChange={(e:any)=>handleUpdateEnablementField(e)} // todo : check switch
                placeholder="Campaign Enablement"
                value={campaignItems.isActive}
                checked={campaignItems.isActive}
                labelPosition={SWITCH_LABEL_POSITIONS.BEFORE}
                isDisabled={campaignItems.id
                    && (!permissions?.hasCampaignUpdatePermissions && !permissions?.hasCampaignSuperAdminPermissions)}
            />
    );
};
