import React, { useEffect,useState } from 'react';
import get from 'lodash/get';
import {
  DescriptionField,
  NameField,
  LogoutURLField,
  URLField,
  AbbreviationField,
  AppIdField,
  WorkflowIdIdField,
  EnterpriseIncludePermissionField,
  EnterpriseExcludePermissionField
} from '../Elements';
import {
  AdminSideSheetFooterActions,
  CreateNewWorkflowAction,
  ViewLogsAndCopyActions,
} from './AdminActionButtons';
import { AdminCardPreview } from './AdminCardPreview';
import { CommonDropdown } from './AdminCommonSelector';
import { AdminWorkflowExplorer } from './AdminWorkflowExplorer';
import { ISideSheetData, IWorkflow } from '../AdminStore/slices/types/types';
import { SimpleLabel } from '../Elements/SimpleLabel';
import { useUnifyAdminStore } from '../AdminStore';
import {
  useApplicationCategory,
} from '../../../hooks/queries';
import { CdkuURLField } from '../Elements/CdkuURLField';

const statusOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

interface IDetailSideSheet {
  sideSheetData: ISideSheetData | IWorkflow;
}

interface KeyValuePair {
   label: string,
   value: string
}

export interface iAppCategory {
  appCategory? : object
}

export const AdminSideSheetDetails = ({
  sideSheetData,
}: IDetailSideSheet): JSX.Element => {
    /**
     * Data hooks
     */
    const createNewApplication = useUnifyAdminStore(state => state.createNewApplication)
    const createNewWorkflow = useUnifyAdminStore(state => state.createNewWorkflow)
    const { data } = useApplicationCategory();
    const [category,setCategory] = useState<KeyValuePair[]>();
    const [selectedCategory,setSelectedCategory] = useState<KeyValuePair>({label:'',value:''});
    /**
   * Local Component derived states
   */
  const shouldApplicationFieldsDisplay = get(sideSheetData, 'appId', false);

  useEffect(()=>{
    if (data && data.appCategory){
      let array = Object.keys(data.appCategory);
      let categories:KeyValuePair[] = [];
      array.forEach(element => categories.push({label:element , value:element.toLowerCase()}));
      setCategory(categories);
      let provider = get(sideSheetData, 'appCategory', '');
      let selected:any = categories.find((cat:any) => cat.value === provider);
      setSelectedCategory(selected);
    }

  },[data,sideSheetData])

  const selectedEnablementOption = {
    label: get(sideSheetData, 'isActive', false) ? 'Active' : 'Inactive',
    value: get(sideSheetData, 'isActive', false)
  };
  
  const onSelect = (options:any) =>{
    setSelectedCategory(options);
  }

  
  return (
    <>
      {!shouldApplicationFieldsDisplay && <SimpleLabel />}
      {(createNewApplication || createNewWorkflow) &&  <AppIdField/>}
      { createNewWorkflow &&  <WorkflowIdIdField/>}
      <NameField />
      <DescriptionField />
      <CommonDropdown
        placeholder="Enablement Status"
        options={statusOptions}
        selectedOption={selectedEnablementOption}
        label="Enablement Status"
        name='isActive'
        />
      <EnterpriseIncludePermissionField/>
      <EnterpriseExcludePermissionField/>
      <URLField />
      <CdkuURLField />
      {(shouldApplicationFieldsDisplay || createNewApplication) && <LogoutURLField />}
      {(shouldApplicationFieldsDisplay || createNewApplication) && <AbbreviationField />}
      {(shouldApplicationFieldsDisplay || createNewApplication) && !createNewWorkflow && (
      <CommonDropdown
        options={category}
        selectedOption={selectedCategory}
        onSelect={onSelect}
        placeholder="App Category"
        label="App Category"
        name='appCategory'
       />
      )}

      {(!createNewApplication && !createNewWorkflow) && <ViewLogsAndCopyActions />}
      {(shouldApplicationFieldsDisplay && !createNewApplication && !createNewWorkflow) && <CreateNewWorkflowAction />}
      {(shouldApplicationFieldsDisplay && !createNewApplication && !createNewWorkflow) && <AdminWorkflowExplorer />}
      <AdminCardPreview categories={data} selectedCategory={selectedCategory}/>
      <AdminSideSheetFooterActions />
    </>
  );
};
