import {create} from "zustand";
import { IAMUserInfo } from '../types';

export interface IamUserInfoState {
    iamUserInfo: IAMUserInfo;
    setIamUserInfo: (iamUserInfo:IAMUserInfo) => void;
    isLoading: boolean;
    setIsLoading: (isLoading:boolean) => void;
    isErrror: boolean;
    setIsError: (isErrror:boolean) => void;
  }
  
export const useIamUserProfileStore = create<IamUserInfoState>((set) => ({
    iamUserInfo: {},
    setIamUserInfo: (iamUserInfo) => set(() => ({ iamUserInfo })),
    isLoading: false,
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    isErrror: false,
    setIsError: (isErrror) => set(() => ({ isErrror }))
 }));
