import {Checkbox} from 'cdk-radial'
import get from 'lodash/get';
import React from 'react';
import { useUnifyAdminStore } from '../AdminStore';
import { AdminInputWithCustomHelperText } from '../Blocks/AdminInputWithCustomHelperText';
export const DescriptionField = (): JSX.Element => {

  /**
   * Data Store
   */
  const permissions = useUnifyAdminStore(state => state.permissions);
  const sideSheetData = useUnifyAdminStore((state) => state.sideSheetData);
  const showFrenchTranslation = useUnifyAdminStore((state) => state.showFrenchTranslation)
  const setShowFrenchTranslation = useUnifyAdminStore((state) => state.setShowFrenchTranslation)
  const sideSheetSubWorkflowData = useUnifyAdminStore(
    (state) => state.sideSheetDataSubWorkflow
  );
  const updateSideSheetData = useUnifyAdminStore(
    (state) => state.updateSideSheetData
  );
  const isWorkflowSideSheetCollapse = useUnifyAdminStore(
    (state) => state.isWorkflowSideSheetCollapse
  );
  const handleUpdateNameField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    return updateSideSheetData({
      fieldName: 'description',
      value: { [event.target.name]: event.target.value, enUS:description.enUS}, //English description is always readonly
    });
  };
  const handleUpdateEnDescriptionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    return updateSideSheetData({
      fieldName: 'description',
      value: {[event.target.name]: event.target.value, frCA:description.frCA},
    });
  };

  const description = get(
    !isWorkflowSideSheetCollapse ? sideSheetSubWorkflowData : sideSheetData,
    'description'
  );
  return (
    <>
      <AdminInputWithCustomHelperText
        helperLabel={`${description?.enUS?.length || 0}/200`}
        dataTestId="description-input"
        errorMessage="Error message"
        id="description-input"
        inputDivider="left"
        label="Description"
        name="enUS"
        onChange={handleUpdateEnDescriptionField}
        onInputClear={function noRefCheck() {}}
        placeholder="Description"
        maxLength={200}
        value={description?.enUS}
        rows={Number(description?.enUS?.length / 48).toPrecision(1)}
        isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
      />
      <Checkbox
          checked={showFrenchTranslation}
          dataTestId="show-french-checkbox"
          id="newsletter"
          label="Show French Description"
          labelPosition="after"
          name="frenchShow"
          onChange={()=>setShowFrenchTranslation(!showFrenchTranslation)}
          size="standard"
      />
      {
          showFrenchTranslation &&
          <AdminInputWithCustomHelperText
          helperLabel={`${description?.frCA?.length || 0}/350`}
          dataTestId="fr-description-input"
          errorMessage="Error message"
          id="fr-description-input"
          inputDivider="left"
          label="French Description"
          name="frCA"
          onChange={handleUpdateNameField}
          onInputClear={function noRefCheck() {}}
          placeholder="French Description"
          maxLength={350}
          value={description?.frCA}
          rows={Number(description.frCA?.length / 48).toPrecision(1)}
          isDisabled={permissions?.hasAppReadOnlyPermissions && !permissions?.hasAppSuperAdminPermissions && !permissions.hasAppUpdatePermissions}
          />
      }
    </>
  );
};
