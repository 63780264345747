import { useEffect, useState } from 'react';
import { Profile } from './Profile';

export type CDKContactProfile = {
  firstName: string;
  lastName: string;
  title: string;
  phoneNo: string;
  email: string;
};

export const MeetMyTeamList = ({data}:any) =>{
    const [profiles, setProfiles] = useState<CDKContactProfile[]>([]);
    const [noDataFound,setNoDataFound] = useState<boolean>();
    useEffect(()=>{
      if (data === null || (data && data.length === 0)){
        setNoDataFound(true);
      }
      if (data){
        setProfiles(data);
      }
    },[data]);

    return (
      <div role="list">
       {noDataFound && <div>CDK Contact Not Found </div>}
       {profiles.map((profile:any,index:any) =>(
          <Profile profile={profile} key={index}></Profile>
        ))}
      </div>
    )
  }