import Pane from './Pane';
import { usePaneManagement } from '../../contexts/PaneManagementContext';
import { useAllWorkflowsAndApplications } from '../../hooks/queries';
import { isNonNullable } from '../../types/typeGuards';
import { useChannelMessage } from '../MessageProvider/MessageContext';
import { PaneData } from '../../types';
import AppStack from '../AppStack/AppStack';

export default function PaneManager() {
  const { openPanes, ctaPane, activePane, isPaneExpaneded, cdkuPane } =
    usePaneManagement();
  const { data } = useAllWorkflowsAndApplications();
  const { addProduct } = useChannelMessage();

  const workflows = data.map((app: any) => {
    // adding logoutUrl to workflows from its parent application
    const workflow = app.appId
      ? {
          ...app,
          logoutUrl:
            data.find((item) => item.id === app.appId)?.logoutUrl || '',
        }
      : { ...app };
    return workflow;
  });

  let panes = openPanes
    .map((paneId) =>
      (workflows || data).find((workflow: any) => workflow.id === paneId)
    )
    .filter(isNonNullable);

  const frameLoadHandler = (pane: PaneData, evt: Event) => {
    const { id, url: origin, logoutUrl } = pane;
    addProduct({
      id,
      origin,
      logoutUrl,
      frameWindow: (evt.target as HTMLIFrameElement).contentWindow,
    });
  };

  return (
    <>
      <AppStack 
        cdkuPane={cdkuPane || ''} 
        openedApps={panes} 
        activeApp={activePane || ''} 
        showStack={isPaneExpaneded}
        frameLoadHandler={frameLoadHandler} />
      
      {ctaPane && (
        <Pane
          key={'ctapane'}
          active={activePane === 'ctapane'}
          isExpanded={isPaneExpaneded}
          url={ctaPane}
          name={'ctapane'}
          id={'ctapane'}
        />
      )}
    </>
  );
}
