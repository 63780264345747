import {create} from "zustand";
import { UserSettings } from '../types';

export interface UserSettingsState {
    userSettings: UserSettings;
    setUserSettings: (userSettings:UserSettings) => void;
    apiStatus: string;//"error" | "idle" | "loading" | "success"
    setApiStatus: (apiStatus:string) => void;
  }
  
export const useUserSettingsStore = create<UserSettingsState>((set) => ({
   userSettings: {
    theme: "dark"
   },
   setUserSettings: (userSettings) => set(() => ({ userSettings })),
   apiStatus: "",
   setApiStatus: (apiStatus) => set(() => ({ apiStatus })),
 }));
