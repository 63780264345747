import { useTranslation } from "react-i18next";
import { useTheme } from "../../contexts/Theme";
import { IconInfo, IconEdit, IconDelete, IconExpandLess, IconExpandMore } from 'cdk-radial';
import { UserCardsContainer, UserCardsHeader, CardButton } from "./UserCardStyles"
import { useEffect, useState } from "react";
import UserCardForm from "./UserCardForm";
import { useUserCards, useCreateUserCard, useUpdateUserCard, useDeleteUserCard } from "../../hooks/queries";
import { CardMenu } from "../Cards/BaseCard";
import { useShowDialog } from '../../contexts/AppStateContext';
import { UserCardsList } from "./UserCardsList";
import CardDeleteDialog from "../Dialogs/CardDeleteDialog";
import WorkflowContainer from "../WorkflowContainer/WorkflowContainer";
import { RoundedIconButton } from "../Buttons/IconButton";
import { useAnalytics } from "dms-analytics";
import { AMPLITUDE_EVENTS } from "../../constants";
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk'

export interface CustomApps {
    apps?: UserCard[];
    loginId?: string;
}

export interface UserCard {
    name?: string;
    description?: string;
    url?: string;
    iconText?: string;
    appId?: string;
}

export function UserCards() {
    const {t} = useTranslation();
    const menuOptions = [
        { label: t('baseCard.menu.learnMore'), key:'LEARN_MORE',  icon: <IconInfo /> },
        { label: t('baseCard.menu.editCard'), key: 'EDIT_CARD', icon: <IconEdit /> },
        { label: t('baseCard.menu.deleteCard'), key: 'DELETE_CARD', icon: <IconDelete/> },
    ];
    const { darkMode } = useTheme();
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const { data: userCards } = useUserCards();
    const { data: updatedUserCards, mutate: saveUserCard } = useCreateUserCard();
    const { mutate: updateUserCard } = useUpdateUserCard();
    const { mutate: deleteUserCard } = useDeleteUserCard(); 
    const [ userCardList, setUserCardList ] = useState<CustomApps>();
    const showDialog = useShowDialog();
    const [ editingCard, setEditingCard ] = useState<UserCard | null>();
    const userCustomCardsFeature = useFeatureFlag('UNIFY_UI_CUSTOM_CARDS');
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState(false);
    const [ menuCard, setMenuCard ] = useState<UserCard | null>();
    const [ isExpanded, setIsExpanded ] = useState(true);
    const { trackEvent } = useAnalytics();

    useEffect( () => {
        userCards && setUserCardList(userCards);
        setEditingCard({} as UserCard);
    }, [userCards] );

    useEffect( () => {
        updatedUserCards && setUserCardList(updatedUserCards);
        setEditingCard({} as UserCard);
    }, [updatedUserCards] );

    const menuHandler = (option: CardMenu, {iconText, description, name, url, appId}: UserCard) => {
        switch(option.key) {
            case 'LEARN_MORE':
                showDialog('LEARN_MORE', {
                    iconColor: "#000",
                    iconText,
                    name,
                    description,
                    isEnabled: true,
                    showDescription: true,
                });
                break;

            case 'EDIT_CARD':
                setEditingCard( { name, description, url, appId } );
                setDialogOpen(true);
                break;

            case 'DELETE_CARD':
                setMenuCard({iconText, description, name, url, appId});
                setShowDeleteConfirm(true);
                break;
        }
    }

    const cardSaveHandler = (card: UserCard) => {
        if(card.appId) {
            updateUserCard(card);
            const updatedCards = userCardList?.apps?.map((app) => {
                if(card.appId === app.appId) {
                    return card;
                }
                return app;
            });
            setUserCardList({ loginId: '', apps: updatedCards});
        } else {
            saveUserCard(card);
            trackEvent(AMPLITUDE_EVENTS.USER_GENERATED_CARD);
        }
        setDialogOpen(false);
        setEditingCard(null);
    }

    const onDeleteConfirm = () => {
        deleteUserCard( menuCard as UserCard );
        const updatedCards = userCardList?.apps?.filter( (app) => app.appId !== menuCard?.appId );
        setUserCardList({ loginId: '', apps: updatedCards});
         setMenuCard(null);
        setShowDeleteConfirm(false);
    }

    return (
        <>
        { userCustomCardsFeature && 
            <UserCardsContainer>
                <WorkflowContainer isExpanded={isExpanded} darkMode={darkMode} closedHeight={"36px"}>
                <UserCardsHeader>
                    <span style={{paddingRight: "20px"}}>{ t('myUnifyTab.myCards') } </span> 
                    <CardButton
                        darkMode={darkMode}
                        onClick={() => setDialogOpen(true) }
                        rel="noreferrer"
                        target="_blank"
                        style={
                          { color: darkMode ? 'white' : 'black',  marginLeft: '10px' }
                        }
                        text={t('userCardForm.createNew')}
                        data-testid="btn-user-card"
                    />
                    <RoundedIconButton
                        dataTestId="expand-user-cards"
                        icon={
                        isExpanded ? (
                            <IconExpandLess />
                        ) : (
                            <IconExpandMore />
                        )
                        }
                        style={{ marginLeft: 'auto' }}
                        darkMode={darkMode}
                        onClick={() => setIsExpanded(!isExpanded)}
                        text=""
                    />
                </UserCardsHeader>
                <UserCardsList userCardList={userCardList || { }} menuOptions={menuOptions} menuHandler={menuHandler}/>
                <UserCardForm 
                    cardData={editingCard || {}} 
                    closeHandler={() => { setEditingCard(null); setDialogOpen(false) }} 
                    showDialog={dialogOpen} 
                    saveHandler={cardSaveHandler} />
                <CardDeleteDialog 
                    isOpen={showDeleteConfirm} 
                    onCancel={() => setShowDeleteConfirm(false)} 
                    onConfirm={ onDeleteConfirm }  />  
                 </WorkflowContainer>  
            </UserCardsContainer>
        }
        </>
    )
}
