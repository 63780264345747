import moment from 'moment';

function getFormattedDate(date: any, time: string) {
    if (date && time) {
        const myDate = moment(
            date?.format('MM/DD/YYYY') +
            'T' +
            time,
            'MM/DD/YYYY hh:mm ZZ'
        );
        return myDate;
    }
    return null;
}

export function updateOutageModel(sideSheetData: any) {
    const createdDateTime = getFormattedDate(sideSheetData.createdDate, sideSheetData.createdTime);
    const applications = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.id) : null;
    const applicationNames = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.label) : null;
    const workflows = sideSheetData.targetWorkflows?.length > 0 ?
        sideSheetData.targetWorkflows.map((wflow: any) => wflow.id) : null;

    const model = {
        body: sideSheetData.body,
        caseId: sideSheetData.caseId,
        title: sideSheetData.title,
        target: {
            workflow: workflows,
            enterpriseIds: sideSheetData.targetEnterprises?.length > 0 ? sideSheetData.targetEnterprises.split(",") : null,
            application: applications,
        },
        externalUrl: sideSheetData.linkToLearnMore,
        subject: sideSheetData.subject,
        createdDate: createdDateTime,
        type: 'outage',
        source: 'unifyAdmin',
        application: applicationNames ? applicationNames.join(",") : '',
        startDate: sideSheetData.outageType === 'Planned' ?
            getFormattedDate(sideSheetData.startDate, sideSheetData.startTime) : null,
        endDate:
            getFormattedDate(sideSheetData.endDate, sideSheetData.endTime),
    };
    return model;
}


export const createOutageModel = (sideSheetData: any) => {

    const createdDateTime = getFormattedDate(sideSheetData.createdDate, sideSheetData.createdTime);
    const applications = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.id) : null;
    const applicationNames = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.label) : null;
    const workflows = sideSheetData.targetWorkflows?.length > 0 ?
        sideSheetData.targetWorkflows.map((wflow: any) => wflow.id) : null;

    const model = {
        body: sideSheetData.body,
        caseId: sideSheetData.caseId,
        title: sideSheetData.title,
        target: {
            workflow: workflows,
            enterpriseIds: sideSheetData.targetEnterprises?.length > 0 ? sideSheetData.targetEnterprises.split(",") : null,
            application: applications,
        },
        externalUrl: sideSheetData.linkToLearnMore,
        subject: sideSheetData.subject,
        createdDate: createdDateTime,
        type: 'outage',
        source: 'unifyAdmin',
        application: applicationNames ? applicationNames.join(",") : '',
        startDate: sideSheetData.outageType === 'Planned' ?
            getFormattedDate(sideSheetData.startDate, sideSheetData.startTime) : null,
        endDate: sideSheetData.outageType === 'Planned' ?
            getFormattedDate(sideSheetData.endDate, sideSheetData.endTime) : null,
    };
    return model;
}

export const createFeatureModel = (sideSheetData: any) => {
    const applications = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.id) : null;
    const applicationNames = sideSheetData.targetApplications?.length > 0 ?
        sideSheetData.targetApplications.map((app: any) => app.label) : null;
    const workflows = sideSheetData.targetWorkflows?.length > 0 ?
        sideSheetData.targetWorkflows.map((wflow: any) => wflow.id) : null;

    const model = {
        body: sideSheetData.body,
        title: sideSheetData.title,
        target: {
            workflow: workflows,
            enterpriseIds: sideSheetData.targetEnterprises?.length > 0 ?
                sideSheetData.targetEnterprises.split(",") : null,
            application: applications,
        },
        externalUrl: sideSheetData.linkToLearnMore,
        subject: sideSheetData.subject,
        createdDate: sideSheetData.createdDate,
        type: 'release',
        source: 'unifyAdmin',
        application: applicationNames ? applicationNames.join(",") : '',
        startDate:
            getFormattedDate(sideSheetData.startDate, sideSheetData.startTime),
        endDate:
            getFormattedDate(sideSheetData.endDate, sideSheetData.endTime),
    };
    return model;
}
