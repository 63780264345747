import moment from 'moment';

export function StatusDecorator(columnId: string, value: any) {
    if (columnId === 'active') {
        return value === true ? 'Active' : 'Inactive';
    } else if (
        columnId === 'createdDate' ||
        columnId === 'startDate' ||
        columnId === 'endDate'
    ) {
        return value
            ? moment(value)
                .local()
                .format('MM/DD/YYYY h:mm:ss A')
            : '';
    } else {
        return value;
    }
}
export function targetAppDecorator(appids: any, applications: any) {
    if (appids) {
        return applications && applications[0]
            .filter((app: any) => appids.includes(app.appId))
            .map((app: any) => (app.name)).join(", ");
    }
    return '';
};

export function targetWorkflowDecorator(wflowids: any, workflows: any) {
    if (wflowids) {
        return workflows && workflows[0]
            .filter((wflow: any) => wflowids.includes(wflow.workflowId))
            .map((wflow: any) => (wflow.name)).join(", ");
    }
    return '';
};
