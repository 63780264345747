export default function launchExternal(url: string) {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const launchType = urlParams.get('launchtype');
    const LAUNCH_TYPES = {
        MODAL: 'modal',
        TAB: 'tab'
    };
    const launchModal = () => {
        window.open(
        url,
        "unifytab",
        `left=0,top=200,width=${window.innerWidth},height=${window.innerHeight - 110}`);
    }
    const launchTab = () => {
        window.open(url);
    }

    if(launchType === LAUNCH_TYPES.MODAL) {
        launchModal();
        return true
    }
    else if(launchType === LAUNCH_TYPES.TAB) {
        launchTab();
        return true
    }
    return false;
}
